import { environment } from "./../../../../../environments/environment";
import { Component, Input, EventEmitter, Output } from "@angular/core";
@Component({
    selector: "app-verify-email-prompt",
    templateUrl: "./verify-email-prompt.component.html",
    styleUrls: ["./verify-email-prompt.component.scss"]
})
export class VerifyEmailPromptComponent {
    @Input() emailAddress: string;
    @Input() verifyEmailMessage: {
        error: boolean;
        message: string;
    };

    @Output() resend = new EventEmitter();

    readonly theme = environment.theme;

    constructor() {}
}
