<div class="subscription">
    <loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>

    <fx-container [fluid]="true">
        <fx-layout [columns]="24" [gutters]="false">
            <fx-layout-column [sizes]="{ mobile: '24' }">
                <fx-layout [columns]="24" [gutters]="true">
                    <fx-layout-column [sizes]="{ mobile: '24', tablet: '8', laptop: '8' }">
                        <div class="subscription-content" *ngIf="!currentPackage">
                            {{ (isMe$ | async) ? "You have no" : "They have no" }} {{ packageDisplayName }} active.
                        </div>
                        <div class="subscription-content" *ngIf="currentPackage">
                            <div class="subscription-icon-container">
                                <i class="las la-award"></i>
                            </div>
                            <div class="subscription-details">
                                <div *ngIf="errorMessage">{{ errorMessage }}</div>
                                <div *ngIf="!errorMessage">
                                    <p>{{ packageName }} package</p>
                                    <p class="subscription-description" [innerHTML]="packageDescription"></p>
                                </div>
                            </div>
                        </div>
                    </fx-layout-column>
                </fx-layout>
            </fx-layout-column>
            <!-- Should be able to append classes -->
            <!-- <fx-layout-column [sizes]="{ mobile: '24' }">
                <div class="subscription-button-container">
                    <button
                        *ngIf="activeSubscription"
                        (click)="cancelSubscription()"
                        class="fx-btn fx-btn--primary-outlined fx-btn--sm"
                    >
                        Cancel subscription
                    </button>
                    <button
                        *ngIf="!activeSubscription"
                        (click)="purchaseSubscription()"
                        class="fx-btn fx-btn--secondary fx-btn--sm"
                    >
                        BUY ${{ packageCost }}/MTH
                    </button>
                </div>
            </fx-layout-column> -->
        </fx-layout>
    </fx-container>
</div>
