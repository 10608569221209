import { Component, Inject } from "@angular/core";
import { ThreadsService } from "src/app/services/threads.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { IParticipant, IThread, SubjectType, IThreadCard } from "@findex/threads";
import { Loader } from "src/app/services/loader";
import { SigmaService } from "src/app/services/sigma.service";

@Component({
    selector: "thread-remove-participant",
    templateUrl: "./thread-remove-participant.component.html",
    styleUrls: ["./thread-remove-participant.component.scss"]
})
export class ThreadRemoveParticipantComponent {
    loader = new Loader();
    participant: IParticipant;
    private thread: IThread;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<ThreadRemoveParticipantComponent>,
        private threadsService: ThreadsService,
        private sigmaService: SigmaService
    ) {
        this.participant = data.participant;
        this.thread = data.thread;
    }

    async confirm() {
        this.loader.show();

        await this.threadsService.removeParticipant(this.thread.id, this.participant.id).toPromise();
        await this.removeFromDashboard(this.thread.id, this.participant.id);

        this.loader.hide();
        this.close();
    }

    close() {
        this.dialogRef.close();
    }

    private async removeFromDashboard(threadId: string, participantId: string): Promise<void> {
        const dashboardCard = await this.getDashboardCard(participantId);
        if (!dashboardCard) return;

        const { subjects, id: cardId } = dashboardCard;

        const subjectIndex = subjects.findIndex(
            subject => subject.id === threadId && subject.type === SubjectType.Thread
        );
        if (subjectIndex < 0) {
            return;
        }

        subjects.splice(subjectIndex, 1);
        await this.threadsService.updateCard(dashboardCard.id, cardId, subjects).toPromise();
    }

    private async getDashboardCard(participantId: string): Promise<IThreadCard> {
        const dashboardId = await this.sigmaService.getDashboard(participantId).toPromise();
        const cards = await this.threadsService.getCards(dashboardId).toPromise();

        return cards.find(card => card.type === SubjectType.Thread);
    }
}
