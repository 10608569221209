import { CognitoUser, CognitoUserSession, IMfaSettings, CognitoUserAttribute } from "amazon-cognito-identity-js";

export type SMS_CHALLENGE = {
    CODE_DELIVERY_DELIVERY_MEDIUM: "string";
    CODE_DELIVERY_DESTINATION: "string"; //masked phone number +*******7315
};

export const refreshSession = (cognitoUser: CognitoUser): Promise<CognitoUserSession> => {
    return new Promise((resolve, reject) => {
        const currentSession = cognitoUser.getSignInUserSession();
        cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, result) => {
            if (err) return reject(err);
            return resolve(result);
        });
    });
};

export const getUserAttributes = (cognitoUser: CognitoUser): Promise<CognitoUserAttribute[]> => {
    return new Promise((resolve, reject) => {
        cognitoUser.getUserAttributes((err, result) => {
            if (err) return reject(err);
            return resolve(result);
        });
    });
};

export const logout = (cognitoUser: CognitoUser) => {
    return new Promise((resolve, reject) => {
        cognitoUser.globalSignOut({
            onSuccess: result => {
                return resolve(result);
            },
            onFailure: err => {
                return reject(err);
            }
        });
    });
};

export const sendCustomChallengeAnswer = (cognitoUser: CognitoUser, answerChallenge: string) => {
    return new Promise((resolve, reject) => {
        cognitoUser.sendCustomChallengeAnswer(answerChallenge, {
            onFailure: err => {
                return reject(err);
            },
            onSuccess: result => {
                return resolve(result);
            }
        });
    });
};

export const sendMFACode = (cognitoUser: CognitoUser, code: string): Promise<CognitoUserSession> => {
    return new Promise((resolve, reject) => {
        cognitoUser.sendMFACode(code, {
            onSuccess: result => {
                resolve(result);
            },
            onFailure: result => {
                reject(result);
            }
        });
    });
};

export const setDeviceStatusRemembered = (cognitoUser: CognitoUser) => {
    return new Promise((resolve, reject) => {
        cognitoUser.setDeviceStatusRemembered({
            onSuccess: result => {
                return resolve(result);
            },
            onFailure: result => {
                return reject(result);
            }
        });
    });
};

export const setDeviceStatusNotRemembered = (cognitoUser: CognitoUser) => {
    return new Promise((resolve, reject) => {
        cognitoUser.setDeviceStatusNotRemembered({
            onSuccess: result => {
                return resolve(result);
            },
            onFailure: err => {
                return reject(err);
            }
        });
    });
};

export const enableMFA = (cognitoUser: CognitoUser) => {
    return new Promise((resolve, reject) => {
        cognitoUser.enableMFA((err, result) => {
            if (err) return reject(err);
            return resolve(result);
        });
    });
};

export const setUserMfaPreference = (
    cognitoUser: CognitoUser,
    smsMfa: IMfaSettings,
    softwareTokenMfa: IMfaSettings
) => {
    return new Promise((resolve, reject) => {
        cognitoUser.setUserMfaPreference(smsMfa, softwareTokenMfa, (err, result) => {
            if (err) return reject(err);
            return resolve(result);
        });
    });
};

export const getMFAOptions = (cognitoUser: CognitoUser) => {
    return new Promise((resolve, reject) => {
        cognitoUser.getMFAOptions((err, result) => {
            if (err) return reject(err);
            return resolve(result);
        });
    });
};

export const listDevices = (cognitoUser: CognitoUser, limit: number, paginationToken: string) => {
    return new Promise((resolve, reject) => {
        cognitoUser.listDevices(limit, paginationToken, {
            onFailure: err => {
                return reject(err);
            },
            onSuccess: result => {
                return resolve(result);
            }
        });
    });
};

/** utility function to get deviceKey from storage */
export const getDevice = (cognitoUser: any): string | null => {
    if (!cognitoUser.storage) {
        return null;
    }
    const storage = cognitoUser.storage;
    const entries = Object.entries(storage);
    const deviceKey = entries.filter(([key]) => key.includes("deviceKey"));
    if (deviceKey.length && deviceKey[0] && deviceKey[0][1] && typeof deviceKey[0][1] === "string") {
        return deviceKey[0][1];
    }

    return null;
};

export const rememberDevice = (cognitoUser: CognitoUser) => {
    const deviceKey = getDevice(cognitoUser);
    if (deviceKey) {
        (cognitoUser as any).deviceKey = deviceKey;
        return setDeviceStatusRemembered(cognitoUser);
    }

    return Promise.resolve("no device found");
};

export const forgetDevice = (cognitoUser: CognitoUser) => {
    const deviceKey = getDevice(cognitoUser);
    if (deviceKey) {
        (cognitoUser as any).deviceKey = deviceKey;
        return setDeviceStatusNotRemembered(cognitoUser);
    }

    return Promise.resolve("no device found");
};
