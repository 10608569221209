import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { IParticipant } from "@findex/threads";
import { ParticipantCache } from "../../services/participant-cache.service";
import { trigger, transition, animate, style } from "@angular/animations";

@Component({
    selector: "user-detail-card",
    templateUrl: "./user-detail-card.component.html",
    styleUrls: ["./user-detail-card.component.scss"],
    animations: [
        trigger("fadeIn", [
            transition(":enter", [style({ opacity: 0 }), animate("100ms", style({ opacity: 1 }))]),
            transition(":leave", [animate("100ms", style({ opacity: 0 }))])
        ])
    ]
})
export class UserDetailCardComponent implements OnInit {
    @Input() userId: string;
    @Input() meetingStart: string;
    @Input() currentUserId: string;

    user$: Observable<IParticipant>;
    constructor(private participantCacheService: ParticipantCache) {}

    ngOnInit() {
        this.user$ = this.participantCacheService.getParticipant(this.userId);
    }
}
