import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FxUiModule } from "@findex/fx-ui";
import { MatInputModule, MatFormFieldModule, MatDialogModule } from "@angular/material";
import { MessageEventComponent } from "./components/message-event/message-event.component";
import { CreateMessageComponent } from "./components/create-message/create-message.component";
import { ThreadsUiModule } from "../../threads-ui";

@NgModule({
    imports: [CommonModule, MatInputModule, MatFormFieldModule, FxUiModule, ThreadsUiModule, MatDialogModule],
    declarations: [MessageEventComponent, CreateMessageComponent],
    entryComponents: [MessageEventComponent, CreateMessageComponent]
})
export class MessageCardModule {}
