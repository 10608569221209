import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

declare let gtag: Function;

const { trackingId, tagManagerId } = environment.analytics.google;

@Injectable({ providedIn: "root" })
export class AnalyticsService {
    recordEvent(category: string, action: string, label?: string, value?: number) {
        if (tagManagerId && window["dataLayer"]) {
            window["dataLayer"].push({
                event: "app.event",
                event_action: action,
                event_category: category,
                event_label: label,
                event_value: value
            });
        } else if (trackingId && window["dataLayer"]) {
            gtag("event", action, {
                event_category: category,
                event_label: label,
                value: value
            });
        }
    }

    pageViewed(path: string) {
        if (tagManagerId && window["dataLayer"]) {
            window["dataLayer"].push({
                event: "app.pageview",
                pageview_path: path
            });
        } else if (trackingId) {
            gtag("config", environment.analytics.google.trackingId, {
                page_path: path
            });
        }
    }
}
