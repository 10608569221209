import { Pipe, PipeTransform } from "@angular/core";
import { IParticipant } from "@findex/threads";

@Pipe({ name: "threadParticipants" })
export class ThreadParticipantsPipe implements PipeTransform {
    transform(participants: IParticipant[], role: string) {
        if (!role || !participants) return null;

        if (role === "Staff" || role === "Client") {
            const items = participants
                .filter(val => val.role === role)
                .map(val => {
                    return val.profile.name;
                });
            return items.join(", ");
        }
        return null;
    }
}
