import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { filter, take, switchMap } from "rxjs/operators";
import { AuthService } from "src/modules/findex-auth";

export interface ISocketEvent {
    threadId: string;
    cardId: string;
    eventKey: string;
    state?: boolean;
}
export interface IWsNotificationMessage {
    subjectType: string;
    eventType: string;
    threadId: string;
    actorId: string;
    cardId?: string;
    eventId?: string;
    eventKey?: string;
    state?: boolean;
}

@Injectable({ providedIn: "root" })
export class WebsocketService implements OnDestroy {
    private reconnectDelay = 1000;
    private reconnect = true;
    private socket: WebSocket;
    private subject = new Subject<ISocketEvent>();

    constructor(private authService: AuthService) {
        this.createSocket();
    }

    connect(threadId: string): Observable<ISocketEvent> {
        return this.subject.pipe(filter(event => event.threadId === threadId));
    }

    ngOnDestroy() {
        this.subject.complete();
        this.reconnect = false;

        if (this.socket) {
            this.socket.close();
        }
    }

    private waitForAuthHeaders(): Observable<any> {
        return this.authService.getUser().pipe(
            filter(user => !!user),
            take(1),
            switchMap(() => this.authService.getHttpHeaders())
        );
    }

    private async createSocket() {
        const headers = await this.waitForAuthHeaders().toPromise();
        if (!headers) return;
        const queryString = Object.entries(headers)
            .map(([key, val]) => `${key}=${encodeURIComponent(val as string)}`)
            .join("&");

        const url = `${environment.threadsWebsockets}?${queryString}`;
        this.socket = new WebSocket(url);

        this.socket.onopen = this.socketOpened;
        this.socket.onmessage = this.socketMessage;
        this.socket.onerror = this.socketError;
        this.socket.onclose = this.socketClosed;
    }

    private socketClosed = (event: CloseEvent) => {
        console.info("Socket closed", event.code);

        if (!this.reconnect) return;
        setTimeout(() => this.createSocket(), this.reconnectDelay);
    };

    private socketOpened = (_event: any) => {
        console.info("Socket opened");
    };

    private socketMessage = async (event: MessageEvent) => {
        try {
            const data: IWsNotificationMessage = JSON.parse(event.data);
            const { threadId, cardId, eventKey, state } = data;
            if (!threadId) {
                return console.log("Unknown event", data);
            }

            this.subject.next({ threadId, cardId, eventKey, state });
        } catch (err) {
            console.error("Error processing event", event, err);
        }
    };

    private socketError = (event: any) => {
        console.log("socket error", event);
        this.socket.close();
    };
}
