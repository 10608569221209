import { Component, OnInit } from "@angular/core";
import { ProductTypes, StaffService } from "../../services/staff.service";
import { SigmaService } from "../../../../app/services/sigma.service";
import { Router } from "@angular/router";
import { Loader } from "src/app/services/loader";
import { environment } from "../../../../environments/environment";
import { AnalyticsService } from "src/modules/analytics";
import { OnboardingCompletionStatus } from "src/modules/onboarding/components/onboarding-completion/onboarding-completion.component";
import { AuthService } from "src/modules/findex-auth";
import { map } from "rxjs/internal/operators/map";
import { OnboardingService } from "src/modules/onboarding/services/onboarding.service";
import { take } from "rxjs/operators";
import { CalendarModalComponent } from "src/modules/card-modules/calendar-card/components/calendar-modal/calendar-modal.component";
import { MatDialog } from "@angular/material";
import { ISlot } from "@findex/fx-ui/lib/components/calendar/calendar";
import { CalendarService } from "@findex/calendar";
import { IStaffProfile } from "@findex/threads";

@Component({
    selector: "app-select-bookkeeper",
    templateUrl: "./select-bookkeeper.component.html",
    styleUrls: ["./select-bookkeeper.component.scss"]
})
export class SelectBookkeeperComponent implements OnInit {
    userId: string;
    constructor(
        private staffService: StaffService,
        private sigmaService: SigmaService,
        private router: Router,
        private analyticsService: AnalyticsService,
        private authService: AuthService,
        private onboardingService: OnboardingService,
        private dialog: MatDialog,
        private calendarService: CalendarService
    ) {}

    bookkeepers: IStaffProfile[];
    error: string;
    steps = ["Business name", "Industry type", "Meet your bookkeeper"];
    activeStep = "Meet your bookkeeper";
    readonly theme = environment.theme;

    loader = new Loader();

    getFirstName(staffProfile: IStaffProfile) {
        const parts = staffProfile.name.split(" ");
        return parts.length > 0 ? parts[0] : "advisor";
    }

    async ngOnInit() {
        this.loader.show();
        switch (environment.featureFlags.onboardingType) {
            case "none":
                this.router.navigateByUrl("/dashboard");
                return;
            case "sameday":
                this.router.navigateByUrl("/select-staff");
                return;
            case "sigma":
                try {
                    this.staffService.fetchStaff({ product: ProductTypes.Bookkeeping }).subscribe(result => {
                        this.bookkeepers = result.matchingStaff;
                    });
                    this.userId = await this.authService
                        .getUser()
                        .pipe(
                            map(user => user.id),
                            take(1)
                        )
                        .toPromise();
                    this.checkUserBookkeeperStatus();
                } catch (err) {
                    this.error = "An error occurred, please try again";
                } finally {
                    this.loader.hide();
                }
                break;
        }
    }

    async checkUserBookkeeperStatus() {
        const onboardingDetails = await this.onboardingService.getCompletionDetails(this.userId).toPromise();
        if (onboardingDetails && onboardingDetails.selectedStaff) {
            this.router.navigateByUrl("/dashboard");
        }
    }

    async requestChat(bookkeeper: IStaffProfile, type: string) {
        this.error = null;

        this.loader.show();

        try {
            const { userId } = bookkeeper;
            const createOnboarding$ = this.sigmaService.createOnboarding(userId, type === "call");
            const { thread, appointmentId } = await this.loader.wrap(createOnboarding$).toPromise();

            await this.onboardingService
                .updateCompletionDetails(this.userId, { selectedStaff: bookkeeper })
                .toPromise();

            if (type !== "call") {
                this.router.navigate(["timelines", thread.id]);
                return;
            }

            this.router.navigate(["/dashboard"]);
            await this.openCalendarModal(appointmentId, bookkeeper);
        } catch (err) {
            this.error = "An error occurred, please try again";
            throw err;
        } finally {
            this.loader.hide();
        }
    }

    async openCalendarModal(invitationId: string, bookkeeper: IStaffProfile) {
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: {
                invitationId: invitationId,
                staffParticipant: bookkeeper
            }
        };

        const booking = await this.dialog
            .open<CalendarModalComponent, any, ISlot>(CalendarModalComponent, options)
            .afterClosed()
            .toPromise();

        if (booking) {
            await this.bookMeeting(invitationId, booking);
        }
    }

    goBack() {
        this.analyticsService.recordEvent("select-bookkeeper", "go-back");
        this.router.navigate(["/register/completion"], {
            queryParams: { status: OnboardingCompletionStatus.INDUSTRY_TYPE }
        });
    }

    private async bookMeeting(invitationId: string, slot: ISlot) {
        this.loader.show();

        try {
            await this.calendarService.setAppointment(invitationId, slot.start, slot.end).toPromise();
        } finally {
            this.loader.hide();
        }
    }
}
