import { Component, OnDestroy, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { combineLatest, Observable, of, Subscription, throwError, zip } from "rxjs";
import { UserProfileService } from "../../services/user-profile.service";
import { catchError, distinctUntilChanged, map, shareReplay, switchMap, take, tap, filter } from "rxjs/operators";
import { User } from "../../../findex-auth/model/User";
import { Loader } from "src/app/services/loader";
import { OnboardingService } from "../../../onboarding/services/onboarding.service";
import { isEqual } from "lodash";
import { MatDialog } from "@angular/material/dialog";
import { IndustryDialogComponent } from "../industry-dialog/industry-dialog.component";
import { BUSINESS_INDUSTRY_NAMES_BY_VALUE } from "../../../shared/constants/IndustryTypes";
import { ActivatedRoute } from "@angular/router";
import { ChangePasswordDialogComponent } from "../change-password-dialog/change-password-dialog.component";
import { AuthService } from "src/modules/findex-auth";
import { Role } from "@findex/threads";
import { ThreadsService } from "src/app/services/threads.service";
import { environment } from "../../../../environments/environment";
import { HandledError } from "src/app/services/sentry-error-handler";
import { AdminSetPasswordDialog } from "../admin-set-password-dialog/admin-set-password-dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CustomValidators } from "../../../shared/validators";
import { ConfirmMobileDialogComponent } from "../confirm-mobile-dialog/confirm-mobile-dialog.component";

@Component({
    selector: "app-user-profile",
    styleUrls: ["./user-profile.component.scss"],
    templateUrl: "./user-profile.component.html"
})
export class UserProfileComponent implements OnInit, OnDestroy {
    errorMessage: string = "";

    readonly theme = environment.theme;
    readonly signupCountries = environment.featureFlags.signupCountries;

    private originalProfile: User;
    //TODO improve the types
    private originalOnboardingData: any;

    private errorSubscription: Subscription;
    private profileSubscription: Subscription;

    private currentUserRole: Role;
    private userId: string;

    Role = Role;
    myRole$: Observable<Role>;
    roleOptions = Object.entries(Role).filter(([, val]) => val !== Role.Client);
    originalProfileRole: string;
    profileRole: string;
    isClientSearch$: Observable<boolean>;
    showChangePassword$: Observable<boolean>;

    internationalPhoneNo: string;
    internationalPhoneNoValid: boolean;

    loader = new Loader();

    readonly simpleProfile = environment.featureFlags.simpleProfile;
    readonly onboardingType = environment.featureFlags.onboardingType;

    form = new FormGroup({
        fullName: new FormControl(null, [Validators.required]),
        emailAddress: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.email]),
        mobileNumber: new FormControl({ value: null, disabled: true }, [
            Validators.required,
            CustomValidators.mobileNumberValidator
        ])
    });

    sigmaForm = new FormGroup({
        businessName: new FormControl(""),
        industryTypes: new FormControl([])
    });

    //Same day tax specific
    sameDayTaxForm = new FormGroup({
        dateOfBirth: new FormControl(null, [CustomValidators.dateValidator("dd/MM/yyyy")]),
        taxFileNumber: new FormControl(null, [CustomValidators.taxFileNumberValidator]),
        bankName: new FormControl(null, []),
        accountName: new FormControl(null, []),
        accountBsb: new FormControl(null, [Validators.pattern(/^\d+$/)]),
        accountNumber: new FormControl(null, [Validators.pattern(/^\d+$/)]),
        isReturningCustomer: new FormControl(null, []),
        idType: new FormControl(null, []),
        idNumber: new FormControl(null, []),
        idExpiry: new FormControl(null, [CustomValidators.dateValidator("dd/MM/yyyy")])
    });

    constructor(
        private userProfileService: UserProfileService,
        private onboardingService: OnboardingService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private threadsService: ThreadsService,
        private dialog: MatDialog,
        private location: Location
    ) {}

    back() {
        this.location.back();
    }

    ngOnInit(): void {
        this.errorMessage = "";

        this.errorSubscription = this.route.queryParams.subscribe(queryParams => {
            const { errorMessage } = queryParams;
            if (errorMessage) {
                this.errorMessage = errorMessage;
            }
        });

        const userId$ = this.route.params.pipe(
            map(params => params.userId),
            distinctUntilChanged()
        );

        const userProfile$ = userId$.pipe(
            switchMap(userId => {
                this.userId = userId;
                return this.getProfile(userId);
            }),
            catchError(error => this.handleError(error))
        );

        const onboardingData$ = userId$.pipe(
            switchMap(userId => this.userIdOrMe(userId)),
            tap(userId => this.refreshProfileRole(userId)),
            switchMap(userId => this.loader.wrap(this.getOnboardingData(userId)))
        );

        //TODO it'd probably be good to combine these two things into one backend service at some point
        this.profileSubscription = zip(userProfile$, onboardingData$).subscribe(([user, onboardingData]) => {
            if (user) {
                this.extractFieldsFromUserProfile(user);
            }
            if (onboardingData) {
                this.extractFieldsFromOnboardingData(onboardingData);
            }
        });

        this.myRole$ = this.authService.getUser().pipe(
            filter(user => !!user),
            switchMap(user => this.threadsService.getGlobalRole(user.id)),
            tap(role => (this.currentUserRole = role)),
            shareReplay(1)
        );

        this.isClientSearch$ = this.route.url.pipe(
            map(urlSegmentArray => urlSegmentArray.find(urlSegment => urlSegment.path === "clients") !== undefined)
        );

        this.showChangePassword$ = combineLatest(this.myRole$, this.isClientSearch$).pipe(
            map(([role, isClientSearch]) => !(role === Role.Staff && isClientSearch))
        );
    }

    ngOnDestroy() {
        if (this.errorSubscription) this.errorSubscription.unsubscribe();
        if (this.profileSubscription) this.profileSubscription.unsubscribe();
    }

    private getOnboardingData(userId: string) {
        switch (this.onboardingType) {
            case "sigma":
                return this.onboardingService.getCompletionDetails(userId);
            case "sameday":
                return this.userProfileService.getPrivateProfileDetails(userId);
        }
        return of(undefined);
    }

    private extractFieldsFromUserProfile(user: User) {
        this.originalProfile = user;
        this.form.controls.fullName.setValue(user.name);
        if (user.details) {
            const mobileNumber = user.details.mobileNumber || "";
            this.form.controls.mobileNumber.setValue(mobileNumber);
            const emailAddress = user.details.emailAddress || "";
            this.form.controls.emailAddress.setValue(emailAddress.toLowerCase());
        } else {
            this.errorMessage = "Profile for user could not be found";
        }
    }

    private extractFieldsFromRoleData(role) {
        this.originalProfileRole = role;
    }

    private extractFieldsFromOnboardingData(onboardingData: any) {
        this.originalOnboardingData = onboardingData || {};
        if (this.onboardingType === "sigma") {
            const { controls } = this.sigmaForm;
            controls.businessName.setValue(this.originalOnboardingData.businessName);
            controls.industryTypes.setValue([...this.originalOnboardingData.industryTypes]);
        }
        if (this.onboardingType === "sameday") {
            const { controls } = this.sameDayTaxForm;
            controls.dateOfBirth.setValue(this.originalOnboardingData.dateOfBirth || "");
            controls.taxFileNumber.setValue(this.originalOnboardingData.taxFileNumber || "");
            controls.bankName.setValue(this.originalOnboardingData.bankName || "");
            controls.accountName.setValue(this.originalOnboardingData.accountName || "");
            controls.accountBsb.setValue(this.originalOnboardingData.accountBsb || "");
            controls.accountNumber.setValue(this.originalOnboardingData.accountNumber || "");
            controls.isReturningCustomer.setValue(
                this.originalOnboardingData.isReturningCustomer == null
                    ? null
                    : this.originalOnboardingData.isReturningCustomer
            );
            controls.idType.setValue(this.originalOnboardingData.idType || null);
            controls.idNumber.setValue(this.originalOnboardingData.idNumber || "");
            controls.idExpiry.setValue(this.originalOnboardingData.idExpiry || "");
        }
    }

    refreshProfileRole(userId: string) {
        const refreshRole$ = this.threadsService.getGlobalRole(userId);
        this.loader.wrap(refreshRole$).subscribe(role => {
            this.originalProfileRole = role;
            this.profileRole = role;
        });
    }

    setProfileRole(role: Role | "null") {
        if (role === "null") role = null; //Binding value = null still gives us a string
        this.profileRole = role;
    }

    saveChanges() {
        this.errorMessage = "";
        //TODO better validation
        this.commitChanges();
    }

    private getChangedRoleProperties() {
        const role =
            this.originalProfileRole !== this.profileRole
                ? {
                      role: this.profileRole
                  }
                : undefined;

        return role;
    }

    private getChangedUserProperties() {
        const profile = this.originalProfile;
        const profileDetails = this.originalProfile.details;
        if (!profile || !profileDetails) {
            return {};
        }

        const formFullname = this.form.controls.fullName.value;
        const name = formFullname !== profile.name ? formFullname : undefined;

        const formEmailAddress = this.form.controls.emailAddress.value;
        const profileDetailEmail = profileDetails.emailAddress || "";
        const emailAddress =
            formEmailAddress.toLowerCase() !== profileDetailEmail.toLowerCase()
                ? formEmailAddress.toLowerCase()
                : undefined;

        const formMobileNumber = this.form.controls.mobileNumber.value;
        const profileMobileNumber = profileDetails.mobileNumber || "";
        const mobileNumber =
            formMobileNumber.toLowerCase() !== profileMobileNumber.toLowerCase()
                ? formMobileNumber.toLowerCase()
                : undefined;

        const details = {
            name,
            emailAddress,
            mobileNumber
        };
        return Object.keys(details).reduce((acc, key) => {
            const value = details[key];
            return value ? { ...acc, [key]: value } : acc;
        }, {});
    }

    private getChangedOnboardingProperties() {
        let details = {};
        if (this.onboardingType === "sigma") {
            const { controls } = this.sigmaForm;
            const businessName =
                controls.businessName.value !== this.originalOnboardingData.businessName
                    ? controls.businessName.value
                    : undefined;
            const industryTypes = !isEqual(controls.industryTypes.value, this.originalOnboardingData.industryTypes)
                ? controls.industryTypes.value
                : undefined;

            details = {
                businessName,
                industryTypes
            };
        }
        if (this.onboardingType === "sameday") {
            const controls = this.sameDayTaxForm.controls;

            const dateOfBirth =
                controls.dateOfBirth.value !== this.originalOnboardingData.dateOfBirth
                    ? controls.dateOfBirth.value
                    : undefined;
            const taxFileNumber =
                controls.taxFileNumber.value !== this.originalOnboardingData.taxFileNumber
                    ? controls.taxFileNumber.value
                    : undefined;
            const bankName =
                controls.bankName.value !== this.originalOnboardingData.bankName ? controls.bankName.value : undefined;
            const accountName =
                controls.accountName.value !== this.originalOnboardingData.accountName
                    ? controls.accountName.value
                    : undefined;
            const accountBsb =
                controls.accountBsb.value !== this.originalOnboardingData.accountBsb
                    ? controls.accountBsb.value
                    : undefined;
            const accountNumber =
                controls.accountNumber.value !== this.originalOnboardingData.accountNumber
                    ? controls.accountNumber.value
                    : undefined;
            const isReturningCustomer =
                controls.isReturningCustomer.value !== this.originalOnboardingData.isReturningCustomer
                    ? controls.isReturningCustomer.value
                    : undefined;
            const idType = controls.value !== this.originalOnboardingData.idType ? controls.idType.value : undefined;
            const idNumber =
                controls.value !== this.originalOnboardingData.idNumber ? controls.idNumber.value : undefined;
            const idExpiry =
                controls.value !== this.originalOnboardingData.idExpiry ? controls.idExpiry.value : undefined;
            details = {
                dateOfBirth,
                taxFileNumber,
                bankName,
                accountName,
                accountBsb,
                accountNumber,
                isReturningCustomer,
                idType,
                idNumber,
                idExpiry
            };
        }
        return Object.keys(details).reduce((acc, key) => {
            const value = details[key];
            return value || value === false ? { ...acc, [key]: value } : acc;
        }, {});
    }

    private userIdOrMe(userId?: string): Observable<string> {
        if (userId) {
            return of(userId);
        } else {
            return this.authService.getUser().pipe(
                take(1),
                map(user => user.id)
            );
        }
    }

    private getProfile(userId: string): Observable<User> {
        if (userId) {
            return this.loader.wrap(this.userProfileService.getUserProfile(userId));
        } else {
            return this.loader.wrap(this.userProfileService.getCurrentUserProfile());
        }
    }

    private updateProfile(userId: string, changedUserProperties: any): Observable<{ updatedUser: User }> {
        const originalId = this.originalProfile.id;
        if (userId) {
            return this.userProfileService
                .updateUserProfile(userId, changedUserProperties)
                .pipe(map(result => ({ updatedUser: { ...result.updatedUser, id: originalId } })));
        } else {
            return this.userProfileService
                .updateCurrentUserProfile(changedUserProperties)
                .pipe(map(result => ({ updatedUser: { ...result.updatedUser, id: originalId } })));
        }
    }

    private async commitChanges() {
        this.loader.show();

        const changedUserProperties = this.getChangedUserProperties();

        try {
            if (Object.keys(changedUserProperties).length > 0) {
                const userId = this.route.snapshot.params.userId;
                const updatedProfileDetails = await this.updateProfile(userId, changedUserProperties).toPromise();
                this.extractFieldsFromUserProfile(updatedProfileDetails.updatedUser);
            }

            const changedOnboardingProperties = this.getChangedOnboardingProperties();

            if (Object.keys(changedOnboardingProperties).length > 0) {
                if (this.onboardingType === "sigma") {
                    const controls = this.sigmaForm.controls;
                    await this.onboardingService
                        .updateCompletionDetails(this.originalProfile.id, {
                            businessName: controls.businessName.value,
                            industryTypes: controls.industryTypes.value
                        })
                        .toPromise();
                }
                if (this.onboardingType === "sameday") {
                    const controls = this.sameDayTaxForm.controls;
                    await this.userProfileService
                        .updatePrivateProfileInfo(this.originalProfile.id, {
                            dateOfBirth: controls.dateOfBirth.value,
                            taxFileNumber: controls.taxFileNumber.value,
                            bankName: controls.bankName.value,
                            accountName: controls.accountName.value,
                            accountBsb: controls.accountBsb.value,
                            accountNumber: controls.accountNumber.value,
                            isReturningCustomer: controls.isReturningCustomer.value,
                            idType: controls.idType.value,
                            idNumber: controls.idNumber.value,
                            idExpiry: controls.idExpiry.value
                        })
                        .toPromise();
                }
                const onboardingUpdate = await this.getOnboardingData(this.originalProfile.id).toPromise();
                this.extractFieldsFromOnboardingData(onboardingUpdate);
            }

            if (this.originalProfileRole !== this.profileRole) {
                const role = await this.threadsService
                    .putGlobalRole(this.originalProfile.id, this.profileRole as Role)
                    .toPromise();

                this.extractFieldsFromRoleData(role);
            }
        } catch (error) {
            this.handleError(error);
        } finally {
            this.loader.hide();
            // TODO: this is broken. Disable for now
            // if (!this.originalProfile.mobileNumberVerified) {
            //     this.showConfirmMobileDialog(this.originalProfile.details.mobileNumber);
            // }
        }
    }

    showConfirmMobileDialog(mobileNumber: string) {
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            minWidth: "100%",
            data: {
                mobileNumber,
                codeSent: true
            }
        };
        this.dialog
            .open(ConfirmMobileDialogComponent, options)
            .afterClosed()
            .pipe(
                switchMap(() => {
                    this.loader.show();
                    //We need to update the profile in case the user changed their mobile
                    return this.getProfile(this.userId);
                })
            )
            .subscribe(user => {
                this.extractFieldsFromUserProfile(user);
                this.loader.hide();
            });
    }

    showResetPasswordDialog() {
        const options = {
            disableClose: true,
            panelClass: ["modal-container", "mat-dialog-no-styling"],
            maxWidth: "100%",
            minWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: { userId: this.userId }
        };

        if (this.currentUserRole) {
            return this.dialog.open(AdminSetPasswordDialog, options);
        } else {
            return this.dialog.open(ChangePasswordDialogComponent, options);
        }
    }

    private handleError(error: any): Observable<any> {
        if (error.error && error.error.message) {
            this.errorMessage = error.error.message;
        } else {
            this.errorMessage = "Could not update the user profile";
        }

        return throwError(new HandledError(error));
    }

    saveShouldBeEnabled() {
        if (!this.originalProfile) {
            return false;
        }
        const changedProperties = {
            ...this.getChangedUserProperties(),
            ...this.getChangedOnboardingProperties(),
            ...this.getChangedRoleProperties()
        };
        if (this.onboardingType === "sigma") {
            if (!this.sigmaForm.valid) {
                return false;
            }
        }
        if (this.onboardingType === "sameday") {
            if (!this.sameDayTaxForm.valid) {
                return false;
            }
        }
        return Object.keys(changedProperties).length > 0;
    }

    //Sigma-specific
    showIndustryTypeModal() {
        const { controls } = this.sigmaForm;
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container", "threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: {
                industryTypes: [...controls.industryTypes.value]
            }
        };

        return this.dialog
            .open(IndustryDialogComponent, options)
            .afterClosed()
            .pipe(
                switchMap((result: { industryTypes: string[]; save: boolean }) => {
                    if (result.save === true && !isEqual(controls.industryTypes.value, result.industryTypes)) {
                        return of(result.industryTypes);
                    } else {
                        return of(undefined);
                    }
                })
            )
            .subscribe((updatedIndustryTypes: string[] | undefined) => {
                if (updatedIndustryTypes) {
                    controls.industryTypes.setValue([...updatedIndustryTypes]);
                }
            });
    }

    getIndustryTypeName(industryType: string) {
        return BUSINESS_INDUSTRY_NAMES_BY_VALUE[industryType] || "Unknown";
    }

    removeIndustryType(index: number) {
        const { controls } = this.sigmaForm;
        const updated = controls.industryTypes.value.splice(index, 1);
        controls.industryTypes.setValue(updated);
    }
}
