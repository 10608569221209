<loader-icon *ngIf="showLoader" [color]="'light'"></loader-icon>
<div
    class="fx-onboarding fx-onboarding--high-logo fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }} sdtr-bg-mobile"
>
    <div class="fx-onboarding-header fx-onboarding-header--back">
        <div class="fx-brand"></div>
    </div>

    <div class="fx-onboarding-container fx-container">
        <div class="fx-onboarding-content two-factor">
            <h1 class="fx-onboarding-title">Security code</h1>
            <p class="fx-onboarding-body">
                Please enter the code we sent to your mobile.
            </p>
            <form class="fx-onboarding-form fx-form" [ngClass]="['fx-form--' + theme.formTheme]" [formGroup]="form">
                <div class="fx-form-group" *ngIf="errorMessage">
                    <div class="fx-form-validation-panel">
                        <div class="fx-form-validation-panel-item fx-onboarding-form-error">{{ errorMessage }}</div>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="verifyCode">Pin</label>
                    <div class="fx-form-control fx-form-control--inline-button">
                        <input
                            autocomplete="one-time-code"
                            formControlName="verifyCode"
                            class="fx-form-input"
                            name="verifyCode"
                            placeholder="PIN"
                            type="text"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['verifyCode'].valid && form.controls['verifyCode'].touched
                            }"
                        />
                        <button
                            type="button"
                            class="fx-form-inline-button"
                            [disabled]="disableResend$ | async"
                            (click)="resendCode()"
                        >
                            RESEND
                        </button>
                    </div>

                    <div class="fx-form-validation" *ngIf="form.controls['verifyCode'].hasError('pattern')">
                        Verification code must be 6 numeric characters.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="fx-form-group">
                    <fx-checkbox
                        class="fx-form-group-action fx-checkbox--{{ theme.formTheme }}"
                        [checked]="rememberDevice"
                        (change)="toggleRememberDevice($event)"
                    >
                        Keep me logged in on this trusted device
                    </fx-checkbox>
                </div>
                <div class="fx-form-group">
                    <button
                        class="fx-btn fx-btn--full-width"
                        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                        [disabled]="form.controls.verifyCode.valid === false"
                        (click)="submitCode()"
                    >
                        CONTINUE
                    </button>
                </div>
            </form>
            <p class="fx-onboarding-body" *ngIf="supportEmail">
                <a href="mailto:{{ supportEmail }}">Click here</a> to contact support if you do not receive a security
                code via SMS.
            </p>
        </div>
    </div>
</div>
