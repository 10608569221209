import { Component, Output, EventEmitter } from "@angular/core";
import { NgxDropzoneChangeEvent } from "ngx-dropzone";

@Component({
    selector: "file-input",
    templateUrl: "./file-input.component.html",
    styleUrls: ["./file-input.component.scss"]
})
export class FileInputComponent {
    @Output() file = new EventEmitter<File>();

    onSelect(event: NgxDropzoneChangeEvent) {
        for (const file of event.addedFiles) {
            this.file.emit(file);
        }
    }
}
