import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-business-industry-option",
    templateUrl: "./business-industry-option.component.html",
    styleUrls: ["./business-industry-option.component.scss"]
})
export class BusinessIndustryOptionComponent {
    @Input() name: string;
    @Input() value: string;
    @Input() selected: boolean;

    @Output() updated: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    toggleSelected() {
        this.selected = !this.selected;
        this.updated.emit();
    }
}
