import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "signature-icon",
    templateUrl: "./signature-icon.component.html",
    styleUrls: ["./signature-icon.component.scss"]
})
export class SignatureIconComponent implements OnInit {
    @Input() theme: "dark" | "light" | "fill";
    @Input() text?: string;
    @Input() cursor?: boolean = true;
    @Input() styled?: boolean = true;

    class: string;

    constructor() {}

    ngOnInit() {
        this.class = `signature-icon--${this.theme}`;
        if (!this.cursor) {
            this.class += " signature-icon--no-click";
        }
        if (!this.styled) {
            this.class += " signature-icon--unstyled";
        }
    }
}
