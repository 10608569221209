import { IThreadListing, ThreadTypes } from "@findex/threads";

export interface IThreadGroupListing extends Partial<IThreadListing> {
    parentId?: string;
    parentTitle?: string;
    isAccordion?: boolean;
    name?: string;
    active?: boolean;
    children?: IThreadGroupListing[];
}

export class ThreadGrouper {
    static groupThreads(threads: IThreadGroupListing[]): IThreadGroupListing[] {
        if (!threads) return [];

        /**
         * Builds a tree if at least one thread have parent id attribute
         * Otherwise, groups the thread by their type
         */
        const canBuildTree = threads.some(thread => !!thread.parentId);
        if (canBuildTree) {
            return this.buildTree(threads);
        }

        return this.buildGroup(threads);
    }

    /**
     * Recursive function to build tree
     */
    private static buildTree(
        array: IThreadGroupListing[],
        parent?: IThreadGroupListing,
        tree?: IThreadGroupListing[]
    ): IThreadGroupListing[] {
        tree = tree || [];
        parent = parent || ({} as IThreadGroupListing);

        const children = array.filter(child => child.parentId === parent.id);

        if (children.length) {
            if (!parent.id) {
                tree = this.sortChildren(children);
            } else {
                parent.name = parent.title;
                parent.children = this.sortChildren(children);
            }

            children.forEach(child => {
                this.buildTree(array, child);
            });
        }

        return tree;
    }

    private static buildGroup(threads: IThreadGroupListing[]): IThreadGroupListing[] {
        const groups: IThreadGroupListing[] = [];

        for (let thread of threads) {
            const { type } = thread;

            const displayName = this.getDisplayName(type);
            const group = this.safelyGetGroup(groups, displayName);

            group.children.push(thread);
        }

        for (let group of groups) {
            group.children.sort(this.orderThreads);
        }

        return groups.sort(this.compareGroups);
    }

    public static getDisplayName(type: string): string {
        switch (type) {
            case ThreadTypes.Bookkeeping:
                return "Bookkeeping";
            case ThreadTypes.BasicTaxReturn:
                return "Tax Return";
        }
        return "Other";
    }

    private static safelyGetGroup(groups: IThreadGroupListing[], name: string): IThreadGroupListing {
        const group = groups.find(group => group.name === name);

        if (group) {
            return group;
        } else {
            const newGroup = { name, type: "container", isAccordion: true, children: [] };
            groups.push(newGroup);
            return newGroup;
        }
    }

    private static compareGroups(a: IThreadListing, b: IThreadListing): number {
        const aTitle = a.title || "";
        const bTitle = b.title || "";
        return aTitle.localeCompare(bTitle);
    }

    public static orderThreads(a: IThreadListing, b: IThreadListing): number {
        const aUpdated = a.preview ? a.preview.timestamp : a.createdAt;
        const bUpdated = b.preview ? b.preview.timestamp : b.createdAt;

        if (!aUpdated || !bUpdated) return 0;

        const aTime = new Date(aUpdated);
        const bTime = new Date(bUpdated);

        return bTime.getTime() - aTime.getTime();
    }

    private static sortChildren(children: IThreadGroupListing[]) {
        const isContainer = children.every(child => child.type === "container");
        const sortedChildren = isContainer ? children.sort(this.compareGroups) : children.sort(this.orderThreads);
        return sortedChildren;
    }
}
