import { Component, Inject, OnDestroy } from "@angular/core";
import { CardResources, IEventService, THREAD_CARD_RESOURCES } from "src/app/interfaces/IUiCard";
import { ICardEvent, IThread, IThreadCard, Role } from "@findex/threads";
import { Observable, Subscription } from "rxjs";
import { map, scan, filter } from "rxjs/operators";
import { AuthService } from "src/modules/findex-auth";
import { environment } from "../../../../../environments/environment";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CustomValidators } from "../../../../shared/validators";
import { ThreadsService } from "../../../../../app/services/threads.service";
import { Loader } from "../../../../../app/services/loader";

interface PrivateUserProfileDetails {
    isReturningCustomer: boolean;
    dateOfBirth: string;
    taxFileNumber: string;
}

@Component({
    selector: "client-profile-card",
    templateUrl: "./client-profile-card.component.html",
    styleUrls: ["./client-profile-card.component.scss"]
})
export class ClientProfileCardComponent implements OnDestroy {
    role: Role;
    roles = Role;

    card: IThreadCard;
    thread: IThread;
    eventsSubscription: Subscription;
    userId: Observable<string>;

    completed: boolean = false;
    saveDisabled: boolean = false;
    readonly theme = environment.theme;
    readonly threadStates = environment.threadStates;

    isReturningCustomer: boolean = true;

    form = new FormGroup({
        dateOfBirth: new FormControl(null, [Validators.required, CustomValidators.dateValidator("dd/MM/yyyy")]),
        taxFileNumber: new FormControl(null, [Validators.required, CustomValidators.taxFileNumberValidator])
    });

    loader = new Loader();

    constructor(
        @Inject(THREAD_CARD_RESOURCES) resources: CardResources,
        authService: AuthService,
        private threadsService: ThreadsService
    ) {
        const { eventService, thread, card, role } = resources;

        this.thread = thread;
        this.card = card;
        this.role = role;
        this.eventsSubscription = eventService.events.pipe(scan(this.insertEvent, [])).subscribe();
        this.userId = authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );

        this.loadAllEvents(eventService);
    }

    ngOnDestroy(): void {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
        }
    }

    insertEvent = (allEvents: ICardEvent[], event: ICardEvent): ICardEvent[] => {
        if (event.type === "complete-form") {
            this.completed = true;
        }
        const existing = allEvents.find(event => event.id === event.id);
        if (existing) return allEvents;

        allEvents.unshift(event);
        return allEvents;
    };

    private async loadAllEvents(eventService: IEventService) {
        const hasMore = await eventService.loadHistorical();

        if (hasMore) {
            await this.loadAllEvents(eventService);
        }
    }

    saveProfile() {
        if (this.completed) {
            return;
        }
        const { dateOfBirth, taxFileNumber } = this.form.value;
        const details: PrivateUserProfileDetails = {
            isReturningCustomer: this.isReturningCustomer,
            dateOfBirth,
            taxFileNumber
        };
        this.saveDisabled = true;
        this.loader
            .wrap(
                this.threadsService.updateCompleteProfileCard<PrivateUserProfileDetails>(
                    this.thread.id,
                    this.card.id,
                    details
                )
            )
            .subscribe(() => {});
    }

    shouldDisableSave() {
        return this.saveDisabled || (!this.isReturningCustomer && !this.form.valid);
    }
}
