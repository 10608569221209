import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { TabsItemComponent } from "@findex/fx-ui/lib/components/tabs/tab-item/tabs-item.component";
@Component({
    selector: "app-user-settings",
    styleUrls: ["./user-settings.component.scss"],
    templateUrl: "./user-settings.component.html"
})
export class UserSettingsComponent implements OnInit {
    readonly subscriptionEnabled = environment.featureFlags.subscriptionEnabled;
    readonly packageDisplayName = environment.featureFlags.packageDisplayName;

    @ViewChild("profileTab", { static: false }) profileTab: TabsItemComponent;
    @ViewChild("subscriptionTab", { static: false }) subscriptionTab: TabsItemComponent;

    constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            if (params["subscriptions"]) {
                setTimeout(() => {
                    this.profileTab.active = false;
                    this.subscriptionTab.active = true;
                    this.router.navigate(["."], { relativeTo: this.activatedRoute });
                });
            }
        });
    }
}
