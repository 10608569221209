import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { IThreadListing, Role } from "@findex/threads";
import { ThreadsStateModalComponent } from "src/app/components/threads-state/thread-state-modal/threads-state-modal.component";
import { ThreadStateService } from "src/modules/threads-ui/services/thread-state.service";

export interface ThreadStateListing extends IThreadListing {
    stateDescription?: string;
}

@Component({
    selector: "job-status",
    templateUrl: "./job-status.component.html",
    styleUrls: ["./job-status.component.scss"]
})
export class JobStatusComponent implements OnInit {
    @Input() threads: ThreadStateListing[];
    @Input() role: Role;

    constructor(private dialog: MatDialog, private threadStateService: ThreadStateService) {}

    ngOnInit() {
        if (this.threads) {
            this.threads.map((thread: ThreadStateListing) => {
                const stafffName = this.threadStateStaff(thread.participants);
                const states = this.threadStateService.stateList(thread.title);
                if (!this.threadStateService.isActiveThread(thread.state)) {
                    return thread;
                }
                // select first state if thread.state is undefined
                const description = (states.find(x => x.name === thread.state) || states[0]).description;
                thread.stateDescription = this.threadStateService.populateDescription(description, stafffName);
                return thread;
            });
        }
    }

    threadStateStaff(participants): string {
        const staffName = participants.filter(val => val.role === "Staff");
        return staffName[0].profile.name;
    }

    openThreadsStatus(threads: IThreadListing[]) {
        const config = {
            data: {
                threads: threads,
                role: this.role
            },
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container", "threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%"
        };
        this.dialog.open(ThreadsStateModalComponent, config);
    }
}
