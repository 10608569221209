import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppMaterialModule } from "./app-material.module";
import { FindexUiModule } from "src/modules/findex-ui";
import { FindexAuthModule } from "src/modules/findex-auth";
import { VaultCardModule } from "src/modules/card-modules/vault-card";
import { MessageCardModule } from "src/modules/card-modules/message-card";
import { CalendarCardModule } from "src/modules/card-modules/calendar-card";
import { VideoChatCardModule } from "src/modules/card-modules/video-chat-card";
import { ThreadCardModule } from "src/modules/card-modules/thread-card";
import { ThreadsUiModule } from "src/modules/threads-ui";
import { SameDayTaxModule } from "src/modules/samedaytax";
import { SigmaModule } from "src/modules/sigma";
import { ReportsModule } from "src/modules/reports";

import { SharedModule } from "src/modules/shared";

import { AppComponent } from "./app.component";
import { ThreadRouteComponent } from "./components/thread-route/thread-route.component";
import { CreateCardComponent } from "./components/create-card/create-card.component";
import { CreateCardDocumentComponent } from "./components/create-card/create-card-document/create-card-document.component";
import { RenameFileModalComponent } from "./components/create-card/rename-file-modal/rename-file-modal.component";
import { ThreadsListRouteComponent } from "./components/threads-list-route/threads-list-route.component";
import { ThreadsStateModalComponent } from "./components/threads-state/thread-state-modal/threads-state-modal.component";
import { NestedMessageComponent } from "./components/nested-message/nested-message.component";
import { ReadStatusComponent } from "./components/read-status/read-status.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { SlideMenuIconComponent } from "./components/slide-menu-icon/slide-menu-icon.component";
import { ThreadParticipantsComponent } from "./components/thread-participants/thread-participants.component";
import { ThreadAddParticipantComponent } from "./components/thread-participants/thread-add-participant/thread-add-participant.component";
import { ThreadRemoveParticipantComponent } from "./components/thread-participants/thread-remove-participant/thread-remove-participant.component";
import { CancelThreadModalComponent } from "./components/cancel-thread-modal/cancel-thread-modal.component";
import { CancelThreadReasonModalComponent } from "./components/cancel-thread-reason-modal/cancel-thread-reason-modal.component";

import { ReversePipe } from "./pipes/reverse.pipe";
import { FindInPipe } from "./pipes/find-in.pipe";
import { SentryErrorHandler } from "./services/sentry-error-handler";
import { UserProfileModule } from "../modules/user-profile/user-profile.module";
import { ShepherdService } from "angular-shepherd";
import { ClientProfileCardModule } from "../modules/card-modules/client-profile-card";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        AppComponent,
        ThreadsListRouteComponent,
        ThreadsStateModalComponent,
        NestedMessageComponent,
        ReadStatusComponent,
        ReversePipe,
        FindInPipe,
        CreateCardComponent,
        CreateCardDocumentComponent,
        RenameFileModalComponent,
        ThreadRouteComponent,
        DashboardComponent,
        SlideMenuIconComponent,
        ThreadParticipantsComponent,
        ThreadAddParticipantComponent,
        ThreadRemoveParticipantComponent,
        CancelThreadModalComponent,
        CancelThreadReasonModalComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AppMaterialModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FindexAuthModule,
        FindexUiModule,
        VideoChatCardModule,
        VaultCardModule,
        MessageCardModule,
        CalendarCardModule,
        ThreadCardModule,
        UserProfileModule,
        ThreadsUiModule,
        SharedModule,
        ClientProfileCardModule,
        SameDayTaxModule,
        SigmaModule,
        ReportsModule,
        ReactiveFormsModule
    ],
    providers: [
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        { provide: ShepherdService, useClass: ShepherdService }
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        ThreadsStateModalComponent,
        RenameFileModalComponent,
        ThreadAddParticipantComponent,
        ThreadRemoveParticipantComponent,
        CancelThreadModalComponent,
        CancelThreadReasonModalComponent
    ]
})
export class AppModule {}
