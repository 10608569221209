import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MetricSummary, MetricNames, Metric } from "@findex/threads";
import { HttpClient } from "@angular/common/http";
import { environmentCommon, environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class ReportsService {
    constructor(private http: HttpClient) {}

    getSummary(): Observable<MetricSummary> {
        const { reports } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${reports}`;
        return this.http.get<MetricSummary>(url);
    }

    getDetailed<MetricType>(metricName: MetricNames): Observable<Metric<MetricType>[]> {
        const { reports } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${reports}/${metricName}`;
        return this.http.get<Metric<MetricType>[]>(url);
    }
}
