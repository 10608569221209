<div class='fx-onboarding-dialog'>
    <div class="fx-onboarding-back">
        <button class="fx-onboarding-back-button" (click)="close()">
            <i class="fx-onboarding-back-icon las la-times"></i>
        </button>
    </div>
    <div class="fx-onboarding-dialog-content">
        <app-error [isModal]="true" [data]="data"></app-error>
    </div>
</div>
