<loader-icon *ngIf="showLoader" [color]="'light'"></loader-icon>
<ng-container [ngSwitch]="status" *ngIf="!showLoader">
    <app-business-name
        class="onboarding-detail-component"
        *ngSwitchCase="ALL_STATUSES.BUSINESS_NAME"
        [businessName]="persistedUserDataModel.businessName"
        (modelUpdated)="updateUserModel($event)"
        (modelValidated)="validStateChanged($event)"
        (progressState)="progressState()"
    ></app-business-name>
    <app-industry-type
        class="onboarding-detail-component"
        *ngSwitchCase="ALL_STATUSES.INDUSTRY_TYPE"
        [selectedIndustryTypes]="persistedUserDataModel.industryTypes"
        (modelUpdated)="updateUserModel($event)"
        (modelValidated)="validStateChanged($event)"
        (goBack)="goBack()"
        (progressState)="progressState()"
    ></app-industry-type>
</ng-container>
