import { Pipe, PipeTransform } from "@angular/core";
import { Role, RoleOrder } from "@findex/threads";

@Pipe({ name: "minRole" })
export class MinRolePipe implements PipeTransform {
    transform(role: Role, minExpectedRole: Role): boolean {
        if (!role || !minExpectedRole) {
            return false;
        }

        const roleIndex = RoleOrder.indexOf(role);
        const expectedIndex = RoleOrder.indexOf(minExpectedRole);

        if (roleIndex < 0 || expectedIndex < 0) {
            return false;
        }

        return roleIndex <= expectedIndex;
    }
}
