import { Component, OnInit, OnDestroy } from "@angular/core";

@Component({
    selector: "findex-slide-menu",
    templateUrl: "./findex-slide-menu.component.html",
    styleUrls: ["./findex-slide-menu.component.scss"]
})
export class FindexSlideMenuComponent implements OnInit, OnDestroy {
    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
