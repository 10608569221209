import { CognitoUser } from "amazon-cognito-identity-js";

export enum LoginStep {
    PASSWORD_RESET = "passwordReset",
    LOGIN_COMPLETE = "loginComplete",
    LOGIN_PROCESSING = "loginProcessing", // Used for when we're waiting for the Amplify event
    LOGIN_CHALLENGE = "loginChallenge", // 2 factor auth - used for sms_mfa
    LOGIN_CANCELLED = "loginCancelled", // Used when e.g. dialogs are closed
    LOGIN_FAILED = "loginFailed"
}

export enum LoginChallenge {
    smsMfa = "SMS_MFA"
}

export type LoginStepDetails = {
    step: LoginStep;
    details?: {
        type?: string;
        userDetails?: CognitoUser | any;
        [key: string]: any;
    };
};

export enum LoginChallengeResult {
    SUCCESS = "success",
    FAILED_ATTEMPT = "failedAttempt",
    TIMEOUT = "timeout",
    DENIED = "denied"
}
