import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IParticipant, IThread, Role } from "@findex/threads";
import { MatDialog } from "@angular/material";
import { ThreadAddParticipantComponent } from "./thread-add-participant/thread-add-participant.component";
import { ThreadRemoveParticipantComponent } from "./thread-remove-participant/thread-remove-participant.component";
import { Router } from "@angular/router";
import { AuthService } from "src/modules/findex-auth/services/auth.service";
import { filter, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { UserProfileService, ParticipantDetail } from "../../../modules/user-profile/services/user-profile.service";

@Component({
    selector: "thread-participants",
    templateUrl: "./thread-participants.component.html",
    styleUrls: ["./thread-participants.component.scss"]
})
export class ThreadParticipantsComponent implements OnChanges {
    @Input() thread: IThread;
    @Input() role: Role;

    addParticipantsButton = environment.featureFlags.addParticipants;

    threadParticipantDetails = environment.featureFlags.threadParticipantDetail;
    threadParticipants: ParticipantDetail[] = [];

    roles = Role;
    userId$: Observable<string>;

    showDialog = false;

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private authService: AuthService,
        private userProfileService: UserProfileService
    ) {
        this.userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.thread && changes.thread.currentValue.participants) {
            this.getThreadParticipantsDetail()
                .then(participantsDetail => {
                    this.threadParticipants = participantsDetail;
                })
                .catch(() => {
                    this.threadParticipants = this.thread.participants;
                });
        }
    }

    private async getThreadParticipantsDetail(): Promise<ParticipantDetail[]> {
        if (!this.threadParticipantDetails) {
            return this.thread.participants;
        }
        const enrichedParticipants = await Promise.all(
            this.thread.participants.map(participant => {
                return this.userProfileService.getParticipantDetail(participant, this.role);
            })
        );
        return enrichedParticipants;
    }

    async openDialog() {
        await this.dialog
            .open(ThreadAddParticipantComponent, {
                data: { thread: this.thread },
                position: { top: "0px" },
                height: "100vh",
                maxWidth: "100vw",
                panelClass: ["mat-dialog-no-styling", "threads-sidebar"]
            })
            .afterClosed()
            .toPromise();
    }

    openProfile(participantId: string) {
        this.router.navigate(["/admin", "clients", participantId]);
    }

    remove(participant: IParticipant) {
        this.dialog.open(ThreadRemoveParticipantComponent, {
            data: { participant, thread: this.thread }
        });
    }
}
