<modal-layout (close)="close()" header="Add Participant" [footer]="false">
    <div modal-body>
        <threads-input id="client-search" (currentValue)="searchClients($event)">Search</threads-input>
        <loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>

        <div *ngIf="clients$ | async; let clients">
            <div *ngFor="let client of clients" class="dialog-participant" (click)="addParticipant(client)">
                <fx-avatar [name]="client.name" [isLarge]="false"></fx-avatar>
                <div class="dialog-participant-user">
                    <div class="dialog-participant-user-name">
                        {{ client.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal-layout>
