<div class="attendee-modal">
    <div class="attendee-modal-header">
        <h2>Add attendee</h2>
    </div>
    <div class="attendee-modal-content">
        <div class="attendee" *ngFor="let attendee of participants">
            <fx-checkbox
                [checked]="attendees.includes(attendee)"
                (change)="toggleAttendee(attendee)"
                [disabled]="currentUserId == attendee.id"
            >
                <user-detail-card [userId]="attendee.id"></user-detail-card>
            </fx-checkbox>
        </div>
    </div>
    <div class="attendee-modal-footer">
        <button class="fx-btn fx-btn--primary fx-btn--white" role="button" (click)="cancel()">CANCEL</button>
        <button
            class="fx-btn"
            [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
            role="button"
            (click)="save()"
        >
            ADD
        </button>
    </div>
</div>
