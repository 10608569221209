import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Document } from "../create-card-document/create-card-document.component";
import { environment } from "src/environments/environment";

@Component({
    selector: "rename-file-modal",
    templateUrl: "./rename-file-modal.component.html",
    styleUrls: ["./rename-file-modal.component.scss"]
})
export class RenameFileModalComponent {
    name: string;
    readonly theme = environment.theme;

    constructor(
        @Inject(MAT_DIALOG_DATA) document: Document,
        private dialogRef: MatDialogRef<RenameFileModalComponent>
    ) {
        this.name = document.description;
    }

    save() {
        this.dialogRef.close(this.name);
    }

    cancel() {
        this.dialogRef.close();
    }
}
