import { IThreadCard } from "@findex/threads";
import { Injectable } from "@angular/core";
import { ThreadsService } from "src/app/services/threads.service";
import { VAULT_ACTION } from "@findex/vault";
import { IVaultState } from "src/modules/card-modules/vault-card/interfaces/IVaultState";
import { IVaultItem } from "src/modules/card-modules/vault-card";

export type VaultDocument = {
    card: IThreadCard;
    actorId: string;
    timestamp: string;
    title: string;
    category: string;
    vaultId: string;
    fileId: string;
    filename: string;
    isRfi?: boolean;
    signable?: boolean;
    signed?: boolean;
};

@Injectable({ providedIn: "root" })
export class ThreadsVaultService {
    constructor(private threadsService: ThreadsService) {}

    async getThreadDocuments(threadId: string): Promise<VaultDocument[]> {
        const cards = await this.threadsService.getCards(threadId).toPromise();

        const documents$ = cards
            .filter(card => card.type == "vault")
            .map(card => this.getCardDocuments(threadId, card));

        const cardDocuments = await Promise.all(documents$);
        return [].concat.apply([], cardDocuments);
    }

    private async getCardDocuments(threadId: string, card: IThreadCard): Promise<VaultDocument[]> {
        const state = await this.threadsService.getCardState(threadId, card.id).toPromise();
        return this.stateToDocuments(card, state);
    }

    private stateToDocuments(card: IThreadCard, state: IVaultState): VaultDocument[] {
        if (!state || !state.groups) return [];

        const documents: VaultDocument[] = [];

        for (const group of state.groups) {
            for (const item of group.items) {
                const itemDocs = this.groupItemToDocuments(card, item);
                documents.push.apply(documents, itemDocs);
            }
        }

        return documents;
    }

    private groupItemToDocuments(card: IThreadCard, item: IVaultItem): VaultDocument[] {
        if (!item || !item.files) return [];
        return item.files.map(file => {
            const { vaultId, fileId, displayName } = item;
            const { filename, actorId, timestamp } = file;

            const signable = item.actions.includes(VAULT_ACTION.Sign);
            const isRfi = item.actions.includes(VAULT_ACTION.Rfi);
            const signed = item.signed;
            const title = item.files.length == 1 && !isRfi ? displayName : filename;
            const category = displayName;

            return { card, actorId, timestamp, title, vaultId, fileId, filename, category, isRfi, signed, signable };
        });
    }
}
