import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { Observable, of } from "rxjs";
import { switchMap, mapTo } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { User } from "../model/User";
import { LoginDialogComponent } from "../components/login-dialog/login-dialog.component";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private dialog: MatDialog, private router: Router) {}

    // @ts-ignore
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.getUser().pipe(
            switchMap(user => {
                if (state.url === "/register/signup") {
                    return this.loggedOutHandler(user);
                }
                if (state.url.startsWith("/register/verify-mobile")) {
                    return of(true);
                }
                return this.validOrAuth(user);
            })
        );
    }

    // need to clean up once the correct form is in place. This maintains original functionality
    private loggedOutHandler(user: User): Observable<boolean> {
        if (!user) {
            return of(true);
        } else {
            if (user.mobileNumberVerified) {
                this.router.navigateByUrl("/dashboard");
            } else {
                this.router.navigateByUrl("/register/verify-mobile");
            }
            return of(false);
        }
    }

    private validOrAuth(user: User): Observable<boolean> {
        if (user) {
            if (!user.mobileNumberVerified) {
                this.router.navigateByUrl("/register/verify-mobile");
            }
            return of(true);
        } else {
            const options = {
                disableClose: true,
                backdropClass: "modal-backdrop",
                panelClass: ["modal-container", "mat-dialog-no-styling"],
                maxWidth: "100%",
                minWidth: "100%",
                maxHeight: "100%",
                minHeight: "100%"
            };

            return this.dialog
                .open(LoginDialogComponent, options)
                .afterClosed()
                .pipe(mapTo(true));
        }
    }
}
