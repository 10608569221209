<loader-icon *ngIf="loader.counter && !showLoadingStatus" color="light"></loader-icon>
<div class="loading-status" *ngIf="showLoadingStatus" [@fadeIn]>
    <div class="fx-brand"></div>
    <fx-loading-status [statuses]="statuses" [width]="230"></fx-loading-status>
</div>
<div
    class="fx-onboarding fx-onboarding--high-logo fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }} sdtr-bg-blank"
    *ngIf="!loader.counter"
>
    <div class="fx-onboarding-container fx-container" *ngIf="staffProfiles?.length > 0">
        <div class="fx-onboarding-content">
            <div class="fx-onboarding-text">
                <div class="fx-onboarding-go-back">
                    <button
                        class="fx-onboarding-back-button"
                        (click)="goBack()"
                        analyticsClick="onboarding_selectstaffback"
                    >
                        <i class="fx-onboarding-back-icon las la-long-arrow-alt-left"></i>
                    </button>
                </div>
                <h1 class="fx-onboarding-title select-staff-title">Welcome to Same Day Tax Refunds,</h1>
                <p class="fx-onboarding-body">Service {{ (currentPackage$ | async)?.name }}</p>
                <p class="fx-onboarding-body">
                    Based on the service you have chosen we have handpicked a selection of our Accountants to choose
                    from.
                </p>
                <p class="fx-onboarding-body">
                    Do you have a day already in mind? Select a day
                    <a (click)="findAvailabilityModal()" analyticsClick="onboarding_bookbyday">here</a>
                </p>
            </div>

            <div class="select-staff-list">
                <div class="select-staff" *ngFor="let staffProfile of staffProfiles">
                    <div class="select-staff-content">
                        <div class="select-staff-background"></div>
                        <app-staff-detail [staffProfile]="staffProfile"></app-staff-detail>
                        <div class="select-staff-buttons">
                            <button
                                class="fx-btn fx-btn--primary fx-btn--primary-outlined"
                                type="submit"
                                analyticsClick="onboarding_schedulemeeting"
                                (click)="selectStaff(staffProfile, 'call')"
                            >
                                Schedule Online Meeting
                            </button>
                            <button
                                class="fx-btn fx-btn--white"
                                type="submit"
                                analyticsClick="onboarding_messagestaff"
                                (click)="selectStaff(staffProfile, 'message')"
                            >
                                Message {{ getFirstName(staffProfile) }}
                            </button>
                        </div>
                    </div>
                </div>
                <p *ngIf="error" class="text-danger">{{ error }}</p>
            </div>
        </div>
    </div>
</div>
