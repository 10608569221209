<div class="create-card-documents">
    <div *ngFor="let file of documents; let i = index" class="create-card-documents-file">
        <div class="create-card-documents-desc">
            <div class="create-card-documents-sign" *ngIf="role | minRole: roles.Staff">
                <fx-checkbox
                    [checked]="file.signature"
                    (change)="file.signature = !file.signature"
                    analyticsClick="app_requestsignature"
                >
                    <signature-icon [theme]="'light'" [cursor]="false" [styled]="false"></signature-icon>
                </fx-checkbox>
            </div>

            <div class="create-card-documents-desc-icon">
                <i class="las la-file"></i>
            </div>

            <div class="text">
                {{ file.description }}
            </div>
        </div>

        <fx-context-menu analyticsClick="app_createcontext">
            <a (click)="rename.emit(file)">
                Rename
            </a>
            <a (click)="remove.emit(file)">
                Delete
            </a>
        </fx-context-menu>
    </div>
</div>
