import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Role, IThread } from "@findex/threads";
import { ThreadStateService, ThreadsStates } from "src/modules/threads-ui/services/thread-state.service";
import { environment } from "src/environments/environment";

const { threadStates } = environment;

@Component({
    selector: "state-list",
    templateUrl: "./state-list.component.html",
    styleUrls: ["./state-list.component.scss"]
})
export class StateListComponent implements OnInit {
    @Input() thread: IThread;
    @Input() title: string;
    @Input() role: Role;
    @Output() save = new EventEmitter<string>();

    roles = Role;
    threadClosedState = threadStates.closed;
    changedState?: string;
    states: ThreadsStates[];

    readonly theme = environment.theme;

    constructor(private threadStateService: ThreadStateService) {}

    ngOnInit() {
        this.states = this.threadStateService.stateList(this.title);
    }

    isChecked(stateIndex: number): boolean {
        const currentState = this.changedState || this.thread.state;
        const currentIndex = this.states.findIndex(state => state.name === currentState);

        return stateIndex < currentIndex || currentIndex === this.states.length - 1;
    }

    isActive(stateIndex: number): boolean {
        const currentState = this.changedState || this.thread.state;
        const currentIndex = this.states.findIndex(x => x.name === currentState);

        return stateIndex === currentIndex && currentIndex !== this.states.length - 1;
    }

    saveState() {
        this.save.emit(this.changedState);
    }

    checkboxChange(index, checked) {
        if (!checked) {
            this.changedState = this.states[index].name;
        } else {
            this.changedState = this.states[index + 1].name;
        }
    }
}
