<loader-icon *ngIf="showLoader" [color]="'light'"></loader-icon>
<div
    class="fx-onboarding fx-onboarding--high-logo fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }}"
    *ngIf="!showLoader"
>
    <div class="fx-onboarding-header fx-onboarding-header--back">
        <div class="fx-brand"></div>
    </div>
    <div class="fx-onboarding-container fx-container">
        <div class="fx-onboarding-content">
            <h1 class="fx-onboarding-title">Forgot password</h1>
            <p class="fx-onboarding-body">
                If you need help resetting your password, we can help by sending you a link to reset it
            </p>
            <form
                class="fx-onboarding-form fx-form"
                [ngClass]="['fx-form--' + theme.formTheme]"
                [formGroup]="form"
                (submit)="beginForgotPasswordProcess()"
            >
                <div
                    class="fx-form-group"
                    *ngIf="message && message.error !== undefined"
                    [ngClass]="message.error ? 'fx-onboarding-form-error' : 'fx-onboarding-form-info'"
                >
                    <div class="fx-form-validation-panel">
                        <div class="fx-form-validation-panel-item">{{ message.message }}</div>
                    </div>
                </div>

                <div class="fx-form-group">
                    <label class="fx-form-label" for="emailAddress">Email</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="emailAddress"
                            id="emailAddress"
                            class="fx-form-input"
                            type="text"
                            placeholder="Email"
                            aria-label="Email"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['emailAddress'].valid && form.controls['emailAddress'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="
                            form.controls['emailAddress'].hasError('required') && form.controls['emailAddress'].touched
                        "
                    >
                        Email is required.

                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="form.controls['emailAddress'].hasError('email') && form.controls['emailAddress'].touched"
                    >
                        Email is invalid.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="fx-form-group">
                    <button
                        class="fx-btn fx-btn--full-width"
                        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                        [disabled]="!form.valid"
                        type="submit"
                    >
                        Send
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
