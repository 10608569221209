<fx-accordion type="secondary" [custom]="true" [isExpanded]="expandInitial" [toggleGutters]="true">
    <ng-container accordion-header>
        <div class="upload-rfi-group-title" analyticsClick="rfi_childcollapse" [analyticsLabel]="item.displayName">
            <span class="title">{{ item.displayName }}</span>
            <div>
                <span>{{ item.files?.length || 0 }}</span>
            </div>
        </div>
        <i
            class="fx-accordion-toggle-icon las la-angle-down"
            analyticsClick="rfi_childcollapse"
            [analyticsLabel]="item.displayName"
        ></i>
    </ng-container>
    <ng-container accordion-content>
        <file-input *ngIf="!closed && !(role | minRole: roles.Staff)" (file)="uploadFile.emit($event)"></file-input>
        <div *ngIf="item.files && item.files.length > 0" class="files">
            <div class="file" *ngFor="let file of item.files; trackBy: trackFile">
                <div class="details">
                    <ng-container *ngIf="file.deleting">
                        Removing file, please wait....
                    </ng-container>

                    <ng-container *ngIf="file.downloading">
                        Downloading, please wait....
                    </ng-container>

                    <ng-container *ngIf="!file.deleting && !file.downloading">
                        <div
                            class="filename filename--uploading"
                            [ngClass]="{ 'filename--uploading': file.progress }"
                            (click)="!file.uploadSub && openFile.emit(file)"
                        >
                            <i class="las la-file"></i>{{ file.filename }}
                        </div>
                        <div class="upload-percent" *ngIf="file.uploadSub">{{ file.progress | number: "0.0-0" }}%</div>
                        <div class="cancel" *ngIf="!closed && !(role | minRole: roles.Staff)" (click)="cancel(file)">
                            <i class="las la-times"></i>
                        </div>
                    </ng-container>
                </div>
                <div class="progress-bar" *ngIf="file.uploadSub">
                    <div class="progress-bar-inner" [ngStyle]="{ width: file.progress + '%' }"></div>
                </div>
            </div>
        </div>
    </ng-container>
</fx-accordion>
