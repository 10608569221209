<loader-icon *ngIf="loader.counter" color="light"></loader-icon>

<div
    class="fx-onboarding fx-onboarding--high-logo fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }} sdtr-bg-completion"
    *ngIf="!loader.counter"
>
    <div class="fx-onboarding-container fx-container onboarding-profile">
        <div class="fx-onboarding-header">
            <div class="fx-brand"></div>
        </div>

        <div class="fx-onboarding-content">
            <div class="fx-onboarding-text">
                <form
                    class="fx-onboarding-form fx-form fx-form--dark"
                    [formGroup]="onboardingForm"
                    (ngSubmit)="continue()"
                >
                    <h2>Have you used {{ appName }} services before?</h2>

                    <div class="fx-form-group" *ngIf="onboardingForm.controls.isReturningCustomer; let control">
                        <div class="fx-form-group-line">
                            <fx-checkbox
                                (change)="control.setValue(true)"
                                [checked]="control.value === true"
                                analyticsClick="onboarding_usedbefore"
                                >Yes</fx-checkbox
                            >
                            <fx-checkbox
                                (change)="control.setValue(false)"
                                [checked]="control.value === false"
                                analyticsClick="onboarding_notusedbefore"
                                >No</fx-checkbox
                            >
                        </div>
                    </div>

                    <h2>So we can proceed, please provide the following:</h2>
                    <div class="fx-form-group">
                        <threads-input
                            formControlName="taxFileNumber"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !onboardingForm.controls['taxFileNumber'].valid &&
                                    onboardingForm.controls['taxFileNumber'].touched
                            }"
                            >1. Tax File Number</threads-input
                        >
                        <div
                            class="fx-form-validation"
                            *ngIf="
                                onboardingForm.controls['taxFileNumber'].hasError('required') &&
                                onboardingForm.controls['taxFileNumber'].touched
                            "
                        >
                            TFN is required.

                            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                        </div>
                        <div
                            class="fx-form-validation"
                            *ngIf="
                                !onboardingForm.controls['taxFileNumber'].hasError('required') &&
                                onboardingForm.controls['taxFileNumber'].hasError('taxFileNumber') &&
                                onboardingForm.controls['taxFileNumber'].touched
                            "
                        >
                            TFN is invalid.
                            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                        </div>
                    </div>

                    <div class="fx-form-group">
                        <threads-input
                            formControlName="dateOfBirth"
                            placeholder="e.g. dd/mm/yyyy"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !onboardingForm.controls['dateOfBirth'].valid &&
                                    onboardingForm.controls['dateOfBirth'].touched
                            }"
                            >2. Date of Birth</threads-input
                        >
                        <div
                            class="fx-form-validation"
                            *ngIf="
                                onboardingForm.controls['dateOfBirth'].hasError('required') &&
                                onboardingForm.controls['dateOfBirth'].touched
                            "
                        >
                            Date of birth is required.

                            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                        </div>
                        <div
                            class="fx-form-validation"
                            *ngIf="
                                onboardingForm.controls['dateOfBirth'].hasError('date') &&
                                onboardingForm.controls['dateOfBirth'].touched
                            "
                        >
                            Date of birth is invalid, please enter a valid dd/mm/yyyy date.
                            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                        </div>
                    </div>

                    <ng-container *ngIf="onboardingForm.controls.idType; let control">
                        <div class="fx-form-group">
                            <label>3. Proof of ID <span class="optional">(optional)</span></label>
                            <p>Please provide one of the below options</p>
                            <div class="fx-form-group-line">
                                <fx-checkbox
                                    (change)="control.setValue('licence')"
                                    [checked]="control.value === 'licence'"
                                    analyticsClick="onboarding_driverslicence"
                                    >Driver licence</fx-checkbox
                                >
                                <fx-checkbox
                                    (change)="control.setValue('passport')"
                                    [checked]="control.value === 'passport'"
                                    analyticsClick="onboarding_passport"
                                    >Passport</fx-checkbox
                                >
                            </div>
                        </div>

                        <div class="fx-form-group fx-form-group-line" *ngIf="control.value === 'licence'">
                            <threads-input formControlName="idNumber">Licence No.</threads-input>
                            <threads-input formControlName="idExpiry" placeholder="e.g. dd/mm/yyyy"
                                >Licence Expiry</threads-input
                            >
                            <div
                                class="fx-form-validation"
                                *ngIf="
                                    onboardingForm.controls['idExpiry'].hasError('required') &&
                                    onboardingForm.controls['idExpiry'].touched
                                "
                            >
                                Expiry is required.

                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                            <div
                                class="fx-form-validation"
                                *ngIf="
                                    onboardingForm.controls['idExpiry'].hasError('date') &&
                                    onboardingForm.controls['idExpiry'].touched
                                "
                            >
                                Expiry is invalid, please enter a valid dd/mm/yyyy date.
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                        </div>

                        <div class="fx-form-group fx-form-group-line" *ngIf="control.value === 'passport'">
                            <threads-input formControlName="idNumber">Passport No.</threads-input>
                            <threads-input formControlName="idExpiry" placeholder="e.g. dd/mm/yyyy"
                                >Date of Expiry</threads-input
                            >
                            <div
                                class="fx-form-validation"
                                *ngIf="
                                    onboardingForm.controls['idExpiry'].hasError('required') &&
                                    onboardingForm.controls['idExpiry'].touched
                                "
                            >
                                Expiry is required.

                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                            <div
                                class="fx-form-validation"
                                *ngIf="
                                    onboardingForm.controls['idExpiry'].hasError('date') &&
                                    onboardingForm.controls['idExpiry'].touched
                                "
                            >
                                Expiry is invalid, please enter a valid dd/mm/yyyy date.
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                        </div>
                    </ng-container>

                    <p class="terms">
                        By providing your 'Tax File Number' (TFN) you are agreeing that Same Day Tax Refunds (Findex
                        (Aust) Pty Ltd) may act as your tax agent, access the ATO Portal and obtain information
                        necessary to complete your tax return and handle your taxation affairs for the period that you
                        are a client. By clicking 'Continue' you accept Same Day Tax Refunds' Terms & Privacy Policy &
                        Terms of Business & Disclaimer and Disclosure.
                    </p>

                    <button
                        class="fx-btn fx-btn--primary-outlined"
                        role="submit"
                        [disabled]="onboardingForm.invalid"
                        analyticsClick="onboarding_profilecomplete"
                    >
                        CONTINUE
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
