import { ICardEvent, IThreadCard, IThread, Role } from "@findex/threads";
import { ComponentType } from "@angular/cdk/portal";
import { InjectionToken } from "@angular/core";
import { Observable, Subject, ReplaySubject } from "rxjs";
import { Loader } from "../services/loader";

export const THREAD_CARD_RESOURCES = new InjectionToken<Role>("THREAD_CARD_RESOURCES");

export interface IEventService {
    events: Observable<ICardEvent>;
    loadHistorical(): Promise<boolean>;
}

export type CardResources<StateType = any> = {
    thread: IThread;
    card: IThreadCard;
    state: Observable<StateType>;
    eventService: IEventService;
    navigateTo: Observable<void>;
    role: Role;
};

export interface IUiCard<C = any> {
    thread: IThread;
    card: IThreadCard;
    cardState: Observable<any>;
    eventsSubject: Subject<ICardEvent>;
    eventsService: IEventService;
    timestamp: number;
    loader: Loader;
    scrollTo?: boolean;
    component: ComponentType<C>;
    navigateTo: ReplaySubject<void>;
}
