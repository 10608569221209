<div class="my-tasks">
    <loader-icon *ngIf="loading" [color]="'light'"></loader-icon>
    <div class="my-tasks-list" *ngIf="!loading">
        <ng-container *ngFor="let entry of entries">
            <my-tasks-item *ngIf="entry.state | isThreadActive" [entry]="entry"></my-tasks-item>
        </ng-container>
    </div>
</div>
<p class="no-tasks" *ngIf="!loading && !entries?.length">
    You have no tasks.
</p>
