<div class="state-list">
    <div>
        <ng-container *ngFor="let state of states; let i = index">
            <div *ngIf="state.name !== 'Complete'" class="state-list-items">
                <fx-checkbox
                    [ngClass]="{
                        'state-list-item-checkbox--active': isActive(i),
                        'state-list-item-checkbox--strikeThrough': isChecked(i)
                    }"
                    (change)="checkboxChange(i, $event)"
                    [disabled]="!(role | minRole: roles.Staff)"
                    [checked]="isChecked(i)"
                >
                    {{ state.name }}
                </fx-checkbox>
            </div>
        </ng-container>
    </div>
    <button
        *ngIf="role | minRole: roles.Staff"
        [disabled]="!changedState || changedState === thread.state"
        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
        (click)="saveState()"
        class="fx-btn fx-btn--full-width"
        role="button"
    >
        {{ changedState !== threadClosedState ? "Save" : "Save and Complete" }}
    </button>
</div>
