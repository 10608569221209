import { Injectable } from "@angular/core";
import { IParticipant } from "@findex/threads";
import { ThreadsService } from "src/app/services/threads.service";
import { Observable, of } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ParticipantCache {
    private cachedParticipants: { [id: string]: Observable<IParticipant> } = {};
    constructor(private threadsService: ThreadsService) {}

    getParticipant(id: string): Observable<IParticipant> {
        if (id == null) {
            return of(null);
        }

        if (!this.cachedParticipants[id]) {
            this.cachedParticipants[id] = this.threadsService.getParticipants([id]).pipe(
                map(results => (results.length ? results[0] : { id })),
                map(participant => this.safeParticipant(participant)),
                shareReplay(1)
            );
        }

        return this.cachedParticipants[id];
    }

    update(participants: IParticipant[]) {
        this.cachedParticipants = {};

        for (const participant of participants) {
            this.cachedParticipants[participant.id] = of(this.safeParticipant(participant));
        }
    }

    private safeParticipant(participant: IParticipant): IParticipant {
        const profile = participant.profile && participant.profile.name ? participant.profile : { name: undefined };
        return ThreadsService.mapParticipant({
            ...participant,
            ...{ profile }
        });
    }
}
