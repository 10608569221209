import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ReportsService } from "../../services/reports.service";
import { ActivatedRoute } from "@angular/router";
import { map, switchMap } from "rxjs/operators";
import { metricNameMap } from "../reports-list/reports-list.component";
import { Metric } from "@findex/threads";
import { Loader } from "src/app/services/loader";

@Component({
    selector: "report",
    templateUrl: "./report.component.html",
    styleUrls: ["./report.component.scss"]
})
export class ReportComponent implements OnInit {
    loader = new Loader();
    displayName$: Observable<string>;
    report$: Observable<Metric<any>[]>;

    constructor(private reportsService: ReportsService, private route: ActivatedRoute) {}

    ngOnInit() {
        const metricName$ = this.route.params.pipe(map(params => params.metricName));

        this.displayName$ = metricName$.pipe(map(metricName => metricNameMap[metricName]));

        this.report$ = metricName$.pipe(
            switchMap(metricName => this.loader.wrap(this.reportsService.getDetailed(metricName)))
        );
    }

    trackByMetric(_index: number, metric: Metric<any>) {
        return metric.id;
    }
}
