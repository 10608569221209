import { ISlot } from "./../calendar-card/calendar-card.component";
import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CalendarService } from "@findex/calendar";
import { IParticipant } from "@findex/threads";
import { Loader } from "src/app/services/loader";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-calendar-modal",
    templateUrl: "./calendar-modal.component.html",
    styleUrls: ["./calendar-modal.component.scss"]
})
export class CalendarModalComponent implements OnInit {
    selectedDate: ISlot;
    start: string;
    end: string;
    minDate: string;
    maxDate: string;
    slots: ISlot[];
    calendarError: string;
    loader = new Loader();
    rescheduleAppointment: boolean;
    readonly theme = environment.theme;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            invitationId: string;
            otherParticipants: IParticipant[];
            rescheduleAppointment: boolean;
            meetingDescription?: string;
        },
        public dialogRef: MatDialogRef<CalendarModalComponent>,
        private calendarService: CalendarService
    ) {
        this.selectedDate = null;
        this.rescheduleAppointment = data.rescheduleAppointment;
    }

    async ngOnInit() {
        const today = await this.getToday();
        this.minDate = today.toISOString();
        this.maxDate = new Date(Date.now() + 60 * 24 * 60 * 60 * 1000).toISOString();
        this.monthChange(today);
    }

    private async getToday(): Promise<Date> {
        const today = new Date();

        if (this.data && this.data.invitationId) {
            this.loader.show();
            const invite = await this.calendarService.getClientInvitation(this.data.invitationId).toPromise();
            this.loader.hide();

            if (invite.dateRange.start) {
                const startDate = new Date(invite.dateRange.start);
                if (startDate.getTime() > today.getTime()) {
                    return startDate;
                }
            }
        }

        return today;
    }

    handleCalendarEvent(event: any): void {
        if (!event) {
            this.selectedDate = null;
            return;
        }

        this.start = event.start || null;
        this.end = event.end || null;

        if (event.start && event.end) {
            this.selectedDate = { start: this.start, end: this.end };
        } else {
            this.selectedDate = null;
        }
    }

    onClose(confirmed: boolean): void {
        if (confirmed) {
            this.dialogRef.close(this.selectedDate);
        } else {
            this.dialogRef.close(null);
        }
    }

    async monthChange(startDate: Date) {
        this.loader.show();

        try {
            //calendar SDK not defining correct type?
            const response: any = await this.calendarService
                .getAvailableSlots(this.data.invitationId, startDate)
                .toPromise();
            this.slots = response.data.slots;
        } catch (error) {
            // TODO present failures according to design
            this.calendarError = `Failed to load availabilities from calendar service. Response: ${error.status} ${error.statusText}`;
        } finally {
            this.loader.hide();
        }
    }
}
