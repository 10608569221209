<fx-card
    *ngIf="userId$ | async; let userId"
    class="calendar-card"
    title="Meeting request"
    [name]="card.createdBy | userToName | async"
    [timestamp]="card.modifiedAt"
    [description]="card.description"
    [invertTheme]="userId === card.createdBy"
>
    <loader-icon *ngIf="loader.counter" color="light"></loader-icon>
    <ng-container *ngIf="(thread.state | isThreadActive) && invitationId">
        <ng-container *ngIf="invitationCancelled">
            <p>
                This meeting has been cancelled by the organiser.
            </p>
        </ng-container>
        <ng-container *ngIf="!invitationCancelled">
            <div class="calendar-card-button-container" *ngIf="!appointmentConfirmed">
                <button
                    *ngIf="role == roles.Client"
                    class="fx-btn fx-btn--primary-outlined"
                    type="button"
                    analyticsClick="app_viewavialability"
                    (click)="openCalendarModal()"
                >
                    VIEW AVAILABILITY
                </button>
                <p *ngIf="role | minRole: roles.Staff">
                    A request has been sent. The client can now schedule an appointment using your availability
                </p>
                <fx-context-menu
                    #menu
                    (click)="$event.stopPropagation()"
                    *ngIf="appointmentConfirmed && role | minRole: roles.Staff"
                >
                    <a (click)="cancelMeeting(); menu.toggleMenuState()" analyticsClick="app_cancelmeeting">
                        Cancel meeting
                    </a>
                </fx-context-menu>
            </div>
            <div
                class="calendar-card-participant-container"
                *ngIf="appointmentConfirmed && otherParticipants.length && otherParticipants[0]?.profile"
            >
                <user-detail-card [userId]="otherParticipants[0].id" [meetingStart]="start"></user-detail-card>
                <fx-context-menu
                    #menu
                    (click)="$event.stopPropagation()"
                    *ngIf="appointmentConfirmed && role | minRole: roles.Staff"
                >
                    <a (click)="cancelMeeting(); menu.toggleMenuState()" analyticsClick="app_staffcancelmeeting">
                        Cancel meeting
                    </a>
                </fx-context-menu>
            </div>
            <div class="calendar-card-button-container" *ngIf="appointmentConfirmed">
                <button
                    *ngIf="role == roles.Client"
                    class="fx-btn fx-btn--primary-outlined"
                    type="button"
                    (click)="openCalendarModal(true)"
                >
                    RESCHEDULE
                </button>
                <button
                    *ngIf="(role | minRole: roles.Staff) && hasScheduledTask"
                    class="fx-btn fx-btn--primary-outlined"
                    (click)="startVc()"
                >
                    Start VC Session
                </button>
            </div>
        </ng-container>
    </ng-container>
</fx-card>
