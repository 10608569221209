import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

export interface ICreateRfiDocument {
    documentType: string;
    edit: boolean;
}
@Component({
    selector: "create-document",
    templateUrl: "./create-document.component.html",
    styleUrls: ["./create-document.component.scss"]
})
export class CreateRfiDocumentComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<CreateRfiDocumentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ICreateRfiDocument
    ) {}

    ngOnInit() {}
}
