import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {
    ICardEvent,
    ICardSubject,
    IPaginated,
    IParticipant,
    IThread,
    IThreadCard,
    IThreadListing,
    Role
} from "@findex/threads";
import { Observable, of, throwError } from "rxjs";
import { environment, environmentCommon } from "../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { IUserDataService } from "../interfaces/IUserDataService";

@Injectable({ providedIn: "root" })
export class ThreadsService implements IUserDataService {
    constructor(private http: HttpClient) {}

    listThreads(next?: string): Observable<IPaginated<IThreadListing[]>> {
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}`;
        const params = next && { next };
        return this.http.get<IPaginated<IThreadListing[]>>(url, { params });
    }

    createThread(type: string, title: string): Observable<IThread> {
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}`;
        const body = { type, title };
        return this.http.post<IThread>(url, body);
    }

    updateThread(threadId: string, updates: Partial<Pick<IThread, "title" | "state" | "notes">>): Observable<void> {
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}`;
        return this.http.put<void>(url, updates);
    }

    getGlobalRole(participantId: string): Observable<Role> {
        const { participants, role } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${participants}/${participantId}${role}`;
        return this.http.get<{ role: Role }>(url).pipe(map(({ role }) => role));
    }

    putGlobalRole(participantId: string, role: Role): Observable<Role> {
        const { participants, role: rolePath } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${participants}/${participantId}${rolePath}`;
        return this.http
            .put<{ role: Role }>(url, { role })
            .pipe(map(({ role }) => role));
    }

    getRole(threadId: string, participantId: string): Observable<Role> {
        const { participants, threads, role } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${participants}/${participantId}${role}`;
        return this.http.get<{ role: Role }>(url).pipe(map(({ role }) => role));
    }

    putParticipant(threadId: string, participantId: string, role: Role) {
        const { threads, participants } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${participants}/${participantId}`;
        return this.http.put(url, { role });
    }

    removeParticipant(threadId: string, participantId: string) {
        const { threads, participants } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${participants}/${participantId}`;
        return this.http.delete(url);
    }

    getThread(threadId: string): Observable<IThread> {
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}`;
        return this.http.get<IThread>(url).pipe(
            map((thread: IThread) => {
                const mappedParticipants = thread.participants.map((participant: IParticipant) => {
                    return ThreadsService.mapParticipant(participant);
                });
                return {
                    ...thread,
                    participants: mappedParticipants
                };
            })
        );
    }

    getCards(threadId: string): Observable<IThreadCard[]> {
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}`;
        return this.http.get<IThreadCard[]>(url);
    }

    getCard(threadId: string, cardId: string): Observable<IThreadCard> {
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}`;
        return this.http.get<IThreadCard>(url);
    }

    getCardEvents(threadId: string, cardId: string, next?: string): Observable<IPaginated<ICardEvent[]>> {
        const { threads, cards, events } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}${events}`;
        const params = next && { next };
        return this.http.get<IPaginated<ICardEvent[]>>(url, { params });
    }

    getEvent(threadId: string, cardId: string, eventKey: string): Observable<ICardEvent> {
        const { threads, cards, events } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}${events}/${eventKey}`;
        return this.http.get<ICardEvent>(url);
    }

    updateCard(threadId: string, cardId: string, subjects?: ICardSubject[], description?: string): Observable<void> {
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}`;
        return this.http.post<void>(url, { subjects, description });
    }

    getCardState(threadId: string, cardId: string): Observable<any> {
        const { threads, cards, state } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}${state}`;
        return this.http.get<any>(url).pipe(
            catchError(error => {
                if (error.status === 404) {
                    return of(undefined);
                }
                throw error;
            })
        );
    }

    rebuildCardState(threadId: string, cardId: string): Observable<void> {
        const { threads, cards, state } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}${state}`;
        return this.http.delete<void>(url);
    }

    getUserData(participantId: string): Observable<any> {
        const { participants, data } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${participants}/${participantId}${data}`;
        return this.http.get<any>(url);
    }

    putUserData(participantId: string, body: any): Observable<void> {
        const { participants, data } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${participants}/${participantId}${data}`;
        return this.http.put<void>(url, body);
    }

    removeTask(taskId: string): Observable<void> {
        const { tasks } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${tasks}/${taskId}`;
        return this.http.delete<void>(url);
    }

    getParticipants(participantIds: string[]): Observable<IParticipant[]> {
        const { participants } = environmentCommon.threadsEndpoints;
        const { base } = environment.threadsEndpoints;
        const url = `${base}${participants}`;
        return this.http.post<IParticipant[]>(url, { participantIds });
    }

    updateCompleteProfileCard<T>(threadId: string, cardId: string, details: T) {
        const { base } = environment.threadsEndpoints;
        const { base: sameDayTaxBase, secureProfile } = environmentCommon.sameDayTaxEndpoints;
        const url = `${base}${sameDayTaxBase}/threads/${threadId}/cards/${cardId}${secureProfile}`;
        return this.http.post(url, details).pipe(
            map((response: any) => {
                const { updatedUser } = response;
                return {
                    updatedUser
                };
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                return throwError(new Error(errorResponse.error ? errorResponse.error.message : errorResponse.message));
            })
        );
    }

    updateUserTimeZone(userId: string): Observable<void> {
        return this.putUserData(userId, {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });
    }
    public static mapParticipant(participant: IParticipant) {
        const staffTitleOverride = environment.featureFlags.temporaryFeatureFlags.staffTitleOverride;
        if (staffTitleOverride) {
            if (participant.profile && participant.profile.title) {
                return {
                    ...participant,
                    profile: {
                        ...participant.profile,
                        title: staffTitleOverride
                    }
                };
            }
        }
        return participant;
    }
}
