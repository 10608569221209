import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { SignUpUserPayload } from "../../../services/onboarding.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-signup",
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"]
})
export class SignUpComponent implements OnInit {
    form = new FormGroup({
        name: new FormControl(null, Validators.required),
        emailAddress: new FormControl(null, [Validators.required, Validators.email])
    });

    policyUrl = environment.policyUrl;
    termsUrl = environment.termsUrl;
    termsOfBusinessUrl = environment.termsOfBusinessUrl;
    disclaimerUrl = environment.disclaimerUrl;
    readonly textResource = environment.featureFlags.text;
    readonly appName = environment.appName;
    readonly theme = environment.theme;

    @Input() userDataModel: SignUpUserPayload;
    @Input() errorMessage: string;
    @Output() progress: EventEmitter<SignUpUserPayload> = new EventEmitter();

    constructor() {}

    ngOnInit() {
        this.form.setValue({
            name: this.userDataModel.name,
            emailAddress: this.userDataModel.emailAddress
        });
    }

    updateParentModel() {
        this.progress.emit(this.form.value);
    }
}
