import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { IThread, IThreadCard, Role } from "@findex/threads";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { IUiCard } from "src/app/interfaces/IUiCard";
import { Loader } from "src/app/services/loader";
import { ThreadsService } from "src/app/services/threads.service";
import { UiCardService } from "src/app/services/ui-card.service";
import { ISocketEvent, WebsocketService } from "src/app/services/websocket.service";
import { ParticipantCache } from "../../services/participant-cache.service";

@Component({
    selector: "thread",
    templateUrl: "./thread.component.html",
    styleUrls: ["./thread.component.scss"]
})
export class ThreadComponent implements OnChanges, OnDestroy {
    @Input() thread: IThread;
    @Input() role: Role;
    @Input() routeToCardId?: string;
    @Input() excludeCardTypes: string[];
    @Output() loadCardComplete = new EventEmitter<void>();

    private uiCards: IUiCard[] = [];

    uiCardsByDate: { [millis: number]: IUiCard[] };
    sortedDates: string[] = [];
    loader = new Loader();

    private wsSubscription: Subscription;
    private eventSubscription: Subscription;
    private cardRouteSubscription: Subscription;

    constructor(
        private uiCardService: UiCardService,
        private threadsService: ThreadsService,
        private websocketService: WebsocketService,
        private participantsCache: ParticipantCache
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        const { thread, routeToCardId } = changes;
        if (thread && thread.currentValue) {
            this.initThread(thread.currentValue);
        }

        if (routeToCardId && this.uiCards && this.uiCards.length) {
            this.uiCardService.routeToCard(this.uiCards, routeToCardId.currentValue);
        }
    }

    ngOnDestroy() {
        if (this.cardRouteSubscription) {
            this.cardRouteSubscription.unsubscribe();
        }

        if (this.wsSubscription) {
            this.wsSubscription.unsubscribe();
        }

        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }
    }

    trackId(_index: number, data: any) {
        return data.id;
    }

    private async loadCards(thread: IThread): Promise<void> {
        this.uiCards = [];
        this.loader.show();

        const threadCards = await this.threadsService.getCards(this.thread.id).toPromise();
        const filteredCards = this.excludeCardTypes
            ? threadCards.filter(card => !this.excludeCardTypes.includes(card.type))
            : threadCards;

        for (const card of filteredCards.reverse()) {
            this.loadCard(thread, card);
        }

        if (this.routeToCardId) {
            this.uiCardService.routeToCard(this.uiCards, this.routeToCardId);
        }
        this.groupCardsByDate();

        this.loader.hide();
    }

    private async loadCard(thread: IThread, card: IThreadCard) {
        const existingCard = this.uiCards.find(uiCard => uiCard.card.id === card.id);

        if (existingCard) {
            Object.assign(existingCard.card, card);
        } else {
            const uiCard = this.uiCardService.mapCard(thread, card);
            if (!uiCard) return;

            this.uiCards.push(uiCard);
            this.uiCards.sort((a, b) => this.uiCardService.compareCards(a, b));
        }

        this.loadCardComplete.emit();
    }

    private async handleNotification(thread: IThread, notification: ISocketEvent) {
        const { threadId, cardId, eventKey } = notification;
        if (threadId !== thread.id) {
            return console.error("Received event for different thread", threadId, thread.id);
        }

        if (eventKey) {
            const event = await this.threadsService.getEvent(threadId, cardId, eventKey).toPromise();
            this.uiCardService.addCardEvent(this.uiCards, cardId, event);
        }
        const card = await this.threadsService.getCard(threadId, cardId).toPromise();
        this.loadCard(thread, card);
        this.groupCardsByDate();
    }

    private initThread(thread: IThread) {
        this.participantsCache.update(thread.participants);
        this.loadCards(thread);

        if (this.wsSubscription) this.wsSubscription.unsubscribe();
        this.wsSubscription = this.websocketService
            .connect(thread.id)
            .pipe(
                filter(notification => !notification.state),
                filter(notification => !!notification.cardId)
            )
            .subscribe(notification => {
                return this.handleNotification(thread, notification);
            });
    }

    private groupCardsByDate() {
        const uiCardsByDate = this.uiCards.reduce((acc: { [millis: number]: IUiCard[] }, uiCard: IUiCard) => {
            const millis: number = this.timestampToDateMillis(uiCard.card.modifiedAt);
            if (!acc[millis]) {
                acc[millis] = [uiCard];
            } else {
                acc[millis].push(uiCard);
            }
            return acc;
        }, {});
        for (const key of Object.keys(uiCardsByDate)) {
            uiCardsByDate[key].sort((a, b) => this.uiCardService.compareCards(a, b)).reverse();
        }
        //Javascript object keys ordering is now preserved since ES5. Order by date descending
        this.uiCardsByDate = uiCardsByDate;
        this.sortedDates = Object.keys(uiCardsByDate)
            .sort((left, right) => Number(right) - Number(left))
            .reverse();
    }

    private timestampToDateMillis(timestamp: string): number {
        return DateTime.fromISO(timestamp)
            .startOf("day")
            .toMillis();
    }

    formatMillis(millis: string): string {
        return DateTime.fromMillis(Number(millis))
            .toLocaleString(DateTime.DATE_HUGE)
            .replace(/,/g, "");
    }

    get keys() {
        return Object.keys(this.uiCardsByDate || {});
    }
}
