import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectBookkeeperComponent } from "./components/select-bookkeeper/select-bookkeeper.component";
import { CallConfirmationModalComponent } from "./components/call-confirmation-modal/call-confirmation-modal.component";
import { FindAvailabilityModalComponent } from "./components/find-availability-modal/find-availability-modal.component";
import { FindexUiModule } from "../findex-ui";
import { StaffDetailComponent } from "./components/staff-detail/staff-detail.component";
import { ThreadsUiModule } from "../threads-ui";
import { AnalyticsModule } from "../analytics";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { ErrorComponent } from "./components/error/error.component";
import { ErrorModalComponent } from "./components/error/error-modal/error-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { ErrorService } from "./services/error.service";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { StateListComponent } from "src/app/components/threads-state/state-list/state-list.component";
import { MouseWheelDirective } from "./directives/mouseheel.directive";
import { SelectStaffComponent } from "./components/select-staff/select-staff.component";
import { VerifyMobileFormComponent } from "./components/verify-mobile-form/verify-mobile-form.component";
import { ReactiveFormsModule } from "@angular/forms";
import { OnboardingProfileComponent } from "./components/onboarding-profile/onboarding-profile.component";
import { StripHtmlPipe } from "./pipes/strip-html.pipe";

@NgModule({
    imports: [CommonModule, FindexUiModule, ThreadsUiModule, MatDialogModule, ReactiveFormsModule, AnalyticsModule],
    declarations: [
        SelectBookkeeperComponent,
        SelectStaffComponent,
        OnboardingProfileComponent,
        CallConfirmationModalComponent,
        StaffDetailComponent,
        ConfirmModalComponent,
        ErrorComponent,
        ErrorModalComponent,
        PaginationComponent,
        StateListComponent,
        VerifyMobileFormComponent,
        MouseWheelDirective,
        VerifyMobileFormComponent,
        FindAvailabilityModalComponent,
        StripHtmlPipe
    ],
    providers: [ErrorService],
    entryComponents: [
        ConfirmModalComponent,
        ErrorModalComponent,
        PaginationComponent,
        StateListComponent,
        FindAvailabilityModalComponent
    ],
    exports: [
        StripHtmlPipe,
        PaginationComponent,
        StateListComponent,
        MouseWheelDirective,
        VerifyMobileFormComponent,
        AnalyticsModule
    ]
})
export class SharedModule {}
