import { AuthorizationLevel } from "./AuthorizationLevel";

export class User {
    id: string;
    name: string;
    emailAddressVerified: boolean;
    mobileNumberVerified: boolean;
    details: {
        emailAddress?: string;
        mobileNumber?: string;
        lastLoginTimestamp?: string;
        [key: string]: string;
    };
    authorizationLevel: AuthorizationLevel;
    type: UserType;
}

export type UserType = "cognito" | "azuread";
