import { NgModule } from "@angular/core";
import { AnalyticsClickDirective } from "./directives/analytics-click.directive";

const exportDeclarations = [AnalyticsClickDirective];

@NgModule({
    imports: [],
    exports: [...exportDeclarations],
    declarations: [...exportDeclarations]
})
export class AnalyticsModule {}
