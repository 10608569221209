<div
    class="fx-onboarding fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }}"
>
    <div class="fx-onboarding-container fx-container">
        <div class="fx-onboarding-content">
            <div class="fx-onboarding-brand fx-brand"></div>
            <form class="fx-onboarding-form fx-form" [ngClass]="['fx-form--' + theme.formTheme]" (submit)="login()">
                <div class="fx-form-group">
                    <button
                        class="fx-onboarding-submit fx-onboarding-button fx-btn "
                        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                        type="submit"
                    >
                        Login
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
