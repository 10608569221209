import { Component, OnInit, Input } from "@angular/core";
import { IThreadGroupListing, ThreadGrouper } from "src/modules/threads-ui/components/thread-list/thread-grouper";
import { ThreadTypes, Role } from "@findex/threads";

@Component({
    selector: "app-thread-state",
    templateUrl: "./thread-state.component.html",
    styleUrls: ["./thread-state.component.scss"]
})
export class ThreadStateComponent implements OnInit {
    @Input() role: Role;
    @Input() threads: any;

    Role = Role;
    groupedThreads: IThreadGroupListing[];

    constructor() {}

    ngOnInit() {
        this.groupedThreads = ThreadGrouper.groupThreads(
            this.threads.filter(thread => thread.type !== ThreadTypes.Dashboard)
        );
    }
}
