import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { AnalyticsService } from "src/modules/analytics";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { isEqual } from "lodash";
import { environment } from "src/environments/environment";

@Component({
    selector: "industry-dialog",
    templateUrl: "./industry-dialog.component.html",
    styleUrls: ["./industry-dialog.component.scss"]
})
export class IndustryDialogComponent implements OnInit {
    originalIndustryTypes: string[];
    industryTypes: string[];
    isValid: boolean = false;
    readonly theme = environment.theme;

    constructor(
        private dialogRef: MatDialogRef<IndustryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private analyticsService: AnalyticsService
    ) {
        this.industryTypes = [...data.industryTypes];
        this.originalIndustryTypes = [...data.industryTypes];
    }

    ngOnInit(): void {
        this.recordAnalyticsEvent("displayed");
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("industry-dialog", category);
    }

    save() {
        this.dialogRef.close({ industryTypes: this.industryTypes, save: true });
    }

    close() {
        this.dialogRef.close({ industryTypes: [], save: false });
    }

    updateUserModel(industryTypes: string[]) {
        this.industryTypes = [...industryTypes];
    }

    validStateChanged(isValid: boolean) {
        this.isValid = isValid;
    }

    shouldDisableSave() {
        if (!this.isValid) {
            return false;
        }
        return !isEqual(this.originalIndustryTypes, this.industryTypes);
    }
}
