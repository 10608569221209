import { Component, OnInit } from "@angular/core";
import { OnboardingService, SignupBusinessPayload } from "../../services/onboarding.service";
import { AnalyticsService } from "src/modules/analytics";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/modules/findex-auth";
import { switchMap, take } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

export enum OnboardingCompletionStatus {
    BUSINESS_NAME = "business-name",
    INDUSTRY_TYPE = "industry-type"
}

@Component({
    selector: "app-onboarding-completion",
    templateUrl: "./onboarding-completion.component.html",
    styleUrls: ["./onboarding-completion.component.scss"]
})
export class OnboardingCompletionComponent implements OnInit {
    persistedUserDataModel: SignupBusinessPayload = {
        businessName: "",
        industryTypes: []
    };
    //This is updated when the values of the child components changes
    workingUserDataModel: SignupBusinessPayload = {
        businessName: "",
        industryTypes: []
    };
    errorMessage: string = "";

    status: OnboardingCompletionStatus = OnboardingCompletionStatus.BUSINESS_NAME;
    showLoader: boolean = false;
    isValid: boolean = false;

    readonly ALL_STATUSES = OnboardingCompletionStatus;

    constructor(
        private onboardingService: OnboardingService,
        private authService: AuthService,
        private analyticsService: AnalyticsService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.showLoader = true;

        switch (environment.featureFlags.onboardingType) {
            case "none":
                this.router.navigateByUrl("/dashboard");
                return;
            case "sameday":
                this.router.navigateByUrl("/onboarding-profile");
                return;
            case "sigma":
                this.route.queryParams.subscribe(params => {
                    const status = params["status"];
                    if (
                        status === OnboardingCompletionStatus.BUSINESS_NAME ||
                        status === OnboardingCompletionStatus.INDUSTRY_TYPE
                    ) {
                        this.status = status;
                    }
                });

                this.authService
                    .getUser()
                    .pipe(
                        take(1),
                        switchMap(user => this.onboardingService.getCompletionDetails(user.id))
                    )
                    .subscribe((userDetails: SignupBusinessPayload) => {
                        this.workingUserDataModel = { ...userDetails };
                        this.persistedUserDataModel = { ...userDetails };
                        this.showLoader = false;
                    });
        }
    }

    async updateUserModel(updatedClientModel: SignupBusinessPayload) {
        Object.keys(updatedClientModel).map(key => {
            if (key !== undefined || key !== "") {
                this.workingUserDataModel[key] = updatedClientModel[key];
            }
        });
    }

    validStateChanged(isValid: boolean) {
        this.isValid = isValid;
    }

    progressState() {
        this.showLoader = true;
        this.authService
            .getUser()
            .pipe(
                take(1),
                switchMap(user => this.onboardingService.updateCompletionDetails(user.id, this.workingUserDataModel))
            )
            .subscribe(() => {
                this.showLoader = false;
                this.persistedUserDataModel = { ...this.workingUserDataModel };

                const analyticsPath = this.route.snapshot.pathFromRoot
                    .map(value => value.url.map(urlValue => urlValue.toString()).join("/"))
                    .join("/");

                this.analyticsService.recordEvent(`register-completion-${this.status}`, "next");
                this.status = this.getNextState();
                this.analyticsService.pageViewed(analyticsPath);
                this.isValid = false;
            });
    }

    goBack() {
        // remove query string so on reload we return to the correct page
        if (window.location.search.length > 0) {
            window.history.replaceState(null, null, window.location.pathname);
        }
        this.workingUserDataModel = { ...this.persistedUserDataModel };
        this.recordAnalyticsEvent(`${this.status}-go-back`);
        this.status = OnboardingCompletionStatus.BUSINESS_NAME;
    }

    getNextState() {
        switch (this.status) {
            case OnboardingCompletionStatus.BUSINESS_NAME:
                return OnboardingCompletionStatus.INDUSTRY_TYPE;
            case OnboardingCompletionStatus.INDUSTRY_TYPE:
                this.recordAnalyticsEvent(`${this.status}-completed`);
                this.navigateToBookkeeperSelection();
                return this.status;
            default:
                return OnboardingCompletionStatus.BUSINESS_NAME;
        }
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("onboarding-completion", category);
    }

    navigateToBookkeeperSelection() {
        this.router.navigate(["/", "select-bookkeeper"]);
    }
}
