import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Role } from "@findex/threads";
import { environment } from "src/environments/environment";

@Component({
    selector: "meeting-details",
    templateUrl: "./meeting-details.component.html",
    styleUrls: ["./meeting-details.component.scss"]
})
export class MeetingDetailsComponent {
    readonly threadStates = environment.threadStates;
    readonly roles = Role;

    @Input() status: string;
    @Input() name?: string;
    @Input() avatar?: string;
    @Input() position?: string;
    @Input() timestamp: string;
    @Input() joinDisabled: boolean;
    @Input() sessionTerminated: boolean;
    @Input() inverted: boolean;
    @Input() role: Role;
    @Input() threadState: string;

    @Output() terminate = new EventEmitter();
    @Output() join = new EventEmitter();

    constructor() {}

    terminateSession() {
        this.terminate.emit();
    }
}
