import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IThread, Role } from "@findex/threads";
import { Observable } from "rxjs";
import { Loader } from "src/app/services/loader";
import { VaultService } from "@findex/vault";
import { Router } from "@angular/router";
import { ThreadsVaultService, VaultDocument } from "../../services/threads-vault.service";

@Component({
    selector: "document-list",
    templateUrl: "./document-list.component.html",
    styleUrls: ["./document-list.component.scss"]
})
export class DocumentListComponent implements OnChanges {
    @Input() thread: IThread;
    @Input() role: Role;

    roles = Role;
    loader = new Loader();
    documents$: Observable<VaultDocument[]>;

    constructor(
        private router: Router,
        private vaultService: VaultService,
        private threadsVaultService: ThreadsVaultService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        const { thread } = changes;

        if (thread && thread.currentValue) {
            const threadId = thread.currentValue.id;
            const documents$ = this.threadsVaultService.getThreadDocuments(threadId);
            this.documents$ = this.loader.wrap(documents$);
        }
    }

    sign(cardId: string, vaultId: string, fileId: string) {
        this.router.navigate([`/timelines/${this.thread.id}/cards/${cardId}`], {
            queryParams: {
                vaultId: vaultId,
                fileId: fileId
            }
        });
    }

    async download(document: VaultDocument) {
        this.loader.show();
        const { vaultId, fileId, filename } = document;
        const downloadWindow = window.open("", "_self");
        const downloadUrl = await this.vaultService.getDownloadUrl(vaultId, fileId, filename).toPromise();
        downloadWindow.location.href = downloadUrl;
        this.loader.hide();
    }
}
