<ng-container *ngIf="user$ | async as user; else loggedOut">
    <div class="fx-l" [ngClass]="{ 'fx-l--show-nav': (showMenu$ | async) == true, 'fx-l--naked': hideLayout }">
        <nav class="fx-l-nav" *ngIf="user">
            <findex-slide-menu>
                <div slide-menu-drawer class="slide-menu-drawer">
                    <div class="slide-menu-navigation">
                        <div
                            class="slide-menu-avatar"
                            (click)="menuAction()"
                            analyticsClick="app_viewprofile"
                            [routerLink]="['profile']"
                        >
                            <div class="slide-menu-avatar-image">
                                <fx-avatar [name]="user.name"></fx-avatar>
                            </div>
                            <div class="slide-menu-avatar-title">
                                {{ user.name }}
                                <span
                                    class="slide-menu-avatar-subtitle"
                                    *ngIf="userOnboardingProfile$ | async; let userOnboardingProfile"
                                >
                                    {{ userOnboardingProfile.businessName }}</span
                                >
                            </div>
                        </div>

                        <ng-container *ngIf="subscriptionEnabled">
                            <!-- TODO: move all this into a component. No time right now -->
                            <ng-container *ngIf="subscription$ | async as subscription; else noSubscription">
                                <div class="slide-menu-subscription" role="button">
                                    <app-slide-menu-icon></app-slide-menu-icon>
                                    <div class="slide-menu-subscription-content">
                                        <span class="slide-menu-subscription-type"
                                            >You are on {{ subscription.name }}
                                            <ng-container *ngIf="subscription.trial"> trial</ng-container></span
                                        >
                                        <a
                                            [routerLink]="['profile']"
                                            analyticsClick="app_viewprofile"
                                            [queryParams]="{ subscriptions: 1 }"
                                            class="slide-menu-subscription-anchor"
                                        >
                                            View {{ packageDisplayName }}
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #noSubscription>
                                <div class="slide-menu-subscription" [routerLink]="['profile']" role="button">
                                    <app-slide-menu-icon></app-slide-menu-icon>
                                    <div class="slide-menu-subscription-content">
                                        <span class="slide-menu-subscription-type"
                                            >No active {{ packageDisplayName }}
                                        </span>
                                        <a
                                            [routerLink]="['profile']"
                                            analyticsClick="app_viewprofile"
                                            class="slide-menu-subscription-anchor"
                                        >
                                            View {{ packageDisplayName }}
                                        </a>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>

                        <ul class="slide-menu-links slide-menu-links--upper">
                            <li *ngIf="user.emailAddressVerified && user.mobileNumberVerified"></li>
                            <li *ngIf="user.emailAddressVerified && user.mobileNumberVerified" id="dashboard-menu-item">
                                <a
                                    (click)="menuAction()"
                                    [routerLink]="['/dashboard']"
                                    analyticsClick="app_viewdashboard"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    ><i class="las la-sliders-h"></i>Dashboard</a
                                >
                            </li>

                            <li *ngIf="user.emailAddressVerified && user.mobileNumberVerified" id="timeline-menu-item">
                                <a
                                    (click)="menuAction()"
                                    analyticsClick="app_viewtimelines"
                                    [routerLink]="['/timelines']"
                                    routerLinkActive="active"
                                    ><i class="las la-comments"></i>Timelines</a
                                >
                            </li>

                            <li *ngIf="role | minRole: roles.Staff" id="reports-menu-item">
                                <a
                                    (click)="menuAction()"
                                    analyticsClick="app_viewreports"
                                    [routerLink]="['/reports']"
                                    routerLinkActive="active"
                                    ><i class="las la-chart-bar"></i>Reports</a
                                >
                            </li>

                            <li *ngIf="role | minRole: roles.Staff">
                                <a
                                    (click)="menuAction()"
                                    [routerLink]="['/admin']"
                                    analyticsClick="app_viewadmin"
                                    routerLinkActive="active"
                                    ><i [class]="'las ' + (role === roles.Staff ? 'la-user' : 'la-user-cog')"></i
                                    >{{ role === roles.Staff ? "Clients" : "Admin" }}</a
                                >
                            </li>
                        </ul>
                    </div>
                    <div class="slide-menu-navigation">
                        <ul class="slide-menu-links">
                            <li *ngIf="supportEmail">
                                <a href="mailto:{{ supportEmail }}" analyticsClick="app_contactsupport"
                                    ><i class="las la-envelope"></i> Support</a
                                >
                            </li>
                            <li *ngIf="user.emailAddressVerified && user.mobileNumberVerified">
                                <a
                                    (click)="menuAction()"
                                    analyticsClick="app_viewprofile"
                                    [routerLink]="['profile']"
                                    [routerLinkActive]="'active'"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    ><i class="las la-cog"></i> Settings</a
                                >
                            </li>
                            <li>
                                <a (click)="logout()" analyticsClick="app_logout" role="button"
                                    ><i class="las la-power-off"></i> Logout</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </findex-slide-menu>
        </nav>
        <div class="fx-l-canvas">
            <div class="fx-l-header" *ngIf="user">
                <findex-header
                    [layoutOpen]="showMenu$ | async"
                    [user]="user"
                    product="EDGE"
                    (logout)="logout()"
                    (menu)="menuAction()"
                ></findex-header>
            </div>
            <div class="fx-l-main">
                <findex-main [loading]="loader.counter">
                    <router-outlet></router-outlet>
                </findex-main>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loggedOut>
    <router-outlet></router-outlet>
</ng-template>
