import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { User } from "../../../findex-auth/model/User";
import { environment } from "src/environments/environment";

@Component({
    selector: "findex-header",
    templateUrl: "./findex-header.component.html",
    styleUrls: ["./findex-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindexHeaderComponent implements OnInit {
    readonly stage = environment.stage;

    @Input() product: string;
    @Input() user: User;
    @Input() layoutOpen: boolean;
    @Output() login = new EventEmitter();
    @Output() logout = new EventEmitter();
    @Output() menu = new EventEmitter();

    userName: string;

    ngOnInit(): void {
        this.userName = this.user ? this.user.name : "";
    }
}
