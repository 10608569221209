<div class="meeting-details">
    <div class="meeting-details-status">{{ status }}</div>

    <div class="meeting-details-participant">
        <ng-container *ngIf="avatar">
            <fx-avatar [name]="avatar" [isLarge]="true"></fx-avatar>
        </ng-container>

        <span class="meeting-details-name" *ngIf="name">
            {{ name }}
        </span>
        <p class="meeting-details-position" *ngIf="position">{{ position }}</p>
        <p>{{ timestamp | date: "d MMM, hh.mm aaa" }}</p>
    </div>

    <div *ngIf="threadState | isThreadActive" class="meeting-details-controls">
        <button
            class="fx-btn fx-btn--primary-outlined"
            role="button"
            *ngIf="!sessionTerminated"
            analyticsClick="app_joinvc"
            [disabled]="joinDisabled"
            (click)="join.emit()"
        >
            JOIN
        </button>

        <button
            class="fx-btn fx-btn--primary-outlined"
            role="button"
            *ngIf="!sessionTerminated && role | minRole: roles.Staff"
            (click)="terminateSession()"
            analyticsClick="app_endvcsession"
        >
            END SESSION
        </button>
    </div>
</div>
