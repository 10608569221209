<loader-icon *ngIf="loader.counter" color="light"></loader-icon>
<div
    class="fx-onboarding fx-onboarding--high-logo fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }}"
    *ngIf="!loader.counter"
>
    <div class="fx-onboarding-header">
        <div class="fx-brand"></div>
    </div>

    <div class="fx-onboarding-container fx-container" *ngIf="bookkeepers?.length > 0">
        <pagination [steps]="steps" [activeStep]="activeStep"></pagination>

        <div class="fx-onboarding-content">
            <div class="fx-onboarding-text">
                <div class="fx-onboarding-go-back">
                    <button class="fx-onboarding-back-button" (click)="goBack()">
                        <i class="fx-onboarding-back-icon las la-long-arrow-alt-left"></i>
                    </button>
                </div>
                <h1 class="fx-onboarding-title select-bookkeeper-title">Meet your bookkeeper</h1>
                <p class="fx-onboarding-body">
                    Schedule a meeting with a bookkeeper specialising in your industry
                </p>
            </div>

            <div class="select-staff-list">
                <div class="select-staff" *ngFor="let staffProfile of bookkeepers">
                    <div class="select-staff-content">
                        <div class="select-staff-background"></div>
                        <app-staff-detail [staffProfile]="staffProfile"></app-staff-detail>
                        <div class="select-staff-buttons">
                            <button
                                class="fx-btn fx-btn--primary fx-btn--primary-outlined"
                                type="submit"
                                (click)="requestChat(staffProfile, 'call')"
                            >
                                Schedule meeting
                            </button>
                            <button
                                class="fx-btn fx-btn--white"
                                type="submit"
                                (click)="requestChat(staffProfile, 'message')"
                            >
                                Message {{ getFirstName(staffProfile) }}
                            </button>
                        </div>
                    </div>
                </div>
                <p *ngIf="error" class="text-danger">{{ error }}</p>
            </div>
        </div>
    </div>
</div>
