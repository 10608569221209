import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule
} from "@angular/material";
import { FxUiModule } from "@findex/fx-ui";
import { VaultModule, VAULT_BASE } from "@findex/vault";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgxDropzoneModule } from "ngx-dropzone";
import { environment } from "src/environments/environment";

import { VaultCardComponent } from "./components/vault-card/vault-card.component";
import { CreateRfiComponent } from "./components/create-rfi/create-rfi.component";
import { UploadComponent } from "./components/upload/upload.component";
import { UploadItemComponent } from "./components/upload/upload-item/upload-item.component";
import { UploadCancelModalComponent } from "./components/upload/upload-cancel-modal/upload-cancel-modal.component";
import { CreateRfiDocumentComponent } from "./components/create-rfi/create-document/create-document.component";
import { CreateRfiRemoveDocumentComponent } from "./components/create-rfi/remove-document/remove-document.component";
import { CreateRfiSuccessComponent } from "./components/create-rfi/success/success.component";
import { FileInputComponent } from "./components/file-input/file-input.component";
import { SigningModalComponent } from "./components/signing-modal/signing-modal.component";
import { CompleteRfiModal } from "./components/upload/complete-rfi-modal/complete-rfi-modal.component";

import { IncludesPipe } from "./pipes/includes.pipe";
import { FindexAuthModule } from "../../findex-auth";
import { ThreadsUiModule } from "../../threads-ui";
import { FindexUiModule } from "src/modules/findex-ui";
import { AnalyticsModule } from "src/modules/analytics";

@NgModule({
    imports: [
        CommonModule,
        VaultModule,
        MatButtonModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        FindexAuthModule,
        FxUiModule,
        ThreadsUiModule,
        FindexUiModule,
        NgxDropzoneModule,
        AnalyticsModule
    ],
    exports: [],
    declarations: [
        VaultCardComponent,
        CreateRfiComponent,
        UploadComponent,
        UploadItemComponent,
        UploadCancelModalComponent,
        IncludesPipe,
        CreateRfiDocumentComponent,
        CreateRfiRemoveDocumentComponent,
        CreateRfiSuccessComponent,
        FileInputComponent,
        SigningModalComponent,
        CompleteRfiModal
    ],
    entryComponents: [
        VaultCardComponent,
        CreateRfiComponent,
        UploadComponent,
        UploadCancelModalComponent,
        CreateRfiDocumentComponent,
        CreateRfiRemoveDocumentComponent,
        CreateRfiSuccessComponent,
        SigningModalComponent,
        CompleteRfiModal
    ],
    providers: [{ provide: VAULT_BASE, useValue: environment.vaultEndpoints.base }]
})
export class VaultCardModule {}
