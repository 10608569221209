import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { Role } from "@findex/threads";
import { VaultService } from "@findex/vault";
import { Observable } from "rxjs";
import { map, pluck } from "rxjs/operators";
import { Loader } from "src/app/services/loader";
import { SigmaService } from "src/app/services/sigma.service";
import { ThreadsService } from "src/app/services/threads.service";
import { environment } from "src/environments/environment";
import { Document } from "./create-card-document/create-card-document.component";
import { RenameFileModalComponent } from "./rename-file-modal/rename-file-modal.component";
import { ThreadStateService } from "src/modules/threads-ui/services/thread-state.service";
export interface ICreateCardEvent {
    type: string;
    inputContext: { value: string };
}

@Component({
    selector: "create-card",
    templateUrl: "./create-card.component.html",
    styleUrls: ["./create-card.component.scss"]
})
export class CreateCardComponent implements OnInit {
    readonly threadStates = environment.threadStates;

    @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;

    @Input() role: Role;
    @Output() newCard = new EventEmitter<ICreateCardEvent>();

    documents: Document[] = [];
    message: string = "";
    errorMessage: string;
    toggleContextMenuState: boolean;
    roles = Role;
    loading = false;
    formError: string = null;
    showAction$: Observable<boolean>;

    constructor(
        private vaultService: VaultService,
        private threadsService: ThreadsService,
        private threadStateService: ThreadStateService,
        private cardService: SigmaService,
        private loader: Loader,
        private route: ActivatedRoute,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        const threadId = this.route.snapshot.params.threadId;
        this.showAction$ = this.threadsService.getThread(threadId).pipe(
            pluck("state"),
            map(state => this.threadStateService.isActiveThread(state))
        );
    }

    async addCard(type: string): Promise<void> {
        this.toggleContextMenu();
        const event: ICreateCardEvent = { type, inputContext: { value: this.message } };
        this.newCard.emit(event);
    }

    async sendMessage() {
        const threadId = this.route.snapshot.params.threadId;

        this.loader.show();
        this.errorMessage = null;

        try {
            if (this.documents.length > 0) {
                await this.sendFiles(threadId, this.message, this.documents);
            } else {
                await this.cardService.createMessageCard(threadId, { message: this.message }).toPromise();
            }
        } catch (err) {
            this.errorMessage = "A problem occurred sending the message";
            throw err;
        } finally {
            this.message = "";
            this.documents = new Array();
            this.loader.hide();
            this.toggleContextMenuState = false;
        }
    }

    menuState(event: boolean) {
        this.toggleContextMenuState = event;
    }

    toggleContextMenu() {
        this.toggleContextMenuState = !this.toggleContextMenuState;
    }

    attachFile(files: FileList) {
        Array.from(files).forEach((val: any) => {
            this.documents.push({ description: val.name, file: val });
        });
        this.fileUpload.nativeElement.value = null;
    }

    removeFile(document: Document) {
        const index = this.documents.indexOf(document);
        this.documents.splice(index, 1);
    }

    renameFile(document: Document) {
        this.dialog
            .open(RenameFileModalComponent, { data: document })
            .afterClosed()
            .subscribe(updatedDescription => {
                if (!updatedDescription) return;
                document.description = updatedDescription;
            });
    }

    validateForm() {
        this.formError = null;
        if (!this.message) {
            this.formError = "Please supply a message";
        }
    }

    private async sendFiles(threadId: string, message: string, documents: Document[]) {
        const card = await this.cardService.createVaultCard(threadId, { description: message }).toPromise();
        const vaultId = card.subjects[0].id;

        for (const document of documents) {
            const { description, file, signature } = document;
            const fileId = await this.vaultService.uploadFile(vaultId, description, file).toPromise();

            const declaration = `I authorise to bind this/these entiries to the engagement and confirm acceptance of the Terms of Business`;
            if (signature && typeof fileId === "string") {
                await this.vaultService.setSignable(vaultId, fileId, declaration).toPromise();
            }
        }
    }
}
