import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { IThread, Role } from "@findex/threads";
import { Loader } from "src/app/services/loader";
import { SamedayService } from "../../../samedaytax/services/sameday.service";
import { ClientDetailsData } from "../client-details-form/client-details-form.component";
import { UserProfileService } from "src/modules/user-profile/services/user-profile.service";
import { VaultService } from "@findex/vault";
import { switchMap, map, tap } from "rxjs/operators";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { ThreadsVaultService, VaultDocument } from "src/modules/threads-ui/services/threads-vault.service";
import { Observable, zip } from "rxjs";
import { SubscriptionService } from "src/modules/user-profile/services/subscription.service";

enum FormState {
    ClientDetails,
    FileSync
}

@Component({
    selector: "sync-files-modal",
    templateUrl: "./sync-files-modal.component.html",
    styleUrls: ["./sync-files-modal.component.scss"]
})
export class SyncFilesModalComponent implements OnInit {
    FormState = FormState;
    currentState: FormState;
    thread: IThread;
    role: Role;
    loader = new Loader();
    userId: string;
    clientDetails$: Observable<ClientDetailsData>;

    uploadFilename: string;
    uploadProgress: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<SyncFilesModalComponent>,
        private threadsVaultService: ThreadsVaultService,
        private sameday: SamedayService,
        private profileService: UserProfileService,
        private vaultService: VaultService,
        private http: HttpClient,
        private subscriptionService: SubscriptionService
    ) {
        this.thread = data.thread;
        this.role = data.role;

        const firstClient = this.thread.participants.find(user => user.role == Role.Client);
        if (firstClient) {
            this.userId = firstClient.id;
        }
    }

    ngOnInit() {
        this.currentState = FormState.ClientDetails;

        const clientDetails = zip(
            this.subscriptionService.getUserSubscriptionDetails(this.userId),
            this.profileService.getPrivateProfileDetails<ClientDetailsData>(this.userId)
        ).pipe(
            map(([subscription, privateData]) => ({
                ...privateData,
                packageId: subscription.currentPackage && subscription.currentPackage.packageId
            }))
        );

        this.clientDetails$ = this.loader.wrap(clientDetails);
    }

    close() {
        this.dialogRef.close(false);
    }

    async completeClientDetails(data: ClientDetailsData) {
        if (!this.userId) throw new Error("No clients found");
        await this.profileService.updatePrivateProfileInfo(this.userId, data).toPromise();

        this.currentState = FormState.FileSync;
        this.uploadDocuments(data.taxFileNumber);
    }

    async uploadDocuments(tfnNumber: string) {
        this.loader.show();
        const documents = await this.threadsVaultService.getThreadDocuments(this.thread.id);

        for (const document of documents) {
            await this.syncToFileSync(tfnNumber, document).toPromise();
        }

        this.loader.hide();
        this.dialogRef.close(true);
    }

    private syncToFileSync(taxFileNumber: string, document: VaultDocument) {
        const { vaultId, fileId, filename, signed } = document;
        const year = new Date().getUTCFullYear().toString();

        return this.vaultService.getDownloadUrl(vaultId, fileId, filename).pipe(
            tap(() => (this.uploadFilename = filename)),
            tap(() => (this.uploadProgress = 1)),
            switchMap(url => this.http.get(url, { responseType: "blob" })),
            map(blob => new File([blob], filename)),
            switchMap(file => this.sameday.uploadFile(file, taxFileNumber, year, filename, signed)),
            tap(event => {
                if (event && event.type === HttpEventType.UploadProgress) {
                    this.uploadProgress = Number((event.loaded / event.total) * 98);
                }
            })
        );
    }
}
