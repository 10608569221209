import { INewsArticle } from "./INewsArticle";
import { INewsArticleMapper } from "./news-article-mapper";
import { IPortalNewsArticle } from "./IPortalNewsArticle";

export class PortalNewsArticle implements INewsArticleMapper {
    map(response: any): INewsArticle[] {
        return response.data.findexCms.insightses.slice(0, 1).map((args: IPortalNewsArticle) => {
            return {
                id: args.id,
                title: args.title,
                date: args.createdAt,
                author: args.adviser ? `${args.adviser.givenName} ${args.adviser.surname}` : "",
                body: args.body.html.split("</p><p>")[1],
                url: `${args.webAddress}`,
                bgUrl: `https://media.graphcms.com/${args.previewImage.handle}`
            };
        });
    }
}
