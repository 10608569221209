import { Pipe, PipeTransform } from "@angular/core";
import { IParticipant } from "@findex/threads";
import { IThreadNodeListing } from "../components/thread-list/thread-list-node/thread-list-node.component";

@Pipe({ name: "threadsSearch" })
export class ThreadsSearchPipe implements PipeTransform {
    transform(threads: IThreadNodeListing[], searchTerm: string) {
        if (!searchTerm || !threads) return threads;

        const lowerSearch = searchTerm.toLowerCase();
        return threads.filter(thread => this.searchThread(thread, lowerSearch));
    }

    private searchThread(thread: IThreadNodeListing, term: string): boolean {
        const threadContains =
            (thread.title && thread.title.toLowerCase().includes(term)) ||
            (thread.type && thread.type.toLowerCase().includes(term)) ||
            (thread.participants && thread.participants.find(participant => this.searchParticipant(participant, term)));

        if (thread.children) {
            return !!threadContains || thread.children.some(child => this.searchThread(child, term));
        } else {
            return !!threadContains;
        }
    }

    private searchParticipant(participant: IParticipant, term: string): boolean {
        if (!participant || !participant.profile) return false;

        const { name, emailAddress } = participant.profile;
        return (
            (name && name.toLowerCase().includes(term)) || (emailAddress && emailAddress.toLowerCase().includes(term))
        );
    }
}
