import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { of, Subscription } from "rxjs";
import { map, switchMap, filter } from "rxjs/operators";
import { Loader } from "src/app/services/loader";
import { environment } from "src/environments/environment";
import { AuthService } from "src/modules/findex-auth/services/auth.service";
import { CustomValidators } from "../../../shared/validators";
import { LoginStep, LoginStepDetails, LoginChallenge } from "../../model/LoginStep";
import { ForgotPasswordDialogComponent } from "../forgot-password-dialog/forgot-password-dialog.component";
import { NewPasswordDialogComponent } from "../new-password-dialog/new-password-dialog.component";
import { TwoFactorAuthenticationDialogComponent } from "../two-factor-authentication-dialog/two-factor-authentication-dialog.component";

@Component({
    selector: "login-form",
    templateUrl: "./login-form.component.html",
    styleUrls: ["./login-form.component.scss"]
})
export class LoginFormComponent implements OnInit, OnDestroy {
    form = new FormGroup({
        emailAddress: new FormControl(null, [Validators.required, Validators.email]),
        password: new FormControl(null, [Validators.required, CustomValidators.passwordValidator])
    });

    @Output() userLoggedIn: EventEmitter<boolean> = new EventEmitter<boolean>();

    loader = new Loader();
    readonly appName = environment.appName;
    readonly theme = environment.theme;
    readonly showAgreePoliciesLogin = environment.featureFlags.showAgreePoliciesLogin;
    readonly policyUrl = environment.policyUrl;
    readonly termsUrl = environment.termsUrl;
    readonly termsOfBusinessUrl = environment.termsOfBusinessUrl;
    readonly disclaimerUrl = environment.disclaimerUrl;
    readonly emailDisclaimerUrl = environment.emailDisclaimerUrl;

    showSignup: boolean;
    errorMessage: string = "";

    private loginSubscription: Subscription;

    constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {
        this.showSignup = environment.featureFlags.signupEnabled;
    }

    async ngOnInit() {
        this.activatedRoute.queryParams.subscribe(async params => {
            this.form.setValue({
                emailAddress: params["emailAddress"] || "",
                password: ""
            });
        });
    }

    ngOnDestroy(): void {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
    }

    async login(): Promise<any> {
        try {
            this.loader.show();

            const { emailAddress, password } = this.form.value;
            const { loginRequired, verificationSent, errorMessage } = await this.authService.checkSignUpStatus(
                emailAddress
            );
            if (loginRequired) {
                this.handleLogin(emailAddress, password);
            } else if (verificationSent) {
                this.errorMessage = "Verification email sent";
            } else {
                this.errorMessage = errorMessage ? errorMessage : "Incorrect username or password";
            }
        } catch (error) {
            this.errorMessage = "Incorrect username or password";
        } finally {
            this.loader.hide();
        }
    }

    handleLogin(emailAddress: string, password: string) {
        const login$ = this.authService.loginWithEmail(emailAddress, password).pipe(
            switchMap(loginStepDetails => this.handleLoginStep(loginStepDetails)),
            //Filter out other steps here, as other steps will trigger more events through this stream
            filter((loginStepDetails: LoginStepDetails) =>
                [LoginStep.LOGIN_CANCELLED, LoginStep.LOGIN_COMPLETE].includes(loginStepDetails.step)
            )
        );

        this.loginSubscription = this.loader.wrap(login$).subscribe(
            (loginStepDetails: LoginStepDetails) => {
                if (loginStepDetails.step === LoginStep.LOGIN_COMPLETE) {
                    this.userLoggedIn.emit(true);
                }

                if (this.loginSubscription) {
                    this.loginSubscription.unsubscribe();
                    this.loginSubscription = null;
                }
            },
            error => {
                this.errorMessage = error && error.message ? error.message : "Incorrect username or password";
            }
        );
    }

    private handleLoginStep(loginStepDetails: LoginStepDetails) {
        const step =
            loginStepDetails.step === LoginStep.LOGIN_CHALLENGE ? loginStepDetails.details.type : loginStepDetails.step;
        if (step === LoginStep.PASSWORD_RESET) {
            return this.showNewPasswordDialog(loginStepDetails.details.userDetails).pipe(
                map((submitted: boolean) => {
                    if (submitted) {
                        return { step: LoginStep.LOGIN_PROCESSING };
                    } else {
                        return { step: LoginStep.LOGIN_CANCELLED };
                    }
                })
            );
        }

        if (step === LoginChallenge.smsMfa) {
            return this.show2faDialog(loginStepDetails.details.userDetails).pipe(
                map((submitted: boolean) => {
                    if (submitted) {
                        return { step: LoginStep.LOGIN_PROCESSING };
                    } else {
                        return { step: LoginStep.LOGIN_CANCELLED };
                    }
                })
            );
        }
        return of(loginStepDetails);
    }

    showNewPasswordDialog(userDetails: any) {
        const options = {
            disableClose: true,
            panelClass: ["modal-container", "mat-dialog-no-styling"],
            maxWidth: "100%",
            minWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: {
                userDetails
            }
        };

        return this.dialog.open(NewPasswordDialogComponent, options).afterClosed();
    }

    show2faDialog(userDetails: any) {
        const options = {
            disableClose: true,
            panelClass: ["modal-container", "mat-dialog-no-styling"],
            maxWidth: "100%",
            minWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: {
                userDetails
            }
        };

        return this.dialog.open(TwoFactorAuthenticationDialogComponent, options).afterClosed();
    }

    showForgotPassword($event) {
        $event.stopPropagation();
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container", "mat-dialog-no-styling"],
            maxWidth: "100%",
            minWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%"
        };

        return this.dialog.open(ForgotPasswordDialogComponent, options);
    }

    loginCancelled() {
        this.userLoggedIn.emit(false);
    }
}
