import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { IThread } from "@findex/threads";
import { ICreateCardData } from "src/app/components/thread-route/thread-route.component";
import { Loader } from "src/app/services/loader";
import { SigmaService } from "src/app/services/sigma.service";
import { environment } from "../../../../../environments/environment";
import { CreateRfiDocumentComponent } from "./create-document/create-document.component";
import { CreateRfiRemoveDocumentComponent } from "./remove-document/remove-document.component";
import { CreateRfiSuccessComponent } from "./success/success.component";

export interface IDocuments {
    description: string;
    category: string;
}

export interface IRfi {
    description: string;
    documents: IDocuments[];
}

@Component({
    selector: "create-rfi",
    templateUrl: "./create-rfi.component.html",
    styleUrls: ["./create-rfi.component.scss"]
})
export class CreateRfiComponent implements AfterViewInit {
    rfiCategories = environment.featureFlags.rfiCategories;
    participants: string;

    loader = new Loader();
    documents = new FormArray([]);

    documentsForm = new FormGroup({
        description: new FormControl("", Validators.required),
        documents: this.documents
    });

    readonly theme = environment.theme;

    @ViewChild("description", { static: false }) public description: ElementRef;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: ICreateCardData,
        private cardService: SigmaService,
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<CreateRfiComponent>,
        private router: Router
    ) {
        this.documentsForm.patchValue({ description: this.data.inputContext.value });
        this.participants = this.createParticipantList(this.data.thread);
    }

    ngAfterViewInit() {
        this.description.nativeElement.focus();
    }

    addDocument(
        $event: Event,
        form: AbstractControl,
        category: string,
        documentType?: string,
        index?: number,
        edit?: boolean
    ) {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        const dialogRef = this.dialog.open(CreateRfiDocumentComponent, {
            data: { documentType: documentType, edit: edit ? true : false },
            panelClass: ["threads-sidebar", "threads-sidebar-inner-modal"],
            autoFocus: true
        });

        dialogRef.afterClosed().subscribe(data => {
            if (!data) {
                return;
            }
            if (data.documentType && !data.edit) {
                (form as FormArray).push(
                    new FormGroup({
                        category: new FormControl(category),
                        description: new FormControl(data.documentType)
                    })
                );
            }
            if (data.documentType && data.edit) {
                (this.documentsForm.controls["documents"] as FormArray)
                    .at(index)
                    .patchValue({ description: data.documentType });
            }
        });
    }

    createParticipantList(thread: IThread) {
        return thread.participants
            .filter(participant => participant.profile && participant.profile.name)
            .map(participant => participant.profile.name)
            .join(", ");
    }

    removeDocument($event: Event, index: number, category: string, documentType: string) {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        const dialogRef = this.dialog.open(CreateRfiRemoveDocumentComponent, {
            data: {
                category: category,
                documentType: documentType
            },
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container", "threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%"
        });

        dialogRef.afterClosed().subscribe(data => {
            if (!data) {
                return;
            }
            (this.documentsForm.controls["documents"] as FormArray).removeAt(index);
        });
    }

    async createRfis(form: FormGroup) {
        const { description, documents } = form.value;
        const { id } = this.data.thread;
        this.loader.show();

        try {
            const card = await this.cardService.createVaultCard(id, { description, documents }).toPromise();
            this.dialog
                .open(CreateRfiSuccessComponent, {
                    data: { participants: this.participants },
                    disableClose: true,
                    backdropClass: "modal-backdrop",
                    panelClass: ["modal-container", "threads-sidebar", "mat-dialog-no-styling"],
                    maxWidth: "100%",
                    maxHeight: "100%",
                    minHeight: "100%"
                })
                .afterClosed()
                .subscribe(result => {
                    if (result && result.viewCard) {
                        this.router.navigate(["/timelines", id, "cards", card.id]);
                        this.dialogRef.close();
                    }
                });
        } finally {
            this.loader.hide();
        }
    }

    close() {
        this.dialogRef.close();
    }
}
