import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ICardEvent } from "@findex/threads";
import { Router } from "@angular/router";
import { ActivityDate } from "../../types/activity-date";

@Component({
    selector: "all-activity",
    templateUrl: "./all-activity.component.html",
    styleUrls: ["./all-activity.component.scss"]
})
export class AllActivityComponent {
    activityDates: ActivityDate[];
    title = "Activity";

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<AllActivityComponent>,
        private router: Router
    ) {
        this.activityDates = data.activityDates;
    }

    close() {
        this.dialogRef.close();
    }

    navigateToThread(event: ICardEvent<any>) {
        const { payload } = event;
        if (payload && payload.threadId) {
            if (payload.subjectId) {
                this.router.navigate(["timelines", payload.threadId, "cards", payload.subjectId]);
            } else {
                this.router.navigate(["timelines", payload.threadId]);
            }
        }
        this.close();
    }
}
