import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { LoginDialogComponent } from "./components/login-dialog/login-dialog.component";
import { FindexUiModule } from "../findex-ui";
import { CommonModule } from "@angular/common";
import { MatButtonModule, MatDialogModule, MatFormFieldModule, MatInputModule } from "@angular/material";
import { RouterModule } from "@angular/router";
import { LoginPageComponent } from "./components/login-page/login-page.component";
import { LoginFormComponent } from "./components/login-form/login-form.component";
import { StaffLoginPageComponent } from "./components/staff-login-page/staff-login-page.component";
import { ForgotPasswordFormComponent } from "./components/forgot-password-form/forgot-password-form.component";
import { ForgotPasswordDialogComponent } from "./components/forgot-password-dialog/forgot-password-dialog.component";
import { NewPasswordFormComponent } from "./components/new-password-form/new-password-form.component";
import { NewPasswordDialogComponent } from "./components/new-password-dialog/new-password-dialog.component";
import { TwoFactorAuthenticationFormComponent } from "./components/two-factor-authentication-form/two-factor-authentication-form.component";
import { TwoFactorAuthenticationDialogComponent } from "./components/two-factor-authentication-dialog/two-factor-authentication-dialog.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { AnalyticsModule } from "../analytics";
import { MsalModule } from "@azure/msal-angular";
import { msalConfig } from "./services/strategies/azure-ad-auth.strategy";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        HttpClientModule,
        FindexUiModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        RouterModule,
        AnalyticsModule,
        MsalModule.forRoot(msalConfig)
    ],
    exports: [LoginDialogComponent],
    declarations: [
        LoginDialogComponent,
        LoginPageComponent,
        LoginFormComponent,
        StaffLoginPageComponent,
        ForgotPasswordFormComponent,
        ForgotPasswordDialogComponent,
        NewPasswordFormComponent,
        NewPasswordDialogComponent,
        TwoFactorAuthenticationFormComponent,
        TwoFactorAuthenticationDialogComponent,
        ResetPasswordComponent
    ],
    entryComponents: [
        LoginDialogComponent,
        LoginPageComponent,
        LoginFormComponent,
        StaffLoginPageComponent,
        ForgotPasswordDialogComponent,
        NewPasswordDialogComponent,
        TwoFactorAuthenticationDialogComponent
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }]
})
export class FindexAuthModule {}
