<fx-tabs>
    <fx-tabs-item header="Profile" #profileTab>
        <div class="user-settings-content">
            <app-user-profile></app-user-profile>
        </div>
    </fx-tabs-item>
    <fx-tabs-item [header]="packageDisplayName" *ngIf="subscriptionEnabled" #subscriptionTab>
        <div class="user-settings-content">
            <app-subscription></app-subscription>
        </div>
    </fx-tabs-item>
</fx-tabs>
