import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivePackageDetails } from "../../services/user-profile.service";
import { ActivatedRoute } from "@angular/router";
import { Loader } from "../../../../app/services/loader";
import { catchError, filter, switchMap, switchMapTo, tap, pluck, map } from "rxjs/operators";
import { throwError, Observable, Subscription } from "rxjs";
import { SubscriptionDetails, SubscriptionService } from "../../services/subscription.service";
import { MatDialog } from "@angular/material/dialog";
import {
    ConfirmModalComponent,
    ConfirmModalParams
} from "../../../shared/components/confirm-modal/confirm-modal.component";
import { HandledError } from "src/app/services/sentry-error-handler";
import { environment } from "src/environments/environment";
import { AuthService } from "src/modules/findex-auth";

@Component({
    selector: "app-subscription",
    templateUrl: "./subscription.component.html",
    styleUrls: ["./subscription.component.scss"]
})
export class SubscriptionComponent implements OnInit, OnDestroy {
    readonly packageDisplayName: string;
    private profileSubscription: Subscription;

    errorMessage = "";

    userId = "";
    isMe$: Observable<boolean>;
    currentPackage: ActivePackageDetails;
    activeSubscription: ActivePackageDetails;
    packageName = "";
    packageDescription = "";
    packageCost = "";
    loader = new Loader();

    //TODO remove this in favor of a better subscription model for same day tax
    appTheme = environment.appTheme;

    constructor(
        private subscriptionService: SubscriptionService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService
    ) {
        this.packageDisplayName = environment.featureFlags.packageDisplayName;
    }

    ngOnInit(): void {
        this.errorMessage = "";
        const userProfile$ = this.activatedRoute.params.pipe(
            switchMap(params => this.updateSubscriptionDetails(params.userId))
        );

        this.profileSubscription = userProfile$.subscribe(() => {});
    }

    ngOnDestroy() {
        if (this.profileSubscription) {
            this.profileSubscription.unsubscribe();
        }
    }

    purchaseSubscription() {
        const update$ = this.subscriptionService.updateSubscription(this.userId, this.currentPackage.packageId).pipe(
            switchMap(() => this.updateSubscriptionDetails(this.userId)),
            catchError(error => this.handleError(error))
        );

        return this.loader.wrap(update$).subscribe();
    }

    cancelSubscription() {
        const cancelSubscription$ = this.loader.wrap(this.subscriptionService.cancelSubscription(this.userId));

        const dialogConfig: ConfirmModalParams = {
            promptText: `If you cancel, your ${this.packageDisplayName} will still be active until the next calendar month. Any work in progress will be completed.`,
            areYouSureText: `Are you sure you want to cancel your ${this.packageDisplayName}?`,
            confirmText: "Yes",
            declineText: "No"
        };
        this.dialog
            .open<ConfirmModalComponent, ConfirmModalParams, boolean>(ConfirmModalComponent, {
                data: dialogConfig,
                width: "400px"
            })
            .afterClosed()
            .pipe(
                filter(value => value === true),
                switchMapTo(cancelSubscription$),
                switchMap(() => this.updateSubscriptionDetails(this.userId)),
                catchError(error => this.handleError(error))
            )
            .subscribe();
    }

    private handleError(error: any): Observable<any> {
        if (error.error && error.error.message) {
            this.errorMessage = error.error.message;
        } else {
            this.errorMessage = "A problem occurred fetching the user profile";
        }

        return throwError(new HandledError(error));
    }

    private updateSubscriptionDetails(userId: string) {
        this.isMe$ = this.authService.getUser().pipe(
            pluck("id"),
            // check if auth'd user is the same as the user we're looking up
            map(authId => !userId || userId === authId)
        );
        const subscriptionDetails$ = userId
            ? this.subscriptionService.getUserSubscriptionDetails(userId)
            : this.subscriptionService.getCurrentUserSubscriptionDetails();

        const update$ = subscriptionDetails$.pipe(
            tap(subscriptionDetails => {
                if (subscriptionDetails) {
                    this.extractFieldsFromSubscriptionDetails(subscriptionDetails);
                }
            }),
            catchError(error => this.handleError(error))
        );

        return this.loader.wrap(update$);
    }

    private extractFieldsFromSubscriptionDetails(subscriptionDetails: SubscriptionDetails) {
        if (subscriptionDetails) {
            const { currentPackage, activeSubscription } = subscriptionDetails;
            this.currentPackage = currentPackage;
            this.activeSubscription = activeSubscription;
            if (currentPackage) {
                this.packageName = currentPackage.name;
                this.packageDescription = currentPackage.description;
                this.packageCost = currentPackage.costDetails.amount;
            }
        }
    }
}
