<ng-container *ngIf="userId | async; let userId">
    <fx-card
        title="Documents"
        [name]="card.createdBy | userToName | async"
        [timestamp]="card.modifiedAt"
        [description]="card.description"
        [invertTheme]="userId === card.createdBy"
    >
        <ng-container [ngSwitch]="isRfi">
            <div class="rfi-content" [ngClass]="{ 'rfi-content--inverted': userId === card.createdBy }">
                <div class="rfi" *ngSwitchCase="true">
                    <div [ngSwitch]="closed">
                        <ng-container *ngSwitchCase="true">RFI Complete</ng-container>
                        <ng-container *ngSwitchDefault>
                            <i class="files-header-paperclip las la-paperclip"></i>&nbsp; {{ downloadable }} file{{
                                downloadable === 1 ? "" : "s"
                            }}
                            provided
                        </ng-container>
                    </div>
                    <button
                        *ngIf="(thread.state | isThreadActive) || (role | minRole: roles.Staff)"
                        class="fx-btn fx-btn--primary-outlined"
                        analyticsClick="app_providedocuments"
                        (click)="openUploadModal()"
                    >
                        {{ closed || (role | minRole: roles.Staff) ? "View Documents" : "Provide Documents" }}
                    </button>
                </div>

                <div class="attachments" *ngSwitchDefault>
                    <fx-accordion
                        [isExpanded]="expand"
                        (accordionClicked)="expand = !expand"
                        [custom]="true"
                        class="test"
                        *ngIf="state$ | async; let state"
                    >
                        <ng-container accordion-header *ngIf="downloadable; let count">
                            <div class="files-header-title" analyticsClick="app_expandfiles">
                                <i class="files-header-paperclip las la-paperclip"></i>&nbsp; {{ count }} attachment{{
                                    count > 1 ? "s" : ""
                                }}
                                <signature-icon
                                    *ngIf="this.documentsUnsigned"
                                    [theme]="'fill'"
                                    [cursor]="false"
                                    [text]="this.documentsUnsigned"
                                ></signature-icon>
                            </div>
                            <i class="fx-accordion-toggle-icon las la-angle-down" analyticsClick="app_expandfiles"></i>
                        </ng-container>
                        <div accordion-content class="files" *ngIf="state.groups">
                            <ng-container *ngFor="let group of state.groups">
                                <div class="file" *ngFor="let item of group.items">
                                    <div class="file-icon">
                                        <i class="las la-file"></i>
                                    </div>
                                    <div class="filename" (click)="downloadItem(item)">{{ item.displayName }}</div>
                                    <ng-container *ngIf="!item.signed && item.actions.includes('sign')">
                                        <signature-icon
                                            (click)="signItem(item)"
                                            analyticsClick="app_signdocument"
                                            *ngIf="role == roles.Client"
                                            [theme]="'light'"
                                            [text]="'Sign'"
                                        ></signature-icon>
                                        <signature-icon
                                            *ngIf="role | minRole: roles.Staff"
                                            [theme]="'dark'"
                                            [text]="'Pending'"
                                            [cursor]="false"
                                        ></signature-icon>
                                    </ng-container>
                                    <signature-icon
                                        *ngIf="item.signed"
                                        [theme]="'dark'"
                                        [cursor]="true"
                                        [text]="'Signed'"
                                    ></signature-icon>
                                </div>
                            </ng-container>
                        </div>
                    </fx-accordion>
                </div>
            </div>
        </ng-container>

        <loader-icon *ngIf="loader.counter" [color]="'dark'"></loader-icon>
    </fx-card>
</ng-container>
