import { Pipe, PipeTransform } from "@angular/core";
import { ThreadStateService } from "src/modules/threads-ui/services/thread-state.service";

@Pipe({ name: "isThreadActive" })
export class IsThreadActivePipe implements PipeTransform {
    constructor(private threadStateService: ThreadStateService) {}

    transform(state: string): boolean {
        return this.threadStateService.isActiveThread(state);
    }
}
