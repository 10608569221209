<loader-icon *ngIf="showLoader" [color]="'light'"></loader-icon>
<div
    class="fx-onboarding fx-onboarding--high-logo fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }} sdtr-bg-mobile"
>
    <div class="fx-onboarding-header">
        <div class="fx-brand"></div>
    </div>
    <div class="fx-onboarding-container fx-container">
        <div class="fx-onboarding-content verify-mobile">
            <h1 class="fx-onboarding-title">Verify mobile</h1>
            <p class="fx-onboarding-body">
                You should have received an SMS code - please enter it below to verify your new mobile.
            </p>

            <app-verify-mobile-form
                [mobileNumber]="mobileNumber"
                [codeSent]="codeSent"
                (showLoader)="this.showLoader = $event"
                (completed)="this.close()"
            >
            </app-verify-mobile-form>
        </div>
    </div>
</div>
