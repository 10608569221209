import { environment } from "./../../../../environments/environment";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "src/modules/findex-auth/services/auth.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { getPasswordErrorMessage, CustomValidators } from "../../../shared/validators";

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.component.html",
    styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    form = new FormGroup(
        {
            newPassword: new FormControl(null, [Validators.required, CustomValidators.passwordValidator]),
            confirmPassword: new FormControl(null, [Validators.required, CustomValidators.passwordValidator])
        },
        CustomValidators.passwordsMatchValidator("newPassword", "confirmPassword")
    );

    readonly theme = environment.theme;
    showLoader: boolean;
    errorMessage: string = "";

    private userName: string;
    private code: string;

    constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) {}

    async ngOnInit() {
        this.activatedRoute.queryParams.subscribe(async params => {
            if (!params.userName || !params.code) {
            } else {
                this.userName = params.userName;
                this.code = params.code;
            }
        });
        this.form.setValue({
            newPassword: "",
            confirmPassword: ""
        });
    }

    ngOnDestroy(): void {}

    async updatePassword() {
        const newPassword = this.form.controls.newPassword.value;
        const result = await this.authService.confirmPasswordReset(this.userName, this.code, newPassword);
        if (result.success) {
            this.authService.loginWithEmail(this.userName, newPassword).subscribe(() => {
                this.router.navigateByUrl("/");
            });
        } else {
            this.errorMessage = result.errorMessage || "An unknown error occurred.";
        }
    }

    getPasswordError(controlName: string) {
        return getPasswordErrorMessage(controlName);
    }
}
