import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { environment, environmentCommon } from "../../../../environments/environment";
import { IAuthenticationStrategy } from "../../model/IAuthenticationStrategy";
import { User } from "../../model/User";
import { mapTo, tap } from "rxjs/operators";
import { AuthorizationLevel } from "../../model/AuthorizationLevel";
import { LoginStep, LoginStepDetails } from "../../model/LoginStep";

@Injectable({ providedIn: "root" })
export class InvitationAuthStrategy implements IAuthenticationStrategy {
    private user$ = new BehaviorSubject<User>(null);
    private token: string;

    constructor(private http: HttpClient) {}

    startLogin(_username: string = null): Observable<LoginStepDetails> {
        return of({ step: LoginStep.LOGIN_COMPLETE });
    }

    answerChallenge(_challenge: any, answer: string): Observable<LoginStepDetails> {
        const url = `${environment.auth.base}${environmentCommon.auth.invitation}`;
        const headers = {
            "findex-invitation": answer
        };

        return this.http
            .get<any>(url, { headers })
            .pipe(
                //TODO: map user properly
                tap(() => (this.token = answer)),
                tap(({ body }) =>
                    this.user$.next({
                        id: "123",
                        name: body.name,
                        type: "cognito",
                        details: body.details,
                        authorizationLevel: AuthorizationLevel.NOMINAL,
                        emailAddressVerified: false,
                        mobileNumberVerified: false
                    })
                ),
                mapTo({ step: LoginStep.LOGIN_COMPLETE })
            );
    }

    logout(): Observable<any> {
        this.token = null;
        this.user$.next(null);
        return of(null);
    }

    getUser(): Observable<User> {
        return this.user$.asObservable();
    }

    getHttpHeaders(): Observable<any> {
        return of({
            "findex-invitation": this.token || "none"
        });
    }
}
