import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { ThreadsService } from "src/app/services/threads.service";
import { Loader } from "src/app/services/loader";
import { IThread, Role, ThreadTypes } from "@findex/threads";
import { IThreadGroupListing, ThreadGrouper } from "src/modules/threads-ui/components/thread-list/thread-grouper";
import { environment } from "../../../../environments/environment";
import { ThreadStateService } from "src/modules/threads-ui/services/thread-state.service";
import { NewThreadModal } from "../../../../modules/sigma";

const { featureFlags, threadStates } = environment;

@Component({
    selector: "threads-state",
    templateUrl: "./threads-state-modal.component.html",
    styleUrls: ["./threads-state-modal.component.scss"]
})
export class ThreadsStateModalComponent {
    role: Role;
    groupedThreads: IThreadGroupListing[];

    constructor(
        @Inject(MAT_DIALOG_DATA) data: { threads: IThreadGroupListing[]; role: Role },
        private dialogRef: MatDialogRef<ThreadsStateModalComponent>,
        private dialog: MatDialog,
        private threadsService: ThreadsService,
        public loader: Loader,
        private stateService: ThreadStateService
    ) {
        this.groupedThreads = ThreadGrouper.groupThreads(
            data.threads
                .filter(thread => thread.type !== ThreadTypes.Dashboard)
                .map(val => {
                    val.active = true;
                    return val;
                })
        );

        this.role = data.role;
    }

    close() {
        this.dialogRef.close();
    }

    async saveState(thread: IThread, state: string) {
        this.loader.show();
        try {
            await this.threadsService.updateThread(thread.id, { state }).toPromise();
            thread.state = state;
            if (thread.state === threadStates.closed) {
                if (featureFlags.newThreadButton) {
                    this.openNewThreadDialog(thread);
                } else {
                    this.stateService.closeThread(thread, this.role);
                }
            }
        } finally {
            this.loader.hide();
        }
    }

    private openNewThreadDialog(thread: IThread) {
        const config = {
            data: {
                thread: thread,
                role: this.role
            },
            panelClass: ["threads-sidebar", "threads-sidebar-inner-modal"],
            disableClose: true
        };

        this.dialog.open(NewThreadModal, config);
    }
}
