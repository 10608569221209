import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ThreadsService } from "src/app/services/threads.service";
import { Observable } from "rxjs";
import { Role } from "@findex/threads";
import { AuthService } from "src/modules/findex-auth";
import { filter, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-sameday-profile-form",
    templateUrl: "./sameday-profile-form.component.html",
    styleUrls: ["./sameday-profile-form.component.scss"]
})
export class SamedayProfileFormComponent {
    @Input() form: FormGroup;

    readonly appName = environment.appName;
    roles = Role;
    role$: Observable<Role>;

    constructor(authService: AuthService, threadsService: ThreadsService) {
        this.role$ = authService.getUser().pipe(
            filter(user => !!user),
            switchMap(user => threadsService.getGlobalRole(user.id))
        );
    }
}
