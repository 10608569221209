<div class="thread" *ngIf="!loader.counter" [ngClass]="{ 'thread--disabled': thread?.state === 'Complete' }">
    <div class="thread-date-group" *ngFor="let date of sortedDates">
        <div class="thread-date-group-title" *ngIf="sortedDates.length > 1">
            <span class="thread-date-group-line">&nbsp;</span>
            <div class="thread-date-group-title-text">{{ formatMillis(date) }}</div>
            <span class="thread-date-group-line">&nbsp;</span>
        </div>
        <ui-card-portal
            *ngFor="let card of uiCardsByDate[date]; trackBy: trackId"
            [scrollTo]="!card.loader.counter && card.scrollTo"
            [uiCard]="card"
            [role]="role"
        ></ui-card-portal>
    </div>
</div>
<loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
