import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment, environmentCommon } from "../../../environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { User } from "../../findex-auth/model/User";
import { AnalyticsService } from "src/modules/analytics";
import { IParticipant, Role } from "@findex/threads";
import { IPrivateProfileDetails } from "../../samedaytax/interfaces/IPrivateProfileDetails";

interface UpdateProfileResponse {
    updatedUser: User;
}

export interface ParticipantDetail extends IParticipant {
    participantDetail?: string;
}

interface UpdateUserPayload {
    emailAddress?: string;
    oldPassword?: string;
    newPassword?: string;
    password?: string;
    mobileNumber?: string;
    name?: string;
}

export interface PackageCostDetails {
    amount: string;
    currency: "AUD";
    frequency: "MONTHLY";
}

export interface ActivePackageDetails {
    packageId: string;
    name: string;
    description: string;
    costDetails: PackageCostDetails;
    startDate: string;
    endDate?: string;
    updatedDate: string;
    trial: boolean;
}

@Injectable({ providedIn: "root" })
export class UserProfileService {
    constructor(private httpClient: HttpClient, private analyticsService: AnalyticsService) {}

    getCurrentUserProfile(): Observable<User> {
        const { base } = environment.threadsEndpoints;
        const { myProfile } = environmentCommon.threadsEndpoints;
        const url = `${base}${myProfile}`;
        return this.fetchUser(url);
    }

    getUserProfile(userId: string): Observable<User> {
        const { base } = environment.threadsEndpoints;
        const { users } = environmentCommon.threadsEndpoints;
        const url = `${base}${users}/${userId}`;
        return this.fetchUser(url);
    }

    private fetchUser(url: string) {
        return this.httpClient.get<User>(url);
    }

    updateUserProfile(userId: string, details: UpdateUserPayload): Observable<{ updatedUser: Omit<User, "id"> }> {
        const { base } = environment.threadsEndpoints;
        const { users } = environmentCommon.threadsEndpoints;
        const url = `${base}${users}/${userId}`;
        return this.updateProfile(url, details);
    }

    updateCurrentUserProfile(details: UpdateUserPayload): Observable<{ updatedUser: Omit<User, "id"> }> {
        //TODO Ideally this would go through threads backend, didn't have time to do this though.
        const url = `${environment.auth.base}${environmentCommon.auth.endpoints.myProfile}`;
        return this.updateProfile(url, details);
    }

    private updateProfile(url: string, details: UpdateUserPayload): Observable<{ updatedUser: Omit<User, "id"> }> {
        const body = {
            ...details,
            emailAddress: details.emailAddress ? details.emailAddress.toLowerCase() : undefined,
            userPoolClientId: environment.auth.userPoolWebClientId,
            themeName: environment.appTheme,
            redirectUrl: environment.emailVerifyUrl
        };
        return this.httpClient.post<UpdateProfileResponse>(url, body).pipe(
            map((response: UpdateProfileResponse) => {
                const { updatedUser } = response;
                return {
                    updatedUser,
                    //The user ID returned from auth does not have the prefix e.g. cognito-* , so we have to discard it.
                    id: undefined
                };
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                if (errorResponse.error && errorResponse.error.data && errorResponse.error.data.status) {
                    const { data } = errorResponse.error;
                    const status = data.status;
                    if (status === "THROTTLED") {
                        this.recordAnalyticsEvent("update-throttle");
                        const throttleHorizon = data.throttleHorizonMinutes
                            ? `up to ${data.throttleHorizonMinutes}`
                            : "a few";

                        return throwError(
                            new Error(
                                `You have sent too many verifications recently. Please wait for ${throttleHorizon} minutes.`
                            )
                        );
                    }
                }
                this.recordAnalyticsEvent("update-unknown-error");
                return throwError(new Error(errorResponse.error ? errorResponse.error.message : errorResponse.message));
            })
        );
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("user-profile", category);
    }

    updatePrivateProfileInfo<T>(userId: string, details: T) {
        const { base } = environment.threadsEndpoints;
        const { base: sameDayTaxBase, secureProfile, secureProfileBase } = environmentCommon.sameDayTaxEndpoints;
        const url = `${base}${sameDayTaxBase}${secureProfileBase}/${userId}${secureProfile}`;
        return this.httpClient.post<T>(url, details).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                return throwError(new Error(errorResponse.error ? errorResponse.error.message : errorResponse.message));
            })
        );
    }

    getPrivateProfileDetails<T>(userId: string): Observable<T> {
        const { base } = environment.threadsEndpoints;
        const { base: sameDayTaxBase, secureProfile, secureProfileBase } = environmentCommon.sameDayTaxEndpoints;
        const url = `${base}${sameDayTaxBase}${secureProfileBase}/${userId}${secureProfile}`;
        return this.httpClient.get<T>(url);
    }

    async getParticipantDetail(participant: IParticipant, currentRole: Role): Promise<ParticipantDetail> {
        switch (environment.appName) {
            case "Same Day Tax Refunds": {
                try {
                    if (
                        !(currentRole === Role.Staff || currentRole === Role.Administrator) ||
                        participant.role !== Role.Client
                    ) {
                        return participant;
                    }
                    const privateDetails = await this.getPrivateProfileDetails<IPrivateProfileDetails>(
                        participant.id
                    ).toPromise();
                    const participantDetail =
                        privateDetails && privateDetails.taxFileNumber
                            ? `TFN ${privateDetails.taxFileNumber}`
                            : "No TFN Provided";
                    return { ...participant, participantDetail };
                } catch (error) {
                    console.error("Unable to get participant detail", participant.id);
                    return participant;
                }
            }
            default:
                return participant;
        }
    }

    updateUserLastLogin(userId: string): Observable<void> {
        const { base } = environment.threadsEndpoints;
        const { lastLogin } = environmentCommon.threadsEndpoints;
        const lastLoginUserId = lastLogin.replace(":userId", userId);
        const url = `${base}${lastLoginUserId}`;
        return this.httpClient.post<void>(url, {});
    }
}
