import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges } from "@angular/core";
import { IThreadListing, Role } from "@findex/threads";
import { IThreadGroupListing } from "../thread-grouper";
import { environment } from "../../../../../environments/environment";

const { closeThreadContextMenu } = environment.featureFlags;

export interface IThreadNodeListing extends Omit<IThreadGroupListing, "children"> {
    isExpanded: boolean;
    children: IThreadNodeListing[];
}
@Component({
    selector: "thread-list-node",
    templateUrl: "./thread-list-node.component.html",
    styleUrls: ["./thread-list-node.component.scss"]
})
export class ThreadListNodeComponent implements OnChanges {
    @Input() globalRole: Role;
    @Input() activeId: string;
    @Input() nodes: IThreadNodeListing[];
    @Output() itemClicked: EventEmitter<IThreadListing> = new EventEmitter();
    @Output() changeTimelineStateClicked: EventEmitter<{ node: IThreadListing; newState: string }> = new EventEmitter();

    readonly closeThreadContextMenu = closeThreadContextMenu;
    history: IThreadNodeListing[] = [];
    activeNode: IThreadNodeListing;
    filter = "";

    goToChild(node: IThreadNodeListing) {
        this.filter = "";
        this.activeNode = node;
        this.history.push(node);
    }

    goUp(node?: IThreadNodeListing) {
        this.filter = "";
        this.activeNode = node;
        if (node) {
            const index = this.history.indexOf(node);
            if (index > -1) {
                this.history = this.history.slice(0, index + 1);
            }
        } else {
            this.history = [];
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        const { activeId } = changes;

        if (activeId && activeId.currentValue) {
            const foundNode = this.nodes.find(node => this.containsActiveId(activeId.currentValue, node));
            if (foundNode) {
                foundNode.isExpanded = true;
            }
        }
    }

    private containsActiveId(activeId: string, node: IThreadNodeListing) {
        if (!node || !activeId) {
            return false;
        }

        if (node.id === activeId) {
            return true;
        } else if (node.children) {
            return node.children.some(node => this.containsActiveId(activeId, node));
        } else {
            return false;
        }
    }
}
