import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { MyTasksEntry } from "../my-tasks.component";

@Component({
    selector: "my-tasks-item",
    templateUrl: "./my-tasks-item.component.html",
    styleUrls: ["./my-tasks-item.component.scss"]
})
export class MyTasksItemComponent implements OnInit {
    @Input() entry: MyTasksEntry;

    hideThreadType = environment.featureFlags.temporaryFeatureFlags
        ? environment.featureFlags.temporaryFeatureFlags.hideThreadTypeMyTasks
        : false;

    constructor() {}

    ngOnInit() {}
}
