import { Component, OnDestroy, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Loader } from "src/app/services/loader";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CustomValidators } from "../../validators";
import { UserProfileService } from "src/modules/user-profile/services/user-profile.service";
import { AuthService } from "src/modules/findex-auth";
import { take, filter, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

type PrivateUserProfileDetails = {
    isReturningCustomer: boolean;
    dateOfBirth: string;
    taxFileNumber: string;
    idType: string;
    idNumber: string;
    idExpiry: string;
};

@Component({
    selector: "onboarding-profile",
    templateUrl: "./onboarding-profile.component.html",
    styleUrls: ["./onboarding-profile.component.scss"]
})
export class OnboardingProfileComponent implements OnInit, OnDestroy {
    private profileSubscription: Subscription;
    readonly appName = environment.appName;
    readonly theme = environment.theme;

    loader = new Loader();
    onboardingForm = new FormGroup({
        isReturningCustomer: new FormControl(null, [Validators.required]),
        dateOfBirth: new FormControl("", [Validators.required, CustomValidators.dateValidator("dd/MM/yyyy")]),
        taxFileNumber: new FormControl("", [Validators.required, CustomValidators.taxFileNumberValidator]),
        idType: new FormControl(null, []),
        idNumber: new FormControl(null, []),
        idExpiry: new FormControl(null, [CustomValidators.dateValidator("dd/MM/yyyy")])
    });

    constructor(
        private userProfileService: UserProfileService,
        private authService: AuthService,
        private router: Router
    ) {}

    ngOnInit() {
        this.profileSubscription = this.authService
            .getUser()
            .pipe(
                filter(user => !!user),
                switchMap(user => {
                    return this.loader.wrap(
                        this.userProfileService.getPrivateProfileDetails<PrivateUserProfileDetails>(user.id)
                    );
                })
            )
            .subscribe(user => this.setProfile(user));
    }

    ngOnDestroy() {
        if (this.profileSubscription) {
            this.profileSubscription.unsubscribe();
        }
    }

    async continue() {
        if (this.onboardingForm.invalid) return;

        this.loader.show();
        const {
            isReturningCustomer,
            dateOfBirth,
            taxFileNumber,
            idType,
            idNumber,
            idExpiry
        } = this.onboardingForm.value;
        const details: PrivateUserProfileDetails = {
            isReturningCustomer,
            dateOfBirth,
            taxFileNumber,
            idType,
            idNumber,
            idExpiry
        };

        const user = await this.authService
            .getUser()
            .pipe(take(1))
            .toPromise();
        await this.userProfileService.updatePrivateProfileInfo<PrivateUserProfileDetails>(user.id, details).toPromise();

        this.loader.hide();

        this.router.navigateByUrl("/select-staff");
    }

    private setProfile(profile: PrivateUserProfileDetails) {
        const formData = Object.entries(this.onboardingForm.value)
            .filter(([, val]) => !!val)
            .reduce((data, [key, val]) => (data[key] = val), {});

        const mergedData = Object.assign({}, profile, formData);
        this.onboardingForm.patchValue(mergedData);
    }
}
