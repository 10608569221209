import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../findex-auth";
import { UserSettingsComponent } from "./components/user-settings/user-settings.component";
import { ConfirmEmailComponent } from "./components/confirm-email/confirm-email.component";

const routes: Routes = [
    { path: "", component: UserSettingsComponent, canActivate: [AuthGuard] },
    { path: "verify-email", component: ConfirmEmailComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserProfileRoutingModule {}
