<div
    class="fx-onboarding fx-onboarding--high-logo fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }} sdtr-bg-email"
>
    <div class="fx-onboarding-header">
        <div class="fx-brand"></div>
    </div>

    <div class="fx-onboarding-container fx-container">
        <div class="fx-onboarding-content">
            <h1 class="fx-onboarding-title">Email verified</h1>
            <p class="fx-onboarding-body">Email {{ emailAddress }} has been successfully verified</p>

            <div>
                <button
                    class="fx-onboarding-button fx-btn"
                    [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                    [routerLink]="['/login']"
                    [queryParams]="{ redirect: '/register/verify-mobile', emailAddress: emailAddress }"
                    analyticsClick="onboarding_continuetologin"
                >
                    Continue to login
                </button>
            </div>
        </div>
    </div>
</div>
