import { Role, IThreadListing } from "@findex/threads";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { CloseAction } from "src/environments/environment.common";
import { CancelThreadModalComponent } from "src/app/components/cancel-thread-modal/cancel-thread-modal.component";
import { ComponentType } from "@angular/cdk/portal";

const { threadStates, featureFlags } = environment;

export interface ThreadsStates {
    name: string;
    description?: string;
}

type ActionRegistry = {
    [action: string]: {
        component: ComponentType<any>;
        config: Omit<MatDialogConfig<any>, "data">;
    };
};

@Injectable({
    providedIn: "root"
})
export class ThreadStateService {
    // TODO: Refactor with workflows, do no overcomplicate for now.
    onboardingStates: ThreadsStates[] = [
        {
            name: "Introductory meeting",
            description: "Please book in a meeting to meet with <staff>"
        },
        {
            name: "Send us your information",
            description: "Please send through your information."
        },
        {
            name: "Provision of services proposal",
            description: "We are working on your client services agreement (CSA)."
        },
        {
            name: "Acceptance of service proposal",
            description: "Please review and accept your client services agreement (CSA)."
        },
        {
            name: "Set up your file",
            description: "<staff> is finalising your onboarding."
        },
        {
            name: threadStates.closed
        }
    ];

    bookkeepingStates: ThreadsStates[] = [
        {
            name: "Send us your information",
            description: "<staff> needs some information from you please"
        },
        {
            name: "Process your bookkeeping",
            description: "<staff> is processing your <service>."
        },
        {
            name: "Month end close",
            description: "<staff> is finalising your <service>."
        },
        {
            name: "Provision of monthly management reports",
            description: "Your monthly reporting is available for review."
        },
        {
            name: threadStates.closed
        }
    ];

    closeActions: ActionRegistry = {};

    constructor(private dialog: MatDialog) {}

    stateList(title: string): ThreadsStates[] {
        return title === "Onboarding" ? this.onboardingStates : this.bookkeepingStates;
    }

    populateDescription(description: string, staff: string, service: string = "Bookkeeping"): string {
        return description.replace(/<staff>/g, staff).replace(/<service>/g, service);
    }

    cancelThread(thread: IThreadListing, role: Role): Observable<boolean> {
        const config = {
            data: { thread, role },
            panelClass: "mat-dialog-overflow",
            disableClose: true
        };

        return this.dialog.open(CancelThreadModalComponent, config).afterClosed();
    }

    setCloseAction(action: CloseAction, component: ComponentType<any>, config: MatDialogConfig) {
        this.closeActions[action] = { component, config };
    }

    closeThread(thread: IThreadListing, role: Role): Observable<boolean> {
        const action = this.closeActions[featureFlags.onCloseThread];
        if (!action) return of(true);

        const config = {
            ...action.config,
            data: { thread, role }
        };

        return this.dialog.open(action.component, config).afterClosed();
    }

    isActiveThread(state?: string): boolean {
        if (!state) return true;
        return state !== threadStates.closed && state !== threadStates.cancelled;
    }
}
