<div
    class="fx-l-modal"
    [ngClass]="{
        'fx-l-modal--sticky-footer': footer,
        'fx-l-modal--has-sub-header': subHeader,
        'fx-l-modal--has-description': headerDescription
    }"
>
    <ng-container *ngIf="customHeader" #headerContent>
        <ng-content select="[modal-header]"></ng-content>
    </ng-container>
    <div *ngIf="header" class="fx-l-modal-header">
        <div class="fx-l-modal-header-icon" (click)="close.emit(null)">
            <i class="las la-times"></i>
        </div>
        <div *ngIf="subHeader" class="fx-l-modal-sub-header">
            {{ subHeader }}
        </div>
        <h2>
            {{ header }}
        </h2>
        <div *ngIf="headerDescription" class="fx-l-modal-header-description">
            {{ headerDescription }}
        </div>
    </div>
    <div class="fx-l-modal-content" [ngClass]="{ 'fx-l-modal-content--no-gutters': contentGutters === false }">
        <ng-content select="[modal-body]"></ng-content>
    </div>
    <div *ngIf="footer" class="fx-l-modal-footer">
        <ng-content select="[modal-footer]"></ng-content>
    </div>
</div>
