import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AdminService } from "../../../../modules/admin/services/admin.service";
import { Loader } from "src/app/services/loader";
import { User } from "src/modules/findex-auth";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ThreadsService } from "src/app/services/threads.service";
import { Role, IThread, SubjectType, IThreadCard } from "@findex/threads";
import { Subject, Observable } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { SigmaService } from "src/app/services/sigma.service";

@Component({
    selector: "thread-add-participant",
    templateUrl: "thread-add-participant.component.html",
    styleUrls: ["thread-add-participant.component.scss"]
})
export class ThreadAddParticipantComponent implements OnInit, OnDestroy {
    loader = new Loader();
    searchName: string;
    clients$: Observable<User[]>;

    private thread: IThread;
    private termSubject = new Subject<string>();

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private adminService: AdminService,
        private dialogRef: MatDialogRef<ThreadAddParticipantComponent>,
        private threadsService: ThreadsService,
        private sigmaService: SigmaService
    ) {
        this.thread = data.thread;
    }

    ngOnInit() {
        this.clients$ = this.termSubject.pipe(
            debounceTime(350),
            switchMap(term => this.searchForClients(term))
        );
    }

    ngOnDestroy() {
        this.termSubject.complete();
    }

    async searchClients(searchTerm: string) {
        this.searchName = searchTerm;
        this.termSubject.next(searchTerm);
    }

    async searchForClients(searchTerm: string) {
        if (!searchTerm) return [];

        this.loader.show();
        const clients = await this.adminService.searchClients(searchTerm).toPromise();
        this.loader.hide();
        return clients;
    }

    async addParticipant(user: User) {
        this.searchClients("");
        this.loader.show();

        await this.threadsService.putParticipant(this.thread.id, user.id, Role.Client).toPromise();
        await this.addToDashboard(this.thread.id, user.id);

        this.loader.hide();
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close();
    }

    private async addToDashboard(threadId: string, participantId: string): Promise<void> {
        const dashboardCard = await this.getDashboardCard(participantId);
        if (!dashboardCard) return;

        const { subjects, id: cardId } = dashboardCard;

        //Check if already contains thread, should never, but just to be safe
        if (subjects.some(subject => subject.id === threadId && subject.type === SubjectType.Thread)) {
            return;
        }

        subjects.push({ id: threadId, type: SubjectType.Thread });
        await this.threadsService.updateCard(threadId, cardId, subjects).toPromise();
    }

    private async getDashboardCard(participantId: string): Promise<IThreadCard> {
        const dashboardId = await this.sigmaService.getDashboard(participantId).toPromise();
        const cards = await this.threadsService.getCards(dashboardId).toPromise();

        return cards.find(card => card.type === SubjectType.Thread);
    }
}
