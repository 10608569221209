<loader-icon *ngIf="showLoader" [color]="'light'"></loader-icon>
<div class="fx-onboarding fx-onboarding--high-logo fx-onboarding--light" *ngIf="!showLoader">
    <div class="fx-onboarding-header fx-onboarding-header--back">
        <div class="fx-brand"></div>
    </div>
    <div class="fx-onboarding-container fx-container">
        <div class="fx-onboarding-content">
            <h1 class="fx-onboarding-title">New password</h1>
            <p class="fx-onboarding-body">
                Please fill the form below to change your password. If you have forgotten your password, please log out
                and select "Forgot your password" from the login screen.
            </p>
            <form class="fx-onboarding-form fx-form fx-form--dark" [formGroup]="form" (submit)="updatePassword()">
                <div class="fx-form-group" *ngIf="errorMessage">
                    <div class="fx-form-validation-panel">
                        <div class="fx-form-validation-panel-item">{{ errorMessage }}</div>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="oldPassword">Old password</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="oldPassword"
                            class="fx-form-input"
                            type="password"
                            placeholder="Old password"
                            aria-label="oldPassword"
                            togglePassword
                            [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                            [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['oldPassword'].valid && form.controls['oldPassword'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="!form.controls['oldPassword'].valid && form.controls['oldPassword'].touched"
                    >
                        {{ getPasswordError("Old password") }}
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="newPassword">New password</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="newPassword"
                            class="fx-form-input"
                            type="password"
                            placeholder="New password"
                            aria-label="newPassword"
                            togglePassword
                            [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                            [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['newPassword'].valid && form.controls['newPassword'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="!form.controls['newPassword'].valid && form.controls['newPassword'].touched"
                    >
                        {{ getPasswordError("New password") }}
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="confirmPassword">Confirm password</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="confirmPassword"
                            class="fx-form-input"
                            type="password"
                            placeholder="Confirm password"
                            aria-label="confirmPassword"
                            togglePassword
                            [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                            [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['confirmPassword'].valid && form.controls['confirmPassword'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="!form.controls['confirmPassword'].valid && form.controls['confirmPassword'].touched"
                    >
                        {{ getPasswordError("Confirm password") }}
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="fx-form-group">
                    <button
                        class="fx-btn--primary-outlined fx-btn--full-width"
                        analyticsClick="app_savepassword"
                        [disabled]="!form.valid"
                        type="submit"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
