import { Component, Input, HostBinding, OnInit } from "@angular/core";

@Component({
    selector: "loader-icon",
    templateUrl: "./loader-icon.component.html",
    styleUrls: ["./loader-icon.component.scss"]
})
export class LoaderIconComponent implements OnInit {
    @Input() color = "#fff";
    @Input() static = false;

    @HostBinding("class.static") staticClass: boolean = false;

    ngOnInit() {
        this.staticClass = this.static;
    }
}
