import { Component, Inject } from "@angular/core";
import { Observable, zip } from "rxjs";
import { THREAD_CARD_RESOURCES, CardResources } from "src/app/interfaces/IUiCard";
import { CardState } from "../../types/card-state";
import { Role, ICardEvent, SubjectType, IThreadListing } from "@findex/threads";
import { scan, shareReplay } from "rxjs/operators";
import { ThreadsService } from "src/app/services/threads.service";
import { environment } from "../../../../../environments/environment";

@Component({
    selector: "thread-card",
    templateUrl: "./thread-card.component.html",
    styleUrls: ["./thread-card.component.scss"]
})
export class ThreadCardComponent {
    activity$: Observable<ICardEvent[]>;
    state: Observable<CardState>;
    role: Role;

    readonly showJobStatus: boolean;

    threads$: Observable<IThreadListing[]>;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) private resources: CardResources,
        private threadsService: ThreadsService
    ) {
        const { state, role, eventService, card } = resources;

        this.showJobStatus = environment.featureFlags.jobStatusEnabled;

        this.state = state;
        this.role = role;

        this.activity$ = eventService.events.pipe(scan(this.accumulate, []));

        this.threads$ = zip(
            ...card.subjects
                .filter(subject => subject.type === SubjectType.Thread)
                .map(subject => subject.id)
                .map(id => this.threadsService.getThread(id))
        ).pipe(shareReplay(1));

        this.loadAll();
    }

    private accumulate(acc: ICardEvent[], val: ICardEvent): ICardEvent[] {
        acc.unshift(val);
        acc.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        return acc;
    }

    private async loadAll() {
        if (await this.resources.eventService.loadHistorical()) {
            await this.loadAll();
        }
    }
}
