import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ICardEvent, Activity, ActivityService } from "@findex/threads";
import { MatDialog } from "@angular/material";
import { AllActivityComponent } from "../all-activity/all-activity.component";
import { ActivityDate } from "../../types/activity-date";
import { Router } from "@angular/router";

@Component({
    selector: "recent-activity",
    templateUrl: "./recent-activity.component.html",
    styleUrls: ["./recent-activity.component.scss"]
})
export class RecentActivityComponent implements OnChanges {
    @Input() events: ICardEvent[];
    activityDates: ActivityDate[];
    recentDates: ActivityDate[];

    constructor(private dialog: MatDialog, private router: Router) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.events) {
            const events = changes.events.currentValue || [];
            this.activityDates = this.groupByDate(events);
            this.recentDates = this.sliceGroupActivity(this.activityDates, 3);
        }
    }

    openAllActivity() {
        const data = { activityDates: this.activityDates };

        this.dialog.open(AllActivityComponent, {
            data,
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container", "threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%"
        });
    }

    private sliceGroupActivity(groups: ActivityDate[], limit: number): ActivityDate[] {
        const sliced = [];

        let count = 0;
        for (const group of groups) {
            const slicedGroup = {
                date: group.date,
                activities: group.activities.slice(0, limit - count)
            };

            count += slicedGroup.activities.length;
            sliced.push(slicedGroup);

            if (count >= limit) break;
        }

        return sliced;
    }

    private groupByDate(events: ICardEvent[]): ActivityDate[] {
        if (!events) return [];

        const groups: { [timestamp: string]: Activity[] } = {};

        for (const event of events) {
            const date = this.timestampToDate(event.createdAt);
            const group = groups[date] || [];

            const activity = ActivityService.mapFromThreadEvent(event);
            if (!activity.description) continue;

            group.push(activity);
            groups[date] = group;
        }

        return Object.entries(groups).map(([date, activities]) => ({ date, activities }));
    }

    private timestampToDate(timestamp: string): string {
        const date = new Date(timestamp);
        date.setHours(0, 0, 0, 0);
        return date.toISOString();
    }

    navigateToThread(event: ICardEvent<any>) {
        const { payload } = event;
        if (payload && payload.threadId) {
            if (payload.subjectId) {
                this.router.navigate(["timelines", payload.threadId, "cards", payload.subjectId]);
            } else {
                this.router.navigate(["timelines", payload.threadId]);
            }
        }
    }
}
