import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../findex-auth";
import { Router } from "@angular/router";
import { AnalyticsService } from "src/modules/analytics";
import { take } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Component({
    selector: "app-verify-mobile",
    templateUrl: "./verify-mobile.component.html",
    styleUrls: ["./verify-mobile.component.scss"]
})
export class VerifyMobileComponent implements OnInit {
    readonly theme = environment.theme;

    constructor(private authService: AuthService, private router: Router, private analyticsService: AnalyticsService) {}

    mobileNumber: string;

    showLoader: boolean = false;

    ngOnInit() {
        this.authService
            .getUser()
            .pipe(take(1))
            .subscribe(user => {
                if (user) {
                    this.mobileNumber = user.details.mobileNumber || "";
                } else {
                    this.recordAnalyticsEvent("login-required");
                    this.router.navigate(["/login"], {
                        queryParams: { redirect: "/register/verify-mobile" }
                    });
                }
            });
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("verify-mobile", category);
    }

    async redirectAfterVerify() {
        if (environment.featureFlags.onboardingType) {
            await this.router.navigateByUrl("/register/completion");
        } else {
            await this.router.navigateByUrl("/dashboard");
        }
    }
}
