<div
    class="fx-onboarding fx-onboarding--high-logo fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }} sdtr-bg-email"
>
    <div class="fx-onboarding-header">
        <div class="fx-brand"></div>
    </div>

    <div class="fx-onboarding-container fx-container">
        <div class="fx-onboarding-content">
            <h1 class="fx-onboarding-title">Verify email</h1>
            <p class="fx-onboarding-body">
                We've sent you an email to verify your details. Once received, open it up and follow the link provided
                to continue your account setup.
            </p>
            <p class="fx-onboarding-body">
                Didn't receive email?
                <a (click)="resend.emit()" role="button" analyticsClick="onboarding_verifyresend">RESEND</a>
            </p>
            <div
                *ngIf="verifyEmailMessage && verifyEmailMessage.error !== undefined"
                [ngClass]="verifyEmailMessage.error ? 'fx-onboarding-form-error' : 'fx-onboarding-form-info'"
            >
                {{ verifyEmailMessage.message }}
            </div>
        </div>
    </div>
</div>
