import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Loader } from "src/app/services/loader";
import { VaultService } from "@findex/vault";

export type CompleteRfiData = { vaultId: string };

@Component({
    selector: "complete-rfi",
    templateUrl: "./complete-rfi-modal.component.html",
    styleUrls: ["./complete-rfi-modal.component.scss"]
})
export class CompleteRfiModal {
    public loader = new Loader();
    private vaultId: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: CompleteRfiData,
        private dialogRef: MatDialogRef<CompleteRfiModal>,
        private vaultService: VaultService
    ) {
        if (!data.vaultId) throw new Error("Missing vault ID");
        this.vaultId = data.vaultId;
    }

    async completeRfi() {
        this.loader.show();
        await this.vaultService.closeVault(this.vaultId).toPromise();
        this.loader.hide();
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close(false);
    }
}
