import { ComponentType } from "@angular/cdk/portal";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { IThread, Role } from "@findex/threads";
import { EMPTY, Observable, of, Subscription, zip } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from "rxjs/operators";
import { CardComponentRegistry } from "src/app/services/card-component.registry";
import { Loader } from "src/app/services/loader";
import { ThreadsService } from "src/app/services/threads.service";
import { WebsocketService } from "src/app/services/websocket.service";
import { AuthService } from "src/modules/findex-auth";
import { ICreateCardEvent } from "../create-card/create-card.component";

export interface ICreateCardData {
    thread: IThread;
    inputContext: ICreateCardEvent["inputContext"];
}
@Component({
    selector: "thread-route",
    templateUrl: "./thread-route.component.html",
    styleUrls: ["thread-route.component.scss"]
})
export class ThreadRouteComponent implements OnInit {
    threadId$: Observable<string>;
    thread$: Observable<IThread>;
    cardId$: Observable<string>;
    role$: Observable<Role>;

    loader = new Loader();

    @ViewChild("threadsContainer", { static: false }) threadsContainer: ElementRef;

    private wsSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private threadsService: ThreadsService,
        private authService: AuthService,
        private registry: CardComponentRegistry,
        private dialog: MatDialog,
        private websocketService: WebsocketService,
        private router: Router
    ) {}

    ngOnInit() {
        this.threadId$ = this.route.params.pipe(
            map(params => params.threadId),
            distinctUntilChanged(),
            shareReplay(1)
        );

        this.cardId$ = this.route.params.pipe(
            map(params => params.cardId),
            distinctUntilChanged(),
            filter(cardId => !!cardId)
        );

        this.thread$ = this.getThread();

        const userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );

        this.role$ = zip(this.threadId$, userId$).pipe(
            switchMap(([threadId, userId]) => this.loader.wrap(this.threadsService.getRole(threadId, userId)))
        );

        this.initThread();
    }

    initThread() {
        if (this.wsSubscription) {
            this.wsSubscription.unsubscribe();
        }

        this.wsSubscription = this.route.params
            .pipe(
                map(params => params.threadId),
                switchMap(threadId =>
                    this.websocketService
                        .connect(threadId)
                        .pipe(filter(event => event.threadId && !event.cardId && !event.eventKey && !event.state))
                )
            )
            .subscribe(() => (this.thread$ = this.getThread()));
    }

    scrollToBottom() {
        this.threadsContainer.nativeElement.scrollTop = 0;
    }

    async addCard(event: ICreateCardEvent, thread: IThread): Promise<void> {
        const component$ = this.loader.wrap(this.getCreateComponent(thread, event.type));

        const component = await component$.toPromise();
        if (!component) return;

        const data = { thread, inputContext: event.inputContext };

        this.dialog.open(component, {
            data,
            position: { top: "0px" },
            height: "100vh",
            maxWidth: "100vw",
            panelClass: ["mat-dialog-no-styling", "threads-sidebar"],
            autoFocus: false
        });
    }

    private getThread() {
        return this.threadId$.pipe(
            switchMap(threadId => this.loader.wrap(this.threadsService.getThread(threadId))),
            switchMap(thread => {
                if (thread.type === "container") {
                    this.router.navigateByUrl("/timelines");
                    return EMPTY;
                }

                return of(thread);
            })
        );
    }

    private getCreateComponent(thread: IThread, type: string): Observable<ComponentType<any> | null> {
        const createHandler = this.registry.getAction(type);
        const componentFactory = createHandler(thread);

        if (!componentFactory) {
            console.error("Could not resolve an action for", type);
        }

        return componentFactory;
    }
}
