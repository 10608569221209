import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { UserProfileRoutingModule } from "./user-profile-routing.module";
import { FindexUiModule } from "../findex-ui";
import { FindexAuthModule } from "../findex-auth";
import { ThreadsUiModule } from "../threads-ui";
import { UserSettingsComponent } from "./components/user-settings/user-settings.component";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { MatIconModule } from "@angular/material/icon";
import { IndustryDialogComponent } from "./components/industry-dialog/industry-dialog.component";
import { OnboardingModule } from "../onboarding/onboarding.module";
import { FxUiModule } from "@findex/fx-ui";
import { SubscriptionComponent } from "./components/subscription/subscription.component";
import { ChangePasswordDialogComponent } from "./components/change-password-dialog/change-password-dialog.component";
import { ChangePasswordFormComponent } from "./components/change-password-form/change-password-form.component";
import { ConfirmEmailComponent } from "./components/confirm-email/confirm-email.component";
import { AdminSetPasswordDialog } from "./components/admin-set-password-dialog/admin-set-password-dialog";
import { SamedayProfileFormComponent } from "./components/sameday-profile-form/sameday-profile-form.component";
import { ConfirmMobileDialogComponent } from "./components/confirm-mobile-dialog/confirm-mobile-dialog.component";
import { SharedModule } from "../shared";

const angularImports = [CommonModule, FormsModule, FindexAuthModule];
const materialImports = [MatInputModule, MatFormFieldModule];

@NgModule({
    imports: [
        FindexUiModule,
        ThreadsUiModule,
        UserProfileRoutingModule,
        ...materialImports,
        ...angularImports,
        MatIconModule,
        OnboardingModule,
        FxUiModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    declarations: [
        UserSettingsComponent,
        UserProfileComponent,
        IndustryDialogComponent,
        SubscriptionComponent,
        ChangePasswordDialogComponent,
        ChangePasswordFormComponent,
        ConfirmEmailComponent,
        AdminSetPasswordDialog,
        SamedayProfileFormComponent,
        ConfirmMobileDialogComponent
    ],
    exports: [SamedayProfileFormComponent],
    entryComponents: [
        UserSettingsComponent,
        IndustryDialogComponent,
        ChangePasswordDialogComponent,
        AdminSetPasswordDialog,
        ConfirmMobileDialogComponent
    ]
})
export class UserProfileModule {}
