import { environment } from "./../../../../environments/environment";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-verify-email-success",
    templateUrl: "./verify-email-success.component.html",
    styleUrls: ["./verify-email-success.component.scss"]
})
export class VerifyEmailSuccessComponent implements OnInit {
    emailAddress: string = "";
    readonly theme = environment.theme;
    constructor(private activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.emailAddress = params["emailAddress"] || "";
        });
    }
}
