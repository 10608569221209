import { NgModule } from "@angular/core";
import { FxUiModule } from "@findex/fx-ui";
import { FindexUiModule } from "../findex-ui";
import { CommonModule } from "@angular/common";
import { PortalModule } from "@angular/cdk/portal";

import { ThreadComponent } from "./components/thread/thread.component";
import { ThreadListComponent } from "./components/thread-list/thread-list.component";
import { UiCardPortalComponent } from "./components/ui-card-portal/ui-card-portal.component";
import { UserDetailCardComponent } from "./components/user-detail-card/user-detail-card.component";
import { ThreadListItemComponent } from "./components/thread-list-item/thread-list-item.component";
import { ThreadsInputComponent } from "./components/threads-input/threads-input.component";
import { ThreadsCheckboxComponent } from "./components/threads-checkbox/threads-checkbox.component";
import { ModalLayoutComponent } from "./components/modal-layout/modal-layout.component";
import { DocumentListComponent } from "./components/document-list/document-list.component";
import { ThreadListNodeComponent } from "./components/thread-list/thread-list-node/thread-list-node.component";

import { ScrollToDirective } from "./directives/scroll-to.directive";

import { MinRolePipe } from "./pipes/min-role";
import { UserToNamePipe } from "./pipes/user-to-name";
import { ThreadParticipantPipe } from "./pipes/thread-participant.pipe";
import { ThreadsSearchPipe } from "./pipes/threads-search.pipe";
import { ThreadParticipantsPipe } from "./pipes/thread-participants.pipe";
import { IsThreadActivePipe } from "./pipes/is-thread-active.pipe";
import { AnalyticsModule } from "../analytics";

const exportDeclarations = [
    ThreadListComponent,
    ThreadListItemComponent,
    ThreadComponent,
    ThreadsInputComponent,
    ThreadsCheckboxComponent,
    ModalLayoutComponent,
    UserDetailCardComponent,
    DocumentListComponent,
    MinRolePipe,
    UserToNamePipe,
    ThreadParticipantPipe,
    ThreadParticipantsPipe,
    ThreadsSearchPipe,
    ThreadListNodeComponent,
    IsThreadActivePipe
];

@NgModule({
    imports: [CommonModule, FindexUiModule, FxUiModule, PortalModule, AnalyticsModule],
    declarations: [...exportDeclarations, UiCardPortalComponent, ScrollToDirective],
    exports: [...exportDeclarations]
})
export class ThreadsUiModule {}
