import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { switchMap, map } from "rxjs/operators";
import { ThreadsService } from "../../../app/services/threads.service";
import { MinRolePipe } from "../../threads-ui/pipes/min-role";
import { Role } from "@findex/threads";

@Injectable({ providedIn: "root" })
export class StaffGuard implements CanActivate {
    constructor(private authService: AuthService, private threadsService: ThreadsService) {}

    canActivate(): Observable<boolean> {
        return this.authService.getUser().pipe(
            switchMap(user => this.threadsService.getGlobalRole(user.id)),
            map(role => new MinRolePipe().transform(role, Role.Staff))
        );
    }
}
