import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { ThreadsService } from "src/app/services/threads.service";
import { CardState, CardSubject } from "../../types/card-state";
import { Router } from "@angular/router";
import { Loader } from "src/app/services/loader";

type UpcomingMeeting = {
    threadId: string;
    cardId: string;
    start: string;
    createdBy: string;
};

@Component({
    selector: "upcoming-meetings",
    templateUrl: "./upcoming-meetings.component.html",
    styleUrls: ["./upcoming-meetings.component.scss"]
})
export class UpcomingMeetingsComponent implements OnChanges {
    @Input() state: CardState;

    upcomingMeetings: UpcomingMeeting[];
    loader = new Loader();

    constructor(private threadsService: ThreadsService, private router: Router) {}

    ngOnChanges(changes: SimpleChanges) {
        const { state } = changes;

        if (state && state.currentValue) {
            this.initMeetings(state.currentValue);
        }
    }

    goToMeeting(meeting: UpcomingMeeting) {
        const { threadId, cardId } = meeting;
        this.router.navigate(["/timelines", threadId, "cards", cardId]);
    }

    private async initMeetings(state: CardState) {
        this.loader.show();

        const meetings$ = state.cardSubjects
            .filter(subject => state.cardTypes[subject.cardId] === "calendar")
            .reduce((cards, subject) => this.uniqueCards(cards, subject), [])
            .map(([threadId, cardId]) => this.getMeetingState(threadId, cardId));

        const meetings = await Promise.all(meetings$);
        this.upcomingMeetings = meetings
            .filter(meeting => !!meeting)
            .sort((upcomingMeeting1, upcomingMeeting2) => {
                return new Date(upcomingMeeting1.start).getTime() - new Date(upcomingMeeting2.start).getTime();
            });

        this.loader.hide();
    }

    private async getMeetingState(threadId: string, cardId: string): Promise<UpcomingMeeting> {
        const cardState = await this.threadsService.getCardState(threadId, cardId).toPromise();
        const today = new Date();

        if (!cardState || !cardState.scheduled || !this.isMeetingAfter(today, cardState.meetingStart)) {
            return null;
        }

        const { createdBy } = await this.threadsService.getCard(threadId, cardId).toPromise();

        return {
            threadId,
            cardId,
            start: cardState.meetingStart,
            createdBy: createdBy
        };
    }

    private isMeetingAfter(date: Date, meetingStart: string): boolean {
        if (!meetingStart) return false;

        const startDate = new Date(meetingStart);
        return startDate.getTime() > date.getTime();
    }

    private uniqueCards(cards: [string, string][], subject: CardSubject): [string, string][] {
        const existingCard = cards.find(([threadId, cardId]) => this.matchSubject(threadId, cardId, subject));

        if (!existingCard) {
            const { threadId, cardId } = subject;
            cards.push([threadId, cardId]);
        }

        return cards;
    }

    private matchSubject(threadId: string, cardId: string, subject: CardSubject): boolean {
        return subject.threadId === threadId && subject.cardId === cardId;
    }
}
