<div
    class="fx-onboarding fx-onboarding--high-logo fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }} sdtr-bg-profile"
>
    <div class="fx-onboarding-header">
        <div class="fx-brand"></div>
    </div>

    <div class="fx-onboarding-container fx-container">
        <div class="fx-onboarding-content">
            <h1 class="fx-onboarding-title">Hi {{ userDataModel.name }},</h1>
            <p class="fx-onboarding-body">Welcome to {{ appName }}. Leave your details below to get started.</p>
            <p class="fx-onboarding-body">
                With bank level security for your private information, we verify your details upon signing up. In two
                quick steps, your meeting will be booked.
            </p>
            <form
                class="fx-onboarding-form fx-form client-profile-form"
                [ngClass]="['fx-form--' + theme.formTheme]"
                [formGroup]="form"
                (submit)="updateParentModel()"
            >
                <div class="fx-form-group" *ngIf="errorMessage">
                    <div class="fx-form-validation-panel">
                        <div class="fx-form-validation-panel-item fx-onboarding-form-error">{{ errorMessage }}</div>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="name">Full name</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="name"
                            class="fx-form-input"
                            name="Full name"
                            placeholder="eg. John Doe"
                            type="text"
                            [ngClass]="{
                                'fx-form-input--error': !form.controls['name'].valid && form.controls['name'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched"
                    >
                        Full name is required.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="emailAddress">Email</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="emailAddress"
                            class="fx-form-input"
                            name="Email"
                            placeholder="john.doe@sigma.com"
                            type="text"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['emailAddress'].valid && form.controls['emailAddress'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="
                            form.controls['emailAddress'].hasError('required') && form.controls['emailAddress'].touched
                        "
                    >
                        Email is required.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="form.controls['emailAddress'].hasError('email') && form.controls['emailAddress'].touched"
                    >
                        Email is invalid.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="password">Password</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="password"
                            class="fx-form-input"
                            name="Password"
                            placeholder="Password"
                            type="password"
                            togglePassword
                            [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                            [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['password'].valid && form.controls['password'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="!form.controls['password'].valid && form.controls['password'].touched"
                    >
                        {{ getPasswordError("Password") }}
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="confirmPassword">Confirm Password</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="confirmPassword"
                            class="fx-form-input"
                            name="ConfirmPassword"
                            placeholder="Confirm Password"
                            type="password"
                            togglePassword
                            [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                            [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['confirmPassword'].valid && form.controls['confirmPassword'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="!form.controls['confirmPassword'].valid && form.controls['confirmPassword'].touched"
                    >
                        {{ getPasswordError("ConfirmPassword") }}
                    </div>
                </div>
                <div class="fx-form-group">
                    <button
                        class="fx-btn fx-btn--full-width"
                        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                        [disabled]="!form.valid"
                        analyticsClick="onboarding_createprofile"
                        type="submit"
                    >
                        CREATE PROFILE
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
