<modal-layout [customHeader]="true" [footer]="false">
    <div modal-header>
        <div class="fx-l-modal-header fx-l-modal-header--no-title">
            <div class="fx-l-modal-header-icon" (click)="closeModal()">
                <i class="las la-times"></i>
            </div>

            <div class="signing-modal-header">
                <div class="signing-modal-header-name">
                    <div class="signing-modal-header-icon">
                        <i class="las la-file"></i>
                    </div>
                    <span>{{ vaultItem.displayName }}</span>
                </div>

                <a class="signing-modal-header-download" (click)="download()">DOWNLOAD</a>
            </div>
        </div>
    </div>
    <div modal-body>
        <div class="signing-modal fx-form fx-form--outlined">
            <div class="signing-modal-file-sign">
                <h1>Electronic Signature Required</h1>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="name">Your name</label>
                    <div class="fx-form-control">
                        <input
                            id="name"
                            class="fx-form-input"
                            type="text"
                            aria-label="Your Name"
                            placeholder="eg. John doe"
                            [value]="name"
                            (change)="name = $event.target.value"
                        />
                    </div>
                </div>

                <div class="fx-form-group">
                    <label class="fx-form-label" for="title">Your title</label>
                    <div class="fx-form-control">
                        <input
                            id="title"
                            class="fx-form-input"
                            type="text"
                            aria-label="Your title"
                            placeholder="eg. Director"
                            [value]="title"
                            (change)="title = $event.target.value"
                        />
                    </div>
                </div>

                <fx-checkbox [checked]="agreed" (change)="agreed = !agreed">
                    I am the party intended to sign the above document, or am authorised to sign it on their behalf. I
                    acknowledge that the information in the above document is true and accurate. I agree or am
                    authorised to agree to be bound by the terms referenced or contained in the above document.
                    Together, this dialog box and the above document form the agreement.
                </fx-checkbox>

                <p *ngIf="errorMessage" class="signing-modal-file-sign-error">{{ errorMessage }}</p>

                <button
                    class="fx-btn fx-btn--full-width"
                    [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                    role="button"
                    (click)="accept()"
                >
                    I ACCEPT
                </button>
            </div>
        </div>
    </div>
</modal-layout>
