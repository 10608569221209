import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FindexUiModule } from "../../findex-ui";
import { FindexAuthModule } from "../../findex-auth";
import { ThreadsUiModule } from "../../threads-ui";
import { MatIconModule } from "@angular/material/icon";
import { FxUiModule } from "@findex/fx-ui";
import { ThreadCardComponent } from "./components/thread-card/thread-card.component";
import { UpcomingMeetingsComponent } from "./components/upcoming-meetings/upcoming-meetings.component";
import { MyTasksComponent } from "./components/my-tasks/my-tasks.component";
import { MyTasksItemComponent } from "./components/my-tasks/my-tasks-item/my-tasks-item.component";
import { NewsArticleItemComponent } from "./components/news-article/news-article-item/news-article-item.component";
import { NewsComponent } from "./components/news-article/news.component";
import { RecentActivityComponent } from "./components/recent-activity/recent-activity.component";
import { AllActivityComponent } from "./components/all-activity/all-activity.component";
import { JobStatusComponent } from "./components/job-status/job-status.component";
import { RouterModule } from "@angular/router";
import { ThreadStateService } from "src/modules/threads-ui/services/thread-state.service";
import { ThreadStateComponent } from "src/app/components/threads-state/thread-state.component";
import { SharedModule } from "src/modules/shared";
import { NewsArticleMapper, newsArticleMapperFactory } from "./components/news-article/model/news-article-mapper";
import { AnalyticsModule } from "src/modules/analytics";

const angularImports = [CommonModule, FormsModule, FindexAuthModule, RouterModule];
const materialImports = [MatInputModule, MatFormFieldModule];

@NgModule({
    imports: [
        FindexUiModule,
        ThreadsUiModule,
        ...materialImports,
        ...angularImports,
        MatIconModule,
        FxUiModule,
        SharedModule,
        AnalyticsModule
    ],
    declarations: [
        ThreadCardComponent,
        UpcomingMeetingsComponent,
        NewsArticleItemComponent,
        NewsComponent,
        MyTasksComponent,
        MyTasksItemComponent,
        RecentActivityComponent,
        AllActivityComponent,
        JobStatusComponent,
        ThreadStateComponent
    ],
    entryComponents: [ThreadCardComponent, AllActivityComponent],
    providers: [ThreadStateService, { provide: NewsArticleMapper, useFactory: newsArticleMapperFactory }]
})
export class ThreadCardModule {}
