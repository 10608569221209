<modal-layout [customHeader]="true">
    <div modal-header>
        <div class="fx-l-modal-header fx-l-modal-header--inner-modal">
            <div class="fx-l-modal-header-icon" (click)="close()">
                <i class="las la-times"></i>
            </div>
        </div>
    </div>
    <div modal-body>
        <div class="success-rfi">
            <div class="success-rfi-header">RFI sent</div>

            <div class="success-rfi-desc">Your RFI was successfully sent to {{ data.participants }}.</div>
        </div>
    </div>
    <div modal-footer>
        <button
            class="fx-btn fx-btn--primary fx-btn--primary-outlined fx-btn--full-width"
            (click)="view()"
            type="button"
        >
            View
        </button>
    </div>
</modal-layout>
