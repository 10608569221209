import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { AnalyticsService } from "src/modules/analytics";

@Component({
    selector: "findex-profile",
    templateUrl: "./findex-profile.component.html",
    styleUrls: ["./findex-profile.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class FindexProfileComponent implements OnInit {
    @Input() userName: string;
    showMenu: boolean = false;

    constructor(private analyticsService: AnalyticsService) {}

    ngOnInit() {}

    toggleMenu() {
        this.showMenu = !this.showMenu;
        const eventAction = this.showMenu ? "shown" : "hidden";
        this.analyticsService.recordEvent("profile-menu", eventAction);
    }

    close() {
        this.showMenu = false;
    }
}
