<ng-container *ngIf="groupedThreads; let groups">
    <fx-accordion
        *ngFor="let group of groups"
        [type]="'secondary'"
        [disabled]="false"
        [isExpanded]="true"
        [toggleGutters]="true"
        (accordionClicked)="group.active = !group.active"
    >
        <ng-container accordion-header>
            <div class="thread-state-title">
                <span>{{ group.name }}</span>
            </div>
            <i class="fx-accordion-toggle-icon las la-angle-down"></i>
        </ng-container>
        <ng-container accordion-content class="upload-rfi-group-content">
            <ng-container *ngFor="let thread of group.children">
                <div *ngIf="thread.state !== 'Complete'" class="thread-state-thread">
                    <div class="thread-state-thread-header">
                        <span
                            >{{ thread.title }} -
                            {{
                                thread.participants | threadParticipants: (role === Role.Staff ? "Client" : "Staff")
                            }}</span
                        >
                    </div>
                    <state-list [thread]="thread" [role]="role" [title]="thread.title"></state-list>
                </div>
            </ng-container>
        </ng-container>
    </fx-accordion>
</ng-container>
