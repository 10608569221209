import { environmentCommon as common, EnvironmentSpecificConfig } from "./environment.common";
import { SAME_DAY_FEATURE_CONFIG } from "./same-day-tax-feature-flags";

const threadsUrl = "https://threads-backend.sameday.findex.dev/qa";
const vaultUrl = "https://vault-backend.sameday.findex.dev/qa";
const authUrl = "https://auth-backend.sameday.findex.dev/qa";
const calendarInternal = "https://internal.qa.calendar.sameday.findex.dev";
const calendarExternal = "https://external.qa.calendar.sameday.findex.dev";
const calendarWeb = "https://calendar.qa.findex.dev";

export const environmentCommon = common;
export const environment: EnvironmentSpecificConfig = {
    featureFlags: SAME_DAY_FEATURE_CONFIG,
    production: false,
    appName: "Same Day Tax Refunds",
    appTheme: "sameday",
    appUrl: "https://qa.sameday.findex.dev",
    stage: "qa",
    sentry: "https://d2078fc1c2314cef9de98f5f92fe6f57@sentry.findex.solutions/11",
    analytics: {
        google: {
            tagManagerId: "GTM-5JR6JVK",
            tagManagerAuth: "yEW5hvKSrU_XDUeuLZMXkw",
            tagManagerPreview: "env-73"
        }
    },
    theme: {
        onboardingTheme: "light",
        onboardingCentreAligned: false,
        formTheme: "dark",
        formSubmitActionOutlined: true
    },
    searchOptimization: {
        url: ""
    },
    threadsEndpoints: {
        base: threadsUrl
    },
    commonEndpoints: {
        base: threadsUrl + "/common"
    },
    sigmaEndpoints: {
        base: threadsUrl + "-app/sigma"
    },
    videoChatEndpoints: {
        base: threadsUrl + "/video"
    },
    vaultEndpoints: {
        base: vaultUrl
    },
    threadsWebsockets: "wss://threads-ws.sameday.findex.dev/qa",
    registration: {
        redirectUrl: "https://qa.sameday.findex.dev/register/email-success",
        invalidPackageRedirect: "https://sdtr-website.qa.findex.solutions/services?invalid-package=true"
    },
    emailVerifyUrl: "https://qa.sameday.findex.dev/profile/verify-email",
    auth: {
        base: authUrl,
        userPoolId: "ap-southeast-2_mPhUv9XZR",
        userPoolWebClientId: "5ofp8n2hgclslvdsqb6qittb3q",
        protectedResources: [threadsUrl, vaultUrl, authUrl, calendarInternal, calendarExternal],
        forgotPasswordRedirect: "https://qa.sameday.findex.dev/reset-password"
    },
    activeDirectory: {
        clientId: "c6935598-8cb8-4fcb-9083-f08b91ee6efe"
    },
    calendar: {
        internalUrl: calendarInternal,
        externalUrl: calendarExternal,
        webUrl: calendarWeb,
        appId: "sameday"
    },
    opentokApiKey: "46761922",
    news: {
        sourceUrl: "https://sdtr-website.qa.findex.solutions",
        allArticlePath: "tax-tips",
        fileName: "articles.json"
    },
    policyUrl: "https://sdtr-website.qa.findex.solutions/privacy-policy",
    termsUrl: "https://sdtr-website.qa.findex.solutions/terms-and-conditions",
    termsOfBusinessUrl: "https://sdtr-website.qa.findex.solutions/terms-of-business",
    disclaimerUrl: "https://sdtr-website.qa.findex.solutions/disclaimer-and-disclosure",
    calendarLocation: "Same Day Tax Refunds VC",
    errorRedirectUrl: "https://qa.sameday.findex.dev/error",
    emailTimelineUrl: "https://qa.sameday.findex.dev/timelines",
    threadStates: {
        closed: "closed",
        cancelled: "cancelled",
        open: "open"
    }
};
