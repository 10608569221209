import { Component, Input, OnInit } from "@angular/core";
import { INewsArticle } from "../model/INewsArticle";
import { environment } from "src/environments/environment.sigma";

@Component({
    selector: "news-article-item",
    templateUrl: "./news-article-item.component.html",
    styleUrls: ["./news-article-item.component.scss"]
})
export class NewsArticleItemComponent implements OnInit {
    @Input() article: INewsArticle;
    sigmaWebUrl = environment.news.sourceUrl;

    constructor() {}

    ngOnInit() {}
}
