import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-business-name",
    templateUrl: "./business-name.component.html",
    styleUrls: ["./business-name.component.scss"]
})
export class BusinessNameComponent implements OnChanges {
    @Output() modelValidated: EventEmitter<boolean> = new EventEmitter();
    @Output() modelUpdated: EventEmitter<any> = new EventEmitter();
    @Output() progressState: EventEmitter<any> = new EventEmitter();

    @Input() businessName: string = "";

    isValid: boolean;
    readonly appName = environment.appName;
    readonly theme = environment.theme;
    steps = ["Business name", "Industry type", "Meet your bookkeeper"];
    activeStep = "Business name";

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.businessName) {
            this.nameUpdated();
        }
    }

    updateParentModel() {
        this.modelUpdated.emit({ businessName: this.businessName });
    }

    nameUpdated() {
        this.isValid = this.businessName.trim() !== "";
        this.modelValidated.emit(this.isValid);
        this.updateParentModel();
    }
}
