<div
    class="fx-onboarding fx-onboarding--high-logo fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }}"
>
    <div class="fx-onboarding-header">
        <div class="fx-brand"></div>
    </div>
    <div class="fx-onboarding-container fx-container business-name">
        <pagination [steps]="steps" [activeStep]="activeStep"></pagination>

        <div class="fx-onboarding-content">
            <h1 class="fx-onboarding-title business-name-title">Welcome to {{ appName }}</h1>
            <p class="fx-onboarding-body">
                Please let us know your business name and industry so we can match you to your bookkeeper.
            </p>
            <div class="fx-onboarding-form fx-form fx-form--dark">
                <div class="fx-form-group">
                    <label class="fx-form-label" for="name">Business name</label>
                    <div class="fx-form-control">
                        <input
                            class="fx-form-input"
                            id="name"
                            type="text"
                            placeholder="Business name"
                            aria-label="Business name"
                            [(ngModel)]="businessName"
                            (ngModelChange)="nameUpdated()"
                        />
                    </div>
                </div>
                <div class="fx-form-group">
                    <button
                        class="fx-btn fx-btn--full-width fx-btn--primary-outlined business-name-button"
                        [disabled]="!isValid"
                        (click)="progressState.emit()"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
