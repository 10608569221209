import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { CalendarService } from "@findex/calendar";
import { IParticipant, IThread, IThreadCard, Role, SubjectType } from "@findex/threads";
import { DateTime } from "luxon";
import { Observable, of, Subscription } from "rxjs";
import { filter, flatMap, map, take } from "rxjs/operators";
import { CardResources, THREAD_CARD_RESOURCES } from "src/app/interfaces/IUiCard";
import { Loader } from "src/app/services/loader";
import { ThreadsService } from "src/app/services/threads.service";
import { environment } from "src/environments/environment";
import { VideoChatService } from "src/modules/card-modules/video-chat-card";
import { AuthService } from "../../../../findex-auth";
import { CalendarModalComponent } from "../calendar-modal/calendar-modal.component";
export interface ISlot {
    start: string;
    end: string;
}

export interface ICalendarAvailability {
    availabilityView: string;
    slots: ISlot[];
}

type CalendarState = {
    meetingStart?: string;
    meetingEnd?: string;
    scheduled?: boolean;
    availableActions?: {
        type: any;
        participantIds?: string[];
        participantRoles: Role[];
    }[];
};

@Component({
    selector: "calendar-card",
    templateUrl: "./calendar-card.component.html",
    styleUrls: ["./calendar-card.component.scss"]
})
export class CalendarCardComponent implements OnInit, OnDestroy {
    readonly threadStates = environment.threadStates;

    loader = new Loader();
    card: IThreadCard;
    userId$: Observable<string>;
    private state: Observable<any>;
    private stateSub: Subscription;
    private appointmentSub: Subscription;
    private navigateSub: Subscription;
    private userSub: Subscription;

    thread: IThread;
    role: Role;
    roles = Role;
    invitationId: string;
    start: Date;
    end: Date;
    appointmentConfirmed = false;
    otherParticipants: IParticipant[] = [];
    invitationCancelled = false;
    hasScheduledTask: boolean;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) private resources: CardResources,
        private threadsService: ThreadsService,
        private calendarService: CalendarService,
        private createChat: VideoChatService,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog
    ) {
        const { thread, card, state, role } = resources;
        this.userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );

        this.thread = thread;
        this.card = card;
        this.state = state;
        this.role = role;

        this.stateSub = this.state.subscribe(result => this.setState(result));
    }

    async ngOnInit() {
        await this.getInvitation();
        this.triggerReschedule();
        this.checkScheduledTask();
    }

    private async setOtherParticipants(role: Role, invitationId: string, participants: IParticipant[]) {
        const invitation$ = this.calendarService.getClientInvitation(invitationId);
        const invite = await this.loader.wrap(invitation$).toPromise();

        if (invite) {
            const userList = role === Role.Client ? invite.staff : invite.invitees;

            const usersInMeeting = [...userList].filter(user => user && user.email).map(user => user.id);

            this.otherParticipants = participants.filter(({ id }) => usersInMeeting.some(user => id.includes(user)));
        }

        if (!this.otherParticipants || !this.otherParticipants.length) {
            const userId = await this.userId$.pipe(take(1)).toPromise();
            this.otherParticipants = participants.filter(participant => participant.id !== userId);
        }
    }

    private triggerReschedule() {
        const { cardId } = this.activatedRoute.snapshot.params;
        const { reschedule } = this.activatedRoute.snapshot.queryParams;
        if (cardId === this.card.id && reschedule && this.role === Role.Client) {
            this.openCalendarModal(true);
        }
    }

    confirmed(invitationId: string, slot: ISlot) {
        this.loader.show();
        this.appointmentSub = this.calendarService
            .checkAvailability(invitationId, slot.start)
            .pipe(
                flatMap((checkAvailabilityResponse: ICalendarAvailability) => {
                    const startDate = DateTime.fromISO(slot.start);
                    const slotIsAvailable = checkAvailabilityResponse.slots.find(
                        (currentSlot: ISlot) => DateTime.fromISO(currentSlot.start).toMillis() === startDate.toMillis()
                    );
                    if (slotIsAvailable) {
                        return this.calendarService.setAppointment(invitationId, slot.start, slot.end);
                    }
                    return of({});
                })
            )
            .subscribe(() => {
                this.loader.hide();
                this.appointmentConfirmed = true;
                this.checkScheduledTask();
            });

        this.start = null;
        this.end = null;
    }

    ngOnDestroy() {
        if (this.stateSub) {
            this.stateSub.unsubscribe();
        }

        if (this.appointmentSub) {
            this.appointmentSub.unsubscribe();
        }

        if (this.navigateSub) {
            this.navigateSub.unsubscribe();
        }

        if (this.userSub) {
            this.userSub.unsubscribe();
        }
    }

    async cancelMeeting(): Promise<void> {
        this.loader.show();

        const attendeeId = await this.userId$.pipe(take(1)).toPromise();

        //Set invitation state to Cancelled and remove any existing appointments.
        await this.calendarService.cancelAppointment(this.invitationId, attendeeId).toPromise();

        await this.removeScheduledTasks();

        this.loader.hide();
        this.invitationCancelled = true;
        this.checkScheduledTask();
    }

    async removeScheduledTasks(): Promise<void> {
        //Delete all scheduled tasks
        const tasks = this.card.subjects.filter(subject => subject.type === SubjectType.ScheduledTask);
        await Promise.all(tasks.map(task => this.threadsService.removeTask(task.id).toPromise()));

        //Remove scheduled tasks from subjects
        const otherSubjects = this.card.subjects.filter(subject => !tasks.includes(subject));
        await this.threadsService.updateCard(this.thread.id, this.card.id, otherSubjects).toPromise();
        this.checkScheduledTask();
    }

    async startVc(): Promise<void> {
        this.loader.show();
        await this.removeScheduledTasks();

        //Kick off VC session
        await this.createChat.createChat(this.thread.id).toPromise();
        this.loader.hide();
    }

    async getInvitation() {
        const { card, role, thread } = this.resources;
        this.invitationId = this.getInvitationId(card);
        if (this.invitationId) {
            await this.setOtherParticipants(role, this.invitationId, thread.participants);
        }
    }

    private getInvitationId(card: IThreadCard): string {
        const calendarSubjects = card.subjects.filter(subject => subject.type === SubjectType.Calendar);

        if (calendarSubjects.length !== 1) {
            console.error("Did not find exactly 1 calendar subject", calendarSubjects);
            return null;
        }

        const subject = calendarSubjects.pop();
        return subject.id;
    }

    private async setState(state: Partial<CalendarState>) {
        if (state) {
            const { meetingStart, meetingEnd, scheduled } = state;

            if (meetingStart) {
                const startDate = new Date(meetingStart);
                if (!isNaN(startDate.getTime())) {
                    this.start = startDate;
                }
            }

            if (meetingEnd) {
                const endDate = new Date(meetingEnd);
                if (!isNaN(endDate.getTime())) {
                    this.end = endDate;
                }
            }
            this.appointmentConfirmed = scheduled;
            this.invitationCancelled = !scheduled && state.availableActions && state.availableActions.length === 0;
        }

        if (!this.navigateSub) {
            this.navigateSub = this.resources.navigateTo.subscribe(() => this.openCard());
        }
    }

    private openCard() {
        if (!this.appointmentConfirmed) {
            this.openCalendarModal();
        }
    }

    openCalendarModal(reschedule?: boolean) {
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: {
                invitationId: this.invitationId,
                otherParticipants: this.otherParticipants,
                rescheduleAppointment: reschedule
            }
        };

        return this.dialog
            .open<CalendarModalComponent, any, ISlot>(CalendarModalComponent, options)
            .afterClosed()
            .subscribe(slot => {
                if (slot) {
                    this.confirmed(this.invitationId, slot);
                }
            });
    }

    private checkScheduledTask() {
        const tasks = this.card.subjects.filter(subject => subject.type === SubjectType.ScheduledTask);
        if (tasks.length) {
            this.hasScheduledTask = true;
        } else {
            this.hasScheduledTask = false;
        }
    }
}
