import { NgModule } from "@angular/core";
import {
    MatButtonModule,
    MatMenuModule,
    MatTableModule,
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
} from "@angular/material";

@NgModule({
    imports: [],
    exports: [
        MatButtonModule,
        MatMenuModule,
        MatTableModule,
        MatDialogModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatIconModule
    ]
})
export class AppMaterialModule {}
