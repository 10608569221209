import { NgModule } from "@angular/core";

import { ReportsRoutingModule } from "./reports-routing.module";
import { ReportsListComponent } from "./components/reports-list/reports-list.component";
import { ReportComponent } from "./components/report/report.component";
import { CommonModule } from "@angular/common";
import { ThreadsUiModule } from "../threads-ui";
import { FindexUiModule } from "../findex-ui";

@NgModule({
    declarations: [ReportsListComponent, ReportComponent],
    imports: [CommonModule, ReportsRoutingModule, ThreadsUiModule, FindexUiModule]
})
export class ReportsModule {}
