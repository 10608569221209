import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment, environmentCommon } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { IStaffProfile } from "@findex/threads";
import * as lodash from "lodash";

export enum ProductTypes {
    Bookkeeping = "bookkeeping"
}

type ListStaffResponse = {
    matchingStaff: IStaffProfile[];
    recommendedStaff: IStaffProfile;
};

@Injectable({
    providedIn: "root"
})
export class StaffService {
    constructor(private http: HttpClient) {}

    fetchStaff(filters: {
        product?: ProductTypes;
        packageId?: string;
        specialities?: string[];
    }): Observable<ListStaffResponse> {
        const { base } = environment.threadsEndpoints;
        const { staff } = environmentCommon.threadsEndpoints;
        const url = `${base}${staff}`;

        //Remove undefined or null params
        const params = lodash.omitBy(
            {
                product: filters.product,
                packageId: filters.packageId,
                specialities: filters.specialities ? filters.specialities.join(",") : undefined
            },
            lodash.isNil
        );

        return this.http
            .get<IStaffProfile[]>(url, { params })
            .pipe(map(response => this.mapStaffResponse(response)));
    }

    private mapStaffResponse(staff: IStaffProfile[]): ListStaffResponse {
        if (!staff || !staff.length) {
            throw new Error("No staff found");
        }

        const mappedStaff = staff.map((staffProfile: IStaffProfile) => {
            const staffTitleOverride = environment.featureFlags.temporaryFeatureFlags.staffTitleOverride;
            if (staffTitleOverride) {
                return {
                    ...staffProfile,
                    title: staffTitleOverride
                };
            }
            return staffProfile;
        });

        return {
            matchingStaff: mappedStaff,
            recommendedStaff: mappedStaff[0]
        };
    }
}
