import { ErrorHandler, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment, environmentCommon } from "../../../environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AnalyticsService } from "src/modules/analytics";
import { ActivePackageDetails } from "./user-profile.service";
import { HandledError } from "src/app/services/sentry-error-handler";

interface GetSubscriptionResponse {
    userId: string;
    subscriptionDetails: SubscriptionDetails;
}

export interface SubscriptionDetails {
    currentPackage: ActivePackageDetails;
    activeSubscription?: ActivePackageDetails;
    packageHistory: ActivePackageDetails[];
}

@Injectable({ providedIn: "root" })
export class SubscriptionService {
    constructor(
        private httpClient: HttpClient,
        private analyticsService: AnalyticsService,
        private errorHandler: ErrorHandler
    ) {}

    getUserSubscriptionDetails(userId: string) {
        const { base } = environment.sigmaEndpoints;
        const { subscription } = environmentCommon.sigmaEndpoints;
        const url = `${base}${subscription}/${userId}`;
        return this.getSubscriptionDetails(url);
    }

    getCurrentUserSubscriptionDetails() {
        const { base } = environment.sigmaEndpoints;
        const { mySubscription } = environmentCommon.sigmaEndpoints;
        const url = `${base}${mySubscription}`;
        return this.getSubscriptionDetails(url);
    }

    private getSubscriptionDetails(url: string): Observable<SubscriptionDetails> {
        return this.httpClient.get<GetSubscriptionResponse>(url).pipe(
            map(response => response.subscriptionDetails),
            catchError((errorResponse: HttpErrorResponse) => {
                try {
                    this.errorHandler.handleError(new HandledError(errorResponse));
                } catch (error) {}
                this.recordAnalyticsEvent("fetch-unknown-error");
                return throwError(new Error(errorResponse.error ? errorResponse.error.message : errorResponse.message));
            })
        );
    }

    updateSubscription(userId: string, packageId: string): Observable<void> {
        const { base } = environment.sigmaEndpoints;
        const { subscription } = environmentCommon.sigmaEndpoints;
        const url = `${base}${subscription}/${userId}`;
        const body = { packageId };
        return this.httpClient.post(url, body).pipe(
            tap(() => {
                this.recordAnalyticsEvent("update-success");
            }),
            map(() => undefined),
            catchError((errorResponse: HttpErrorResponse) => {
                try {
                    this.errorHandler.handleError(new HandledError(errorResponse));
                } catch (error) {}
                this.recordAnalyticsEvent("update-unknown-error");
                return throwError(new Error(errorResponse.error ? errorResponse.error.message : errorResponse.message));
            })
        );
    }

    cancelSubscription(userId: string): Observable<void> {
        const { base } = environment.sigmaEndpoints;
        const { subscription } = environmentCommon.sigmaEndpoints;
        const url = `${base}${subscription}/${userId}`;
        return this.httpClient.delete(url).pipe(
            tap(() => {
                this.recordAnalyticsEvent("cancel-success");
            }),
            map(() => undefined),
            catchError((errorResponse: HttpErrorResponse) => {
                try {
                    this.errorHandler.handleError(new HandledError(errorResponse));
                } catch (error) {}
                this.recordAnalyticsEvent("cancel-unknown-error");
                return throwError(new Error(errorResponse.error ? errorResponse.error.message : errorResponse.message));
            })
        );
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("subscription", category);
    }
}
