import { Component, OnInit } from "@angular/core";
import { StaffService } from "../../services/staff.service";
import { IInitialVaultPayload, SigmaService } from "../../../../app/services/sigma.service";
import { Router } from "@angular/router";
import { Loader } from "src/app/services/loader";
import { environment } from "../../../../environments/environment";
import { AnalyticsService } from "src/modules/analytics";
import { OnboardingCompletionStatus } from "src/modules/onboarding/components/onboarding-completion/onboarding-completion.component";
import { AuthService } from "src/modules/findex-auth";
import { map } from "rxjs/internal/operators/map";
import { OnboardingService } from "src/modules/onboarding/services/onboarding.service";
import { filter, shareReplay, switchMap, take } from "rxjs/operators";
import { CalendarModalComponent } from "src/modules/card-modules/calendar-card/components/calendar-modal/calendar-modal.component";
import { MatDialog } from "@angular/material";
import { ISlot } from "@findex/fx-ui/lib/components/calendar/calendar";
import { CalendarService } from "@findex/calendar";
import { SubscriptionService } from "../../../user-profile/services/subscription.service";
import { Observable } from "rxjs";
import { ActivePackageDetails } from "../../../user-profile/services/user-profile.service";
import { IStaffProfile } from "@findex/threads";
import { trigger, transition, style, animate } from "@angular/animations";
import { FindAvailabilityModalComponent } from "../find-availability-modal/find-availability-modal.component";

@Component({
    selector: "app-select-staff",
    templateUrl: "./select-staff.component.html",
    styleUrls: ["./select-staff.component.scss"],
    animations: [
        trigger("fadeIn", [
            transition(":enter", [style({ opacity: 0 }), animate("2s ease-out", style({ opacity: 1 }))])
        ])
    ]
})
export class SelectStaffComponent implements OnInit {
    userId: string;
    constructor(
        private staffService: StaffService,
        private sigmaService: SigmaService,
        private router: Router,
        private analyticsService: AnalyticsService,
        private authService: AuthService,
        private onboardingService: OnboardingService,
        private subscriptionService: SubscriptionService,
        private dialog: MatDialog,
        private calendarService: CalendarService
    ) {}

    currentPackage$: Observable<ActivePackageDetails | null>;

    staffProfiles: IStaffProfile[];
    selectedStaff: IStaffProfile;
    error: string;
    readonly theme = environment.theme;

    loader = new Loader();

    statuses = ["Finalising your account...", "​Creating your profile...", "Preparing your dashboard..."];
    showLoadingStatus = false;

    async ngOnInit() {
        switch (environment.featureFlags.onboardingType) {
            case "none":
                this.router.navigateByUrl("/dashboard");
                break;
            case "sigma":
                this.router.navigateByUrl("/select-bookkeeper");
                break;
            case "sameday":
                this.loadStaff();
                break;
        }
    }

    async checkUserStaffStatus() {
        this.loader.show();
        const onboardingDetails = await this.onboardingService.getCompletionDetails(this.userId).toPromise();
        this.loader.hide();
        if (onboardingDetails && onboardingDetails.selectedStaff) {
            this.router.navigateByUrl("/dashboard");
        }
    }

    async selectStaff(staff: IStaffProfile, type: "message" | "call", bookSlot?: ISlot) {
        this.error = null;
        this.loader.show();
        this.showLoadingStatus = true;
        await this.onboardingService.updateCompletionDetails(this.userId, { selectedStaff: staff }).toPromise();
        try {
            const { userId } = staff;
            const initialVaultPayload = this.createInitialVaultPayload();
            const createOnboarding$ = this.sigmaService.createOnboarding(userId, type === "call", initialVaultPayload);
            const { thread, appointmentId } = await this.loader.wrap(createOnboarding$).toPromise();

            if (type !== "call") {
                this.router.navigate(["timelines", thread.id]);
                return;
            }

            if (bookSlot) {
                await this.bookMeeting(appointmentId, bookSlot);
            }

            this.showLoadingStatus = false;
            this.router.navigate(["/dashboard"]);

            if (!bookSlot) {
                await this.openCalendarModal(appointmentId, staff);
            }
        } catch (err) {
            //TODO: temp till onboarding timeout issue fixed
            // this.error = "An error occurred, please try again";
            // throw err;
        } finally {
            this.loader.hide();
            this.showLoadingStatus = false;
            this.router.navigate(["dashboard"]);
        }
    }

    getFirstName(staffProfile: IStaffProfile) {
        const parts = staffProfile.name.split(" ");
        return parts.length > 0 ? parts[0] : "advisor";
    }

    private createInitialVaultPayload(): IInitialVaultPayload | undefined {
        if (environment.featureFlags.onboardingType !== "sameday") {
            return undefined;
        }

        const description = "";
        const rfiDocuments = environment.featureFlags.onboardingRfiDocuments;

        const documents = Object.keys(rfiDocuments).reduce(
            (acc: { description: string; category: string }[], categoryName) => {
                const documentsForCategory = rfiDocuments[categoryName];
                const mappedDocuments = documentsForCategory.map(document => ({
                    description: document.description,
                    category: categoryName
                }));
                return acc.concat(mappedDocuments);
            },
            []
        );
        return {
            description,
            documents
        };
    }

    async findAvailabilityModal() {
        const currentPackage = await this.currentPackage$.pipe(take(1)).toPromise();
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            position: { top: "0px" },
            height: "100vh",
            maxWidth: "100vw",
            panelClass: ["mat-dialog-no-styling", "threads-sidebar"],
            data: {
                packageId: currentPackage.packageId,
                staffProfiles: this.staffProfiles,
                meetingDescription: "Select an available time below to book your online meeting"
            }
        };

        const booking = await this.dialog
            .open<FindAvailabilityModalComponent, any, { slot: ISlot; staff: IStaffProfile }>(
                FindAvailabilityModalComponent,
                options
            )
            .afterClosed()
            .toPromise();

        if (booking) {
            const { slot, staff } = booking;
            await this.selectStaff(staff, "call", slot);
        }
    }

    async openCalendarModal(invitationId: string, staff: IStaffProfile) {
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: {
                invitationId: invitationId,
                staffParticipant: staff,
                meetingDescription: "Select an available time below to book your online meeting"
            }
        };

        const booking = await this.dialog
            .open<CalendarModalComponent, any, ISlot>(CalendarModalComponent, options)
            .afterClosed()
            .toPromise();

        if (booking) {
            await this.bookMeeting(invitationId, booking);
        }
    }

    goBack() {
        this.analyticsService.recordEvent("select-staff", "go-back");
        this.router.navigate(["/register/completion"], {
            queryParams: { status: OnboardingCompletionStatus.INDUSTRY_TYPE }
        });
    }

    private async bookMeeting(invitationId: string, slot: ISlot) {
        this.loader.show();

        try {
            await this.calendarService.setAppointment(invitationId, slot.start, slot.end).toPromise();
        } finally {
            this.loader.hide();
        }
    }

    private async loadStaff() {
        this.loader.show();

        try {
            this.currentPackage$ = this.subscriptionService.getCurrentUserSubscriptionDetails().pipe(
                filter(subscription => !!subscription && !!subscription.currentPackage),
                map(subscription => subscription.currentPackage),
                shareReplay(1)
            );

            const staff$ = this.currentPackage$.pipe(
                map(subscription => subscription.packageId),
                switchMap(packageId => this.staffService.fetchStaff({ packageId }))
            );

            this.loader.wrap(staff$).subscribe(result => {
                this.staffProfiles = result.matchingStaff;
            });

            this.userId = await this.authService
                .getUser()
                .pipe(
                    map(user => user.id),
                    take(1)
                )
                .toPromise();

            this.checkUserStaffStatus();
        } catch (err) {
            this.error = "An error occurred, please try again";
        } finally {
            this.loader.hide();
        }
    }
}
