import { IThreadListing, Role, IThread } from "@findex/threads";
import { Component, Input, EventEmitter, Output } from "@angular/core";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { CancelThreadReasonModalComponent } from "src/app/components/cancel-thread-reason-modal/cancel-thread-reason-modal.component";

@Component({
    selector: "thread-list-item",
    templateUrl: "./thread-list-item.component.html",
    styleUrls: ["./thread-list-item.component.scss"]
})
export class ThreadListItemComponent {
    readonly threadStates = environment.threadStates;
    readonly roles = Role;

    @Input() globalRole: Role;
    @Input() node: IThread;
    @Input() active: boolean;
    @Input() contextEnabled: boolean;

    @Output() itemClicked = new EventEmitter<IThreadListing>();
    @Output() changeTimelineStateClicked = new EventEmitter<{ node: IThreadListing; newState: string }>();

    constructor(private dialog: MatDialog) {}

    selected(node: IThreadListing) {
        this.itemClicked.next(node);
    }

    contextStopPropagation(event: MouseEvent) {
        event.stopPropagation();
    }

    changeTimelineState(event: MouseEvent, node: IThreadListing, newState: string) {
        event.stopPropagation();
        this.changeTimelineStateClicked.next({ node, newState });
    }

    cancellationReason(event: MouseEvent, thread: IThread) {
        event.stopPropagation();
        const data = {
            notes: thread.notes,
            clientName: thread.participants
                .filter(participant => participant.role === "Client")
                .map(participant => participant.profile.name)
                .join(", ")
        };

        this.dialog.open(CancelThreadReasonModalComponent, {
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container"],
            maxWidth: "420px",
            height: "auto",
            data: data
        });
    }
}
