<div
    class="fx-onboarding fx-onboarding--high-logo fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }} sdtr-bg-email"
>
    <div class="fx-onboarding-header" [ngClass]="{ 'fx-onboarding-header--back' : isModal }">
        <div class="fx-brand"></div>
    </div>
    <div class="fx-onboarding-container fx-container">
        <div class="fx-onboarding-content">
            <h1 class="fx-onboarding-title">{{ errorTitle }}</h1>
            <p class="fx-onboarding-body">{{ errorMessage }}</p>
            <div>
                <button
                    *ngIf="!isModal"
                    class="fx-onboarding-button fx-btn"
                    [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                    (click)="buttonClick(buttonTitle)"
                >
                    {{ buttonTitle }}
                </button>
            </div>
        </div>
    </div>
</div>
