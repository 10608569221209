import { CalendarModalComponent } from "./components/calendar-modal/calendar-modal.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
    AddInvitationModule,
    AcceptInviteModule,
    CALENDAR_INTERNAL,
    CALENDAR_EXTERNAL,
    CALENDAR_WEB,
    CalendarService
} from "@findex/calendar";
import { CreateInvitationComponent } from "./components/create-invitation/create-invitation.component";
import { AttendeeModalComponent } from "./components/create-invitation/attendee-modal/attendee-modal.component";
import { CalendarCardComponent } from "./components/calendar-card/calendar-card.component";
import { MatSnackBarModule, MatDialogModule } from "@angular/material";
import { AuthInterceptor } from "../../findex-auth";
import { environment } from "../../../environments/environment";
import { ThreadsUiModule } from "../../threads-ui";
import { FindexUiModule } from "../../findex-ui";
import { AnalyticsModule } from "src/modules/analytics";

@NgModule({
    imports: [
        CommonModule,
        AddInvitationModule,
        AcceptInviteModule,
        MatSnackBarModule,
        ThreadsUiModule,
        FindexUiModule,
        MatDialogModule,
        AnalyticsModule
    ],
    exports: [],
    declarations: [CreateInvitationComponent, CalendarCardComponent, CalendarModalComponent, AttendeeModalComponent],
    entryComponents: [CreateInvitationComponent, CalendarCardComponent, CalendarModalComponent, AttendeeModalComponent],
    providers: [
        CalendarService,
        //TODO: SDK should have interceptor and endpoint values not threads module
        { provide: CALENDAR_INTERNAL, useValue: `${environment.calendar.internalUrl}/` },
        { provide: CALENDAR_EXTERNAL, useValue: `${environment.calendar.externalUrl}/` },
        { provide: CALENDAR_WEB, useValue: `${environment.calendar.webUrl}/` },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ]
})
export class CalendarCardModule {}
