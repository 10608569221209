import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { IParticipant } from "@findex/threads";
import { environment } from "src/environments/environment";

@Component({
    selector: "attendee-modal",
    templateUrl: "./attendee-modal.component.html",
    styleUrls: ["./attendee-modal.component.scss"]
})
export class AttendeeModalComponent {
    participants: IParticipant[];
    attendees: IParticipant[];
    currentUserId: string;
    readonly theme = environment.theme;

    constructor(@Inject(MAT_DIALOG_DATA) data: any, private dialogRef: MatDialogRef<AttendeeModalComponent>) {
        this.participants = data.participants;
        this.attendees = Array.from(data.attendees);
        this.currentUserId = data.currentUserId;
    }

    toggleAttendee(attendee: IParticipant) {
        const attendeeIndex = this.attendees.indexOf(attendee);

        if (attendeeIndex < 0) {
            this.attendees.push(attendee);
        } else {
            this.attendees.splice(attendeeIndex, 1);
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    save() {
        this.dialogRef.close(this.attendees);
    }
}
