import { Injectable } from "@angular/core";
import { environment } from "../../../../../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { INewsArticle } from "../model/INewsArticle";
import { NewsArticleMapper } from "../model/news-article-mapper";

const { news: NEWS_CONFIG } = environment;

@Injectable({ providedIn: "root" })
export class NewsService {
    constructor(private http: HttpClient, private mapper: NewsArticleMapper) {}

    getRecentArticles(): Observable<INewsArticle[]> {
        const url = `${NEWS_CONFIG.sourceUrl}/${NEWS_CONFIG.fileName}`;
        return this.http.get<any>(url).pipe(map(response => this.mapper.map(response)));
    }
}
