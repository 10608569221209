<loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
<div class="thread-state fx-l-modal">
    <div class="fx-l-modal-header">
        <div class="fx-l-modal-header-icon" (click)="close()">
            <i class="las la-times"></i>
        </div>
        <h2>Job status</h2>
    </div>
    <div class="fx-l-modal-content fx-l-modal-content--no-gutters">
        <ng-container *ngIf="groupedThreads; let groups">
            <fx-accordion
                *ngFor="let group of groups"
                [type]="'secondary'"
                [custom]="true"
                [disabled]="false"
                [isExpanded]="true"
                [toggleGutters]="true"
                (accordionClicked)="group.active = !group.active"
            >
                <ng-container accordion-header>
                    <div class="thread-state-title">
                        <span>{{ group.name }}</span>
                    </div>
                    <i class="fx-accordion-toggle-icon las la-angle-down"></i>
                </ng-container>
                <ng-container accordion-content class="upload-rfi-group-content">
                    <div class="thread-state-thread" *ngFor="let thread of group.children">
                        <div class="thread-state-thread-header">
                            <span
                                >{{ thread.title }} -
                                {{
                                    thread.participants | threadParticipants: (role === "Staff" ? "Client" : "Staff")
                                }}</span
                            >
                        </div>
                        <state-list
                            [thread]="thread"
                            [role]="role"
                            [title]="thread.title"
                            (save)="saveState(thread, $event)"
                        ></state-list>
                    </div>
                </ng-container>
            </fx-accordion>
        </ng-container>
    </div>
</div>
