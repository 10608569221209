import { Component, Input, SimpleChanges, OnChanges, Injector, OnDestroy } from "@angular/core";
import { PortalInjector, ComponentPortal, ComponentType } from "@angular/cdk/portal";
import { IThreadCard, IThread, Role } from "@findex/threads";
import { THREAD_CARD_RESOURCES, IUiCard, CardResources, IEventService } from "src/app/interfaces/IUiCard";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";
import { AuthService } from "src/modules/findex-auth";

@Component({
    selector: "ui-card-portal",
    templateUrl: "./ui-card-portal.component.html",
    styleUrls: ["./ui-card-portal.component.scss"]
})
export class UiCardPortalComponent implements OnChanges, OnDestroy {
    @Input() uiCard: IUiCard;
    @Input() role: Role;

    userId$: Observable<string>;
    mounted: ComponentPortal<any>;

    constructor(private injector: Injector, authService: AuthService) {
        this.userId$ = authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        const { uiCard } = changes;

        if (uiCard && uiCard.currentValue) {
            const { thread, component, card, cardState, eventsService, navigateTo } = uiCard.currentValue;

            this.updateComponent(component, thread, card, cardState, eventsService, navigateTo, this.role);
        }
    }

    ngOnDestroy() {
        if (!this.uiCard) return;

        //Complete what we can so card-modules don't accidentally cause memory leaks
        this.uiCard.eventsSubject.complete();
        this.uiCard.navigateTo.complete();
    }

    private updateComponent(
        component: ComponentType<any>,
        thread: IThread,
        card: IThreadCard,
        state: Observable<any>,
        eventService: IEventService,
        navigateTo: Observable<any>,
        role: Role
    ) {
        const cardResources = { thread, card, state, eventService, navigateTo, role };

        const injector = this.createInjector(cardResources);

        this.mounted = new ComponentPortal(component, null, injector);
    }

    private createInjector(cardResources: CardResources): PortalInjector {
        const tokens = new WeakMap();

        tokens.set(THREAD_CARD_RESOURCES, cardResources);
        return new PortalInjector(this.injector, tokens);
    }
}
