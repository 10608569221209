import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { AnalyticsService } from "src/modules/analytics";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { getPasswordErrorMessage, CustomValidators } from "../../../shared/validators";
import { UserProfileService } from "../../services/user-profile.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "change-password-dialog",
    templateUrl: "./admin-set-password-dialog.html",
    styleUrls: ["./admin-set-password-dialog.scss"]
})
export class AdminSetPasswordDialog implements OnInit {
    form = new FormGroup(
        {
            newPassword: new FormControl(null, [Validators.required, CustomValidators.passwordValidator]),
            confirmPassword: new FormControl(null, [Validators.required, CustomValidators.passwordValidator])
        },
        CustomValidators.passwordsMatchValidator("newPassword", "confirmPassword")
    );

    showLoader: boolean;
    errorMessage: string = "";

    @Output() passwordUpdated: EventEmitter<void> = new EventEmitter<void>();

    private userId: string;

    constructor(
        private dialogRef: MatDialogRef<AdminSetPasswordDialog>,
        private userProfileService: UserProfileService,
        @Inject(MAT_DIALOG_DATA) data,
        private analyticsService: AnalyticsService
    ) {
        this.userId = data.userId;
    }

    async ngOnInit() {
        this.recordAnalyticsEvent("displayed");
        this.form.setValue({
            newPassword: "",
            confirmPassword: ""
        });
    }

    async updatePassword() {
        const password = this.form.controls.newPassword.value;

        this.showLoader = true;
        this.userProfileService.updateUserProfile(this.userId, { password }).subscribe(
            () => {
                this.showLoader = false;
                this.passwordUpdated.emit();
            },
            error => {
                this.showLoader = false;
                this.errorMessage = error.message || "An unknown error occurred.";
            }
        );
    }

    getPasswordError(controlName: string) {
        return getPasswordErrorMessage(controlName);
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("admin-set-password", category);
    }

    public closeDialog() {
        this.dialogRef.close();
    }
}
