<loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
<fx-layout [gutters]="false">
    <fx-layout-column [sizes]="{ mobile: 24 }" *ngIf="thread$ | async; let thread">
        <ng-container *ngIf="role$ | async; let role">
            <div class="fx-threads-breadcrumbs">
                <h2>{{ thread.title }}</h2>
            </div>
            <div class="fx-threads-tabs">
                <fx-tabs>
                    <fx-tabs-item
                        #threadTab
                        analyticsClick="app_tabtimelines"
                        [analyticsTriggerOn]="threadTab.active"
                        header="Timeline"
                        [ngClass]="{ hide: !threadTab.active }"
                    >
                        <div #threadsContainer class="fx-threads-messages" invertScroll>
                            <thread
                                [ngClass]="{ loading: loader.counter }"
                                [thread]="thread"
                                [role]="role"
                                [routeToCardId]="cardId$ | async"
                                [excludeCardTypes]="['thread']"
                                (loadCardComplete)="scrollToBottom()"
                            ></thread>
                        </div>
                        <div class="fx-threads-new-message">
                            <create-card
                                *ngIf="thread.state != 'Complete'"
                                [role]="role"
                                (newCard)="addCard($event, thread)"
                            ></create-card>
                        </div>
                    </fx-tabs-item>

                    <fx-tabs-item
                        #documentsTab
                        analyticsClick="app_tabdocuments"
                        [analyticsTriggerOn]="documentsTab.active"
                        header="Documents"
                        [ngClass]="{ hide: !documentsTab.active }"
                    >
                        <document-list
                            class="fx-threads-documents"
                            *ngIf="documentsTab.active"
                            [thread]="thread"
                            [role]="role"
                        ></document-list>
                    </fx-tabs-item>

                    <thread-participants [thread]="thread" [role]="role"></thread-participants>
                </fx-tabs>
            </div>
        </ng-container>
    </fx-layout-column>
</fx-layout>
