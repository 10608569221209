import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ErrorModalComponent } from "../components/error/error-modal/error-modal.component";

@Injectable({
    providedIn: "root"
})
export class ErrorService {
    constructor(private dialog: MatDialog) {}

    public showError(title?: string, message?: string) {
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container", "mat-dialog-no-styling"],
            maxWidth: "100%",
            minWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: {
                title: title || undefined,
                message: message || undefined
            }
        };

        this.dialog.open(ErrorModalComponent, options);
    }
}
