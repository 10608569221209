import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IVaultItem } from "src/modules/card-modules/vault-card";
import { IVaultItemFile } from "../../../interfaces/IVaultItemFile";
import { Role } from "@findex/threads";

@Component({
    selector: "upload-item",
    templateUrl: "./upload-item.component.html",
    styleUrls: ["./upload-item.component.scss"]
})
export class UploadItemComponent implements OnInit {
    toggleMenu: boolean;
    roles = Role;

    @Output() uploadFile = new EventEmitter<File>();
    @Output() deleteFile = new EventEmitter<IVaultItemFile>();
    @Output() openFile = new EventEmitter<IVaultItemFile>();

    @Input() expandInitial: string;
    @Input() role: Role;
    @Input() closed: boolean;
    @Input() item: IVaultItem;
    @Input() error?: string;

    constructor() {}

    ngOnInit() {}

    closeMenu() {
        this.toggleMenu = false;
    }

    cancel(file: IVaultItemFile) {
        if (file.uploadSub) {
            file.uploadSub.unsubscribe();
            file.uploadSub = null;

            const index = this.item.files.indexOf(file);
            if (index < 0) return;

            this.item.files.splice(index, 1);
        } else {
            this.deleteFile.emit(file);
        }
    }

    trackFile(_index: number, file: IVaultItemFile) {
        return file.filename;
    }
}
