import { Component, Inject } from "@angular/core";
import { THREAD_CARD_RESOURCES, CardResources, IEventService } from "src/app/interfaces/IUiCard";
import { IThreadCard, ICardEvent } from "@findex/threads";
import { Observable } from "rxjs";
import { scan, map, filter } from "rxjs/operators";
import { AuthService } from "src/modules/findex-auth";

@Component({
    selector: "message-event",
    templateUrl: "./message-event.component.html",
    styleUrls: ["./message-event.component.scss"]
})
export class MessageEventComponent {
    card: IThreadCard;
    events: Observable<ICardEvent[]>;
    userId: Observable<string>;

    constructor(@Inject(THREAD_CARD_RESOURCES) resources: CardResources, authService: AuthService) {
        const { eventService, card } = resources;

        this.card = card;
        this.events = eventService.events.pipe(scan(this.insertEvent, []));
        this.userId = authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );

        this.loadAllEvents(eventService);
    }

    insertEvent = (allEvents: ICardEvent[], event: ICardEvent): ICardEvent[] => {
        const existing = allEvents.find(event => event.id === event.id);
        if (existing) return allEvents;

        allEvents.unshift(event);
        return allEvents;
    };

    private async loadAllEvents(eventService: IEventService) {
        const hasMore = await eventService.loadHistorical();

        if (hasMore) {
            await this.loadAllEvents(eventService);
        }
    }
}
