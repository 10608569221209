import { Injectable } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";
import { Observable } from "rxjs";
import { IThread } from "@findex/threads";

type CreationHandler = (thread: IThread) => Observable<ComponentType<any> | null>;

@Injectable({ providedIn: "root" })
export class CardComponentRegistry {
    private cardMap: { [type: string]: ComponentType<any> } = {};
    private actionMap: { [type: string]: CreationHandler } = {};

    registerCard(eventType: string, cardComponent: ComponentType<any>) {
        this.cardMap[eventType] = cardComponent;
    }

    registerAction(eventType: string, handler: CreationHandler) {
        this.actionMap[eventType] = handler;
    }

    getComponent(eventType: string): ComponentType<any> {
        return this.cardMap[eventType];
    }

    getAction(eventType: string): CreationHandler {
        return this.actionMap[eventType];
    }
}
