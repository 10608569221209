import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { User } from "../model/User";

const redirectUrls = ["/register/signup", "/register/email-success"];

@Injectable({ providedIn: "root" })
export class NoAuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    // @ts-ignore
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.getUser().pipe(
            switchMap((user: User) => {
                return redirectUrls.includes(state.url) ? this.handleRedirect(user) : of(!user);
            })
        );
    }

    private handleRedirect(user: User): Observable<boolean> {
        if (!user) {
            return of(true);
        } else {
            if (user.mobileNumberVerified) {
                this.router.navigateByUrl("/dashboard");
            } else {
                this.router.navigateByUrl("/register/verify-mobile");
            }
            return of(false);
        }
    }
}
