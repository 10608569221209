<div
    #dragContainer
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragDisabled]="isDisabled"
    class="vc-modal"
>
    <div [ngClass]="{ active: !!token }" class="close" (click)="close()" title="Close">
        <i class="las la-times"></i>
    </div>
    <div
        *ngIf="!!token"
        class="toggle-minimise"
        (click)="toggleMinimise()"
        [title]="isMinimised ? 'Maximize' : 'Minimise'"
    >
        <i [class]="isMinimised ? 'las la-expand' : 'las la-compress'"></i>
    </div>
    <loader-icon *ngIf="loading" [color]="'light'" [static]="true"></loader-icon>
    <ng-container *ngIf="details$ | async; let details" [ngSwitch]="!!token">
        <div *ngSwitchDefault class="meeting">
            <meeting-details
                *ngIf="!autoJoin"
                [status]="details.status"
                [name]="details.name"
                [avatar]="details.name"
                [position]="details.position"
                [timestamp]="details.timestamp"
                [inverted]="(userId$ | async) === details.actor"
                [joinDisabled]="!details.sessionId"
                (join)="join(details.sessionId)"
                [sessionTerminated]="details.isTerminated"
                (terminate)="terminateSession()"
                [role]="role"
            ></meeting-details>
        </div>
        <vc-session *ngSwitchCase="true" [sessionId]="details.sessionId" [token]="token" (end)="close()"></vc-session>
        <div class="vc-modal-error" *ngIf="errorMessage">{{ errorMessage }}</div>
    </ng-container>
</div>
