export type IndustryType = { name: string; value: string };

export const BUSINESS_INDUSTRY_TYPES: IndustryType[] = [
    { name: "Agriculture, forestry and fishing", value: "division-a" },
    { name: "Mining", value: "division-b" },
    { name: "Manufacturing", value: "division-c" },
    { name: "Electricity, gas, water and waste services", value: "division-d" },
    { name: "Construction", value: "division-e" },
    { name: "Wholesale trade", value: "division-f" },
    { name: "Retail trade", value: "division-g" },
    { name: "Accommodation and food services", value: "division-h" },
    { name: "Transport, postal and warehousing", value: "division-i" },
    { name: "Information media and telecommunications", value: "division-j" },
    { name: "Financial and insurance services", value: "division-k" },
    { name: "Rental, hiring and real estate services", value: "division-l" },
    { name: "Professional, scientific and technical services", value: "division-m" },
    { name: "Administrative and support services", value: "division-n" },
    { name: "Public administration and safety", value: "division-o" },
    { name: "Education and training", value: "division-p" },
    { name: "Health care and social assistance", value: "division-q" },
    { name: "Arts and recreation services", value: "division-r" },
    { name: "Other services", value: "division-s" }
];
export const BUSINESS_INDUSTRY_NAMES_BY_VALUE: { [key: string]: string } = BUSINESS_INDUSTRY_TYPES.reduce(
    (acc, industryType) => ({
        ...acc,
        [industryType.value]: industryType.name
    }),
    {}
);
