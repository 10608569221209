<div *ngIf="showAction$ | async" class="create-card-actions" [ngClass]="{ 'slide-up': toggleContextMenuState }">
    <loader-icon *ngIf="loading" [color]="'dark'"></loader-icon>

    <create-card-document
        *ngIf="documents.length > 0"
        [role]="role"
        [documents]="documents"
        (remove)="removeFile($event)"
        (rename)="renameFile($event)"
    ></create-card-document>

    <div class="create-card-actions-controls">
        <div class="create-card-actions-open" (click)="toggleContextMenu()">
            <i [ngClass]="{ rotate90: toggleContextMenuState }" class="las la-plus"></i>
        </div>

        <div class="create-card-actions-message">
            <textarea
                analyticsClick="app_typeamessage"
                textareaAutoresize
                placeholder="Type a message here..."
                aria-label="message"
                (input)="message = $event.target.value; validateForm()"
                [value]="message"
                class="fx-form-input"
            ></textarea>

            <div class="create-card-actions-error">
                {{ this.formError }}
            </div>
        </div>

        <button class="create-card-actions-send" [disabled]="this.formError || message == ''" (click)="sendMessage()">
            <i class="las la-paper-plane"></i>
        </button>
    </div>
</div>

<div class="menu">
    <fx-slide-up-menu [toggleMenu]="toggleContextMenuState" [offset]="86">
        <div class="create-card-menu">
            <ng-container *ngIf="role | minRole: roles.Client" (click)="addCard('upload')">
                <input
                    #fileUpload
                    type="file"
                    (change)="attachFile($event.target.files)"
                    multiple
                    style="display: none"
                />
                <a
                    *ngIf="role | minRole: roles.Client"
                    (click)="toggleContextMenu(); fileUpload.click()"
                    class="create-card-menu-button"
                    analyticsClick="app_attachfile"
                >
                    <i class="las la-paperclip la-2x"></i> Attach
                </a>
            </ng-container>
            <a
                *ngIf="role | minRole: roles.Staff"
                (click)="addCard('rfi')"
                analyticsClick="app_createrfi"
                class="create-card-menu-button"
            >
                <i class="las la-door-open la-2x"></i> RFI
            </a>
            <a
                *ngIf="role | minRole: roles.Staff"
                (click)="addCard('calendar')"
                analyticsClick="app_createcalendar"
                class="create-card-menu-button"
            >
                <i class="las la-calendar-alt la-2x"></i> Appointment
            </a>
            <a
                *ngIf="role | minRole: roles.Staff"
                (click)="addCard('video-chat')"
                analyticsClick="app_createvc"
                class="create-card-menu-button"
            >
                <i class="las la-video la-2x"></i> Video
            </a>
        </div>
        <p class="text-danger" *ngIf="errorMessage">{{ errorMessage }}</p>
    </fx-slide-up-menu>
</div>
