<loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
<modal-layout (close)="close()" [contentGutters]="false" [header]="'Create RFI'">
    <div modal-body>
        <form [formGroup]="documentsForm" class="upload-rfi-form">
            <div *ngIf="documentsForm.controls.description; let control" class="upload-rfi-message">
                <div class="fx-form fx-form--outlined">
                    <div class="fx-form-group">
                        <textarea
                            textareaAutoresize
                            placeholder="Type a message here..."
                            (keydown)="$event.stopPropagation()"
                            aria-label="message"
                            formControlName="description"
                            class="fx-form-input"
                            #description
                        >
                        </textarea>
                    </div>
                </div>

                <p *ngIf="control.touched && control.errors?.required" class="upload-rfi-error">
                    Please enter a description
                </p>
            </div>
            <div *ngFor="let category of rfiCategories; let i = index" class="upload-rfi-group">
                <div class="fx-l-component">
                    <fx-accordion
                        [type]="'secondary'"
                        [custom]="true"
                        [disabled]="false"
                        [isExpanded]="i == 0"
                        [toggleGutters]="true"
                    >
                        <ng-container accordion-header>
                            <div class="upload-rfi-group-title">{{ category }}</div>
                            <i class="fx-accordion-toggle-icon las la-angle-down"></i>
                        </ng-container>

                        <ng-container accordion-content class="upload-rfi-group-content">
                            <div class="upload-rfi-group-content-add">
                                <a
                                    analyticsClick="rfi_adddocument"
                                    (click)="addDocument($event, documentsForm.get('documents'), category)"
                                    >ADD DOCUMENT TYPE
                                    <i class="las la-plus"></i>
                                </a>
                            </div>

                            <ng-container *ngFor="let documents of documentsForm.value.documents; let i = index">
                                <div *ngIf="documents.category === category" class="upload-rfi-group-content-document">
                                    <div>
                                        {{ documents.description }}
                                    </div>
                                    <fx-context-menu>
                                        <a
                                            (click)="
                                                addDocument(
                                                    $event,
                                                    documentsForm.get('documents'),
                                                    category,
                                                    documents.description,
                                                    i,
                                                    true
                                                )
                                            "
                                            >Edit</a
                                        >
                                        <a (click)="removeDocument($event, i, category, documents.description)"
                                            >Remove</a
                                        >
                                    </fx-context-menu>
                                </div>
                            </ng-container>
                        </ng-container>
                    </fx-accordion>
                </div>
            </div>
        </form>
    </div>
    <div modal-footer>
        <button
            class="fx-btn fx-btn--full-width"
            [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
            type="submit"
            analyticsClick="rfi_createrfi"
            [disabled]="documentsForm.invalid || documentsForm.value.documents.length === 0"
            (click)="createRfis(documentsForm)"
        >
            CREATE
        </button>
    </div>
</modal-layout>
