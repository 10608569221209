import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Loader } from "src/app/services/loader";
import { SigmaService } from "src/app/services/sigma.service";

@Component({
    selector: "create-message",
    templateUrl: "./create-message.component.html",
    styleUrls: ["./create-message.component.scss"]
})
export class CreateMessageComponent {
    message: string = "";

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<CreateMessageComponent>,
        private cardService: SigmaService,
        private loader: Loader
    ) {}

    async sendMessage(message: string) {
        const { id } = this.data.thread;

        this.loader.show();
        try {
            await this.cardService.createMessageCard(id, { message }).toPromise();
        } finally {
            this.loader.hide();
        }
        this.dialogRef.close();
    }
}
