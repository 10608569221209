import { environment } from "./../../../../environments/environment";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { getPasswordErrorMessage, CustomValidators } from "../../../shared/validators";
import { AuthService } from "../../services/auth.service";

@Component({
    selector: "change-password-form",
    templateUrl: "./new-password-form.component.html",
    styleUrls: ["./new-password-form.component.scss"]
})
export class NewPasswordFormComponent implements OnInit, OnDestroy {
    form = new FormGroup(
        {
            newPassword: new FormControl(null, [Validators.required, CustomValidators.passwordValidator]),
            confirmPassword: new FormControl(null, [Validators.required, CustomValidators.passwordValidator])
        },
        CustomValidators.passwordsMatchValidator("newPassword", "confirmPassword")
    );

    readonly theme = environment.theme;
    showLoader: boolean;
    errorMessage: string = "";

    @Input() userDetails: any;
    @Output() passwordUpdated: EventEmitter<void> = new EventEmitter<void>();

    constructor(private authService: AuthService) {}

    async ngOnInit() {
        this.form.setValue({
            newPassword: "",
            confirmPassword: ""
        });
    }

    ngOnDestroy(): void {}

    async updatePassword() {
        const newPassword = this.form.controls.newPassword.value;

        this.showLoader = true;
        this.authService.completeNewPassword(this.userDetails, newPassword).subscribe(
            () => {
                this.showLoader = false;
                this.passwordUpdated.emit();
            },
            error => {
                this.showLoader = false;
                this.errorMessage = error.message || "An unknown error occurred.";
            }
        );
    }

    getPasswordError(controlName: string) {
        return getPasswordErrorMessage(controlName);
    }
}
