import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, HostListener } from "@angular/core";
import { IThreadListing, Role } from "@findex/threads";
import { ThreadGrouper, IThreadGroupListing } from "./thread-grouper";
import { Observable } from "rxjs";
import { map, filter, switchMap, shareReplay } from "rxjs/operators";
import { AuthService } from "src/modules/findex-auth";
import { ThreadsService } from "src/app/services/threads.service";

export const HIDE_LIST_SIZE = 1024;

@Component({
    selector: "thread-list",
    templateUrl: "./thread-list.component.html",
    styleUrls: ["./thread-list.component.scss"]
})
export class ThreadListComponent implements OnChanges {
    @Input() threadListings$: Observable<IThreadListing[]>;
    @Input() activeId: string;
    @Output() selected = new EventEmitter<IThreadListing>();
    @Output() changeTimelineState = new EventEmitter<{ node: IThreadListing; newState: string }>();

    threadGroups$: Observable<IThreadGroupListing[]>;
    smallWidth: boolean = window.innerWidth < HIDE_LIST_SIZE;
    globalRole$: Observable<Role>;

    constructor(private authService: AuthService, private threadsService: ThreadsService) {
        this.globalRole$ = this.authService.getUser().pipe(
            filter(user => !!user),
            switchMap(user => this.threadsService.getGlobalRole(user.id)),
            shareReplay(1)
        );
    }

    @HostListener("window:resize", ["$event"])
    windowResized(event: any) {
        this.smallWidth = event.target.innerWidth < HIDE_LIST_SIZE;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.threadListings$) {
            this.groupThreads();
        }
    }

    private groupThreads() {
        if (this.threadListings$) {
            this.threadGroups$ = this.threadListings$.pipe(
                map(threadListings => ThreadGrouper.groupThreads(threadListings))
            );
        } else {
            this.threadGroups$ = null;
        }
    }
}
