import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { environmentCommon } from "src/environments/environment.common";
import { environment } from "../../environments/environment";

type ThemeInfo = {
    title: string;
    message: string;
    tileHexColor: string;
};

@Injectable({ providedIn: "root" })
export class ThemeService {
    private theme: string;
    private readonly appVersion = environmentCommon.appVersion;

    constructor(private meta: Meta, private title: Title, @Inject(DOCUMENT) private doc) {
        this.theme = environment.appTheme;
    }

    private themes: { [key: string]: ThemeInfo } = {
        findex: {
            title: "Findex",
            message: "TBD",
            tileHexColor: "#034650"
        },
        sigma: {
            title: "Sigma",
            message: "Good people, Better Bookkeepers",
            tileHexColor: "#FFFFFF"
        },
        sameday: {
            title: "Same Day Tax Refunds",
            message: "Same Day Tax Refunds",
            tileHexColor: "#FFFFFF"
        }
    };

    apply() {
        this.initialiseMetaTags();
        this.setTitle(this.themes[this.theme].title);
        this.initialiseLinkElements();
    }

    private setTitle(newTitle: string) {
        this.title.setTitle(newTitle);
    }

    private initialiseMetaTags() {
        this.meta.addTag({ name: "application-name", content: this.themes[this.theme].title });
        this.meta.addTag({ name: "msapplication-TileColor", content: this.themes[this.theme].tileHexColor });
        this.meta.addTag({
            name: "msapplication-TileImage",
            content: `./assets/icons/mstile-${this.theme}-144x144.png`
        });

        this.meta.addTag({ name: "og:url", content: environment.searchOptimization.url });
        this.meta.addTag({ name: "og:type", content: "website" });
        this.meta.addTag({ name: "og:title", content: this.themes[this.theme].title });
        this.meta.addTag({ name: "og:description", content: this.themes[this.theme].message });
        this.meta.addTag({ name: "og:image", content: `facebook-share-icon-${this.theme}-70x70.png` });
    }

    private initialiseLinkElements() {
        this.createLink("apple-touch-icon-precomposed", `./assets/icons/${this.theme}/apple-touch-icon-57x57.png`, 57);
        this.createLink(
            "apple-touch-icon-precomposed",
            `./assets/icons/${this.theme}/apple-touch-icon-114x114.png`,
            114
        );
        this.createLink("apple-touch-icon-precomposed", `./assets/icons/${this.theme}/apple-touch-icon-72x72.png`, 72);
        this.createLink(
            "apple-touch-icon-precomposed",
            `./assets/icons/${this.theme}/apple-touch-icon-144x144.png`,
            144
        );
        this.createLink(
            "apple-touch-icon-precomposed",
            `./assets/icons/${this.theme}/apple-touch-icon-120x120.png`,
            120
        );
        this.createLink(
            "apple-touch-icon-precomposed",
            `./assets/icons/${this.theme}/apple-touch-icon-152x152.png`,
            152
        );
        this.createLink("icon", `./assets/icons/${this.theme}/favicon-32x32.png`, 32, "image/png");
        this.createLink("icon", `./assets/icons/${this.theme}/favicon-16x16.png`, 16, "image/png");
        this.createLink("icon", `favicon-${this.theme}.ico`);
    }

    private createLink(rel: string, href: string, size?: number, type?: string) {
        let link: HTMLLinkElement = this.doc.createElement("link");

        link.setAttribute("rel", rel);
        link.setAttribute("href", `${href}?v=${this.appVersion}`);
        if (type) link.setAttribute("type", type);
        if (size) link.setAttribute("sizes", `${size}x${size}`);
        this.doc.head.appendChild(link);
    }
}
