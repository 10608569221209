<div class="fx-threads">
    <div class="fx-threads-frame">
        <fx-layout [gutters]="false">
            <fx-layout-column *ngIf="!smallWidth || !activeId" [sizes]="{ mobile: 24, laptop: 8 }">
                <ng-container *ngIf="threadGroups$ | async; else loading; let threadGroups">
                    <p class="no-threads" *ngIf="!threadGroups.length">You have no messages</p>

                    <thread-list-node
                        *ngIf="threadGroups.length"
                        [globalRole]="globalRole$ | async"
                        [activeId]="activeId"
                        [nodes]="threadGroups"
                        (itemClicked)="selected.emit($event)"
                        (changeTimelineStateClicked)="changeTimelineState.emit($event)"
                    ></thread-list-node>
                </ng-container>
                <ng-template #loading>
                    <loader-icon [color]="'light'"></loader-icon>
                </ng-template>
            </fx-layout-column>

            <fx-layout-column [sizes]="{ mobile: 24, laptop: 16 }">
                <fx-layout [gutters]="false">
                    <fx-layout-column [sizes]="{ mobile: 24 }">
                        <ng-content></ng-content>
                    </fx-layout-column>
                </fx-layout>
            </fx-layout-column>
        </fx-layout>
    </div>
</div>
