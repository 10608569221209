<ng-container *ngIf="showJobStatus && role !== 'Client'">
    <job-status *ngIf="threads$ | async; let threads" [threads]="threads" [role]="role"></job-status>
</ng-container>
<div [ngClass]="{ staffView: !showJobStatus || role !== 'Client' }">
    <fx-layout>
        <fx-layout-column *ngIf="showJobStatus && role == 'Client'" [sizes]="{ mobile: 24, laptop: 8 }">
            <fx-card class="fx-dashboard-card fx-dashboard-card--job-status" [conversation]="false">
                <h2>Job Status</h2>
                <job-status *ngIf="threads$ | async; let threads" [threads]="threads" [role]="role"></job-status>
            </fx-card>
        </fx-layout-column>

        <fx-layout-column [sizes]="{ mobile: 24, laptop: 8 }">
            <fx-card class="fx-dashboard-card" [conversation]="false" id="dashboard-my-tasks">
                <h2>My tasks</h2>
                <my-tasks [state]="state | async" [role]="role"></my-tasks>
            </fx-card>
        </fx-layout-column>

        <fx-layout-column [sizes]="{ mobile: 24, laptop: showJobStatus && role === 'Client' ? 8 : 16 }">
            <fx-card class="fx-dashboard-card" [conversation]="false">
                <h2>Most recent activity</h2>
                <recent-activity [events]="activity$ | async"></recent-activity>
            </fx-card>
        </fx-layout-column>

        <fx-layout-column [sizes]="{ mobile: 24, laptop: 8 }">
            <fx-card class="fx-dashboard-card" [conversation]="false" id="dashboard-upcoming-meetings">
                <h2>Upcoming meetings</h2>
                <upcoming-meetings [state]="state | async"></upcoming-meetings>
            </fx-card>
        </fx-layout-column>

        <fx-layout-column [sizes]="{ mobile: 24, laptop: 16 }">
            <fx-card class="fx-dashboard-card" [conversation]="false">
                <h2>Latest news</h2>
                <news></news>
            </fx-card>
        </fx-layout-column>
    </fx-layout>
</div>
