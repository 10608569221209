import { Inject, Injectable } from "@angular/core";
import { environment, environmentCommon } from "../../../environments/environment";
import { IUserDataService } from "../../../app/interfaces/IUserDataService";
import { ThreadsService } from "../../../app/services/threads.service";
import { Observable, defer } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { IStaffProfile } from "@findex/threads";

export interface SignUpUserPayload {
    name: string;
    emailAddress: string;
    mobileNumber?: string;
    password: string;
    packageId: string;
}

export interface SignupBusinessPayload {
    businessName?: string;
    industryTypes?: string[];
    selectedStaff?: IStaffProfile;
}

interface CreateUserResponse {
    data: { status: string };
    userId?: string;
    message?: string;
}

@Injectable({
    providedIn: "root"
})
export class OnboardingService {
    constructor(@Inject(ThreadsService) private userDataService: IUserDataService, private http: HttpClient) {}

    async isValidPackage(packageId: string): Promise<boolean> {
        if (!packageId) return false;

        const { base } = environment.sigmaEndpoints;
        const { packages, validPackage } = environmentCommon.sigmaEndpoints;
        const url = `${base}${packages}/${packageId}${validPackage}`;

        try {
            const { valid } = await this.http.get<{ valid: boolean }>(url).toPromise();
            return valid;
        } catch (err) {
            if (!err || err.status !== 400) throw err;
            return false;
        }
    }

    async createTrialUser(details: SignUpUserPayload): Promise<{ errorMessage?: string; signupSuccessful: boolean }> {
        try {
            const { base } = environment.sigmaEndpoints;
            const { signUp } = environmentCommon.sigmaEndpoints;
            const url = `${base}${signUp}`;

            const initialPayload = { packageId: details.packageId };
            const email = details.emailAddress.toLowerCase();
            const body = {
                name: details.name,
                emailAddress: email,
                password: details.password,
                userPoolClientId: environment.auth.userPoolWebClientId,
                redirectUrl: `${environment.registration.redirectUrl}?emailAddress=${email}`,
                errorRedirectUrl: environment.errorRedirectUrl,
                themeName: environment.appTheme,
                initialPayload
            };
            const result = await this.http.post<CreateUserResponse>(url, body).toPromise();
            const status = result && result.data ? result.data.status : "ERROR";
            switch (status) {
                case "OK":
                    return { signupSuccessful: true };
                default:
                    return { errorMessage: result.message, signupSuccessful: false };
            }
        } catch (error) {
            const errorMessage = "Sorry, something went wrong";
            return { errorMessage, signupSuccessful: false };
        }
    }

    getCompletionDetails(userId: string): Observable<SignupBusinessPayload> {
        return defer(() => this.lazyInitData(userId));
    }

    updateCompletionDetails(userId: string, details: SignupBusinessPayload): Observable<void> {
        return defer(() => this.patchUserData(userId, details));
    }

    private async patchUserData(userId: string, updated: SignupBusinessPayload): Promise<void> {
        const userData = await this.lazyInitData(userId);

        for (const key in updated) {
            if (updated[key] === undefined || updated[key] === null) continue;
            userData[key] = updated[key];
        }

        await this.userDataService.putUserData(userId, userData).toPromise();
    }

    private async lazyInitData(userId: string): Promise<SignupBusinessPayload> {
        const existing = await this.userDataService.getUserData(userId).toPromise();

        return {
            businessName: "",
            industryTypes: [],
            ...existing
        };
    }
}
