import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "../../services/auth.service";

@Component({
    selector: "staff-login-page",
    templateUrl: "./staff-login-page.component.html",
    styleUrls: ["./staff-login-page.component.scss"]
})
export class StaffLoginPageComponent implements OnInit, OnDestroy {
    private redirect: string = "";
    private loginSubscription: Subscription;
    readonly theme = environment.theme;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private authService: AuthService) {}

    ngOnInit() {
        this.loginSubscription = combineLatest(this.authService.getUser(), this.activatedRoute.queryParams).subscribe(
            ([user, params]) => {
                this.redirect = params["redirect"] || "/dashboard";
                if (user) {
                    this.router.navigate([this.redirect]);
                }
            }
        );
    }

    login() {
        return this.authService.loginAsStaff();
    }

    ngOnDestroy() {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
    }
}
