<div class="thread-participants" *ngIf="thread?.participants">
    <button class="thread-participants-toggle" (click)="showDialog = !showDialog" analyticsClick="app_viewparticipants">
        <i class="las la-user-plus"></i> <span class="badge">{{ thread.participants.length }}</span>
    </button>

    <div class="dialog" *ngIf="showDialog">
        <div class="dialog-participant-container" *ngFor="let participant of threadParticipants">
            <div class="dialog-participant" (click)="openProfile(participant.id)">
                <fx-avatar [name]="participant.profile.name" [isLarge]="false"></fx-avatar>
                <div class="dialog-participant-user">
                    <div class="dialog-participant-user-name">
                        {{ participant.profile?.name }}
                    </div>
                    <div class="dialog-participant-user-title">
                        {{ participant.profile?.title }}
                    </div>
                    <div class="dialog-participant-user-title" *ngIf="threadParticipantDetails">
                        {{ participant.participantDetail }}
                    </div>
                </div>
            </div>
            <div
                class="dialog-participant-delete"
                *ngIf="addParticipantsButton && thread.participants.length > 2 && participant.id !== (userId$ | async)"
                (click)="remove(participant)"
            >
                &#10006;
            </div>
        </div>

        <button
            (click)="openDialog()"
            *ngIf="addParticipantsButton && (role | minRole: roles.Staff)"
            class="dialog-add"
        >
            ADD PARTICIPANT
        </button>
    </div>
</div>
