<div class="industry-type-select">
    <div class="industry-type-select-search">
        <div class="fx-form">
            <div class="fx-form-control">
                <i class="fx-form-icon fx-form-icon--left las la-search"></i>
                <input
                    class="fx-form-input"
                    type="text"
                    placeholder="Search"
                    aria-label="Industry type"
                    [(ngModel)]="filter"
                    (ngModelChange)="searchUpdated()"
                />
            </div>
        </div>
    </div>
    <div class="industry-type-select-list">
        <app-business-industry-option
            *ngFor="let option of filteredIndustryTypes"
            [name]="option.name"
            [value]="option.value"
            [selected]="option.selected"
            (updated)="selectionToggled(option)"
        ></app-business-industry-option>
    </div>
</div>
