<div class="news-article-item">
    <fx-layout [columns]="24">
        <fx-layout-column [sizes]="{ mobile: 24, desktop: 7 }">
            <div class="news-article-item-image" [ngStyle]="{ 'background-image': 'url(' + article.bgUrl + ')' }"></div>
        </fx-layout-column>

        <fx-layout-column [sizes]="{ mobile: 24, desktop: 17 }">
            <div class="news-article-item-desc">
                <div class="news-article-item-desc-title">
                    {{ article.title }}
                </div>
                <div class="news-article-item-desc-date">
                    {{ article.date | date: "d MMM yyyy" }} |
                    {{ article.author }}
                </div>
                <div class="news-article-item-desc-blurb">
                    {{ article.body | slice: 0:84 }}...
                    <a *ngIf="article.url" [href]="article.url" analyticsClick="app_newsreadmore" target="_blank"
                        >Read more...</a
                    >
                </div>
            </div>
        </fx-layout-column>
    </fx-layout>
</div>
