import { Injectable } from "@angular/core";
import { environment, environmentCommon } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { IStaffProfile } from "@findex/threads";

export type SignedRequest = {
    url: string;
    fields: { [key: string]: string };
};

type PackageFilter = { packageId?: string; product?: string; specialities?: string };

@Injectable({ providedIn: "root" })
export class SamedayService {
    constructor(private http: HttpClient) {}

    findAvailability(filter: PackageFilter, start: string, end: string): Observable<any> {
        const { base } = environment.threadsEndpoints;
        const { base: samedaytax, findAvailability } = environmentCommon.sameDayTaxEndpoints;
        const url = `${base}${samedaytax}${findAvailability}`;

        const targetTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.http.post(url, { ...filter, start, end, targetTimezone });
    }

    findAvailableStaff(filter: PackageFilter, slotStart: string, slotEnd: string): Observable<IStaffProfile[]> {
        const { base } = environment.threadsEndpoints;
        const { base: samedaytax, findAvailableStaff } = environmentCommon.sameDayTaxEndpoints;
        const url = `${base}${samedaytax}${findAvailableStaff}`;

        const targetTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.http.post<any[]>(url, { ...filter, slotStart, slotEnd, targetTimezone });
    }

    uploadFile(file: File, tfnNumber: string, financialYear: string, filename: string, isSigned: boolean) {
        return this.getSignedRequest(tfnNumber, financialYear, filename, isSigned).pipe(
            switchMap(request => this.upload(file, request))
        );
    }

    private upload(file: File, signedRequest: SignedRequest) {
        const { url, fields } = signedRequest;

        const formData = new FormData();
        for (const [fieldName, fieldVal] of Object.entries(fields)) {
            formData.set(fieldName, fieldVal);
        }

        formData.set("acl", "private");
        formData.set("file", file, file.name);

        return this.http.post(url, formData, { reportProgress: true, observe: "events" });
    }

    private getSignedRequest(
        tfnNumber: string,
        financialYear: string,
        filename: string,
        isSignedFile: boolean
    ): Observable<SignedRequest> {
        const isSigned = (!!isSignedFile).toString();
        const params = { tfnNumber, financialYear, filename, isSigned };
        const { base } = environment.threadsEndpoints;
        const { base: samedaytax, fileSync } = environmentCommon.sameDayTaxEndpoints;

        const url = `${base}${samedaytax}${fileSync}`;
        return this.http.get<SignedRequest>(url, { params });
    }
}
