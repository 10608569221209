import { Component, Input, OnInit } from "@angular/core";
import { IStaffProfile } from "@findex/threads";

@Component({
    selector: "app-staff-detail",
    templateUrl: "./staff-detail.component.html",
    styleUrls: ["./staff-detail.component.scss"]
})
export class StaffDetailComponent implements OnInit {
    @Input() staffProfile: IStaffProfile;

    constructor() {}

    ngOnInit() {}
}
