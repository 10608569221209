import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { getPasswordErrorMessage, CustomValidators } from "../../../../shared/validators";
import { SignUpUserPayload } from "../../../services/onboarding.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-client-profile",
    templateUrl: "./client-profile.component.html",
    styleUrls: ["./client-profile.component.scss"]
})
export class ClientProfileComponent implements OnInit {
    @Input() userDataModel: SignUpUserPayload;
    @Input() errorMessage: string;
    @Output() progress: EventEmitter<SignUpUserPayload> = new EventEmitter();

    appName = environment.appName;
    theme = environment.theme;

    form = new FormGroup(
        {
            name: new FormControl(null, Validators.required),
            emailAddress: new FormControl(null, [Validators.required, Validators.email]),
            password: new FormControl(null, [Validators.required, CustomValidators.passwordValidator]),
            confirmPassword: new FormControl(null, [Validators.required, CustomValidators.passwordValidator])
        },
        CustomValidators.passwordsMatchValidator("password", "confirmPassword")
    );

    constructor() {}

    ngOnInit() {
        this.form.setValue({
            name: this.userDataModel.name,
            emailAddress: this.userDataModel.emailAddress,
            password: this.userDataModel.password,
            confirmPassword: this.userDataModel.password
        });
    }
    updateParentModel() {
        this.progress.emit(this.form.value);
    }

    getPasswordError(controlName: string) {
        return getPasswordErrorMessage(controlName);
    }
}
