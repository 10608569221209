<form class="fx-onboarding-form fx-form" [ngClass]="['fx-form--' + theme.formTheme]" [formGroup]="form">
    <div class="fx-form-group" *ngIf="errorMessage">
        <div class="fx-form-validation-panel">
            <div class="fx-form-validation-panel-item fx-onboarding-form-error">{{ errorMessage }}</div>
        </div>
    </div>
    <div class="fx-form-group">
        <label class="fx-form-label" for="mobileNumber">Mobile</label>
        <div
            class="fx-form-control"
            [ngClass]="{ 'fx-form-control--inline-button': showVerifyCode }"
            [class.no-flag]="signupCountries.length < 2"
        >
            <input
                class="fx-form-input"
                type="text"
                name="Mobile"
                formControlName="mobileNumber"
                [ngClass]="{
                    'fx-form-input--error':
                        !form.controls['mobileNumber'].valid && form.controls['mobileNumber'].touched
                }"
                intlTel
                [intlTelOptions]="{
                    initialCountry: 'au',
                    onlyCountries: signupCountries,
                    allowDropdown: signupCountries.length > 1,
                    customContainer: 'fx-onboarding-intl-input'
                }"
                [intlTelInitialValue]="form.controls.mobileNumber.value"
                (intlTelValid)="internationalPhoneNoValid = $event"
                (intlTelCurrentValue)="internationalPhoneNo = $event"
            />
            <button
                *ngIf="showVerifyCode"
                class="fx-form-inline-button"
                type="button"
                [disabled]="!internationalPhoneNoValid || !form.controls.mobileNumber.valid || (disableResend$ | async)"
                analyticsClick="onboarding_resend"
                (click)="sendCode()"
            >
                RESEND
            </button>
        </div>
        <div
            class="fx-form-validation"
            *ngIf="form.controls['mobileNumber'].hasError('required') && form.controls['mobileNumber'].touched"
        >
            Mobile is required.
            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
        </div>
        <div
            class="fx-form-validation"
            *ngIf="form.controls['mobileNumber'].hasError('invalid') && form.controls['mobileNumber'].touched"
        >
            Mobile number is not valid.
            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
        </div>
    </div>
    <div *ngIf="!showVerifyCode" class="fx-form-group">
        <div class="fx-onboarding-footer">
            Your mobile number will not be shared with third parties or used for marketing communications.
        </div>
        <button
            type="button"
            class="fx-btn fx-btn--primary-outlined fx-btn--full-width"
            [disabled]="!internationalPhoneNoValid || !form.controls.mobileNumber.valid || (disableResend$ | async)"
            analyticsClick="onboarding_sendphone"
            (click)="sendCode()"
        >
            SEND
        </button>
    </div>
    <div class="fx-form-group" *ngIf="showVerifyCode">
        <div class="fx-form-control">
            <label class="fx-form-label" for="verifyCode">Pin</label>
            <input
                autocomplete="one-time-code"
                formControlName="verifyCode"
                class="fx-form-input"
                name="verifyCode"
                placeholder="PIN"
                type="text"
                [ngClass]="{
                    'fx-form-input--error': !form.controls['verifyCode'].valid && form.controls['verifyCode'].touched
                }"
            />
        </div>
        <div class="fx-form-validation" *ngIf="form.controls['verifyCode'].hasError('pattern')">
            Verification code must be 6 numeric characters.
            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
        </div>
    </div>
    <div class="fx-form-group" *ngIf="showVerifyCode">
        <div class="fx-onboarding-footer">
            Your mobile number will not be shared with third parties or used for marketing communications.
        </div>
        <button
            class="fx-btn fx-btn--full-width"
            [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
            [disabled]="form.controls.verifyCode.valid === false"
            (click)="verifyMobileCode()"
            analyticsClick="onboarding_verify"
        >
            VERIFY
        </button>
    </div>
</form>
