import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SyncFilesModalComponent } from "./components/sync-files-modal/sync-files-modal.component";
import { ClientDetailsFormComponent } from "./components/client-details-form/client-details-form.component";
import { ThreadsUiModule } from "../threads-ui";
import { FindexUiModule } from "../findex-ui";

@NgModule({
    imports: [CommonModule, ThreadsUiModule, FindexUiModule],
    declarations: [SyncFilesModalComponent, ClientDetailsFormComponent],
    entryComponents: [SyncFilesModalComponent]
})
export class SameDayTaxModule {}
