import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/modules/findex-auth";
import { take } from "rxjs/internal/operators/take";
import { environment } from "src/environments/environment";

export interface IData {
    message: string;
    title: string;
}

export enum ErrorType {
    VERIFY_EMAIL = "verifyEmailError"
}

export enum ButtonType {
    LOGIN = "LOGIN",
    DASHBOARD = "DASHBOARD"
}

export enum ErrorTitle {
    GENERIC = "Oops something went wrong"
}

export enum ErrorMessage {
    GENERIC = "Something has gone wrong, please try again.",
    VERIFY_EMAIL = "This email has expired or you may have already verified."
}

@Component({
    selector: "app-error",
    templateUrl: "./error.component.html",
    styleUrls: ["./error.component.scss"]
})
export class ErrorComponent implements OnInit {
    @Input() isModal: boolean;
    @Input() data: IData;

    errorTitle: string;
    errorMessage: string;
    buttonTitle: string;

    readonly theme = environment.theme;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService) {}

    ngOnInit() {
        if (!this.isModal) {
            this.errorTitle = ErrorTitle.GENERIC;
            this.activatedRoute.queryParams.subscribe(params => {
                const type = params["type"];
                switch (type) {
                    case ErrorType.VERIFY_EMAIL:
                        this.errorMessage = ErrorMessage.VERIFY_EMAIL;
                        this.buttonTitle = ButtonType.LOGIN;
                        this.redirectLoggedInUser();
                        break;
                    default:
                        this.errorMessage = ErrorMessage.GENERIC;
                        this.buttonTitle = ButtonType.DASHBOARD;
                        break;
                }
            });
        } else {
            this.errorTitle = this.data.title || ErrorTitle.GENERIC;
            this.errorMessage = this.data.message || ErrorMessage.GENERIC;
            this.buttonTitle = `GO TO ${ButtonType.DASHBOARD}`;
        }
    }

    buttonClick(title: string) {
        switch (title) {
            case ButtonType.LOGIN:
                this.router.navigateByUrl("/login");
                break;
            default:
                this.router.navigateByUrl("/dashboard");
                break;
        }
    }

    async redirectLoggedInUser() {
        const user = await this.authService
            .getUser()
            .pipe(take(1))
            .toPromise();

        if (user) {
            this.router.navigateByUrl("/dashboard");
        }
    }
}
