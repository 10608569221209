import { Component, OnDestroy, Inject } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { IThreadCard } from "@findex/threads/dist/interfaces/IThreadCard";
import { IParticipantListing, Role, IThread } from "@findex/threads";
import { MatDialog } from "@angular/material";
import { VcModalComponent, VCModalModel } from "../vc-modal/vc-modal.component";
import { VcStateBuilder } from "../../services/vc-state-builder";
import { AuthService } from "src/modules/findex-auth";
import { map, switchMap, take, filter } from "rxjs/operators";
import { THREAD_CARD_RESOURCES, CardResources } from "src/app/interfaces/IUiCard";
import { VideoChatService } from "../../services/video-chat.service";
import { Loader } from "src/app/services/loader";

@Component({
    selector: "vc-card",
    templateUrl: "./vc-card.component.html",
    styleUrls: ["vc-card.component.scss"]
})
export class VcCardComponent implements OnDestroy {
    private fullscreenSub: Subscription;
    private eventSub: Subscription;
    private userSub: Subscription;
    private modelBuilder = new VcStateBuilder();

    thread: IThread;
    card: IThreadCard;
    userId$: Observable<string>;
    role: Role;
    vcDetails$ = this.modelBuilder.getState();
    loader = new Loader();

    constructor(
        @Inject(THREAD_CARD_RESOURCES) resources: CardResources,
        private dialog: MatDialog,
        private videoChatService: VideoChatService,
        authService: AuthService
    ) {
        const { thread, card, eventService, navigateTo, role } = resources;

        this.thread = thread;
        this.card = card;
        this.role = role;
        this.userId$ = authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );

        this.fullscreenSub = navigateTo.subscribe(() => this.openFullscreen());
        this.modelBuilder.setThreadAndState(thread.id, card.id);
        this.eventSub = eventService.events.subscribe(event => this.modelBuilder.addEvent(event));

        //I'm guessing eventually this will change to be other participants in the call but not sure on UI
        //But then what if you are the first to join? UI question + need ED-248
        this.userSub = this.userId$
            .pipe(filter(user => !!user))
            .subscribe(id => this.setCaller(thread.participants, id));
    }

    ngOnDestroy() {
        if (this.fullscreenSub) this.fullscreenSub.unsubscribe();
        if (this.eventSub) this.eventSub.unsubscribe();
        if (this.userSub) this.userSub.unsubscribe();
    }

    async openFullscreen(join?: boolean) {
        const details = this.vcDetails$;
        const openedModal = this.dialog.open<VcModalComponent, VCModalModel, boolean>(VcModalComponent, {
            maxWidth: "100vw",
            panelClass: "video-chat-modal-timeline",
            disableClose: true,
            closeOnNavigation: false,
            hasBackdrop: false,
            data: { details, join, role: this.role }
        });

        await openedModal.afterClosed().toPromise();
    }

    private setCaller(participants: IParticipantListing[], excludeId: string): void {
        //This is basically a temp hack
        //What do we want to show when there is more than one participant?? UI Question
        let found = participants.find(participant => participant.id !== excludeId);
        if (!found) found = participants.find(() => true);

        if (!found || !found.profile || !found.profile.name) return;
        this.modelBuilder.setCaller(found.profile.name, found.profile.title);
    }

    async terminateSession() {
        const detailSub = this.vcDetails$
            .pipe(
                take(1),
                switchMap(detail => {
                    const threadId = detail.threadId;
                    const cardId = detail.cardId;
                    const { sessionId } = detail;

                    const terminate$ = this.videoChatService.terminateSession(sessionId, threadId, cardId);
                    return this.loader.wrap(terminate$);
                })
            )
            .subscribe(() => {
                detailSub.unsubscribe();
            });
    }
}
