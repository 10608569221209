import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { AnalyticsService } from "src/modules/analytics";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "../../services/auth.service";

@Component({
    selector: "two-factor-authentication-dialog",
    templateUrl: "./two-factor-authentication-dialog.component.html",
    styleUrls: ["./two-factor-authentication-dialog.component.scss"]
})
export class TwoFactorAuthenticationDialogComponent implements OnInit {
    readonly userDetails: any;

    constructor(
        private dialogRef: MatDialogRef<TwoFactorAuthenticationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private analyticsService: AnalyticsService,
        private authService: AuthService
    ) {
        this.userDetails = data.userDetails;
    }

    ngOnInit() {
        this.recordAnalyticsEvent("displayed");
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("two-factor-auth", category);
    }

    public closeDialog(submitted: boolean) {
        if (!submitted) {
            this.authService.cancelLogin();
        }
        this.dialogRef.close(submitted);
    }
}
