import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AnalyticsService } from "src/modules/analytics";
import { environment } from "../../../../environments/environment";

@Component({
    selector: "app-confirm-mobile-dialog",
    templateUrl: "./confirm-mobile-dialog.component.html",
    styleUrls: ["./confirm-mobile-dialog.component.scss"]
})
export class ConfirmMobileDialogComponent implements OnInit {
    readonly theme = environment.theme;

    codeSent: boolean;
    mobileNumber: string;
    showLoader: boolean;

    constructor(
        private dialogRef: MatDialogRef<ConfirmMobileDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: { mobileNumber: string; codeSent: boolean },
        private analyticsService: AnalyticsService
    ) {
        this.mobileNumber = data.mobileNumber;
        this.codeSent = !!data.codeSent;
    }

    ngOnInit() {
        this.recordAnalyticsEvent("show");
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("confirm-mobile", category);
    }

    close() {
        this.recordAnalyticsEvent("complete");
        this.dialogRef.close();
    }
}
