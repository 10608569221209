import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { BUSINESS_INDUSTRY_TYPES } from "../../../../../shared/constants/IndustryTypes";

type IndustryOption = { name: string; value: string; selected: boolean };

const BUSINESS_INDUSTRY_OPTIONS: IndustryOption[] = BUSINESS_INDUSTRY_TYPES.map(option => ({
    ...option,
    selected: false
}));

const isSelected = industryType => industryType.selected;

@Component({
    selector: "app-industry-type-selection",
    templateUrl: "./industry-type-selection.component.html",
    styleUrls: ["./industry-type-selection.component.scss"]
})
export class IndustryTypeSelectionComponent implements OnChanges {
    @Input() selectedIndustryTypes: string[] = [];
    @Output() modelValidated: EventEmitter<boolean> = new EventEmitter();
    @Output() modelUpdated: EventEmitter<any> = new EventEmitter();

    filter: string = "";

    readonly industryTypes = [...BUSINESS_INDUSTRY_OPTIONS].sort((left, right) => left.name.localeCompare(right.name));
    filteredIndustryTypes = this.industryTypes;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedIndustryTypes) {
            changes.selectedIndustryTypes.currentValue.forEach(selectedIndustryType => {
                const industryOption = this.industryTypes.find(
                    industryType => industryType.value === selectedIndustryType
                );
                if (industryOption) {
                    industryOption.selected = true;
                }
            });
            this.updateValidationState();
        }
    }

    updateParentModel() {
        const selectedIndustryTypeNames = this.industryTypes.filter(isSelected).map(industryType => industryType.value);
        this.modelUpdated.emit(selectedIndustryTypeNames);
        this.updateValidationState();
    }

    updateValidationState() {
        if (this.industryTypes.some(isSelected)) {
            this.modelValidated.emit(true);
        } else {
            this.modelValidated.emit(false);
        }
    }

    searchUpdated() {
        if (!this.filter || this.filter.trim() === "") {
            this.filteredIndustryTypes = this.industryTypes;
        } else {
            this.filteredIndustryTypes = this.industryTypes.filter(industryType =>
                industryType.name.toLowerCase().includes(this.filter.toLowerCase())
            );
        }
    }

    selectionToggled(option: IndustryOption) {
        option.selected = !option.selected;
        this.updateParentModel();
    }
}
