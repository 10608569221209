import { Component, Inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IThreadNote } from "@findex/threads";

@Component({
    selector: "app-thread-cancel-reason-modal",
    templateUrl: "./cancel-thread-reason-modal.component.html",
    styleUrls: ["./cancel-thread-reason-modal.component.scss"]
})
export class CancelThreadReasonModalComponent {
    latestNote: IThreadNote;
    readonly theme = environment.theme;
    readonly cancelReasons = {
        facetoface: "Requested in-person meeting",
        unreachable: "Client unreachable",
        notrequired: "Does not require service",
        other: "Other"
    };

    constructor(
        private dialogRef: MatDialogRef<CancelThreadReasonModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { notes: IThreadNote[]; clientName: string }
    ) {
        this.latestNote = data.notes.length > 0 ? data.notes[data.notes.length - 1] : undefined;
    }

    close() {
        this.dialogRef.close();
    }
}
