import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment, environmentCommon } from "../../../environments/environment";
import { AuthorizationLevel } from "../../findex-auth/model/AuthorizationLevel";
import { User } from "../../findex-auth/model/User";

@Injectable()
export class InjectableUser extends User {}

export interface UserListingEntry {
    id: string;
    name: string;
    emailAddress: string;
    mobileNumber: string;
    details?: {
        mobileNumber: string;
        emailAddress: string;
        lastLoginTimestamp: string;
    };
}

@Injectable({ providedIn: "root" })
export class AdminService {
    constructor(private httpClient: HttpClient) {}

    searchClients(searchName: string): Observable<User[]> {
        const params = {
            name: searchName
        };

        const { base } = environment.threadsEndpoints;
        const { users } = environmentCommon.threadsEndpoints;
        const url = `${base}${users}`;

        return this.httpClient
            .get<UserListingEntry[]>(url, { params })
            .pipe(
                map((userListingEntries: UserListingEntry[]): User[] => {
                    return userListingEntries.map(
                        (userListingEntry: UserListingEntry): User => ({
                            id: userListingEntry.id,
                            name: userListingEntry.name,
                            authorizationLevel: AuthorizationLevel.UNKNOWN,
                            emailAddressVerified: undefined,
                            mobileNumberVerified: undefined,
                            type: "cognito",
                            details: {
                                emailAddress: userListingEntry.emailAddress || userListingEntry.details.emailAddress,
                                mobileNumber: userListingEntry.mobileNumber || userListingEntry.details.mobileNumber,
                                lastLoginTimestamp: userListingEntry.details.lastLoginTimestamp
                            }
                        })
                    );
                })
            );
    }

    listStaff(): Observable<User[]> {
        //TODO when we have staff authentication
        // const params = {
        //     type: "staff"
        // };
        // const url = `${AUTH_CONFIG.url}${AUTH_CONFIG.endpoints.user}`;
        // return this.httpClient
        //     .get<{ users: User[] }>(url, { params })
        //     .pipe(map(response => response.users));
        return of([]);
    }
}
