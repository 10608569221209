<loader-icon *ngIf="loader.counter" color="light"></loader-icon>

<h2>Complete request for information</h2>

<p>
    Are you sure you want to complete this request for information? If yes, you will no longer be able to upload further
    documents
</p>

<div class="controls">
    <button (click)="close()" analyticsClick="rfi_cancelcomplete" class="fx-btn fx-btn--primary fx-btn--white">
        No
    </button>
    <button (click)="completeRfi()" analyticsClick="rfi_confirmcomplete" class="fx-btn fx-btn--primary-outlined">
        Yes
    </button>
</div>
