import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { DateTime } from "luxon";

const mobileNumberValidator = (control: AbstractControl): { [key: string]: any } | null => {
    //If it's already showing a required error, don't show this.
    if (!control.value || control.hasError("required")) {
        return null;
    }
    const safeValue = control.value || "";

    const australiaResult = parsePhoneNumberFromString(safeValue, "AU");
    const australiaResultInvalid = !australiaResult || !australiaResult.isValid();

    return australiaResultInvalid ? { invalid: { value: control.value } } : null;
};

const passwordValidator = (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
        return null;
    }
    if (control.value.length < 8) {
        return { length: { value: control.value } };
    }
    if (!/[A-Z]/.test(control.value)) {
        return { needsUppercase: { value: control.value } };
    }
    if (!/[a-z]/.test(control.value)) {
        return { needsLowercase: { value: control.value } };
    }
    if (!/[0-9]/.test(control.value)) {
        return { needsNumber: { value: control.value } };
    }
    return null;
};

const passwordsMatchValidator = (field1Name: string, field2Name: string): ValidatorFn => {
    const errorResult = { matchingFields: {} };
    return (group: FormGroup): ValidationErrors | null => {
        const field1 = group.get(field1Name);
        const field2 = group.get(field2Name);
        if (!field1 || !field2) {
            return errorResult;
        }
        //Don't show an error if either field is not valid.
        if (!field1.valid || !field2.valid) {
            return null;
        }
        return field1.value !== field2.value ? errorResult : null;
    };
};

const dateValidator = (dateFormat = "dd/MM/yyyy") => {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value;
        const errorResult = {
            date: { value: value }
        };

        try {
            //Throws if date is invalid
            //TODO check that it throws if date is invalid
            if (value) {
                const result = DateTime.fromFormat(value, dateFormat);
                if (!result.isValid) {
                    return errorResult;
                }
            }
        } catch (error) {
            return errorResult;
        }
        //No errors
        return null;
    };
};

const taxFileNumberValidator = (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value || "";
    if (!value) return null;

    const errorResult = {
        taxFileNumber: {
            value
        }
    };

    //TFN must be 9 digits long
    if (value.length != 9) {
        return errorResult;
    }
    //Algorithm from https://en.wikipedia.org/wiki/Tax_file_number
    const digits = Array.from(value);
    const weights = [1, 4, 3, 7, 5, 8, 6, 9, 10];

    const mapped = digits.map((digit, index) => Number(digit) * weights[index]);
    const total = mapped.reduce((total, value) => total + value, 0);

    //Sum of weighted digits must be evenly divisible by 11 (e.g. 253)
    if (total % 11 !== 0) {
        return errorResult;
    }
    return null;
};

export const CustomValidators = {
    mobileNumberValidator,
    passwordValidator,
    passwordsMatchValidator,
    dateValidator,
    taxFileNumberValidator
};

export const getPasswordErrorMessage = (controlName: string = "Password") => {
    if (controlName === "ConfirmPassword") {
        return `The passwords don't match!`;
    }
    return `${controlName} must have at least 8 characters and contain at least one of the following; uppercase letter, lowercase letter and number.`;
};
