import { NgModule } from "@angular/core";
import { ActivatedRouteSnapshot, ActivationEnd, Router, RouterModule, Routes } from "@angular/router";
import { AnalyticsService } from "src/modules/analytics";
import { filter } from "rxjs/operators";
import { AuthGuard } from "src/modules/findex-auth";
import { SelectBookkeeperComponent } from "src/modules/shared";
import { VerifiedGuard } from "../modules/findex-auth/services/verified.guard";
import { LoginPageComponent } from "../modules/findex-auth/components/login-page/login-page.component";
import { StaffLoginPageComponent } from "../modules/findex-auth/components/staff-login-page/staff-login-page.component";
import { ThreadsListRouteComponent } from "./components/threads-list-route/threads-list-route.component";
import { ThreadRouteComponent } from "./components/thread-route/thread-route.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { StaffGuard } from "../modules/findex-auth/services/staff.guard";
import { environment } from "../environments/environment";
import { ErrorComponent } from "src/modules/shared/components/error/error.component";
import { ResetPasswordComponent } from "../modules/findex-auth/components/reset-password/reset-password.component";
import { NoAuthGuard } from "../modules/findex-auth/services/no-auth.guard";
import { SelectStaffComponent } from "../modules/shared/components/select-staff/select-staff.component";
import { LoggedInGuard } from "../modules/findex-auth/services/logged-in.guard";
import { OnboardingProfileComponent } from "../modules/shared/components/onboarding-profile/onboarding-profile.component";

const registrationEnabledPaths = [
    { path: "register", loadChildren: "../modules/onboarding/onboarding.module#OnboardingModule" },
    { path: "", redirectTo: "login", pathMatch: "full" },
    { path: "**", redirectTo: "login", pathMatch: "full" }
];
const registrationDisabledPaths = [
    { path: "", redirectTo: "login", pathMatch: "full" },
    { path: "**", redirectTo: "login", pathMatch: "full" }
];
const rootPaths = environment.featureFlags.signupEnabled ? registrationEnabledPaths : registrationDisabledPaths;

const routes: Routes = [
    {
        //Redirect users to the mobile verification screen if they are not mobile verified
        canActivateChild: [VerifiedGuard],
        path: "",
        children: [
            {
                path: "timelines",
                component: ThreadsListRouteComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: ":threadId", component: ThreadRouteComponent, canActivate: [AuthGuard] },
                    { path: ":threadId/cards/:cardId", component: ThreadRouteComponent, canActivate: [AuthGuard] }
                ]
            },
            { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard] },
            { path: "admin", loadChildren: "../modules/admin/admin.module#AdminModule", canActivate: [StaffGuard] },
            {
                path: "reports",
                loadChildren: "../modules/reports/reports.module#ReportsModule",
                canActivate: [StaffGuard]
            },
            { path: "select-bookkeeper", component: SelectBookkeeperComponent, canActivate: [AuthGuard] },
            { path: "select-staff", component: SelectStaffComponent, canActivate: [AuthGuard] },
            { path: "onboarding-profile", component: OnboardingProfileComponent, canActivate: [AuthGuard] },
            { path: "profile", loadChildren: "../modules/user-profile/user-profile.module#UserProfileModule" },
            { path: "login", component: LoginPageComponent, canActivate: [LoggedInGuard] },
            { path: "reset-password", component: ResetPasswordComponent, canActivate: [NoAuthGuard] },
            { path: "staff-login", component: StaffLoginPageComponent },
            { path: "error", component: ErrorComponent },
            ...rootPaths
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor(router: Router, analyticsService: AnalyticsService) {
        router.events.pipe(filter(event => event instanceof ActivationEnd)).subscribe((event: ActivationEnd) => {
            if (!event.snapshot.firstChild) {
                const eventPath = this.getFullRoutePath(event.snapshot);
                const santizedEventPath = eventPath === "" ? "/" : eventPath;
                analyticsService.pageViewed(santizedEventPath);
            }
        });
    }

    private getFullRoutePath(snapshot: ActivatedRouteSnapshot): string {
        if (!snapshot || !snapshot.routeConfig) {
            return "";
        }
        const parentPath = this.getFullRoutePath(snapshot.parent);
        const prefix = parentPath ? `${parentPath}/` : "";
        return `${prefix}${snapshot.routeConfig.path}`;
    }
}
