<div class="user-profile">
    <loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
    <fx-container [fluid]="true">
        <fx-layout [columns]="24" [gutters]="false">
            <fx-layout-column [sizes]="{ mobile: '24' }">
                <fx-layout [columns]="24" [gutters]="true">
                    <fx-layout-column [sizes]="{ mobile: '24', tablet: '4' }">
                        <div class="user-profile-avatar">
                            <fx-avatar [name]="form.controls.fullName.value" [isLarge]="true"></fx-avatar>
                        </div>
                    </fx-layout-column>
                    <fx-layout-column [sizes]="{ mobile: '24', tablet: '20' }">
                        <div class="user-profile-content">
                            <form class="fx-form fx-form--dark" [formGroup]="form">
                                <div class="fx-form-group" *ngIf="errorMessage">
                                    <div class="fx-form-validation-panel">
                                        <div class="fx-form-validation">{{ errorMessage }}</div>
                                    </div>
                                </div>

                                <div class="fx-form-group">
                                    <label class="fx-form-label" for="name">Full name</label>
                                    <div class="fx-form-control">
                                        <input
                                            formControlName="fullName"
                                            id="name"
                                            class="fx-form-input"
                                            type="text"
                                            placeholder="eg. John Doe"
                                            aria-label="Full name"
                                            [ngClass]="{
                                                'fx-form-input--error':
                                                    !form.controls['fullName'].valid &&
                                                    form.controls['fullName'].touched
                                            }"
                                        />
                                    </div>
                                    <div
                                        class="fx-form-validation"
                                        *ngIf="
                                            form.controls['fullName'].hasError('required') &&
                                            form.controls['fullName'].touched
                                        "
                                    >
                                        Full name is required.
                                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                                    </div>
                                </div>
                                <div class="fx-form-group">
                                    <label class="fx-form-label" for="emailAddress">Email</label>
                                    <div class="fx-form-control">
                                        <input
                                            formControlName="emailAddress"
                                            id="emailAddress"
                                            class="fx-form-input"
                                            type="text"
                                            placeholder="john.doe@findex.com.au"
                                            aria-label="Email"
                                            [ngClass]="{
                                                'fx-form-input--error':
                                                    !form.controls['emailAddress'].valid &&
                                                    form.controls['emailAddress'].touched
                                            }"
                                        />
                                    </div>
                                    <div
                                        class="fx-form-validation"
                                        *ngIf="
                                            form.controls['emailAddress'].hasError('required') &&
                                            form.controls['emailAddress'].touched
                                        "
                                    >
                                        Email is required.
                                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                                    </div>
                                    <div
                                        class="fx-form-validation"
                                        *ngIf="
                                            form.controls['emailAddress'].hasError('email') &&
                                            form.controls['emailAddress'].touched
                                        "
                                    >
                                        Email is invalid.
                                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                                    </div>
                                </div>

                                <div class="fx-form-group">
                                    <label class="fx-form-label" for="mobileNumber">Mobile</label>
                                    <div class="fx-form-control" [class.no-flag]="signupCountries.length < 2">
                                        <input
                                            class="fx-form-input"
                                            type="text"
                                            name="Mobile"
                                            formControlName="mobileNumber"
                                            [ngClass]="{
                                                'fx-form-input--error':
                                                    !form.controls['mobileNumber'].valid &&
                                                    form.controls['mobileNumber'].touched
                                            }"
                                            intlTel
                                            [intlTelOptions]="{
                                                initialCountry: 'au',
                                                onlyCountries: signupCountries,
                                                allowDropdown: signupCountries.length > 1,
                                                customContainer: 'fx-onboarding-intl-input'
                                            }"
                                            [intlTelInitialValue]="form.controls.mobileNumber.value"
                                            (intlTelValid)="internationalPhoneNoValid = $event"
                                            (intlTelCurrentValue)="internationalPhoneNo = $event"
                                        />
                                    </div>
                                    <div
                                        class="fx-form-validation"
                                        *ngIf="
                                            form.controls['mobileNumber'].hasError('required') &&
                                            form.controls['mobileNumber'].touched
                                        "
                                    >
                                        Mobile is required.
                                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                                    </div>
                                    <div
                                        class="fx-form-validation"
                                        *ngIf="
                                            form.controls['mobileNumber'].hasError('invalid') &&
                                            form.controls['mobileNumber'].touched
                                        "
                                    >
                                        Mobile number is not valid.
                                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                                    </div>
                                </div>
                                <ng-container *ngIf="!simpleProfile">
                                    <ng-container *ngIf="onboardingType === 'sameday'">
                                        <app-sameday-profile-form [form]="sameDayTaxForm"></app-sameday-profile-form>
                                    </ng-container>
                                    <ng-container *ngIf="onboardingType === 'sigma'">
                                        <div [formGroup]="sigmaForm">
                                            <div class="fx-form-group">
                                                <label class="fx-form-label" for="business">Business name</label>
                                                <div class="fx-form-control">
                                                    <input
                                                        type="email"
                                                        name="business"
                                                        id="business"
                                                        class="fx-form-input"
                                                        placeholder="Business name"
                                                        formControlName="businessName"
                                                    />
                                                </div>
                                            </div>
                                            <div class="user-profile-industry">
                                                <div class="user-profile-industry-header">Industry type</div>
                                                <div class="user-profile-industry-add">
                                                    <div class="user-profile-industry-add-chips">
                                                        <div
                                                            class="user-profile-industry-add-chip"
                                                            *ngFor="
                                                                let industryType of sigmaForm.controls.industryTypes
                                                                    .value;
                                                                let index = index
                                                            "
                                                        >
                                                            <i
                                                                *ngIf="
                                                                    sigmaForm.controls.industryTypes.value.length > 1
                                                                "
                                                                (click)="removeIndustryType(index)"
                                                                class="las la-times"
                                                            ></i>
                                                            <span>{{ getIndustryTypeName(industryType) }}</span>
                                                        </div>
                                                    </div>
                                                    <div></div>
                                                    <div class="user-profile-industry-add-button">
                                                        <i (click)="showIndustryTypeModal()" class="las la-plus"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </form>

                            <div class="fx-form-group" *ngIf="(myRole$ | async) == Role.Administrator">
                                <label class="fx-form-label" for="business">Global User Role</label>
                                <div class="fx-form-control">
                                    <select class="fx-form-select" (change)="setProfileRole($event.target.value)">
                                        <option [selected]="!originalProfileRole" [value]="null">No Global Role</option>
                                        <option
                                            *ngFor="let entry of roleOptions"
                                            [value]="entry[1]"
                                            [selected]="entry[1] === originalProfileRole"
                                            >{{ entry[0] }}</option
                                        >
                                    </select>
                                </div>
                            </div>
                        </div>

                        <button
                            *ngIf="showChangePassword$ | async"
                            (click)="showResetPasswordDialog()"
                            analyticsClick="app_changepassword"
                            class="user-profile-reset-password"
                        >
                            CHANGE PASSWORD
                        </button>
                    </fx-layout-column>
                </fx-layout>
            </fx-layout-column>
            <fx-layout-column [sizes]="{ mobile: '24' }">
                <div class="user-profile-buttons">
                    <button
                        *ngIf="isClientSearch$ | async"
                        class="fx-btn"
                        [ngClass]="['fx-btn--secondary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                        (click)="back()"
                    >
                        BACK
                    </button>
                    <button
                        class="fx-btn"
                        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                        [disabled]="!saveShouldBeEnabled()"
                        (click)="saveChanges()"
                        type="submit"
                    >
                        UPDATE
                    </button>
                </div>
            </fx-layout-column>
        </fx-layout>
    </fx-container>
</div>
