import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule, MatDialogModule } from "@angular/material";
import { CHAT_STAGE, OPENTOK_API_KEY, VideoChatModule } from "@findex/video-chat";

import { environment } from "src/environments/environment";
import { VcCardComponent } from "./components/vc-card/vc-card.component";
import { VcModalComponent } from "./components/vc-modal/vc-modal.component";
import { MeetingDetailsComponent } from "./components/meeting-details/meeting-details.component";
import { ThreadsUiModule } from "../../threads-ui";
import { FindexUiModule } from "../../findex-ui";
import { FxUiModule } from "@findex/fx-ui";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        VideoChatModule,
        ThreadsUiModule,
        FindexUiModule,
        FxUiModule,
        VideoChatModule,
        DragDropModule
    ],
    exports: [],
    declarations: [VcCardComponent, VcModalComponent, MeetingDetailsComponent],
    entryComponents: [VcCardComponent, VcModalComponent],
    providers: [
        { provide: CHAT_STAGE, useValue: environment.stage },
        { provide: OPENTOK_API_KEY, useValue: environment.opentokApiKey }
    ]
})
export class VideoChatCardModule {}
