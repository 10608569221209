import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CustomValidators } from "../../validators";
import { merge, of, Subject } from "rxjs";
import { delay, mapTo, startWith } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../findex-auth";
import { AnalyticsService } from "src/modules/analytics";

@Component({
    selector: "app-verify-mobile-form",
    templateUrl: "./verify-mobile-form.component.html",
    styleUrls: ["./verify-mobile-form.component.scss"]
})
export class VerifyMobileFormComponent implements OnInit {
    @Input()
    mobileNumber: string;
    @Input()
    codeSent: boolean;

    @Output()
    showLoader: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    completed: EventEmitter<void> = new EventEmitter<void>();

    readonly theme = environment.theme;
    readonly signupCountries = environment.featureFlags.signupCountries;

    errorMessage: string = "";
    infoMessage: string = "";
    internationalPhoneNo: string;
    internationalPhoneNoValid: boolean;

    showVerifyCode: boolean = false;

    form = new FormGroup({
        mobileNumber: new FormControl(null, [Validators.required, CustomValidators.mobileNumberValidator]),
        verifyCode: new FormControl({ value: null, disabled: true }, [
            Validators.required,
            Validators.pattern("[0-9]{6}"),
            Validators.maxLength(6),
            Validators.minLength(6)
        ])
    });

    private resendClicked = new Subject<true>();
    disableResend$ = merge(
        this.resendClicked.pipe(startWith(false)), //Start with false so the button is enabled initially
        this.resendClicked.pipe(delay(10000), mapTo(false)) //Un-disable the button after a delay
    );

    constructor(private authService: AuthService, private analyticsService: AnalyticsService) {}

    ngOnInit() {
        this.form.setValue({
            mobileNumber: this.mobileNumber || "",
            verifyCode: ""
        });
        if (this.codeSent) {
            this.enableVerify();
        }
    }

    private enableVerify() {
        this.showVerifyCode = true;
        this.form.controls.verifyCode.enable();
    }

    async sendCode() {
        this.resendClicked.next(true);
        this.enableVerify();
        this.showLoader.emit(true);
        this.infoMessage = "";
        this.errorMessage = "";
        try {
            const result = await this.authService.beginVerifyMobileNumber(this.internationalPhoneNo).toPromise();
            switch (result.status) {
                case "OK":
                    if (this.showVerifyCode) {
                        this.recordAnalyticsEvent("begin-resend");
                        this.infoMessage = `We've sent another code, please check your mobile`;
                    } else {
                        this.recordAnalyticsEvent("begin-resend");
                        this.infoMessage = `We've sent a code, please check your mobile`;
                    }
                    this.recordAnalyticsEvent("begin-resend");
                    this.showVerifyCode = true;
                    break;
                case "VERIFIED":
                    this.recordAnalyticsEvent("begin-already-verified");
                    break;
                case "THROTTLED":
                    this.recordAnalyticsEvent("begin-throttled");
                    this.errorMessage = "Sorry, you've sent too many requests recently.";
                    break;
                case "MOBILE_IN_USE":
                    this.recordAnalyticsEvent("begin-number-in-use");
                    this.errorMessage = "This mobile number is already in use.";
                    break;
                case "REQUEST_ERROR":
                    this.recordAnalyticsEvent("begin-request-error");
                    this.errorMessage = "An error occurred, please try again.";
                    break;
                case "UNKNOWN_ERROR":
                default:
                    this.recordAnalyticsEvent("begin-unknown-error");
                    this.errorMessage = "An error occurred, please try again.";
                    break;
            }
        } catch (err) {
            this.recordAnalyticsEvent("begin-resend-unknown-error");
            this.errorMessage = "An error occurred, please try again.";
        }
        of(undefined)
            .pipe(delay(1000))
            .subscribe(() => {
                this.showLoader.emit(false);
            });
    }

    async verifyMobileCode() {
        //For some reason this value is cleared when refreshUserTokens is called below. Weird!
        const code = `${this.form.controls.verifyCode.value}`;

        this.showLoader.emit(true);
        this.recordAnalyticsEvent("mobile-entered");
        try {
            //Refresh user tokens as updating the profile invalidates existing ones.
            await this.authService.refreshUserTokens();

            const success = await this.submitCode(code);
            if (success === true) {
                this.recordAnalyticsEvent("mobile-verified");
                this.authService.refreshUserTokens().then(async () => {
                    this.completed.emit();
                });
            } else {
                this.recordAnalyticsEvent("fail");
                // this.errorMessage = result.message;
                this.showLoader.emit(false);
            }
        } catch (error) {
            this.recordAnalyticsEvent("mobile-unknown-error");
            this.errorMessage = error.message;
            this.showLoader.emit(false);
        }
    }

    private async submitCode(code: string): Promise<boolean> {
        if (!code) {
            return false;
        }

        this.showLoader.emit(true);

        try {
            const result = await this.authService.confirmMobileNumber(code).toPromise();
            this.showLoader.emit(false);
            switch (result.status) {
                case "OK":
                    this.recordAnalyticsEvent("confirm-success");
                    return true;
                case "INCORRECT_CODE":
                    this.errorMessage = "Sorry, invalid pin, please try again";
                    this.recordAnalyticsEvent("confirm-incorrect");
                    return false;
                case "EXPIRED":
                    this.errorMessage = `The verification code expired, please click "resend" to try again.`;
                    this.recordAnalyticsEvent("confirm-expired");
                    return false;
                case "REQUEST_ERROR":
                    this.recordAnalyticsEvent("confirm-request-error");
                    this.errorMessage = "An error occurred, please try again.";
                    return false;
                case "UNKNOWN_ERROR":
                default:
                    this.recordAnalyticsEvent("confirm-unknown-error");
                    this.errorMessage = "An error occurred, please try again.";
            }
        } catch (err) {
            this.recordAnalyticsEvent("confirm-unknown-error");
            this.errorMessage = "An error occurred, please try again.";
        }
        return false;
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("verify-mobile", category);
    }
}
