<div class="file-input">
    <button class="file-input-browse fx-btn fx-btn--primary fx-btn--primary-outlined" role="button">
        BROWSE FILES
    </button>

    <ngx-dropzone (change)="onSelect($event)">
        <ngx-dropzone-label>
            <p>Drag and drop</p>

            <div class="file-input-line-container">
                <span class="file-input-line">&nbsp;</span>
                <span>Or</span>
                <span class="file-input-line">&nbsp;</span>
            </div>

            <button
                class="fx-btn fx-btn--primary fx-btn--primary-outlined"
                role="button"
                analyticsClick="rfi_browsefiles"
            >
                BROWSE FILES
            </button>
        </ngx-dropzone-label>
    </ngx-dropzone>
</div>
