import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from "@angular/core";

export type ClientDetailsData = {
    packageId: string;
    taxFileNumber: string;
    bankName: string;
    accountName: string;
    accountBsb: string;
    accountNumber: string;
    idType: string;
    idNumber: string;
    idExpiry: string;
};

@Component({
    selector: "client-details-form",
    templateUrl: "./client-details-form.component.html",
    styleUrls: ["./client-details-form.component.scss"]
})
export class ClientDetailsFormComponent implements OnInit, OnChanges {
    //TODO: see if we can pull these from backend
    packages = [
        { label: "Same Day Tax Refund", packageId: "sameday" },
        { label: "Standard", packageId: "standard" },
        { label: "Couples", packageId: "couple" },
        { label: "Business & Property", packageId: "business" }
    ];
    updated: ClientDetailsData;

    @Input() data: ClientDetailsData;
    @Output() continue = new EventEmitter<ClientDetailsData>();

    ngOnInit() {
        this.initData();
    }

    ngOnChanges(changes: SimpleChanges) {
        const { data } = changes;

        if (data) {
            this.initData(data.currentValue);
        }
    }

    enableButton(updated: ClientDetailsData) {
        const { packageId, taxFileNumber } = updated;
        return !packageId || !taxFileNumber;
    }

    save(event: Event, updated: ClientDetailsData) {
        event.preventDefault();
        this.continue.emit(updated);
    }

    private initData(newData?: ClientDetailsData) {
        this.updated = {
            packageId: "",
            taxFileNumber: "",
            bankName: "",
            accountName: "",
            accountBsb: "",
            accountNumber: "",
            idType: "",
            idNumber: "",
            idExpiry: "",
            ...(newData || {})
        };
    }
}
