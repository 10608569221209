import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FxUiModule } from "@findex/fx-ui";
import { MatInputModule, MatFormFieldModule, MatDialogModule } from "@angular/material";
import { ClientProfileCardComponent } from "./components/client-profile-card/client-profile-card.component";
import { ThreadsUiModule } from "../../threads-ui";
import { ReactiveFormsModule } from "@angular/forms";
import { UserProfileModule } from "../../user-profile/user-profile.module";
import { FindexUiModule } from "../../findex-ui";
import { RouterModule } from "@angular/router";

@NgModule({
    imports: [
        CommonModule,
        MatInputModule,
        MatFormFieldModule,
        FxUiModule,
        ThreadsUiModule,
        MatDialogModule,
        ReactiveFormsModule,
        UserProfileModule,
        FindexUiModule,
        RouterModule
    ],
    declarations: [ClientProfileCardComponent],
    entryComponents: [ClientProfileCardComponent]
})
export class ClientProfileCardModule {}
