<div class="fx-form-group">
    <label class="fx-form-label" for="name">Rename file</label>
    <input
        id="name"
        class="fx-form-input"
        type="text"
        aria-label="Document Name"
        [value]="name"
        (change)="name = $event.target.value"
    />
</div>

<div class="buttons">
    <button class="fx-btn fx-btn--primary fx-btn--white" role="button" (click)="cancel()">Cancel</button>
    <button
        class="fx-btn"
        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
        role="button"
        (click)="save()"
    >
        Save
    </button>
</div>
