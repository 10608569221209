import { Injectable } from "@angular/core";
import { ShepherdService } from "angular-shepherd";
import { SlideMenuService } from "./slide-menu.service";
import { delay, map, take } from "rxjs/operators";
import { Observable } from "rxjs";
import { ThreadsService } from "./threads.service";
import { AnalyticsService } from "src/modules/analytics";
import { Router } from "@angular/router";
import { User } from "src/modules/findex-auth";
import { MatDialog } from "@angular/material";

interface UserProfileTourConfig {
    tourConfig?: {
        completedTours?: string[];
    };
}

export interface Tour {
    id: string;
    steps: TourStep[];
    tourRoute: string;
}

export interface TourStep {
    titleHtml: string;
    contentHtml: string;
    nextButtonText: string;
    attachTo?: {
        selector: string;
        side: "right" | "left" | "top" | "bottom";
    };

    // If present, show or hide the sidebar before this step is shown.
    menuStateBeforeShow?: "open" | "closed";
    // Default to false
    canClickTarget?: boolean;
    routeTo?: string;
}

const mapTourStep = (tourStep: TourStep, showMenuFunction: (boolean) => void, waitFor: Observable<any>) => {
    const { attachTo: attachToConfig } = tourStep;

    const attachTo = attachToConfig ? { element: attachToConfig.selector, on: attachToConfig.side } : undefined;

    //This promise resolves when the step is ready to show. For steps where the slide menu needs to be open,
    //it will delay for long enough so that the menu can open. If we don't do this, the tour cannot attach to the
    //element.
    const beforeShowPromise = tourStep.menuStateBeforeShow
        ? () =>
              new Promise<void>(resolve => {
                  showMenuFunction(tourStep.menuStateBeforeShow === "open");
                  waitFor.pipe(take(1), delay(250)).subscribe(() => resolve());
              })
        : () => Promise.resolve();

    return {
        attachTo,
        beforeShowPromise,
        arrow: false,
        buttons: [
            {
                classes: "fx-btn fx-btn--primary",
                text: tourStep.nextButtonText,
                type: "next"
            }
        ],
        cancelIcon: {
            enabled: false
        },
        scrollTo: false,
        text: [
            `
        <div class="tour-content">
            <div class="tour-header">${tourStep.titleHtml}</div>
            <div class="tour-body">${tourStep.contentHtml}</div>
        </div>
        `
        ],
        canClickTarget: tourStep.canClickTarget || false
    };
};

@Injectable({ providedIn: "root" })
export class TourService {
    constructor(
        private shepherdService: ShepherdService,
        private slideMenuService: SlideMenuService,
        private threadsService: ThreadsService,
        private analyticsService: AnalyticsService,
        private router: Router,
        private dialog: MatDialog
    ) {}

    initializeTour(user: User, tour: Tour) {
        if (!tour) {
            return;
        }

        if (this.dialog.openDialogs.length > 0) {
            this.dialog.openDialogs[0].afterClosed().subscribe(() => this.beginTour(user, tour));
        } else {
            this.beginTour(user, tour);
        }
    }

    hideTour() {
        if (this.shepherdService.isActive) {
            this.shepherdService.hide();
        }
    }

    private beginTour(user: User, tour: Tour) {
        this.threadsService.getUserData(user.id).subscribe(profile => {
            const completedTours = this.getCompletedTours(profile);
            if (completedTours.includes(tour.id)) {
                return;
            }
            this.startTour(user.id, tour, completedTours);
        });
    }

    private startTour(userId: string, tour: Tour, completedTours: string[]) {
        this.routeToPreferredRoute(tour);

        this.shepherdService.modal = true;
        this.shepherdService.confirmCancel = false;

        const showMenu = (show: boolean) => {
            if (show) {
                this.slideMenuService.show();
            } else {
                this.slideMenuService.hide();
            }
        };

        this.shepherdService.addSteps(
            tour.steps.map(step => mapTourStep(step, showMenu, this.slideMenuService.showMenu$))
        );

        this.shepherdService.tourObject.on("complete", () => {
            this.recordAnalyticsEvent("complete");
            this.markTourComplete(userId, completedTours, tour).subscribe(() => {});
        });

        this.recordAnalyticsEvent("start");
        this.shepherdService.start();
    }

    private routeToPreferredRoute(tour: Tour) {
        const startingRoute = tour.steps[0].routeTo;
        if (startingRoute && startingRoute !== this.router.url) {
            this.router.navigate([startingRoute]);
        }
    }

    private getCompletedTours(userProfile: UserProfileTourConfig): string[] {
        if (!userProfile || !userProfile.tourConfig || !Array.isArray(userProfile.tourConfig.completedTours)) {
            return [];
        }
        return userProfile.tourConfig.completedTours;
    }

    private markTourComplete(userId, completedTours: string[], tour: Tour) {
        const userData = { tourConfig: { completedTours: [...completedTours, tour.id] } };
        return this.threadsService.putUserData(userId, userData).pipe(map(() => true));
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("tour", category);
    }
}
