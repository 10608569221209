import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Role, IThread } from "@findex/threads";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ThreadsService } from "src/app/services/threads.service";
import { Loader } from "src/app/services/loader";

@Component({
    selector: "cancel-thread-modal",
    styleUrls: ["./cancel-thread-modal.component.scss"],
    templateUrl: "./cancel-thread-modal.component.html"
})
export class CancelThreadModalComponent {
    private thread: IThread;

    loader = new Loader();
    cancelForm = new FormGroup({
        reason: new FormControl("", [Validators.required]),
        reasonOther: new FormControl("") //removed validator until https://github.com/angular/angular/issues/14955 resolved
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) data: { thread: IThread; role: Role },
        private dialogRef: MatDialogRef<CancelThreadModalComponent>,
        private threadService: ThreadsService
    ) {
        this.thread = data.thread;
    }

    close() {
        this.dialogRef.close(false);
    }

    async cancelTimeline() {
        const { reason, reasonOther } = this.cancelForm.value;
        const cancelReason = reason === "other" ? reasonOther : reason;
        const cancelNote = { message: `${cancelReason}`, createdAt: new Date().toISOString() };
        const notes = [...(this.thread.notes || []), cancelNote];

        this.loader.show();
        await this.threadService.updateThread(this.thread.id, { notes }).toPromise();
        this.loader.hide();

        this.dialogRef.close(true);
    }
}
