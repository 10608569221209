<div class="event-date" *ngFor="let activityDate of recentDates">
    <div class="date">{{ activityDate.date | date: "dd LLL, yyyy" }}</div>
    <ng-container *ngFor="let activity of activityDate.activities">
        <div class="recent-activity" (click)="navigateToThread(activity.event)">
            <div class="timestamp">
                <div class="time">{{ activity.event.createdAt | date: "h:mm aaa" }}</div>
            </div>

            <div class="recent-activity-details">
                <fx-avatar [name]="activity.event.actor | userToName | async"></fx-avatar>
                <div class="description">
                    {{ activity.event.actor | userToName | async }}
                    {{ activity.description | slice: 0:60 }}
                </div>
            </div>
        </div>
    </ng-container>
</div>

<p *ngIf="!events || !events.length">You have no recent activity</p>

<div class="dashboard-card-footer" *ngIf="activityDates && activityDates.length">
    <a (click)="openAllActivity()" analyticsClick="app_viewallactivity">View All</a>
</div>
