<div class="create-rfi-document">
    <div class="create-rfi-document-input">
        <div class="fx-form fx-form--dark">
            <div class="fx-form-group">
                <div class="fx-form-control">
                    <input
                        type="text"
                        placeholder="Document Type"
                        aria-label="search"
                        class="fx-form-input"
                        [(ngModel)]="data.documentType"
                        (keyup.enter)="dialogRef.close(data)"
                    />
                </div>
            </div>
        </div>
    </div>
    <button class="fx-btn fx-btn--primary fx-btn--primary-outlined" type="button" [mat-dialog-close]="data">
        {{ data.edit ? "Update" : "Add" }}
    </button>
</div>
