import { ThreadTypes } from "@findex/threads";
import { CloseAction, defaultCalendarMeetingDurations, FeatureConfig } from "./environment.common";

export const SIGMA_FEATURE_CONFIG: FeatureConfig = {
    onboardingType: "sigma",
    signupEnabled: true,
    signupCountries: ["au", "nz"],
    subscriptionEnabled: true,
    simpleProfile: false,
    rfiCategories: ["Permanent documents", "Financial statements", "Banking", "Receipts", "Other"],
    calendarMeetingDurations: defaultCalendarMeetingDurations,
    newThreadButton: true,
    jobStatusEnabled: true,
    closeThreadContextMenu: false,
    supportEmail: "client@sigmahq.com.au",
    packageDisplayName: "subscription",
    initialThreadDetails: {
        threadType: ThreadTypes.Bookkeeping
    },
    onCloseThread: CloseAction.SigmaNewThread,
    temporaryFeatureFlags: {},
    addParticipants: false,
    threadParticipantDetail: false,
    showAgreePoliciesLogin: false,
    text: {
        signupInstruction:
            "Start your free trial today and meet your bookkeeper, who will support your business on our platform and address your bookkeeping needs.",
        signupAction: "Start Free Trial"
    },
    signingRequiresFileDownloadEnabled: false
};
