import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

export interface ICreateRfiRemoveDocument {
    category: string;
    documentType: string;
}

@Component({
    selector: "remove-document",
    templateUrl: "./remove-document.component.html",
    styleUrls: ["./remove-document.component.scss"]
})
export class CreateRfiRemoveDocumentComponent {
    constructor(
        public dialogRef: MatDialogRef<CreateRfiRemoveDocumentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ICreateRfiRemoveDocument
    ) {}

    close() {
        this.dialogRef.close();
    }
}
