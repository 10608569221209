import { ThreadTypes } from "@findex/threads";
import { Tour } from "../app/services/tour.service";

//TODO see SDP-43 - this needs to be improved when we have time.
type OnboardingType = "sigma" | "sameday" | "none";

export enum CloseAction {
    SigmaNewThread,
    SameDayFileSync,
    None
}

export interface ICalendarMeetingDuration {
    durationMinutes: number;
    description: string;
    defaultSelection?: boolean;
}

export const defaultCalendarMeetingDurations = [
    { durationMinutes: 15, description: "15 mins" },
    { durationMinutes: 30, description: "30 mins", defaultSelection: true },
    { durationMinutes: 45, description: "45 mins" },
    { durationMinutes: 60, description: "60 mins" }
];

type SignupCountry = "au" | "nz";

export type FeatureConfig = {
    signupEnabled: boolean;
    signupCountries: SignupCountry[];
    subscriptionEnabled: boolean;
    simpleProfile: boolean;
    onboardingType: OnboardingType;
    //TODO move this to the backend
    onboardingRfiDocuments?: {
        [category: string]: { description: string }[];
    };
    jobStatusEnabled: boolean;
    closeThreadContextMenu: boolean;
    rfiCategories: string[];
    newThreadButton: boolean;
    calendarMeetingDurations: ICalendarMeetingDuration[];
    introductionTour?: Tour;
    supportEmail?: string;
    /**
     * changes the name of the purchasable package, e.g service package or subscription package
     */
    packageDisplayName: string;
    initialThreadDetails?: {
        threadType: ThreadTypes;
    };
    onCloseThread: CloseAction;
    /**
     * We hope to be able to remove these feature flags at some point.
     */
    temporaryFeatureFlags: {
        staffTitleOverride?: string;
        hideThreadTypeMyTasks?: boolean;
    };
    addParticipants: boolean;
    threadParticipantDetail: boolean;
    showAgreePoliciesLogin: boolean;

    //This will one day be pulled from a resource pack
    text: {
        signupInstruction: string;
        signupAction: string;
    };

    /**
     * Allow user to sign file only after downloading the file
     * Defaults to false
     */
    signingRequiresFileDownloadEnabled: boolean;
};

export type EnvironmentSpecificConfig = {
    appName: string;
    appTheme: string;
    appUrl: string;
    featureFlags: FeatureConfig;
    production: boolean;
    stage: string;
    sentry: string;
    analytics: {
        google: {
            trackingId?: string;
            tagManagerId?: string;
            tagManagerAuth?: string;
            tagManagerPreview?: string;
        };
    };
    theme: {
        onboardingTheme: "light" | "dark";
        onboardingCentreAligned: boolean;
        formTheme: "light" | "dark";
        formSubmitActionOutlined: boolean;
    };
    searchOptimization: {
        url: string;
    };
    threadsEndpoints: {
        base: string;
    };
    commonEndpoints: {
        base: string;
    };
    sigmaEndpoints: {
        base: string;
    };
    videoChatEndpoints: {
        base: string;
    };
    vaultEndpoints: {
        base: string;
    };
    threadsWebsockets: string;
    registration: {
        redirectUrl: string;
        invalidPackageRedirect: string;
    };
    emailVerifyUrl: string;
    auth: {
        base: string;
        userPoolId: string;
        userPoolWebClientId: string;
        protectedResources: string[];
        forgotPasswordRedirect: string;
    };
    activeDirectory: {
        clientId: string;
    };
    calendar: {
        internalUrl: string;
        externalUrl: string;
        webUrl: string;
        appId: string;
    };
    opentokApiKey: string;
    news: {
        sourceUrl: string;
        allArticlePath: string;
        fileName: string;
    };
    threadStates: {
        closed: string;
        cancelled: string;
        open: string;
        [stateName: string]: string;
    };
    policyUrl?: string;
    termsUrl?: string;
    termsOfBusinessUrl?: string;
    disclaimerUrl?: string;
    emailDisclaimerUrl?: string;
    calendarLocation: string;
    signupRedirectUrl?: string;
    errorRedirectUrl: string;
    emailTimelineUrl: string;
    searchRoles?: string[];
};

export const environmentCommon = {
    appVersion: require("./../../package.json").version,
    threadsEndpoints: {
        threads: "/threads",
        events: "/events",
        cards: "/cards",
        participants: "/participants",
        role: "/role",
        data: "/data",
        users: "/users",
        myProfile: "/users/me",
        staff: "/staff",
        tasks: "/tasks",
        state: "/state",
        reports: "/reports",
        lastLogin: "/users/:userId/lastLogin"
    },
    cardsEndpoints: {
        calendar: "calendar",
        videoChat: "videoChat",
        vault: "vault",
        message: "message"
    },
    sigmaEndpoints: {
        onboarding: "/onboarding",
        signUp: "/signup",
        subscription: "/subscription",
        mySubscription: "/subscription/me",
        dashboard: "/dashboard",
        packages: "/packages",
        validPackage: "/valid"
    },
    sameDayTaxEndpoints: {
        base: "-app/samedaytax",
        secureProfileBase: "/users",
        secureProfile: "/secure",
        fileSync: "/filesync",
        findAvailability: "/find-availability",
        findAvailableStaff: "/find-available-staff"
    },
    videoChatEndpoints: {
        generateToken: "generateToken",
        terminateSession: "terminateSession",
        createSession: "createSession"
    },
    auth: {
        userIdPrefix: "cognito",
        invitation: "/invitation-detail",
        region: "ap-southeast-2",
        endpoints: {
            verifyMobile: "/verifyMobile",
            confirmMobile: "/confirmMobile",
            confirmEmail: "/confirmEmail",
            checkUser: "/checkUser",
            myProfile: "/myProfile",
            forgotPassword: "/forgotPassword",
            forgotPasswordConfirm: "/forgotPasswordConfirm"
        }
    },
    activeDirectory: {
        userIdPrefix: "azuread",
        tenant: "b48a9eab-75aa-4a5b-9fd7-f39223245c1a"
    },
    login: {
        staff: "/staff-login"
    },
    searchRoles: {
        client: "Client",
        staff: "Staff"
    }
};

export type Environment = typeof environmentCommon & EnvironmentSpecificConfig;
