import { Component, OnInit } from "@angular/core";
import { Loader } from "src/app/services/loader";
import { ReportsService } from "../../services/reports.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MetricSummary, MetricNames } from "@findex/threads";

type MetricStat = { metricName: string; displayName: string; count: number };

export const metricNameMap = {
    [MetricNames.MeetingsBooked]: "Booked meetings",
    [MetricNames.ThreadsCancelled]: "Cancelled threads",
    [MetricNames.ThreadsClosed]: "Closed threads",
    [MetricNames.ThreadsCreated]: "Created threads"
};

@Component({
    selector: "reports-list",
    templateUrl: "./reports-list.component.html",
    styleUrls: ["./reports-list.component.scss"]
})
export class ReportsListComponent implements OnInit {
    loader = new Loader();
    reports$: Observable<MetricStat[]>;

    constructor(private reportsService: ReportsService) {}

    ngOnInit() {
        const reports$ = this.reportsService.getSummary().pipe(map(reports => this.mapToArray(reports)));

        this.reports$ = this.loader.wrap(reports$);
    }

    trackByMetricName(_index: number, stat: MetricStat): string {
        return stat.metricName;
    }

    private mapToArray(reports: MetricSummary): MetricStat[] {
        return Object.entries(reports)
            .filter(([metricName]) => metricNameMap[metricName])
            .map(([metricName, count]) => {
                const displayName = metricNameMap[metricName];
                return { metricName, displayName, count };
            });
    }
}
