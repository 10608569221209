import { Component, OnInit } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { NewsService } from "./services/news.service";
import { INewsArticle } from "./model/INewsArticle";
import { Loader } from "src/app/services/loader";
import { catchError, map } from "rxjs/operators";
import { HandledError } from "../../../../../app/services/sentry-error-handler";

@Component({
    selector: "news",
    templateUrl: "./news.component.html",
    styleUrls: ["./news.component.scss"]
})
export class NewsComponent implements OnInit {
    article$: Observable<INewsArticle[]>;
    loader = new Loader();

    constructor(private newsService: NewsService) {}

    ngOnInit() {
        this.article$ = this.loader.wrap(
            this.newsService.getRecentArticles().pipe(
                map(articles => {
                    if (articles && articles.length) {
                        return articles.slice(0, 1);
                    } else {
                        return articles;
                    }
                }),
                catchError(error => {
                    return throwError(new HandledError(error));
                })
            )
        );
    }

    goToWebsite($event: Event) {
        if ($event) {
            $event.preventDefault();
        }
        window.open(`${environment.news.sourceUrl}/${environment.news.allArticlePath}`, "_blank");
    }
}
