import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FindexHeaderComponent } from "./components/findex-header/findex-header.component";
import { LoaderIconComponent } from "./components/loader-icon/loader-icon.component";
import { FindexProfileComponent } from "./components/findex-profile/findex-profile.component";
import { FindexMainComponent } from "./components/findex-main/findex-main.component";
import { FindexSlideMenuComponent } from "./components/findex-slide-menu/findex-slide-menu.component";
import { FindexDropdownComponent } from "./components/findex-dropdown/findex-dropdown.component";
import { FxContextMenuComponent } from "./components/fx-context-menu/fx-context-menu.component";
import { SignatureIconComponent } from "./components/signature-icon/signature-icon.component";
import { RouterModule } from "@angular/router";
import { FxUiModule } from "@findex/fx-ui";
import { AnalyticsModule } from "../analytics";

@NgModule({
    declarations: [
        FindexHeaderComponent,
        FindexMainComponent,
        FindexProfileComponent,
        FindexSlideMenuComponent,
        LoaderIconComponent,
        FindexDropdownComponent,
        FxContextMenuComponent,
        SignatureIconComponent
    ],
    exports: [
        FindexHeaderComponent,
        FindexMainComponent,
        FindexProfileComponent,
        FindexSlideMenuComponent,
        LoaderIconComponent,
        FindexDropdownComponent,
        FxContextMenuComponent,
        FxUiModule,
        SignatureIconComponent,
        AnalyticsModule
    ],
    imports: [CommonModule, RouterModule, FxUiModule, AnalyticsModule],
    providers: []
})
export class FindexUiModule {}
