import { Component, EventEmitter, Input, Output } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-industry-type",
    templateUrl: "./industry-type.component.html",
    styleUrls: ["./industry-type.component.scss"]
})
export class IndustryTypeComponent {
    @Input() selectedIndustryTypes: string[] = [];
    @Output() modelValidated: EventEmitter<boolean> = new EventEmitter();
    @Output() modelUpdated: EventEmitter<any> = new EventEmitter();
    @Output() goBack: EventEmitter<any> = new EventEmitter();
    @Output() progressState: EventEmitter<any> = new EventEmitter();

    isValid: boolean;
    filter: string = "";
    readonly appName = environment.appName;
    readonly theme = environment.theme;

    steps = ["Business name", "Industry type", "Meet your bookkeeper"];
    activeStep = "Industry type";

    constructor() {}

    updateParentModel(industryTypes: string[]) {
        this.modelUpdated.emit({
            industryTypes
        });
    }

    updateParentValidation(isValid: boolean) {
        this.isValid = isValid;
        this.modelValidated.emit(isValid);
    }
}
