import { startCase } from "lodash";
import { DateTime } from "luxon";
import { Component, OnInit } from "@angular/core";
import { ThreadsService } from "src/app/services/threads.service";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { Observable, zip } from "rxjs";
import { AuthService, User } from "src/modules/findex-auth";
import { IThread, Role } from "@findex/threads";
import { SigmaService } from "src/app/services/sigma.service";
import { Loader } from "src/app/services/loader";
import { environment } from "../../../environments/environment";
import { OnboardingService } from "src/modules/onboarding/services/onboarding.service";
import { Router } from "@angular/router";

const getGreetingTime = (dateTime: DateTime) => {
    if (!dateTime || !dateTime.isValid) {
        return "Hello";
    }

    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening
    const currentHour = parseFloat(dateTime.toFormat("HH"));

    if (isNaN(currentHour)) {
        return "Hello";
    }

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
        // Between 12 PM and 5PM
        return "Good afternoon";
    } else if (currentHour >= splitEvening) {
        // Between 5PM and Midnight
        return "Good evening";
    }
    // Between dawn and noon
    return "Morning";
};

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
    greeting$: Observable<string>;
    thread$: Observable<IThread>;
    role$: Observable<Role>;
    userId$: Observable<string>;
    loader = new Loader();

    readonly signupEnabled = environment.featureFlags.signupEnabled;

    constructor(
        private authService: AuthService,
        private sigmaService: SigmaService,
        private threadsService: ThreadsService,
        private onboardingService: OnboardingService,
        private router: Router
    ) {}

    ngOnInit() {
        this.userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id),
            shareReplay(1)
        );

        if (this.signupEnabled) {
            this.checkUserBookkeeperStatus();
        }
        const threadId$ = this.sigmaService.getMyDashboard().pipe(
            filter(threadId => !!threadId),
            shareReplay(1)
        );

        const thread$ = threadId$.pipe(switchMap(threadId => this.threadsService.getThread(threadId)));

        const role$ = zip(threadId$, this.userId$).pipe(
            switchMap(([threadId, userId]) => this.threadsService.getRole(threadId, userId))
        );

        this.thread$ = this.loader.wrap(thread$);
        this.role$ = this.loader.wrap(role$);
        this.greeting$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => this.userToGreeting(user))
        );
    }

    private userToGreeting(user: User): string {
        const firstName = user.name.includes(" ") ? user.name.split(" ")[0] : user.name;
        const titleCaseName = startCase(firstName);
        const now = DateTime.local();
        return `${getGreetingTime(now)} ${titleCaseName},`;
    }

    async checkUserBookkeeperStatus() {
        const userId = await this.userId$.pipe(take(1)).toPromise();
        const role = await this.threadsService.getGlobalRole(userId).toPromise();

        if (role === Role.Client || !role) {
            const onboardingDetails = await this.onboardingService.getCompletionDetails(userId).toPromise();
            if (onboardingDetails && !onboardingDetails.selectedStaff) {
                switch (environment.featureFlags.onboardingType) {
                    case "sigma":
                        this.router.navigateByUrl("/select-bookkeeper");
                        break;
                    case "sameday":
                        this.router.navigateByUrl("/onboarding-profile");
                        break;
                }
            }
        }
    }
}
