<loader-icon *ngIf="showLoader" [color]="'light'"></loader-icon>

<ng-container [ngSwitch]="status">
    <app-signup
        *ngSwitchCase="ALL_STATUSES.SIGN_UP"
        [userDataModel]="userDataModel"
        [errorMessage]="errorMessage"
        (progress)="progressState($event)"
    ></app-signup>
    <app-client-profile
        *ngSwitchCase="ALL_STATUSES.COMPLETE_PROFILE"
        [userDataModel]="userDataModel"
        (progress)="progressState($event)"
        [errorMessage]="errorMessage"
    ></app-client-profile>
    <app-verify-email-prompt
        *ngSwitchCase="ALL_STATUSES.VERIFY_EMAIL"
        [emailAddress]="userDataModel.emailAddress"
        [verifyEmailMessage]="verifyEmailMessage"
        (resend)="resendEmail()"
    ></app-verify-email-prompt>
</ng-container>
