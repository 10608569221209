<div class="cancel-thread-reason">
    <h1>Cancelled timeline</h1>
    <div *ngIf="latestNote">
        <div class="cancel-thread-reason-item">
            <div class="cancel-thread-reason-item-row">
                <div class="cancel-thread-reason-item-row-title">
                    Client:
                </div>
                <div>
                    {{ data.clientName }}
                </div>
            </div>
            <div class="cancel-thread-reason-item-row">
                <div class="cancel-thread-reason-item-row-title">
                    When:
                </div>
                <div>
                    {{ latestNote.createdAt | date: "medium" }}
                </div>
            </div>
            <div class="cancel-thread-reason-item-row">
                <div class="cancel-thread-reason-item-row-title">
                    Reason:
                </div>
                <div>
                    {{ cancelReasons[latestNote.message] || latestNote.message }}
                </div>
            </div>
        </div>
    </div>
    <button
        type="button"
        (click)="close()"
        class="fx-btn--full-width"
        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
    >
        Done
    </button>
</div>
