import { Component, Input, HostListener, ElementRef, HostBinding } from "@angular/core";

@Component({
    selector: "fx-context-menu",
    templateUrl: "./fx-context-menu.component.html",
    styleUrls: ["./fx-context-menu.component.scss"]
})
export class FxContextMenuComponent {
    @Input() toggleMenu: boolean;
    @Input() light?: boolean;

    @HostListener("document:click", ["$event"])
    click(event: MouseEvent) {
        if (!this.el.nativeElement.contains(event.target)) {
            this.toggleMenu = false;
            this.toggleMenuActive = false;
        }
    }

    @HostBinding("class.upload-context-menu--light") lightMenuClass: boolean = false;
    @HostBinding("class.active") toggleMenuActive: boolean = false;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.lightMenuClass = this.light;
    }
    toggleMenuState() {
        this.toggleMenu = !this.toggleMenu;
        this.toggleMenuActive = this.toggleMenu;
    }
}
