import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IThread, IThreadListing, Role, SubjectType } from "@findex/threads";
import { empty, Observable, of, Subject, zip } from "rxjs";
import { expand, filter, flatMap, map, reduce, shareReplay, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { AnalyticsService } from "src/modules/analytics";
import { SigmaService } from "src/app/services/sigma.service";
import { ThreadStateService } from "src/modules/threads-ui/services/thread-state.service";
import { ThreadsService } from "src/app/services/threads.service";
import { WebsocketService } from "src/app/services/websocket.service";
import { environment } from "src/environments/environment";
import { AuthService } from "src/modules/findex-auth";
import { ThreadGrouper } from "src/modules/threads-ui/components/thread-list/thread-grouper";
import { HIDE_LIST_SIZE } from "src/modules/threads-ui/components/thread-list/thread-list.component";
import { Loader } from "../../services/loader";

const { threadStates } = environment;

const ANALYTICS_CATEGORY = "threads";

@Component({
    selector: "threads-list",
    templateUrl: "./threads-list-route.component.html",
    styleUrls: ["./threads-list-route.component.scss"]
})
export class ThreadsListRouteComponent implements OnInit, OnDestroy {
    role$: Observable<Role>;
    roles = Role;

    loader = new Loader();

    timelineThreads$: Observable<IThreadListing[]>;

    private unbindPreviews = new Subject();

    constructor(
        private sigmaService: SigmaService,
        private threadsService: ThreadsService,
        private router: Router,
        private authService: AuthService,
        private websocketService: WebsocketService,
        private analyticsService: AnalyticsService,
        private threadStateService: ThreadStateService,
        public route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.role$ = this.authService.getUser().pipe(
            map(user => (user ? user.id : undefined)),
            switchMap(participantId => this.threadsService.getGlobalRole(participantId))
        );

        this.timelineThreads$ = this.getTimelineThreads().pipe(
            tap(threads => this.routeAndBind(threads)),
            shareReplay(1)
        );
    }

    selectThread(listing: IThreadListing) {
        this.router.navigate(["/timelines", listing.id]);
    }

    private getTimelineThreads(): Observable<IThread[]> {
        const expandRecursively = (threads: IThreadListing[]) => {
            return zip(...threads.map(thread => this.expandThreads(thread.id))).pipe(
                flatMap(groups => {
                    return groups.map((threadArr, groupIndex) => {
                        return threadArr.map(thread => {
                            return {
                                ...thread,
                                parentId: threads[groupIndex].id,
                                parentTitle: threads[groupIndex].title
                            };
                        });
                    });
                })
            );
        };
        return this.sigmaService.getMyDashboard().pipe(
            flatMap(threadId => this.expandThreads(threadId)),
            expand(threads => (threads.length ? expandRecursively(threads) : empty())),
            reduce((allThreads, nextThreads) => [].concat(allThreads, nextThreads))
        );
    }

    private expandThreads(threadId: string): Observable<IThread[]> {
        if (!threadId) return of([]);
        return of(threadId).pipe(
            filter(threadId => !!threadId),
            switchMap(threadId => this.threadsService.getCards(threadId)),
            map(cards =>
                cards
                    .filter(card => card.type === SubjectType.Thread)
                    .slice(0, 1)
                    .pop()
            ),
            filter(dashboard => !!dashboard),
            map(dashboard => dashboard.subjects.filter(subject => subject.type === SubjectType.Thread)),
            map(subjects => subjects.map(subject => subject.id)),
            switchMap(ids => (ids.length ? zip(...ids.map(id => this.threadsService.getThread(id))) : of([])))
        );
    }

    private async routeAndBind(listings: IThreadListing[]) {
        const dashboardId = await this.sigmaService.getMyDashboard().toPromise();
        const threads = listings.filter(({ type }) => type !== "container");
        this.routeToFirst(threads);
        this.bindWebSocketSubscriptions(threads, dashboardId);
    }

    private routeToFirst(listings: IThreadListing[]) {
        //Do not route if we are already on a thread
        if (this.route.firstChild && this.route.firstChild.snapshot.params.threadId) {
            return;
        }

        //Do not auto route on mobile
        if (window.innerWidth < HIDE_LIST_SIZE) {
            return;
        }

        if (listings && listings.length) {
            const sorted = listings.sort((a, b) => ThreadGrouper.orderThreads(a, b));
            this.selectThread(sorted[0]);
        }
    }

    private bindWebSocketSubscriptions(threads: IThreadListing[], dashboardId: string) {
        this.unbindPreviews.next(null);

        this.websocketService
            .connect(dashboardId)
            .pipe(
                filter(notification => !!(notification.threadId && notification.cardId)),
                switchMap(notification => this.threadsService.getThread(notification.threadId)),
                takeUntil(this.unbindPreviews)
            )
            .subscribe(() => {
                this.timelineThreads$ = this.getTimelineThreads();
            });

        for (const thread of threads) {
            this.websocketService
                .connect(thread.id)
                .pipe(
                    switchMap(notification => this.threadsService.getThread(notification.threadId)),
                    takeUntil(this.unbindPreviews)
                )
                .subscribe(updatedThread => {
                    thread.preview = updatedThread.preview;
                    thread.state = updatedThread.state;
                });
        }
    }

    async changeTimelineState(event: { node: IThreadListing; newState: string }) {
        const doUpdate = await this.shouldUpdate(event.node, event.newState).toPromise();

        if (doUpdate) {
            const params = { state: event.newState };
            const updateState$ = this.threadsService.updateThread(event.node.id, params);
            await this.loader.wrap(updateState$).toPromise();

            this.analyticsService.recordEvent(ANALYTICS_CATEGORY, event.newState);
        }
    }

    ngOnDestroy() {
        this.unbindPreviews.next(null);
        this.unbindPreviews.complete();
    }

    private shouldUpdate(thread: IThreadListing, newState: string): Observable<boolean> {
        if (newState === threadStates.closed) {
            return this.role$.pipe(
                take(1),
                switchMap(role => this.threadStateService.closeThread(thread, role))
            );
        } else if (newState === threadStates.cancelled) {
            return this.role$.pipe(
                take(1),
                switchMap(role => this.threadStateService.cancelThread(thread, role))
            );
        }

        return of(true);
    }
}
