<div class="my-tasks-item" *ngIf="entry">
    <div class="my-tasks-item-header" [routerLink]="['/timelines', entry.threadId]">
        <div class="my-tasks-item-name">
            {{ entry.threadName }}
        </div>
        <div class="my-tasks-item-type" *ngIf="!hideThreadType">
            {{ entry.threadType }}
        </div>
        <div class="my-tasks-item-summary">
            {{ entry.message | slice: 0:60 }}
        </div>
    </div>

    <div
        *ngFor="let action of entry.actions"
        [routerLink]="['/timelines', entry.threadId, 'cards', action.cardId]"
        analyticsClick="app_mytask"
        [analyticsLabel]="action.actionLabel"
        class="my-tasks-item-action"
        [ngClass]="{ 'my-tasks-item-action--complete': false }"
    >
        {{ action.actionLabel || action.actionId }}
    </div>
</div>
