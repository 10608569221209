import { environmentCommon as common, EnvironmentSpecificConfig } from "./environment.common";
import { SIGMA_FEATURE_CONFIG } from "./sigma-feature-flags";

const threadsUrl = "https://threads-backend.sigma.findex.dev/dev";
const vaultUrl = "https://vault-backend.sigma.findex.dev/qa";
const authUrl = "https://api.auth.findex.dev/qa";
const calendarInternal = "https://internal.qa.calendar.sigma.findex.dev";
const calendarExternal = "https://external.qa.calendar.sigma.findex.dev";
const calendarWeb = "https://calendar.qa.findex.dev";

export const environmentCommon = common;
export const environment: EnvironmentSpecificConfig = {
    featureFlags: SIGMA_FEATURE_CONFIG,
    production: false,
    appName: "Sigma",
    appTheme: "sigma",
    appUrl: "http://localhost:4201",
    stage: "dev",
    sentry: "https://eb04b0df4eaf416285a146fe00a83107@sentry.findex.solutions/12",
    analytics: {
        google: {
            trackingId: "UA-164132697-1"
        }
    },
    theme: {
        onboardingTheme: "light",
        onboardingCentreAligned: false,
        formTheme: "dark",
        formSubmitActionOutlined: true
    },
    searchOptimization: {
        url: ""
    },
    threadsEndpoints: {
        base: threadsUrl
    },
    commonEndpoints: {
        base: threadsUrl + "/common"
    },
    sigmaEndpoints: {
        base: threadsUrl + "-app/sigma"
    },
    videoChatEndpoints: {
        base: threadsUrl + "/video"
    },
    vaultEndpoints: {
        base: vaultUrl
    },
    threadsWebsockets: "wss://threads-ws.sigma.findex.dev/dev",
    registration: {
        redirectUrl: "http://localhost:4201/register/email-success",
        invalidPackageRedirect: "https://sigma-website.qa.findex.solutions/pricing?invalid-package=true"
    },
    emailVerifyUrl: "http://localhost:4201/profile/verify-email",
    auth: {
        base: authUrl,
        userPoolId: "ap-southeast-2_EzRUAN9ED",
        userPoolWebClientId: "7ilsaqrcsethoshpvu1iaqqnjr",
        protectedResources: [threadsUrl, vaultUrl, authUrl, calendarInternal, calendarExternal],
        forgotPasswordRedirect: "http://localhost:4201/reset-password"
    },
    activeDirectory: {
        clientId: "8a6c1119-d3cc-4252-95ca-9da3689f3f9c"
    },
    calendar: {
        internalUrl: calendarInternal,
        externalUrl: calendarExternal,
        webUrl: calendarWeb,
        appId: "sigma"
    },
    opentokApiKey: "46424152",
    news: {
        sourceUrl: "https://sigma-website.qa.findex.solutions",
        allArticlePath: "news",
        fileName: "articles.json"
    },
    policyUrl: "https://sigma-website.qa.findex.solutions/privacy-policy",
    termsUrl: "https://sigma-website.qa.findex.solutions/terms-of-service",
    calendarLocation: "Sigma VC",
    errorRedirectUrl: "http://localhost:4201/error",
    emailTimelineUrl: "http://localhost:4201/timelines",
    threadStates: {
        closed: "Complete",
        cancelled: "Cancelled",
        open: "Send us your information" //TODO we should make these IDs instead of the labels
    }
};
