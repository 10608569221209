import { Location } from "@angular/common";
import { Component, Inject, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { IThread, IThreadCard, Role } from "@findex/threads";
import { VaultService, VAULT_ACTION } from "@findex/vault";
import { Observable, Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";
import { CardResources, THREAD_CARD_RESOURCES } from "src/app/interfaces/IUiCard";
import { Loader } from "src/app/services/loader";
import { ThreadsService } from "src/app/services/threads.service";
import { environment } from "src/environments/environment";
import { AuthService } from "src/modules/findex-auth";
import { IVaultItem } from "../../interfaces/IVaultItem";
import { IVaultState } from "../../interfaces/IVaultState";
import { SigningModalComponent } from "../signing-modal/signing-modal.component";
import { UploadComponent } from "../upload/upload.component";

@Component({
    selector: "vault-card",
    templateUrl: "./vault-card.component.html",
    styleUrls: ["./vault-card.component.scss"]
})
export class VaultCardComponent implements OnDestroy {
    readonly threadStates = environment.threadStates;

    private stateSub: Subscription;
    private navigateSub: Subscription;
    private rfiCount = 0;
    private shareCount = 0;

    roles = Role;
    role: Role;
    loader = new Loader();
    card: IThreadCard;
    state: IVaultState;
    userId: Observable<string>;
    state$: Observable<IVaultState>;

    totalFiles = 0;
    downloadable = 0;
    thread: IThread;
    expand: boolean;
    isRfi: boolean;
    rebuilt: boolean;
    documentsUnsigned: number;
    closed: boolean;

    private dialogRef: MatDialogRef<SigningModalComponent>;
    selectedItem: IVaultItem;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) private cardResources: CardResources<IVaultState>,
        private dialog: MatDialog,
        private vaultService: VaultService,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private threadsService: ThreadsService,
        private router: Router,
        authService: AuthService
    ) {
        const { thread, card, state, role } = cardResources;

        this.role = role;
        this.userId = authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );
        this.thread = thread;
        this.card = card;
        this.state$ = state.pipe(filter(state => !!(state && state.groups)));
        this.stateSub = state.subscribe(state => this.updateState(state));
    }

    ngOnDestroy() {
        if (this.stateSub) {
            this.stateSub.unsubscribe();
        }

        if (this.navigateSub) {
            this.navigateSub.unsubscribe();
        }
    }

    openUploadModal() {
        const data = {
            state$: this.state$,
            thread: this.thread,
            role: this.role
        };

        this.dialog
            .open<any, any>(UploadComponent, {
                data,
                position: { top: "0px" },
                height: "100vh",
                maxWidth: "100vw",
                panelClass: ["mat-dialog-no-styling", "threads-sidebar"]
            })
            .afterClosed()
            .subscribe(() => {
                this.location.replaceState(`/timelines/${this.thread.id}`);
            });
    }

    async download(item: IVaultItem) {
        const filename = item.files[0].filename;
        const { vaultId, fileId } = item;
        const downloadWindow = window.open("", "_self");
        downloadWindow.location.href = await this.vaultService.getDownloadUrl(vaultId, fileId, filename).toPromise();
    }

    async downloadItem(item: IVaultItem) {
        if (!item.files.length) return;
        this.download(item);
    }

    signItem(item: IVaultItem) {
        this.selectedItem = item;
        this.dialogRef = this.dialog.open(SigningModalComponent, {
            data: item,
            position: { top: "0px" },
            height: "100vh",
            maxWidth: "100vw",
            panelClass: ["mat-dialog-no-styling", "threads-sidebar"]
        });

        this.dialogRef.afterClosed().subscribe(async result => {
            if (!result) return;

            this.loader.show();
            try {
                await this.vaultService.sign(item.vaultId, item.fileId, result).toPromise();
            } finally {
                this.loader.hide();
            }
        });
    }

    private navigateToCard() {
        if (this.isRfi) {
            this.openUploadModal();
        }
    }

    private updateState(state: IVaultState) {
        const { cardId } = this.activatedRoute.snapshot.params;
        const { vaultId, fileId } = this.activatedRoute.snapshot.queryParams;
        if (state) {
            this.totalFiles = 0;
            this.rfiCount = 0;
            this.shareCount = 0;
            this.downloadable = 0;
            this.closed = state.closed;
            for (const group of state.groups) {
                for (const item of group.items) {
                    this.totalFiles++;

                    if (item.actions.includes(VAULT_ACTION.Download) || item.actions.includes(VAULT_ACTION.Sign)) {
                        this.downloadable = this.downloadable + item.files.length;
                    }

                    if (item.actions.includes(VAULT_ACTION.Rfi)) {
                        this.rfiCount++;
                    } else {
                        this.shareCount++;
                    }

                    if (this.card.id === cardId && vaultId === item.vaultId && fileId === item.fileId) {
                        this.expand = true;
                        this.signItem(item);
                        this.router.navigateByUrl(`/timelines/${this.thread.id}`);
                    }

                    if (this.selectedItem && this.dialogRef && this.dialogRef.componentInstance) {
                        if (item.fileId === this.selectedItem.fileId && item.vaultId === this.selectedItem.vaultId) {
                            this.dialogRef.componentInstance.vaultItem = item;
                        }
                    }
                }
            }

            this.isRfi = this.rfiCount > 0 && this.shareCount === 0;
            this.state = state;

            if (!this.isRfi && state.groups[0]) {
                this.documentsUnsigned = state.groups[0].items.filter(
                    x => !x.signed && x.actions.includes("sign")
                ).length;
            }

            const inconsistentRfi = this.rfiCount > 0 && this.shareCount;
            const inconsistentShare = !this.rfiCount && !this.downloadable;
            if ((inconsistentRfi || inconsistentShare) && !this.rebuilt) {
                //TODO: remove once race condition sorted
                this.rebuilt = true;
                this.threadsService.rebuildCardState(this.thread.id, this.card.id).toPromise();
            }
        }

        if (!this.navigateSub) {
            this.navigateSub = this.cardResources.navigateTo.subscribe(() => this.navigateToCard());
        }
    }
}
