import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { Subscription, combineLatest } from "rxjs";

@Component({
    selector: "login-page",
    templateUrl: "./login-page.component.html",
    styleUrls: ["./login-page.component.scss"]
})
export class LoginPageComponent implements OnInit, OnDestroy {
    private loginSubscription: Subscription;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private authService: AuthService) {}

    ngOnInit() {
        this.loginSubscription = combineLatest(this.authService.getUser(), this.activatedRoute.queryParams).subscribe(
            ([user, params]) => {
                if (user) {
                    const redirectUrl = params["redirect"] || "/dashboard";
                    this.router.navigate([redirectUrl]);
                }
            }
        );
    }

    ngOnDestroy() {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
    }
}
