<modal-layout (close)="close()" [header]="title" [footer]="false">
    <div modal-body>
        <ng-container *ngFor="let activityDate of activityDates">
            <div class="activity-modal-time">
                <span class="activity-modal-time-line">&nbsp;</span>
                {{ activityDate.date | date: "EEEE dd MMMM yyyy" }}<span class="activity-modal-time-line">&nbsp;</span>
            </div>

            <div
                class="activity-modal-item"
                *ngFor="let activity of activityDate.activities"
                (click)="navigateToThread(activity.event)"
            >
                <fx-card
                    [name]="activity.event.actor | userToName | async"
                    [timestamp]="activity.event.createdAt"
                    [description]="(activity.event.actor | userToName | async) + ' ' + activity.description"
                    [conversation]="false"
                >
                </fx-card>
            </div>
        </ng-container>
        <p *ngIf="activityDates && !activityDates.length">You have no recent activity</p>
    </div>
</modal-layout>
