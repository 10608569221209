import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, mapTo } from "rxjs/operators";
import { environment, environmentCommon } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { SigmaService } from "src/app/services/sigma.service";
@Injectable({ providedIn: "root" })
export class VideoChatService {
    constructor(private http: HttpClient, private cardService: SigmaService) {}

    createChat(threadId: string): Observable<null> {
        return this.cardService.createVideoChatCard(threadId).pipe(mapTo(null));
    }

    async getVideoChatToken(sessionId: string): Promise<string> {
        const url = `${environment.videoChatEndpoints.base}/${environmentCommon.videoChatEndpoints.generateToken}`;
        return this.http
            .post<{ token: string }>(url, { sessionId })
            .pipe(map(result => result.token))
            .toPromise();
    }

    async terminateSession(sessionId: string, threadId: string, cardId: string): Promise<void> {
        const url = `${environment.videoChatEndpoints.base}/${environmentCommon.videoChatEndpoints.terminateSession}`;
        return this.http
            .post<{ sessionId: string }>(url, { sessionId, threadId, cardId })
            .pipe(map(() => {}))
            .toPromise();
    }
}
