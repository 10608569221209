import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../findex-auth";
import { ReportsListComponent } from "./components/reports-list/reports-list.component";
import { ReportComponent } from "./components/report/report.component";

const routes: Routes = [
    { path: ":metricName", component: ReportComponent, canActivate: [AuthGuard] },
    { path: "", component: ReportsListComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ReportsRoutingModule {}
