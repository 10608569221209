import { INewsArticle } from "./INewsArticle";
import { INewsArticleMapper } from "./news-article-mapper";
import { environment } from "src/environments/environment";

export class SigmaNewsArticle implements INewsArticleMapper {
    map(response: any): INewsArticle[] {
        return response.map(({ node }) => {
            return {
                id: node.id,
                title: node.data.title.text,
                date: node.first_publication_date,
                author: node.data.authors[0].author.text,
                body: node.data.body.text,
                url: `${environment.news.sourceUrl}${node.url}`,
                bgUrl: node.data.background_image.url
            };
        });
    }
}
