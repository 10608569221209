import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CardState, CardSubject } from "../../types/card-state";
import { ThreadsService } from "../../../../../app/services/threads.service";
import { Role } from "@findex/threads";
import { environment } from "src/environments/environment";

export interface MyTasksAction {
    cardId: string;
    actionId: string;
    actionLabel: string;
}

export interface MyTasksEntry {
    threadId: string;
    threadState: string;
    threadName: string;
    threadType: string;
    message: string;
    actions: MyTasksAction[];
}

type StateActions = {
    availableActions?: { type: string; participantRoles: Role[] }[];
};

interface DashboardCardState extends CardState {
    availableActions?: { type: string; participantRoles: Role[] }[];
    statelessActions: { [cardId: string]: { type: string; participantRoles: Role[] } };
}

type ThreadGroups = { [id: string]: CardSubject[] };

const CARDS_WITH_ACTIONS = ["calendar", "video-chat", "vault", "message", "complete-profile"];
const ACTION_LABELS = {
    scheduleMeeting: "Book a meeting",
    startMeeting: "Start meeting",
    SignFiles: "Sign documents",
    UploadFiles: "Provide documents",
    join: "Join call",
    introduction: "Send a message",
    "complete-profile": "Complete your profile"
};

@Component({
    selector: "my-tasks",
    templateUrl: "./my-tasks.component.html",
    styleUrls: ["./my-tasks.component.scss"]
})
export class MyTasksComponent implements OnChanges {
    @Input() state: DashboardCardState;
    @Input() role: Role;

    readonly threadStates = environment.threadStates;

    constructor(private threadsService: ThreadsService) {}

    loading: boolean;
    entries: MyTasksEntry[];

    ngOnChanges(changes: SimpleChanges) {
        const { state } = changes;

        if (state && state.currentValue) {
            this.initTasks(state.currentValue);
        }
    }

    private async initTasks(state: DashboardCardState) {
        this.loading = true;

        const subjectByThread = state.cardSubjects
            .filter(subject => this.cardCanHaveAction(state.cardTypes[subject.cardId]))
            .reduce<ThreadGroups>((threads, subject) => this.groupByThread(threads, subject), {});

        const entries$ = Object.entries(subjectByThread).map(([threadId, subjects]) =>
            this.getThreadActions(threadId, subjects, state)
        );

        const entries = await Promise.all(entries$);
        this.entries = entries.filter(entry => !!entry);

        this.loading = false;
    }

    private async getThreadActions(
        threadId: string,
        subjects: CardSubject[],
        state: DashboardCardState
    ): Promise<MyTasksEntry> {
        const actions$ = subjects
            .reduce((cards, subject) => this.uniqueCards(cards, subject), [])
            .map(({ threadId, cardId }) => this.getActions(threadId, cardId, state));

        const allActions = await Promise.all(actions$);
        const actions = [].concat(...allActions);

        if (!actions.length) return null;

        const {
            type: threadType,
            title: threadName,
            preview,
            state: threadState
        } = await this.threadsService.getThread(threadId).toPromise();

        const message = preview && preview.summary ? preview.summary : "";
        return { threadId, threadName, threadType, message, actions, threadState };
    }

    private cardCanHaveAction(type: string): boolean {
        return CARDS_WITH_ACTIONS.includes(type);
    }

    private groupByThread(threads: ThreadGroups, subject: CardSubject): ThreadGroups {
        threads[subject.threadId] = threads[subject.threadId] || [];
        threads[subject.threadId].push(subject);
        return threads;
    }

    private uniqueCards(cards: CardSubject[], subject: CardSubject): CardSubject[] {
        const shouldAddCard = !cards.some(currentSubject =>
            this.matchSubject(currentSubject.threadId, currentSubject.cardId, subject)
        );
        return shouldAddCard ? [...cards, subject] : cards;
    }

    private matchSubject(threadId: string, cardId: string, subject: CardSubject): boolean {
        return subject.threadId === threadId && subject.cardId === cardId;
    }

    private async getActions(threadId: string, cardId: string, state: DashboardCardState): Promise<MyTasksAction[]> {
        if (state.statelessActions && state.statelessActions[cardId]) {
            const cardAction = state.statelessActions[cardId];
            if (!cardAction.participantRoles.includes(this.role)) {
                return [];
            }
            return [
                {
                    cardId: cardId,
                    actionId: cardAction.type,
                    actionLabel: ACTION_LABELS[cardAction.type]
                }
            ];
        }
        const cardState = await this.threadsService.getCardState(threadId, cardId).toPromise();
        return this.mapStateToActions(cardId, cardState);
    }

    private mapStateToActions(cardId: string, state: StateActions): MyTasksAction[] {
        if (!state || !state.availableActions) return [];

        return state.availableActions
            .filter(action => action.participantRoles.includes(this.role))
            .map(action => {
                return {
                    cardId: cardId,
                    actionId: action.type,
                    actionLabel: ACTION_LABELS[action.type]
                };
            })
            .filter(action => action.actionLabel);
    }
}
