import { Injectable } from "@angular/core";
import { Observable, of, ReplaySubject, throwError, BehaviorSubject, defer } from "rxjs";
import { environment, environmentCommon } from "src/environments/environment";
import { IAuthenticationStrategy } from "../../model/IAuthenticationStrategy";
import { User } from "../../model/User";
import { map, tap, catchError } from "rxjs/operators";
import { AuthorizationLevel } from "../../model/AuthorizationLevel";
import { LoginStep, LoginStepDetails } from "../../model/LoginStep";
import {
    AuthenticationParameters,
    AuthResponse,
    ServerHashParamKeys,
    AuthError,
    Configuration,
    Account,
    InteractionRequiredAuthError
} from "msal";
import { MsalService, BroadcastService } from "@azure/msal-angular";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
const postLogoutRedirectUri = `${environment.appUrl}${environmentCommon.login.staff}`;
export const msalConfig: Configuration = {
    auth: {
        clientId: environment.activeDirectory.clientId,
        authority: `https://login.microsoftonline.com/${environmentCommon.activeDirectory.tenant}`,
        validateAuthority: false,
        redirectUri: `${environment.appUrl}`,
        postLogoutRedirectUri,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE
    }
};

@Injectable({ providedIn: "root" })
export class AzureAdAuthStrategy implements IAuthenticationStrategy {
    private user$ = new ReplaySubject<User>(1);
    private login$ = new BehaviorSubject<LoginStepDetails>({ step: undefined });

    readonly scopes = [`${environment.activeDirectory.clientId}`];
    readonly authParameters: AuthenticationParameters = { scopes: this.scopes };

    constructor(private msalService: MsalService, private broadcastService: BroadcastService) {
        this.broadcastService.subscribe("msal:loginSuccess", () => {
            this.user$.next(this.mapAccountToUser(this.msalService.getAccount()));
            this.login$.next({ step: LoginStep.LOGIN_COMPLETE });
        });
    }

    getLogin() {
        return this.login$.asObservable();
    }

    startLogin(): Observable<LoginStepDetails> {
        this.loginIfNotInProgress();

        return this.login$.asObservable();
    }

    answerChallenge(): Observable<any | null> {
        return throwError("Not supported by this authentication strategy");
    }

    logout(): Observable<void> {
        return of(null).pipe(
            tap(() => {
                if (this.hasStaffLogin()) {
                    // need to pass this to logout automatically without account selection prompt
                    // alternatively can provide domain_hint
                    const account = this.msalService.getAccount();
                    this.msalService.logout(account.userName);
                }
            })
        );
    }

    getUser(): Observable<User> {
        const user = this.msalService.getAccount();
        return of(this.mapAccountToUser(user));
    }

    handleRedirect(): Promise<AuthResponse> {
        return new Promise((resolve, reject) => {
            return this.msalService.handleRedirectCallback((authError, authResponse) => {
                if (authError) {
                    this.loginIfNotInProgress();
                    reject(authError);
                }

                resolve(authResponse);
            });
        });
    }

    getHttpHeaders(): Observable<any> {
        return this.acquireToken().pipe(
            map(token => (token ? { Authorization: `Bearer ${token}`, awsaccesstoken: "none" } : {}))
        );
    }

    private hasStaffLogin() {
        const hasLogin = this.msalService.getAccount();
        return !!hasLogin;
    }

    private acquireToken() {
        let token: string;
        return defer(() =>
            this.msalService.acquireTokenSilent(this.authParameters).then((response: AuthResponse) => {
                token =
                    response.tokenType === ServerHashParamKeys.ID_TOKEN
                        ? response.idToken.rawIdToken
                        : response.accessToken;
                return token;
            })
        ).pipe(
            catchError(err => {
                if (
                    err instanceof AuthError &&
                    InteractionRequiredAuthError.isInteractionRequiredError(err.errorCode)
                ) {
                    this.loginIfNotInProgress();
                }
                // silent any other errors
                return of({});
            })
        );
    }

    private loginIfNotInProgress() {
        if (!this.msalService.getLoginInProgress()) {
            this.msalService.loginRedirect(this.authParameters);
        }
    }

    private mapAccountToUser(userInfo: Account): User {
        if (!userInfo) {
            return null;
        }
        const rawUserId = userInfo.accountIdentifier;
        const prefixedUserId = `${environmentCommon.activeDirectory.userIdPrefix}-${rawUserId}`;
        return {
            authorizationLevel: AuthorizationLevel.VERIFIED,
            details: {},
            emailAddressVerified: true,
            id: prefixedUserId,
            mobileNumberVerified: true,
            name: userInfo.name,
            type: "azuread"
        };
    }
}
