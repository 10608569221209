import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { OnboardingInitialComponent } from "./components/onboarding-initial/onboarding-initial.component";
import { VerifyEmailSuccessComponent } from "./components/verify-email-success/verify-email-success.component";
import { VerifyMobileComponent } from "./components/verify-mobile/verify-mobile.component";
import { NoAuthGuard } from "../findex-auth/services/no-auth.guard";
import { AuthGuard } from "../findex-auth";
import { OnboardingCompletionComponent } from "./components/onboarding-completion/onboarding-completion.component";

export const OnboardingTrial = {
    path: "signup",
    header: "SignUp"
};

export const OnboardingVerifyEmailSuccess = {
    path: "email-success",
    header: "Verified"
};

export const OnboardingVerifyMobile = {
    path: "verify-mobile",
    header: "Verified"
};

export const OnboardingVerifyMobileSuccess = {
    path: "mobile-success",
    header: "Verified"
};

export const CompleteProfile = {
    path: "completion",
    header: "Complete profile"
};

const routes: Routes = [
    //User should not be logged in for these screens
    { path: OnboardingTrial.path, component: OnboardingInitialComponent, canActivate: [NoAuthGuard] },
    { path: OnboardingVerifyEmailSuccess.path, component: VerifyEmailSuccessComponent, canActivate: [NoAuthGuard] },
    //User should be logged in for these screens
    { path: OnboardingVerifyMobile.path, component: VerifyMobileComponent, canActivate: [AuthGuard] },
    { path: OnboardingVerifyMobileSuccess.path, component: VerifyMobileComponent, canActivate: [AuthGuard] },
    { path: CompleteProfile.path, component: OnboardingCompletionComponent, canActivate: [AuthGuard] },
    { path: "", redirectTo: OnboardingTrial.path, pathMatch: "full" }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OnboardingRoutingModule {}
