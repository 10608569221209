import { Component, Inject, NgZone } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IData } from "../error.component";

@Component({
    selector: "app-error-modal",
    templateUrl: "./error-modal.component.html",
    styleUrls: ["./error-modal.component.scss"]
})
export class ErrorModalComponent {
    constructor(
        private dialogRef: MatDialogRef<ErrorModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IData,
        private ngZone: NgZone
    ) {}

    close() {
        // ErrorHandler is run outside of zone
        this.ngZone.run(() => {
            this.dialogRef.close();
        });
    }
}
