<div class="fx-l-modal-header-icon" (click)="close()">
    <i class="las la-times"></i>
</div>

<form [formGroup]="cancelForm" class="cancel-thread-form">
    <h3>Please provide a cancellation reason</h3>

    <div class="fx-form-group" *ngIf="cancelForm.controls.reason; let control">
        <label class="fx-form-label">Cancellation Reason</label>
        <div class="fx-form-control">
            <select class="fx-form-select" formControlName="reason">
                <option value="facetoface" [selected]="control.value === 'facetoface'"
                    >Requested in-person meeting</option
                >
                <option value="unreachable" [selected]="control.value === 'unreachable'">Client unreachable</option>
                <option value="notrequired" [selected]="control.value === 'notrequired'"
                    >Does not require service</option
                >
                <option value="other" [selected]="control.value === 'other'">Other</option>
            </select>

            <p *ngIf="control.touched && control.errors?.required" class="error-message">
                Please select a reason
            </p>
        </div>
    </div>

    <div *ngIf="cancelForm.controls.reason.value === 'other' && cancelForm.controls.reasonOther; let control">
        <div class="fx-form fx-form--outlined">
            <div class="fx-form-group">
                <textarea
                    textareaAutoresize
                    placeholder="Please provide a reason"
                    aria-label="message"
                    formControlName="reasonOther"
                    class="fx-form-input"
                >
                </textarea>
            </div>
        </div>

        <p *ngIf="control.touched && control.errors?.required" class="error-message">
            Please enter a reason
        </p>
    </div>

    <button
        [disabled]="cancelForm.invalid"
        class="fx-btn fx-btn--primary-outlined fx-btn--full-width"
        role="button"
        (click)="cancelTimeline()"
    >
        Cancel Timeline
    </button>
</form>
