<h2>Create new thread</h2>
<loader-icon *ngIf="loader.counter" color="light"></loader-icon>
<div class="new-thread-type">
    <fx-checkbox
        *ngFor="let threadType of newThreadTypes"
        (change)="setThreadType(threadType)"
        [checked]="currentType == threadType"
    >
        {{ threadType }}
    </fx-checkbox>
    <select
        *ngIf="currentType === 'Monthly'"
        (change)="currentMonth = $event.target.value"
        class="fx-form-select new-thread-select"
    >
        <option *ngFor="let month of newThreadMonths" [selected]="currentMonth === month" [value]="month">
            {{ month }}
        </option>
    </select>
</div>
<div class="new-thread-actions">
    <button class="fx-btn fx-btn--primary" (click)="dialogRef.close()">Cancel</button>
    <button class="fx-btn fx-btn--primary-outlined" [disabled]="!currentMonth && !currentType" (click)="createThread()">
        Create
    </button>
</div>
