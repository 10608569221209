<modal-layout [customHeader]="true">
    <div modal-header>
        <div class="fx-l-modal-header fx-l-modal-header--inner-modal">
            <div class="fx-l-modal-header-icon" (click)="close()">
                <i class="las la-times"></i>
            </div>
        </div>
    </div>
    <div modal-body>
        <div class="remove-rfi">
            <div class="remove-rfi-header">Remove<br />document type</div>

            <div class="remove-rfi-desc">
                Are you sure you want to remove the document type {{ data.documentType }} from {{ data.category }}?
            </div>
        </div>
    </div>
    <div modal-footer>
        <button class="fx-btn fx-btn--primary fx-btn--primary-outlined fx-btn--full-width" [mat-dialog-close]="true">
            Remove
        </button>
    </div>
</modal-layout>
