import { Injectable } from "@angular/core";
import { Observable, of, ObservableInput } from "rxjs";
import { tap, switchMap, finalize } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class Loader {
    public counter: number = 0;

    show() {
        // console.trace("STARTING LOADER");
        setTimeout(() => {
            this.counter++;
        });
    }

    hide() {
        setTimeout(() => {
            this.counter--;
            if (this.counter < 0) {
                console.trace("Hiding loader too many times");
                this.counter = 0;
            }
        });
    }

    wrap<T>(observable: ObservableInput<T>): Observable<T> {
        return of(null).pipe(
            tap(() => this.show()),
            switchMap(() => observable),
            finalize(() => this.hide())
        );
    }
}
