<main class="fx-l-main">
    <div *ngIf="loading" class="loader">
        <loader-icon [color]="'dark'"></loader-icon>
    </div>
    <fx-layout [columns]="24" [gutters]="false">
        <fx-layout-column [sizes]="{ xxs: '24' }">
            <ng-content></ng-content>
        </fx-layout-column>
    </fx-layout>
</main>
