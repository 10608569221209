<loader-icon *ngIf="loader.counter" color="light"></loader-icon>

<div class="report" *ngIf="report$ | async; let report">
    <h1>{{ displayName$ | async }} Report</h1>

    <div class="report-table">
        <table>
            <thead>
                <tr>
                    <th>Timeline ID</th>
                    <th>Actioned by</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let metric of report; trackBy: trackByMetric">
                    <td>{{ metric.threadId }}</td>
                    <td>{{ metric.actorId | userToName | async }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
