<header class="fx-header">
    <div class="fx-header-left-items">
        <a class="fx-header-menu-toggle fx-header-button" (click)="menu.emit()">
            <i class="las la-ellipsis-v" [ngClass]="{ 'la-times': layoutOpen }"></i>
        </a>
        <a class="fx-header-brand fx-brand" [routerLink]="['/']"> </a>
        <span class="fx-header-uat-indicator" *ngIf="stage === 'uat'">User Acceptance Test</span>
    </div>
    <div class="fx-header-right-items">
        <ng-container *ngIf="!userName">
            <findex-profile [userName]="userName" (logOut)="logout.emit()">
                <a aria-label="Log in" (click)="login.emit()">Login</a>
            </findex-profile>
        </ng-container>
    </div>
</header>
