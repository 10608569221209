import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Observable } from "rxjs";
import { IVCDetails } from "../../interfaces/IVCDetails";
import { filter, map, switchMap, take } from "rxjs/operators";
import { fromPromise } from "rxjs/internal-compatibility";
import { VideoChatService } from "../../services/video-chat.service";
import { AuthService } from "../../../../findex-auth";
import { Role } from "@findex/threads";

export type VCModalModel = { details: Observable<IVCDetails>; role: Role; join: boolean };

@Component({
    selector: "vc-modal",
    templateUrl: "./vc-modal.component.html",
    styleUrls: ["./vc-modal.component.scss"]
})
export class VcModalComponent {
    loading: boolean;
    errorMessage: string = "";
    token: string;
    userId$: Observable<string>;
    details$: Observable<IVCDetails>;
    role: Role;
    isMinimised = false;
    isDisabled = true;
    autoJoin: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: VCModalModel,
        private dialog: MatDialogRef<VcModalComponent, boolean>,
        private videoChatService: VideoChatService,
        authService: AuthService
    ) {
        this.details$ = data.details;
        this.role = data.role;
        this.userId$ = authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );

        this.autoJoin = data && data.join ? data.join : false;

        if (this.autoJoin) {
            this.details$
                .pipe(
                    filter(details => !!details.sessionId),
                    map(details => details.sessionId),
                    take(1)
                )
                .subscribe(sessionId => this.join(sessionId));
        }
    }

    close() {
        this.dialog.close();
    }

    toggleMinimise() {
        if (this.isMinimised) {
            this.isDisabled = true;
            this.isMinimised = false;
            this.dialog.addPanelClass("video-chat-modal-timeline--maximised");
            this.dialog.removePanelClass("video-chat-modal-timeline--minimised");
        } else {
            this.isDisabled = false;
            this.isMinimised = true;
            this.dialog.removePanelClass("video-chat-modal-timeline--maximised");
            this.dialog.addPanelClass("video-chat-modal-timeline--minimised");
        }
    }

    async join(sessionId: string) {
        this.errorMessage = "";
        this.loading = true;
        try {
            this.token = await this.videoChatService.getVideoChatToken(sessionId);
        } catch (error) {
            if (error.error) {
                this.errorMessage = error.error.message || "An unknown error occurred";
            } else {
                this.errorMessage = "An unknown error occurred";
            }
        }
        this.loading = false;
    }

    async terminateSession() {
        const detailSub = this.details$
            .pipe(
                take(1),
                switchMap(detail => {
                    const threadId = detail.threadId;
                    const cardId = detail.cardId;
                    const { sessionId } = detail;
                    return fromPromise(this.videoChatService.terminateSession(sessionId, threadId, cardId));
                })
            )
            .subscribe(() => {
                detailSub.unsubscribe();
            });
    }
}
