import { Component, Input, Output, EventEmitter, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "threads-input",
    templateUrl: "./threads-input.component.html",
    styleUrls: ["./threads-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ThreadsInputComponent),
            multi: true
        }
    ]
})
export class ThreadsInputComponent implements ControlValueAccessor {
    @Input() id: string;
    @Input() type: string = "text";
    @Input() value: string;
    @Input() placeholder: string;
    @Input() inputType: string;
    @Input() errorMessage: string;
    disabled: boolean;

    @Output() currentValue = new EventEmitter<string>();

    private onChange: (value: string) => void;
    private onTouch: () => void;

    blur() {
        if (this.onTouch) this.onTouch();
    }

    changed(event: Event) {
        event.stopPropagation();
        const target = event.target as HTMLInputElement;

        if (this.onChange) this.onChange(target.value);
        this.currentValue.emit(target.value);
    }

    writeValue(val: string): void {
        this.value = val;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
