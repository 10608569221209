<div
    class="fx-onboarding fx-onboarding--high-logo fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }}"
>
    <div class="fx-onboarding-header">
        <div class="fx-brand"></div>
    </div>

    <div class="fx-onboarding-container fx-container">
        <pagination [steps]="steps" [activeStep]="activeStep"></pagination>
        <div class="fx-onboarding-content">
            <div class="fx-onboarding-go-back">
                <button class="fx-onboarding-back-button" (click)="goBack.emit()">
                    <i class="fx-onboarding-back-icon las la-long-arrow-alt-left"></i>
                </button>
            </div>
            <h1 class="fx-onboarding-title industry-type-title">Industry type</h1>

            <div class="fx-onboarding-form fx-form fx-form--dark industry-type-selection">
                <div class="fx-form-group">
                    <app-industry-type-selection
                        [selectedIndustryTypes]="selectedIndustryTypes"
                        (modelUpdated)="updateParentModel($event)"
                        (modelValidated)="updateParentValidation($event)"
                    >
                    </app-industry-type-selection>
                </div>
                <div class="fx-form-group">
                    <button
                        class="fx-btn fx-btn--full-width fx-btn--primary-outlined business-name-button"
                        [disabled]="!isValid"
                        (click)="progressState.emit()"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
