import { environment } from "../../../../../../environments/environment";
import { INewsArticle } from "./INewsArticle";
import { SigmaNewsArticle } from "./sigma-news-article";
import { PortalNewsArticle } from "./portal-news-article";

export interface INewsArticleMapper {
    map(args: any): INewsArticle[];
}

export abstract class NewsArticleMapper implements INewsArticleMapper {
    abstract map(args: any): INewsArticle[];
}

const nullMapper: INewsArticleMapper = {
    map(_args: any): INewsArticle[] {
        return [];
    }
};

export const newsArticleMapperFactory = () => {
    switch (environment.appName) {
        case "Findex Portal":
            return new PortalNewsArticle();

        case "Same Day Tax Refunds":
        case "Sigma":
            return new SigmaNewsArticle();

        default:
            return nullMapper;
    }
};
