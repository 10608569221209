<loader-icon *ngIf="loader.counter" color="light"></loader-icon>

<div class="reports" *ngIf="reports$ | async; let reports">
    <h1>Reports</h1>

    <div class="reports-table">
        <table>
            <thead>
                <tr>
                    <th>Metric</th>
                    <th>Count</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let summary of reports; trackBy: trackByMetricName"
                    [routerLink]="['/reports', summary.metricName]"
                >
                    <td class="reports-table-column-name">{{ summary.displayName }}</td>
                    <td class="reports-table-column-count">{{ summary.count }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
