<loader-icon *ngIf="loader.counter" color="light"></loader-icon>
<div
    class="fx-onboarding fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }} sdtr-bg-mesh"
>
    <div class="fx-onboarding-container fx-container">
        <div class="fx-onboarding-content">
            <div class="fx-onboarding-brand fx-brand"></div>
            <form
                class="fx-onboarding-form fx-form"
                [ngClass]="['fx-form--' + theme.formTheme]"
                [formGroup]="form"
                (submit)="login()"
            >
                <div class="fx-form-group fx-onboarding-form-error" *ngIf="errorMessage">
                    <div class="fx-form-validation-panel">
                        <div class="fx-form-validation-panel-item">{{ errorMessage }}</div>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="emailAddress">Email</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="emailAddress"
                            id="emailAddress"
                            class="fx-form-input"
                            type="text"
                            placeholder="john.doe@findex.com.au"
                            aria-label="Email"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['emailAddress'].valid && form.controls['emailAddress'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="
                            form.controls['emailAddress'].hasError('required') && form.controls['emailAddress'].touched
                        "
                    >
                        Email is required.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="form.controls['emailAddress'].hasError('email') && form.controls['emailAddress'].touched"
                    >
                        Email is invalid.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="password">Password</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="password"
                            id="password"
                            class="fx-form-input"
                            type="password"
                            placeholder="Password"
                            aria-label="Password"
                            togglePassword
                            [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                            [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['password'].valid && form.controls['password'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched"
                    >
                        Password is required.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="
                            !form.controls['password'].hasError('required') &&
                            !form.controls['password'].valid &&
                            form.controls['password'].touched
                        "
                    >
                        Password is invalid.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                    <a
                        class="fx-form-group-action"
                        role="button"
                        (click)="showForgotPassword($event)"
                        analyticsClick="login_forgotpassword"
                        >Forgot password?</a
                    >
                </div>

                <div class="fx-form-group">
                    <button
                        class="fx-onboarding-submit fx-onboarding-button fx-btn"
                        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                        [disabled]="!form.valid"
                        analyticsClick="login_login"
                        type="submit"
                    >
                        Login
                    </button>

                    <div *ngIf="showAgreePoliciesLogin" class="fx-form-disclaimer">
                        By clicking 'Login' you accept <br />{{ appName }}'{{
                            appName[appName.length - 1] === "s" ? "" : "s"
                        }}
                        <a
                            *ngIf="termsUrl"
                            [href]="termsUrl"
                            target="_blank"
                            class="fx-form-disclaimer-link"
                            role="button"
                            >Terms</a
                        >
                        <ng-container *ngIf="termsUrl && policyUrl"> &amp; </ng-container>
                        <a
                            *ngIf="policyUrl"
                            [href]="policyUrl"
                            target="_blank"
                            class="fx-form-disclaimer-link"
                            role="button"
                            >Privacy Policy</a
                        >
                        <ng-container *ngIf="(termsUrl || policyUrl) && termsOfBusinessUrl"> &amp; </ng-container>
                        <a
                            *ngIf="termsOfBusinessUrl"
                            [href]="termsOfBusinessUrl"
                            target="_blank"
                            class="fx-form-disclaimer-link"
                            role="button"
                            >Terms of Business</a
                        >
                        <ng-container *ngIf="(termsUrl || policyUrl || termsOfBusinessUrl) && disclaimerUrl">
                            &amp;
                        </ng-container>
                        <a
                            *ngIf="disclaimerUrl"
                            [href]="disclaimerUrl"
                            target="_blank"
                            class="fx-form-disclaimer-link"
                            role="button"
                            >Disclaimer and Disclosure</a
                        >
                        <ng-container
                            *ngIf="(termsUrl || policyUrl || termsOfBusinessUrl || disclaimerUrl) && emailDisclaimerUrl"
                        >
                            &amp;
                        </ng-container>
                        <a
                            *ngIf="emailDisclaimerUrl"
                            [href]="emailDisclaimerUrl"
                            target="_blank"
                            class="fx-form-disclaimer-link"
                            role="button"
                            >Email Disclaimer</a
                        >
                    </div>
                </div>
            </form>
            <div class="fx-onboarding-actions" *ngIf="showSignup">
                Don't have an account?
                <a
                    class="fx-onboarding-bottom-link"
                    role="button"
                    analyticsClick="login_signup"
                    [routerLink]="['/register']"
                    [queryParams]="{ emailAddress: form.controls['emailAddress'].value }"
                    (click)="loginCancelled()"
                    >SIGN UP</a
                >
            </div>
        </div>
    </div>
</div>
