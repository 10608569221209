import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OnboardingRoutingModule } from "./onboarding-routing.module";
import { SignUpComponent } from "./components/onboarding-initial/signup/signup.component";
import { OnboardingService } from "./services/onboarding.service";
import { OnboardingInitialComponent } from "./components/onboarding-initial/onboarding-initial.component";
import { ClientProfileComponent } from "./components/onboarding-initial/client-profile/client-profile.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IndustryTypeComponent } from "./components/onboarding-completion/industry-type/industry-type.component";
import { BusinessIndustryOptionComponent } from "./components/onboarding-completion/industry-type/business-industry-option/business-industry-option.component";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { VerifyEmailSuccessComponent } from "./components/verify-email-success/verify-email-success.component";
import { VerifyMobileComponent } from "./components/verify-mobile/verify-mobile.component";
import { MatDialogModule } from "@angular/material/dialog";
import { VerifyMobileSuccessComponent } from "./components/verify-mobile-success/verify-mobile-success.component";
import { FindexUiModule } from "../findex-ui";
import { VerifyEmailPromptComponent } from "./components/onboarding-initial/verify-email-prompt/verify-email-prompt.component";
import { OnboardingCompletionComponent } from "./components/onboarding-completion/onboarding-completion.component";
import { BusinessNameComponent } from "./components/onboarding-completion/business-name/business-name.component";
import { IndustryTypeSelectionComponent } from "./components/onboarding-completion/industry-type/industry-type-selection/industry-type-selection.component";
import { SharedModule } from "../shared";
import { AnalyticsModule } from "../analytics";

@NgModule({
    imports: [
        CommonModule,
        OnboardingRoutingModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        FormsModule,
        MatIconModule,
        RouterModule,
        MatDialogModule,
        FindexUiModule,
        SharedModule,
        AnalyticsModule
    ],
    declarations: [
        SignUpComponent,
        OnboardingInitialComponent,
        OnboardingCompletionComponent,
        ClientProfileComponent,
        VerifyEmailPromptComponent,
        VerifyEmailSuccessComponent,
        VerifyMobileComponent,
        VerifyMobileSuccessComponent,
        IndustryTypeComponent,
        BusinessNameComponent,
        BusinessIndustryOptionComponent,
        IndustryTypeSelectionComponent,
        IndustryTypeSelectionComponent
    ],
    entryComponents: [],
    exports: [IndustryTypeComponent, IndustryTypeSelectionComponent],
    providers: [OnboardingService]
})
export class OnboardingModule {
    constructor() {}
}
