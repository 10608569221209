import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { IParticipant, IThread } from "@findex/threads";
import { filter, map, take } from "rxjs/operators";
import { Loader } from "src/app/services/loader";
import { SigmaService } from "src/app/services/sigma.service";
import { environment } from "src/environments/environment";
import { AuthService } from "src/modules/findex-auth";
import {
    defaultCalendarMeetingDurations,
    ICalendarMeetingDuration
} from "../../../../../environments/environment.common";
import { AttendeeModalComponent } from "./attendee-modal/attendee-modal.component";

@Component({
    selector: "create-invitation",
    templateUrl: "./create-invitation.component.html",
    styleUrls: ["./create-invitation.component.scss"]
})
export class CreateInvitationComponent implements OnInit {
    meetingName: string;
    attendees: IParticipant[] = [];
    currentUserId: string;
    loader = new Loader();

    readonly theme = environment.theme;
    readonly timeSlots: ICalendarMeetingDuration[] =
        environment.featureFlags.calendarMeetingDurations || defaultCalendarMeetingDurations;

    duration = this.timeSlots.find(timeSlot => {
        return timeSlot.defaultSelection;
    }).durationMinutes;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: { thread: IThread },
        private dialogRef: MatDialogRef<CreateInvitationComponent>,
        private dialog: MatDialog,
        private authService: AuthService,
        private cardService: SigmaService
    ) {}

    ngOnInit() {
        this.setInitialAttendee();
    }

    async setInitialAttendee() {
        this.currentUserId = await this.authService
            .getUser()
            .pipe(
                map(user => user.id),
                take(1)
            )
            .toPromise();
        this.attendees = this.data.thread.participants.filter(participant => participant.id === this.currentUserId);
    }

    addAttendee() {
        const data = {
            participants: this.data.thread.participants,
            attendees: this.attendees,
            currentUserId: this.currentUserId
        };

        this.dialog
            .open(AttendeeModalComponent, { data, panelClass: ["threads-sidebar", "threads-sidebar-inner-modal"] })
            .afterClosed()
            .pipe(filter(attendees => !!attendees))
            .subscribe(attendees => (this.attendees = attendees));
    }

    async create() {
        this.loader.show();
        try {
            await this.cardService
                .createCalendarCard(this.data.thread.id, this.meetingName, this.duration, this.attendees)
                .toPromise();
        } finally {
            this.loader.hide();
        }

        this.dialogRef.close();
    }

    close() {
        this.dialogRef.close();
    }

    remove(userId: string) {
        const index = this.attendees.findIndex(x => x.id === userId);
        if (index < 0) {
            return;
        }
        this.attendees.splice(index, 1);
    }
}
