<loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>

<ng-container *ngIf="documents$ | async; let documents">
    <div class="document" *ngFor="let document of documents">
        <div class="document-metadata">
            {{ document.timestamp | date: "dd MMM yyy h:mmaaa" }}
        </div>
        <div class="document-metadata">
            {{ document.actorId | userToName | async }}
        </div>
        <div class="document-title">
            <span class="downloadable" (click)="download(document)">{{ document.title }}</span>
            <ng-container *ngIf="document.signable && !document.signed">
                <signature-icon
                    *ngIf="role === roles.Client"
                    (click)="sign(document.card.id, document.vaultId, document.fileId)"
                    analyticsClick="app_documentssigndocument"
                    [theme]="'light'"
                    [text]="'Sign'"
                    [cursor]="true"
                ></signature-icon>
                <signature-icon
                    *ngIf="role | minRole: roles.Staff"
                    [theme]="'dark'"
                    [text]="'Pending'"
                    [cursor]="false"
                ></signature-icon>
            </ng-container>
            <signature-icon
                *ngIf="document.signed"
                [theme]="'dark'"
                [text]="'Signed'"
                [cursor]="false"
            ></signature-icon>
        </div>
    </div>
</ng-container>
