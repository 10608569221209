import { ThreadTypes } from "@findex/threads";
import { CloseAction, FeatureConfig } from "./environment.common";

import { TourStep } from "../app/services/tour.service";

const introductionTourSteps: TourStep[] = [
    {
        titleHtml: `Welcome to <img class="tour-logo" />`,
        contentHtml: `
            <p>From here you'll be able to meet your personal tax adviser online and complete your return in minutes!</p>`,
        nextButtonText: `Let's go!`
    },
    {
        titleHtml: `Your Dashboard`,
        contentHtml: `<p>Your dashboard is a summary of your activity, tasks & scheduled upcoming meetings. Latest insights from Same Day Tax Refunds are available in your news.</p>`,
        nextButtonText: `Got it!`,
        attachTo: {
            selector: "#dashboard-menu-item",
            side: "bottom"
        },
        menuStateBeforeShow: "open"
    },
    {
        titleHtml: `Your Tasks`,
        contentHtml: `<p>Your tasks list requires action from you. This can range from uploading documents, booking a meeting or joining a meeting when scheduled.</p>`,
        nextButtonText: `Got it!`,
        attachTo: {
            selector: "#dashboard-my-tasks .fx-card",
            side: "bottom"
        }
    },
    {
        titleHtml: `Upcoming meetings`,
        contentHtml: `<p>All meetings are held on the platform. Please ensure that you join the meeting at the scheduled time. You will need a working microphone and speaker with video camera optional. </p>`,
        nextButtonText: `Got it!`,
        attachTo: {
            selector: "#dashboard-upcoming-meetings .fx-card",
            side: "bottom"
        },
        menuStateBeforeShow: "closed"
    }
];

const onboardingRfiDocuments = {
    Essentials: [
        { description: "Drivers licence / photo ID" }, //Comment to prevent code format for readability
        { description: "Employer payment summaries" }
    ],
    "Work related expenses": [
        { description: "Car (fuel, oil, repairs, interest)" },
        { description: "Home office (equipment, phone bills, internet bills, printing & stationery)" },
        { description: "Equipment insurance premiums" },
        { description: "Work / investment related journals and publications" },
        { description: "Professional association, Union fees" },
        { description: "Seminars / conferences / in-services" },
        { description: "Telephone (calls and rental charges)" },
        { description: "Tools of trade (depreciation, insurance, repairs, replacement)" },
        { description: "Travel (air, bus, taxi fares, accommodation, meals)" },
        { description: "Uniforms and protective clothing (purchased, rental, laundry)" },
        { description: "Self-education (course fees, books, copying, stationery, travel)" }
    ],
    "Other expenses": [
        { description: "Donations" },
        { description: "Interest and dividend deductions" },
        { description: "Tax agent fees" },
        { description: "Other" }
    ],
    "Rental property details (if applicable)": [
        { description: "Details of rental income received" },
        { description: "Interest paid (from bank statements)" },
        { description: "Rates and land tax" },
        { description: "Insurance / body corporate fees" },
        { description: "Repairs and maintenance" },
        { description: "Agents fees" }
    ],
    "Other income": [
        { description: "Details of bank interest received" },
        { description: "Share investment information" },
        { description: "Details of other income and earnings" }
    ],
    "Other items": [
        { description: "Previous years Notice of Assessment" },
        { description: "Details of spouse's income and date of birth" },
        { description: "Motor vehicle logbook" },
        { description: "Private health insurance annual statement" }
    ]
};

export const SAME_DAY_FEATURE_CONFIG: FeatureConfig = {
    onboardingType: "sameday",
    signupEnabled: true,
    signupCountries: ["au"],
    subscriptionEnabled: true,
    simpleProfile: false,
    rfiCategories: [
        "Essentials",
        "Work related expenses",
        "Other expenses",
        "Rental property details (if applicable)",
        "Other income",
        "Other items"
    ],
    onboardingRfiDocuments: onboardingRfiDocuments,
    calendarMeetingDurations: [
        { durationMinutes: 20, description: "20 mins" },
        { durationMinutes: 30, description: "30 mins" },
        { durationMinutes: 40, description: "40 mins", defaultSelection: true },
        { durationMinutes: 60, description: "60 mins" }
    ],
    newThreadButton: true,
    jobStatusEnabled: false,
    closeThreadContextMenu: true,
    supportEmail: "SDTR@findex.com.au",
    packageDisplayName: "service",
    initialThreadDetails: {
        threadType: ThreadTypes.BasicTaxReturn
    },
    onCloseThread: CloseAction.SameDayFileSync,
    temporaryFeatureFlags: {
        staffTitleOverride: "Personal Tax Adviser"
    },
    addParticipants: false,
    threadParticipantDetail: true,
    showAgreePoliciesLogin: true,
    text: {
        signupInstruction: "To book your digital online tax return, create an account.",
        signupAction: "Get Started"
    },
    signingRequiresFileDownloadEnabled: false,
    introductionTour: {
        id: "introduction",
        steps: introductionTourSteps,
        tourRoute: "dashboard"
    }
};
