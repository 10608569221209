import { HttpClient } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { combineLatest, Observable, of, zip, merge, from } from "rxjs";
import { filter, map, mapTo, switchMap, take } from "rxjs/operators";
import { HandledError } from "src/app/services/sentry-error-handler";
import { environment, environmentCommon } from "../../../environments/environment";
import { LoginChallengeResult, LoginStep, LoginStepDetails } from "../model/LoginStep";
import { User } from "../model/User";
import { AzureAdAuthStrategy } from "./strategies/azure-ad-auth.strategy";
import { CognitoAuthStrategy } from "./strategies/cognito-auth.strategy";
import { InvitationAuthStrategy } from "./strategies/invitation-auth.strategy";

interface WebServiceStatusResponse {
    data: { status: string };
    message?: string;
}

@Injectable({ providedIn: "root" })
export class AuthService {
    constructor(
        private activeDirectoryStrategy: AzureAdAuthStrategy,
        private cognitoStrategy: CognitoAuthStrategy,
        private invitationStrategy: InvitationAuthStrategy,
        private http: HttpClient,
        private errorHandler: ErrorHandler
    ) {}

    /**
     * Registers an invitation - not currently used
     */
    // register(emailAddress: string, mobileNumber: string): Observable<any> {
    //     const body = { emailAddress, mobileNumber };
    //     const url = `${environment.auth.base}/registration`;
    //     return this.http.post(url, body);
    // }

    getLogin(): Observable<LoginStepDetails> {
        return merge(this.activeDirectoryStrategy.getLogin(), this.cognitoStrategy.getLogin());
    }

    onLoginSuccess(): Observable<User> {
        return this.getLogin().pipe(
            filter(loginStepDetails => loginStepDetails && loginStepDetails.step === LoginStep.LOGIN_COMPLETE),
            switchMap(() => this.getUser())
        );
    }

    loginAsStaff(): Observable<LoginStepDetails> {
        return this.activeDirectoryStrategy.startLogin();
    }

    loginWithEmail(emailAddress: string, password: string): Observable<LoginStepDetails> {
        return this.cognitoStrategy.startLogin(emailAddress.toLowerCase(), password);
    }

    completeTwoFactor(code: string, rememberDevice: boolean): Observable<LoginChallengeResult> {
        return this.cognitoStrategy.sendMFACode(code, rememberDevice);
    }

    cancelLogin(): void {
        return this.cognitoStrategy.cancelLogin();
    }
    completeNewPassword(userDetails: any, newPassword: string): Observable<void> {
        return this.cognitoStrategy.completeNewPassword(userDetails, newPassword);
    }

    /**
     * @deprecated
     * @param token
     */
    loginWithToken(token: string): Observable<LoginStepDetails> {
        return this.invitationStrategy
            .startLogin()
            .pipe(challenge => this.invitationStrategy.answerChallenge(challenge, token));
    }

    beginVerifyMobileNumber(mobileNumber: string): Observable<{ status: string; message: string }> {
        return this.cognitoStrategy.beginVerifyMobileNumber(mobileNumber);
    }

    confirmMobileNumber(code: string): Observable<{ status: string; message: string }> {
        return this.cognitoStrategy.confirmVerifyMobileNumber(code);
    }

    confirmEmail(code: string): Observable<{ status: string; message: string }> {
        return this.cognitoStrategy.confirmVerifyEmailAddress(code);
    }

    logout(): Observable<void> {
        return zip(
            this.activeDirectoryStrategy.logout(),
            this.cognitoStrategy.logout(),
            this.invitationStrategy.logout()
        ).pipe(mapTo(null));
    }

    getUser(): Observable<User> {
        return combineLatest(
            this.activeDirectoryStrategy.getUser(),
            this.cognitoStrategy.getUser(),
            this.invitationStrategy.getUser()
        ).pipe(map(([staffUser, cognitoUser, inviteUser]) => staffUser || cognitoUser || inviteUser));
    }

    getHttpHeaders(): Observable<any> {
        return this.getUser().pipe(
            take(1),
            switchMap(user => {
                const headers$ = this.userStrategyHeaders(user);
                return zip(headers$, this.invitationStrategy.getHttpHeaders());
            }),
            map(headers =>
                headers.reduce((acc, header) => ({
                    ...header,
                    ...acc
                }))
            )
        );
    }

    onRedirect() {
        return from(this.activeDirectoryStrategy.handleRedirect());
    }

    private userStrategyHeaders(user: User): Observable<any> {
        if (!user) {
            return of({});
        }

        if (user.type === "cognito") {
            return this.cognitoStrategy.getHttpHeaders();
        } else {
            return this.activeDirectoryStrategy.getHttpHeaders();
        }
    }

    async refreshUserTokens() {
        await this.cognitoStrategy.refreshTokens();
    }

    async checkSignUpStatus(
        emailAddress: string
    ): Promise<{
        success: boolean;
        loginRequired: boolean;
        verificationSent: boolean;
        errorMessage?: string;
    }> {
        const { base } = environment.auth;
        const { endpoints } = environmentCommon.auth;
        const url = `${base}${endpoints.checkUser}`;

        const body = {
            emailAddress: emailAddress.toLowerCase(),
            userPoolClientId: environment.auth.userPoolWebClientId,
            redirectUrl: environment.registration.redirectUrl,
            errorRedirectUrl: environment.errorRedirectUrl,
            themeName: environment.appTheme
        };
        try {
            const result = await this.http.post<WebServiceStatusResponse>(url, body).toPromise();
            const { status } = result.data ? result.data : { status: "UNKNOWN" };
            switch (status) {
                case "PROCEED_WITH_SIGNUP":
                    return {
                        success: true,
                        loginRequired: false,
                        verificationSent: false
                    };
                case "VERIFICATION_SENT":
                    return {
                        success: true,
                        loginRequired: false,
                        verificationSent: true
                    };
                case "LOGIN":
                case "PASSWORD_CHANGE_REQUIRED":
                    return {
                        success: true,
                        loginRequired: true,
                        verificationSent: false
                    };
            }
        } catch (errorResponse) {
            this.handleError(errorResponse);
        }
        return {
            success: false,
            errorMessage: "Sorry, something went wrong",
            loginRequired: false,
            verificationSent: false
        };
    }

    async beginForgotPassword(emailAddress: string): Promise<{ success: boolean; errorMessage?: string }> {
        const { base } = environment.auth;
        const { endpoints } = environmentCommon.auth;
        const url = `${base}${endpoints.forgotPassword}`;

        const body = {
            emailAddress,
            userPoolClientId: environment.auth.userPoolWebClientId,
            redirectUrl: environment.auth.forgotPasswordRedirect,
            themeName: environment.appTheme
        };
        try {
            const result = await this.http.post<WebServiceStatusResponse>(url, body).toPromise();
            const status = result.data ? result.data.status : "";
            if (status === "OK") {
                return { success: true };
            }
            return { success: false, errorMessage: result.message };
        } catch (errorResponse) {
            this.handleError(errorResponse);
            return { success: false, errorMessage: errorResponse.error.message };
        }
    }

    async confirmPasswordReset(
        userName: string,
        code: string,
        newPassword: string
    ): Promise<{ success: boolean; errorMessage?: string }> {
        const { base } = environment.auth;
        const { endpoints } = environmentCommon.auth;
        const url = `${base}${endpoints.forgotPasswordConfirm}`;

        const body = {
            userName,
            userPoolClientId: environment.auth.userPoolWebClientId,
            code,
            newPassword
        };
        try {
            const result = await this.http.post<WebServiceStatusResponse>(url, body).toPromise();
            const status = result.data ? result.data.status : "";
            if (status === "OK") {
                return { success: true };
            }
            return { success: false, errorMessage: result.message };
        } catch (errorResponse) {
            return { success: false, errorMessage: errorResponse.error.message };
        }
    }

    private handleError(error: Error) {
        try {
            if ("error" in error) {
                const errorContent = (error as any).error;
                const status = errorContent.data ? errorContent.data.status : "";
                //Some 4xx responses are expected base on user behaviour, e.g. throttling. Only report actual errors.
                if (!status.toUpperCase().includes("ERROR")) {
                    return;
                }
            }
            this.errorHandler.handleError(new HandledError(error));
        } catch (error) {}
    }
}
