import { ErrorHandler, Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import * as Sentry from "@sentry/browser";
import { ErrorService } from "src/modules/shared/services/error.service";
import { HttpErrorResponse } from "@angular/common/http";
import { environmentCommon } from "src/environments/environment.common";

Sentry.init({
    dsn: environment.sentry,
    environment: environment.stage,
    enabled: ["uat", "prod"].includes(environment.stage),
    release: environmentCommon.appVersion
});

export class HandledError {
    constructor(public originalError: any) {}
}

@Injectable()
export class SentryErrorHandler extends ErrorHandler {
    constructor(private errorService: ErrorService) {
        super();
    }
    handleError(error: any): void {
        const responseError = error.rejection || error;
        const isHandled = responseError instanceof HandledError;
        const reportedError = isHandled ? responseError.originalError : responseError;

        // Notes: Global error handler appears to only fire once the unsubscribes
        if (!isHandled) {
            const err = responseError as HttpErrorResponse;
            const title = err.status && err.error.error ? `${err.status} - ${err.error.error}` : null;
            const message = err && err.error && err.error.message ? err.error.message : null;

            this.errorService.showError(title, message);
        }

        Sentry.captureException(reportedError.originalError || reportedError);

        super.handleError(reportedError);
        throw reportedError;
    }
}
