import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface ICreateRfiSuccessData {
    participants: string;
}
@Component({
    selector: "create-rfi-success",
    templateUrl: "./success.component.html",
    styleUrls: ["./success.component.scss"]
})
export class CreateRfiSuccessComponent implements OnInit {
    constructor(
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<CreateRfiSuccessComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ICreateRfiSuccessData
    ) {}

    ngOnInit() {}

    close() {
        this.dialog.closeAll();
    }

    view() {
        this.dialogRef.close({ viewCard: true });
    }
}
