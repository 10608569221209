<modal-layout
    (close)="onClose(false)"
    [contentGutters]="false"
    [header]="rescheduleAppointment ? 'Reschedule meeting' : 'Schedule meeting'"
    [headerDescription]="data.meetingDescription"
>
    <div modal-body>
        <div class="calendar-modal-content">
            <div *ngIf="data.otherParticipants && data.otherParticipants.length">
                <div *ngFor="let otherParticipant of data.otherParticipants" class="calendar-modal-participant">
                    <fx-avatar [name]="otherParticipant.profile.name" [isLarge]="false"></fx-avatar>
                    <div class="calendar-modal-participant-user">
                        <div class="calendar-modal-participant-user-name">
                            {{ otherParticipant.profile.name }}
                        </div>
                        <div class="calendar-modal-participant-user-title">
                            {{ otherParticipant.profile.title }}
                        </div>
                    </div>
                </div>
            </div>

            <loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
            <div *ngIf="calendarError !== ''" class="calendar-modal-error">{{ calendarError }}</div>
            <fx-calendar
                [slots]="slots"
                [minDate]="minDate"
                [maxDate]="maxDate"
                (dateTimeSelected)="handleCalendarEvent($event)"
                (monthChange)="monthChange($event)"
            >
            </fx-calendar>
        </div>
    </div>
    <div modal-footer>
        <button
            class="fx-btn fx-btn--full-width"
            [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
            type="submit"
            [disabled]="!selectedDate"
            (click)="onClose(true)"
        >
            Confirm
        </button>
    </div>
</modal-layout>
