<ng-container *ngIf="article$ | async; else noArticles; let articles">
    <ng-container *ngIf="articles.length; else noArticles">
        <news-article-item *ngFor="let article of articles" [article]="article"></news-article-item>

        <div class="dashboard-card-footer">
            <a (click)="goToWebsite($event)" analyticsClick="app_news" href="">View All</a>
        </div>
    </ng-container>
</ng-container>

<ng-template #noArticles>
    <ng-container *ngIf="!loader.counter">
        Sorry, there are no articles available.
    </ng-container>
</ng-template>

<loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
