<div class="fx-threads-list" [ngClass]="{ 'fx-threads-list--active': active }">
    <div class="fx-threads-category">
        {{ node.title }}

        <span class="fx-threads-category-closed-badge" *ngIf="node.state === threadStates.closed">Closed</span>
        <span class="fx-threads-category-closed-badge" *ngIf="node.state === threadStates.cancelled">Cancelled</span>
    </div>
    <button class="fx-threads-summary-card" (click)="selected(node)">
        <div class="fx-threads-summary-card-row">
            <div class="fx-threads-summary-card-top">
                <span class="fx-threads-summary-card-name">{{
                    (node.preview?.participantId | threadParticipant: node.participants)?.profile?.name
                }}</span>
                <span class="fx-threads-summary-card-type">{{ node.preview?.timestamp || node.createdAt | date }}</span>
            </div>
            <div class="fx-threads-summary-card-bottom">
                <span class="fx-threads-summary-card-summary">{{ node.preview?.summary | slice: 0:60 }}</span>
                <span class="fx-threads-summary-card-time">
                    {{ node.preview?.timestamp || node.createdAt | date: "h:mm aaa" }}
                </span>
            </div>
        </div>
        <fx-context-menu
            #menu
            *ngIf="contextEnabled && globalRole | minRole: roles.Staff"
            [ngSwitch]="node.state | isThreadActive"
            (click)="contextStopPropagation($event)"
        >
            <ng-container *ngSwitchCase="true">
                <a (click)="changeTimelineState($event, node, threadStates.closed); menu.toggleMenuState()">
                    Close Timeline
                </a>
                <a (click)="changeTimelineState($event, node, threadStates.cancelled); menu.toggleMenuState()">
                    Cancel Timeline
                </a>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <a (click)="changeTimelineState($event, node, threadStates.open); menu.toggleMenuState()">
                    Reopen Timeline
                </a>
                <a *ngIf="node.notes" (click)="cancellationReason($event, node); menu.toggleMenuState()">
                    Cancellation Info
                </a>
            </ng-container>
        </fx-context-menu>
    </button>
</div>
