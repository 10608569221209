<div
    class="thread-card {{ uiCard.card.type }}"
    [ngClass]="{
        'fx-threads-message--odd': (userId$ | async) === uiCard?.card.createdBy,
        'thread-card-loading': uiCard.loader.counter
    }"
>
    <loader-icon *ngIf="uiCard.loader.counter" [color]="'light'"></loader-icon>
    <ng-template [cdkPortalOutlet]="mounted"></ng-template>
</div>
