import { ICardEvent } from "@findex/threads";
import { Observable, ReplaySubject } from "rxjs";
import { IVCDetails } from "../interfaces/IVCDetails";

export class VcStateBuilder {
    private subject = new ReplaySubject<IVCDetails>(1);
    private model: IVCDetails = {
        isTerminated: true
    };

    getState(): Observable<IVCDetails> {
        return this.subject.asObservable();
    }

    setThreadAndState(threadId: string, cardId: string) {
        this.model.threadId = threadId;
        this.model.cardId = cardId;
    }

    addEvent(event: ICardEvent) {
        const { type, payload, actor } = event;

        if (type === "scheduled-task") {
            this.model.timestamp = payload.scheduledTime;
        } else if (type === "video-chat-start") {
            this.model.sessionId = payload.sessionId;
            this.model.status = "Call starting...";
            this.model.isTerminated = false;
        } else if (type === "video-chat-end") {
            this.model.status = "Video session concluded.";
            this.model.isTerminated = true;
        }

        this.model.actor = actor;
        this.subject.next(this.model);
    }

    setCaller(name: string, position: string) {
        this.model.name = name;
        this.model.position = position;
        this.subject.next(this.model);
    }
}
