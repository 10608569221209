import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IParticipant, IThread, IThreadCard, Role } from "@findex/threads";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment, environmentCommon } from "../../environments/environment";

export type IInitialVaultPayload = {
    description: string;
    documents: {
        description: string;
        category: string;
    }[];
};

type OnboardingResponse = {
    thread: IThread;
    card?: IThreadCard;
    appointmentId: string;
};

@Injectable({ providedIn: "root" })
export class SigmaService {
    constructor(private http: HttpClient) {}

    createOnboarding(
        participantId: string,
        createMeeting: boolean,
        initialVaultPayload?: IInitialVaultPayload
    ): Observable<OnboardingResponse> {
        const { base } = environment.sigmaEndpoints;
        const { onboarding } = environmentCommon.sigmaEndpoints;
        const url = `${base}${onboarding}`;

        const appName = environment.appName;

        return this.http.post<OnboardingResponse>(url, {
            participantId,
            createMeeting,
            appName,
            threadType: environment.featureFlags.initialThreadDetails.threadType,
            initialVaultPayload
        });
    }

    getMyDashboard(): Observable<string> {
        const { base } = environment.sigmaEndpoints;
        const { dashboard } = environmentCommon.sigmaEndpoints;
        const url = `${base}${dashboard}`;
        return this.http.get<{ dashboardThreadId: any }>(url).pipe(map(({ dashboardThreadId }) => dashboardThreadId));
    }

    getDashboard(userId: string): Observable<string> {
        const { base } = environment.sigmaEndpoints;
        const { dashboard } = environmentCommon.sigmaEndpoints;
        const url = `${base}${dashboard}/${userId}`;
        return this.http.get<{ dashboardThreadId: any }>(url).pipe(map(({ dashboardThreadId }) => dashboardThreadId));
    }

    createCalendarCard(threadId, meetingName, duration, attendees) {
        const staff = attendees.filter(({ role }) => role === Role.Staff);
        const client = attendees.filter(({ role }) => role === Role.Client);
        const getId = (participants: IParticipant[]) => participants.map(participant => participant.id);
        const [staffIds, clientIds] = [staff, client].map(getId);
        const createCardRequest = {
            meetingName,
            duration,
            eventTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "Australia/Melbourne",
            staffIds,
            clientIds
        };

        const { base } = environment.commonEndpoints;
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { calendar } = environmentCommon.cardsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${calendar}`;
        return this.http.post(url, { data: createCardRequest });
    }

    createVideoChatCard(threadId): Observable<IThreadCard> {
        const { base } = environment.commonEndpoints;
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { videoChat } = environmentCommon.cardsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${videoChat}`;
        return this.http.post<IThreadCard>(url, {});
    }

    createVaultCard(threadId, data): Observable<IThreadCard> {
        const { base } = environment.commonEndpoints;
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { vault } = environmentCommon.cardsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${vault}`;
        return this.http.post<IThreadCard>(url, { data });
    }

    createMessageCard(threadId, data): Observable<IThreadCard> {
        const { base } = environment.commonEndpoints;
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { message } = environmentCommon.cardsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${message}`;
        return this.http.post<IThreadCard>(url, { data });
    }
}
