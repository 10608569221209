import { Injectable } from "@angular/core";
import { IThread, IThreadCard, ICardEvent } from "@findex/threads";
import { concat, Observable, Subject, ReplaySubject, defer, empty, merge } from "rxjs";
import { shareReplay, filter, switchMap, distinct } from "rxjs/operators";
import { ThreadsService } from "./threads.service";
import { IUiCard, IEventService } from "../interfaces/IUiCard";
import { WebsocketService } from "./websocket.service";
import { CardComponentRegistry } from "./card-component.registry";
import { Loader } from "./loader";

@Injectable({ providedIn: "root" })
export class UiCardService {
    constructor(
        private threadsService: ThreadsService,
        private registry: CardComponentRegistry,
        private websocketService: WebsocketService
    ) {}

    mapCard(thread: IThread, card: IThreadCard): IUiCard {
        const { type, createdAt, modifiedAt } = card;
        const component = this.registry.getComponent(type);

        if (!component) {
            console.warn("Unsupported card", card);
            return null;
        }

        const eventsSubject = new Subject<ICardEvent>();
        const loader = new Loader();
        const eventsService = this.getCardEvents(eventsSubject, loader, thread.id, card.id);

        return {
            thread,
            card,
            cardState: this.getCardState(thread.id, card.id, loader),
            eventsSubject,
            eventsService,
            timestamp: new Date(modifiedAt || createdAt).getTime(),
            component,
            loader: loader,
            navigateTo: new ReplaySubject<void>(1)
        };
    }

    addCardEvent(allCards: IUiCard[], cardId: string, event: ICardEvent) {
        const card = allCards.find(uiCard => uiCard.card.id === cardId);
        if (!card) return console.warn("Could not find card", cardId, event);

        const eventTime = new Date(event.createdAt);
        card.eventsSubject.next(event);
        card.timestamp = eventTime.getTime();
    }

    routeToCard(allCards: IUiCard[], cardId: string) {
        const card = allCards.find(uiCard => uiCard.card.id === cardId);
        if (!card) return console.error("Could not find card with id", cardId);

        console.info("Routing to card", cardId);
        card.scrollTo = true;
        card.navigateTo.next(null);
    }

    compareCards(a: IUiCard, b: IUiCard) {
        return Date.parse(b.card.modifiedAt) - Date.parse(a.card.modifiedAt);
    }

    private getCardEvents(
        subject: Subject<ICardEvent>,
        loader: Loader,
        threadId: string,
        cardId: string
    ): IEventService {
        let last: string;

        const loadHistorical = async () => {
            loader.show();
            const { next, result } = await this.threadsService.getCardEvents(threadId, cardId, last).toPromise();
            last = next;

            for (const event of result.reverse()) {
                subject.next(event);
            }
            loader.hide();
            return last ? true : false;
        };

        const loadLatestEvents = defer(() => loadHistorical()).pipe(switchMap(() => empty()));
        const events = merge(loadLatestEvents, subject).pipe(distinct(event => event.id));

        return { events, loadHistorical };
    }

    private getCardState(threadId: string, cardId: string, loader: Loader): Observable<any> {
        const state$ = loader.wrap(this.threadsService.getCardState(threadId, cardId));
        const changes$ = this.cardStateChanges(threadId, cardId);

        return concat(state$, changes$).pipe(shareReplay(1));
    }

    private cardStateChanges(threadId: string, cardId: string): Observable<any> {
        return this.websocketService.connect(threadId).pipe(
            filter(notification => notification.cardId === cardId && notification.state),
            switchMap(() => this.threadsService.getCardState(threadId, cardId))
        );
    }
}
