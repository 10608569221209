import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError, EMPTY } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { AuthService } from "../services/auth.service";

const { protectedResources } = environment.auth;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const shouldAuth = protectedResources.find(endpoint => req.url.startsWith(endpoint));
        if (!shouldAuth) return next.handle(req);
        return this.authService.getHttpHeaders().pipe(
            switchMap(setHeaders => {
                if (!setHeaders) {
                    return next.handle(req);
                }

                const reqWithAuth = req.clone({ setHeaders });
                return next.handle(reqWithAuth);
            }),
            catchError(err => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    return this.authService.logout().pipe(switchMap(() => EMPTY));
                }
                return throwError(err);
            })
        );
    }
}
