import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class SlideMenuService {
    private showMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
    showMenu$: Observable<boolean> = this.showMenu.asObservable();

    show() {
        this.showMenu.next(true);
    }

    hide() {
        this.showMenu.next(false);
    }

    toggle() {
        this.showMenu.next(!this.showMenu.value);
    }
}
