<!-- Display accordion if its a container -->
<!-- Otherwise display plain thread list. If it's not a container node, it shouldn't have any children node  -->
<div class="threads-filter">
    <div class="fx-form-control">
        <div class="fx-form-icon fx-form-icon--left ">
            <i class="las la-search"></i>
        </div>
        <input
            class="fx-form-input"
            placeholder="Search Timelines"
            attr.aria-label="Search Timelines"
            type="text"
            analyticsClick="app_searchtimelines"
            [value]="filter || ''"
            (input)="filter = $event.target.value"
        />
    </div>
</div>
<div class="breadcrumb">
    <span *ngIf="history.length" (click)="goUp(history[history.length - 2])" class="breadcrumb-back">
        <i class="las la-arrow-circle-left"></i>
    </span>
    <span class="breadcrumb-start" (click)="goUp()">Timelines</span>
    <ng-container *ngFor="let node of history; let i = index">
        <div class="breadcrumb-item" [ngClass]="{ active: history.length - 1 === i }">
            /
            <span (click)="goUp(node)">
                {{ node.name }}
            </span>
        </div>
    </ng-container>
</div>

<ng-container *ngFor="let node of activeNode?.children || nodes | threadsSearch: filter; let i = index">
    <ng-container *ngIf="node.children || node.type === 'container'">
        <div
            *ngIf="!node.isAccordion"
            class="fx-accordion-toggle fx-accordion-toggle--has-gutters node-group"
            (click)="goToChild(node)"
        >
            <div class="node-name" analyticsClick="app_navigatethreadlist">
                <div class="fx-threads-group-name">{{ node.name }}</div>
                <i class="fx-accordion-toggle-icon icon ion-ios-arrow-down"></i>
            </div>

            <ng-container *ngIf="!node.children">
                <thread-list-item
                    [globalRole]="globalRole"
                    [node]="node"
                    [active]="activeId === node.id"
                    [contextEnabled]="closeThreadContextMenu"
                    (itemClicked)="itemClicked.next($event)"
                    (changeTimelineStateClicked)="changeTimelineStateClicked.next($event)"
                >
                </thread-list-item>
            </ng-container>
        </div>

        <ng-container *ngIf="node.isAccordion">
            <fx-accordion
                [isExpanded]="node.isExpanded"
                [toggleGutters]="true"
                (accordionClicked)="node.isExpanded = !node.isExpanded"
            >
                <div accordion-header analyticsClick="app_togglethreadlist">
                    <div class="fx-threads-group-name">{{ node.name }}</div>
                    <i class="fx-accordion-toggle-icon icon ion-ios-arrow-down"></i>
                </div>

                <div accordion-content>
                    <thread-list-item
                        *ngFor="let child of node.children | threadsSearch: filter"
                        [globalRole]="globalRole"
                        [node]="child"
                        [active]="activeId === child.id"
                        [contextEnabled]="closeThreadContextMenu"
                        (itemClicked)="itemClicked.next($event)"
                        (changeTimelineStateClicked)="changeTimelineStateClicked.next($event)"
                    >
                    </thread-list-item>
                </div>
            </fx-accordion>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!node.children && node.type !== 'container'">
        <thread-list-item
            [globalRole]="globalRole"
            [node]="node"
            [active]="activeId === node.id"
            [contextEnabled]="closeThreadContextMenu"
            (itemClicked)="itemClicked.next($event)"
            (changeTimelineStateClicked)="changeTimelineStateClicked.next($event)"
        >
        </thread-list-item>
    </ng-container>
</ng-container>
