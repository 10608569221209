import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { User } from "../model/User";

const MOBILE_VERIFICATION_PATH = "/register/verify-mobile";

const registrationUrls = ["/register/signup", "/register/email-success", "/login", "/register/verify-mobile"];
const verificationUrls = [MOBILE_VERIFICATION_PATH];

@Injectable({ providedIn: "root" })
export class VerifiedGuard implements CanActivateChild {
    constructor(private authService: AuthService, private router: Router) {}

    // @ts-ignore
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.getUser().pipe(
            switchMap(user => {
                if (user) {
                    return this.checkVerification(user, state.url);
                } else {
                    //Leave it to the auth guard
                    return of(true);
                }
            })
        );
    }

    private checkVerification(user: User, url: string): Observable<boolean> {
        if (user.mobileNumberVerified) {
            if (verificationUrls.includes(url) || registrationUrls.includes(url)) {
                this.router.navigateByUrl("/dashboard");
                return of(false);
            }
        } else {
            if (url !== MOBILE_VERIFICATION_PATH) {
                this.router.navigateByUrl(MOBILE_VERIFICATION_PATH);
                return of(false);
            }
        }
        return of(true);
    }
}
