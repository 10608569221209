<h1>
    <button class="upload-rfi-close" [mat-dialog-close]="true"><i class="las la-times"></i></button>Send a message
</h1>

<mat-form-field>
    <textarea
        (change)="message = $event.target.value"
        [value]="message"
        matInput
        placeholder="Enter your message"
    ></textarea>
</mat-form-field>

<div>
    <button class="fx-btn fx-btn--secondary fx-btn--sm" (click)="sendMessage(message)">Send message</button>
</div>
