import { Directive, Input, OnChanges, SimpleChanges, ElementRef } from "@angular/core";

@Directive({ selector: "[scrollTo]" })
export class ScrollToDirective implements OnChanges {
    @Input() scrollTo: boolean;

    constructor(private element: ElementRef) {}

    ngOnChanges(changes: SimpleChanges) {
        const { scrollTo } = changes;

        if (scrollTo.currentValue) {
            this.element.nativeElement.scrollIntoView();
        }
    }
}
