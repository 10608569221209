<div
    class="fx-onboarding fx-onboarding--{{ theme.onboardingTheme }} {{
        theme.onboardingCentreAligned ? 'fx-onboarding--centered' : ''
    }} sdtr-bg-mesh"
>
    <div class="fx-onboarding-container fx-container">
        <div class="fx-onboarding-content">
            <div class="fx-onboarding-brand fx-brand"></div>
            <form
                class="fx-onboarding-form fx-form"
                [ngClass]="['fx-form--' + theme.formTheme]"
                [formGroup]="form"
                (submit)="updateParentModel()"
            >
                <p class="fx-onboarding-body" *ngIf="textResource.signupInstruction">
                    {{ textResource.signupInstruction }}
                </p>

                <div class="fx-form-group" *ngIf="errorMessage">
                    <div class="fx-form-validation-panel">
                        <div class="fx-form-validation-panel-item">{{ errorMessage }}</div>
                    </div>
                </div>

                <div class="fx-form-group">
                    <label class="fx-form-label" for="name">Full name</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="name"
                            id="name"
                            class="fx-form-input"
                            type="text"
                            placeholder="eg. John Doe"
                            aria-label="Full name"
                            [ngClass]="{
                                'fx-form-input--error': !form.controls['name'].valid && form.controls['name'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched"
                    >
                        Full name is required.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>

                <div class="fx-form-group">
                    <label class="fx-form-label" for="emailAddress">Email</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="emailAddress"
                            id="emailAddress"
                            class="fx-form-input"
                            type="text"
                            placeholder="eg. john.doe@gmail.com"
                            aria-label="Email"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['emailAddress'].valid && form.controls['emailAddress'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="
                            form.controls['emailAddress'].hasError('required') && form.controls['emailAddress'].touched
                        "
                    >
                        Email is required.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="form.controls['emailAddress'].hasError('email') && form.controls['emailAddress'].touched"
                    >
                        Email is invalid.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>

                <div class="fx-form-group ">
                    <button
                        class="fx-onboarding-submit fx-onboarding-button fx-btn"
                        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                        [disabled]="!form.valid"
                        analyticsClick="onboarding_getstarted"
                        type="submit"
                    >
                        {{ textResource.signupAction }}
                    </button>
                    <div class="fx-form-disclaimer">
                        By clicking '{{ textResource.signupAction }}' you accept <br />{{ appName }}'s
                        <a
                            *ngIf="termsUrl"
                            [href]="termsUrl"
                            target="_blank"
                            class="fx-form-disclaimer-link"
                            analyticsClick="onboarding_terms"
                            role="button"
                            >Terms</a
                        >
                        <ng-container *ngIf="termsUrl && policyUrl"> &amp; </ng-container>
                        <a
                            *ngIf="policyUrl"
                            [href]="policyUrl"
                            target="_blank"
                            class="fx-form-disclaimer-link"
                            analyticsClick="onboarding_privacy"
                            role="button"
                            >Privacy Policy</a
                        >
                        <ng-container *ngIf="(termsUrl || policyUrl) && termsOfBusinessUrl"> &amp; </ng-container>
                        <a
                            *ngIf="termsOfBusinessUrl"
                            [href]="termsOfBusinessUrl"
                            target="_blank"
                            class="fx-form-disclaimer-link"
                            analyticsClick="onboarding_termsofbusiness"
                            role="button"
                            >Terms of Business</a
                        >
                        <ng-container *ngIf="(termsUrl || policyUrl || termsOfBusinessUrl) && disclaimerUrl">
                            &amp;
                        </ng-container>
                        <a
                            *ngIf="disclaimerUrl"
                            [href]="disclaimerUrl"
                            target="_blank"
                            class="fx-form-disclaimer-link"
                            analyticsClick="onboarding_disclaimer"
                            role="button"
                            >Disclaimer and Disclosure</a
                        >
                    </div>
                </div>
            </form>
            <div class="fx-onboarding-actions">
                Already have an account?
                <a
                    class="fx-onboarding-bottom-link"
                    [routerLink]="['/login']"
                    analyticsClick="onboarding_login"
                    [queryParams]="{ emailAddress: form.controls['emailAddress'].value }"
                    >LOGIN</a
                >
            </div>
        </div>
    </div>
</div>
