import { Component, Inject } from "@angular/core";
import { DateTime } from "luxon";
import { Loader } from "src/app/services/loader";
import { ThreadsService } from "src/app/services/threads.service";
import { ThreadTypes, IThread, SubjectType, Role } from "@findex/threads";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { SigmaService } from "src/app/services/sigma.service";

@Component({
    selector: "new-thread",
    templateUrl: "./new-thread-modal.component.html",
    styleUrls: ["./new-thread-modal.component.scss"]
})
export class NewThreadModal {
    loader = new Loader();
    thread: IThread;
    newThreadTypes = ["Setup", "Catch Up", "Monthly"];
    newThreadMonths = this.generateMonths();
    currentType: string;
    currentMonth: string = this.newThreadMonths[3];

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        public dialogRef: MatDialogRef<NewThreadModal>,
        private threadsService: ThreadsService,
        private sigmaService: SigmaService
    ) {
        this.thread = data.thread;
    }

    setThreadType(threadType: string) {
        this.currentType = threadType;
    }

    async createThread() {
        this.loader.show();

        const title = this.currentType === "Monthly" ? this.currentMonth : this.currentType;
        const newThread = await this.threadsService.createThread(ThreadTypes.Bookkeeping, title).toPromise();

        const participants$ = this.thread.participants.map(({ id, role }) =>
            this.addParticipant(newThread.id, id, role)
        );
        await Promise.all(participants$);

        this.loader.hide();
        this.dialogRef.close(true);
    }

    private async addParticipant(threadId: string, participantId: string, role: Role): Promise<void> {
        await Promise.all([
            this.threadsService.putParticipant(threadId, participantId, role).toPromise(),
            this.addToDashboard(threadId, participantId)
        ]);
    }

    private async addToDashboard(threadId: string, participantId: string): Promise<void> {
        const dashboardId = await this.sigmaService.getDashboard(participantId).toPromise();
        const cards = await this.threadsService.getCards(dashboardId).toPromise();

        const dashboardCard = cards.find(card => card.type === SubjectType.Thread);
        if (!dashboardCard) return;

        const { subjects, id: cardId } = dashboardCard;

        //Check if already contains thread, should never, but just to be safe
        if (subjects.some(subject => subject.id === threadId && subject.type === SubjectType.Thread)) {
            return;
        }

        subjects.push({ id: threadId, type: SubjectType.Thread });
        await this.threadsService.updateCard(dashboardId, cardId, subjects).toPromise();
    }

    private generateMonths(): string[] {
        const today = DateTime.local();
        const months = [];

        for (let i = -2; i < 2; i++) {
            const monthString = today.plus({ month: i }).toFormat("LLLL yyyy");
            months.push(monthString);
        }

        return months;
    }
}
