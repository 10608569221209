<loader-icon *ngIf="loader.counter" [color]="'dark'"></loader-icon>
<modal-layout (close)="close()" [header]="'Request meeting'" [footer]="false">
    <div modal-body>
        <threads-input id="meetingName" (currentValue)="meetingName = $event" [placeholder]="'Meeting Name'"
            >Meeting Name</threads-input
        >

        <div class="fx-form-group attendees">
            <label class="fx-form-label">Attendees</label>

            <div class="attendees-add-button" (click)="addAttendee()" analyticsClick="calendar_addattendee">
                <i class="las la-plus"></i> ADD
            </div>

            <div class="attendees-added">
                <div class="attendees-attendee" *ngFor="let attendee of attendees">
                    <user-detail-card [userId]="attendee.id" [currentUserId]="currentUserId"></user-detail-card>
                    <div
                        *ngIf="currentUserId !== attendee.id"
                        class="attendees-attendee-remove"
                        (click)="remove(attendee.id)"
                    >
                        <i class="las la-times"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="fx-form-group duration">
            <label class="fx-form-label">Meeting duration</label>

            <div class="duration-selector">
                <div
                    *ngFor="let timeSlot of timeSlots"
                    [ngClass]="{ selected: duration === timeSlot.durationMinutes }"
                    analyticsClick="calendar_settime"
                    [analyticsLabel]="timeSlot.description"
                    (click)="duration = timeSlot.durationMinutes"
                >
                    {{ timeSlot.description }}
                </div>
            </div>
        </div>
        <button
            class="fx-btn fx-btn--full-width"
            [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
            role="button"
            (click)="create()"
            [disabled]="attendees.length < 2 || !meetingName"
            analyticsClick="calendar_createinvitation"
        >
            Create
        </button>
    </div>
</modal-layout>

<!-- <button class="upload-rfi-close" [mat-dialog-close]="true"><i class="las la-times"></i></button>
<app-add-invitation (createdInvitation)="createCard($event)"></app-add-invitation> -->
