import { Pipe, PipeTransform } from "@angular/core";
import { ParticipantCache } from "../services/participant-cache.service";
import { Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { AuthService } from "src/modules/findex-auth";

@Pipe({ name: "userToName" })
export class UserToNamePipe implements PipeTransform {
    constructor(private participantCache: ParticipantCache, private authService: AuthService) {}

    transform(userId: string): Observable<string> {
        return this.participantCache
            .getParticipant(userId)
            .pipe(switchMap(participant => this.cleanName(participant.profile.name)));
    }

    private cleanName(name: string): Observable<string> {
        if (name) {
            return of(name);
        } else {
            return this.authService.getUser().pipe(
                take(1),
                map(user => user.name)
            );
        }
    }
}
