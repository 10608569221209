import { environment } from "./../../../../environments/environment";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { AnalyticsService } from "src/modules/analytics";
import { merge, Subject } from "rxjs";
import { delay, mapTo, startWith } from "rxjs/operators";
import { LoginChallengeResult } from "../../model/LoginStep";

@Component({
    selector: "app-two-factor-authentication-form",
    templateUrl: "./two-factor-authentication-form.component.html",
    styleUrls: ["./two-factor-authentication-form.component.scss"]
})
export class TwoFactorAuthenticationFormComponent implements OnInit {
    errorMessage: string = "";

    showLoader: boolean = false;
    showBack: boolean = false;
    readonly theme = environment.theme;
    supportEmail?: string;

    form = new FormGroup({
        verifyCode: new FormControl(null, [
            Validators.required,
            Validators.pattern("[0-9]{6}"),
            Validators.maxLength(6),
            Validators.minLength(6)
        ])
    });

    @Input() userDetails: any;
    @Output() codeEntered: EventEmitter<void> = new EventEmitter<void>();
    rememberDevice = false;

    private resendClicked = new Subject<true>();
    disableResend$ = merge(
        this.resendClicked.pipe(startWith(false)),
        this.resendClicked.pipe(delay(10000), mapTo(false))
    );

    constructor(private authService: AuthService, private analyticsService: AnalyticsService) {
        this.supportEmail = environment.featureFlags.supportEmail;
    }

    ngOnInit() {
        this.form.setValue({
            verifyCode: ""
        });
    }

    resendCode() {
        this.resendClicked.next(true);
        //trigger resend by sending invalid code
        this.submitCode("1234");
    }

    submitCode(verifyCode?: string) {
        const code = verifyCode ? verifyCode : `${this.form.controls.verifyCode.value}`;
        this.showLoader = true;
        this.authService.completeTwoFactor(code, this.rememberDevice).subscribe(
            (result: any) => {
                switch (result) {
                    case LoginChallengeResult.SUCCESS:
                        this.recordAnalyticsEvent("success");
                        this.codeEntered.emit();
                        break;
                    case LoginChallengeResult.FAILED_ATTEMPT:
                        this.recordAnalyticsEvent("unknown-error");
                        this.errorMessage = verifyCode
                            ? "A new code was sent, please try again."
                            : "The code was incorrect - a new code was sent, please try again.";
                        break;
                    case LoginChallengeResult.TIMEOUT:
                        this.recordAnalyticsEvent("timed-out");
                        this.errorMessage = "Your login timed out, please log in again.";
                        this.showBack = true;
                        break;
                    case LoginChallengeResult.DENIED:
                        this.recordAnalyticsEvent("access-denied");
                        this.errorMessage = "You have failed too many times, please log in again.";
                        this.showBack = true;
                        break;
                }
                this.showLoader = false;
            },
            () => {
                this.recordAnalyticsEvent("unknown-error");
                this.errorMessage = "An error occurred, please try again.";
                this.showLoader = false;
            }
        );
    }

    toggleRememberDevice(status: boolean) {
        this.rememberDevice = status;
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("two-factor-auth", category);
    }
}
