import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Role } from "@findex/threads";

export type Document = {
    description: string;
    file: File;
    signature?: boolean;
};

@Component({
    selector: "create-card-document",
    templateUrl: "./create-card-document.component.html",
    styleUrls: ["./create-card-document.component.scss"]
})
export class CreateCardDocumentComponent implements OnInit {
    @Input() role: Role;
    @Input() documents: Document[];
    @Output() remove = new EventEmitter<Document>();
    @Output() rename = new EventEmitter<Document>();

    roles = Role;

    constructor() {}

    ngOnInit() {}
}
