import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NewThreadModal } from "./components/new-thread-modal/new-thread-modal.component";
import { FindexUiModule } from "../findex-ui";

@NgModule({
    imports: [CommonModule, FindexUiModule],
    declarations: [NewThreadModal],
    entryComponents: [NewThreadModal]
})
export class SigmaModule {}
