<fx-card
    title="Conference"
    [name]="card.createdBy | userToName | async"
    [timestamp]="card.modifiedAt"
    [invertTheme]="(userId$ | async) === card.createdBy"
>
    <loader-icon *ngIf="loader.counter" [color]="'dark'"></loader-icon>
    <ng-container *ngIf="vcDetails$ | async; let details">
        <meeting-details
            *ngIf="details.sessionId"
            [status]="details.status"
            [timestamp]="details.timestamp | date: 'medium'"
            [inverted]="(userId$ | async) === details.actor"
            (join)="openFullscreen(true)"
            (terminate)="terminateSession()"
            [sessionTerminated]="details.isTerminated"
            [role]="role"
            [threadState]="thread.state"
        ></meeting-details>
    </ng-container>
</fx-card>
