import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({ providedIn: "root" })
export class LoggedInGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(): Observable<any> {
        return this.authService.getUser().pipe(
            switchMap(user => {
                if (user) {
                    this.router.navigateByUrl("/dashboard");
                    return of(false);
                } else {
                    return of(true);
                }
            })
        );
    }
}
