import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { Role } from "@findex/threads";
import * as Sentry from "@sentry/browser";
import { Observable, of, Subscription, zip } from "rxjs";
import { distinctUntilChanged, filter, map, switchMap, take, tap } from "rxjs/operators";
import { ThemeService } from "src/app/services/theme.service";
import { CalendarCardComponent, CreateInvitationComponent } from "src/modules/card-modules/calendar-card";
import { CreateMessageComponent, MessageEventComponent } from "src/modules/card-modules/message-card";
import { ThreadCardComponent } from "src/modules/card-modules/thread-card";
import { CreateRfiComponent, VaultCardComponent } from "src/modules/card-modules/vault-card";
import { VcCardComponent, VideoChatService } from "src/modules/card-modules/video-chat-card";
import { User } from "src/modules/findex-auth/model/User";
import { AuthService } from "src/modules/findex-auth/services/auth.service";
import { OnboardingService, SignupBusinessPayload } from "src/modules/onboarding/services/onboarding.service";
import { ActivePackageDetails, UserProfileService } from "src/modules/user-profile/services/user-profile.service";
import { environment } from "../environments/environment";
import { ClientProfileCardComponent } from "../modules/card-modules/client-profile-card";
import { SubscriptionDetails, SubscriptionService } from "./../modules/user-profile/services/subscription.service";
import { AnalyticsService } from "src/modules/analytics";
import { CardComponentRegistry } from "./services/card-component.registry";
import { Loader } from "./services/loader";
import { SlideMenuService } from "./services/slide-menu.service";
import { ThreadsService } from "./services/threads.service";
import { TourService } from "./services/tour.service";
import { ThreadStateService } from "src/modules/threads-ui/services/thread-state.service";
import { SyncFilesModalComponent } from "src/modules/samedaytax";
import { CloseAction } from "src/environments/environment.common";
import { NewThreadModal } from "src/modules/sigma";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
    roles = Role;

    role: Role;
    user$: Observable<User>;
    showMenu$: Observable<boolean>;
    userOnboardingProfile$: Observable<SignupBusinessPayload>;
    hideLayout: boolean;
    subscription$: Observable<ActivePackageDetails>;
    readonly subscriptionEnabled = environment.featureFlags.subscriptionEnabled;
    readonly packageDisplayName = environment.featureFlags.packageDisplayName;
    supportEmail = environment.featureFlags.supportEmail;

    private roleSub: Subscription;

    constructor(
        mapperRegistry: CardComponentRegistry,
        public loader: Loader,
        private authService: AuthService,
        createChatService: VideoChatService,
        private threadsService: ThreadsService,
        private analyticsService: AnalyticsService,
        private onboardingService: OnboardingService,
        private subscriptionService: SubscriptionService,
        private router: Router,
        private slideMenuService: SlideMenuService,
        themer: ThemeService,
        threadStateService: ThreadStateService,
        private tourService: TourService,
        private userProfileService: UserProfileService
    ) {
        this.showMenu$ = slideMenuService.showMenu$;

        mapperRegistry.registerCard("vault", VaultCardComponent);
        mapperRegistry.registerCard("message", MessageEventComponent);
        mapperRegistry.registerCard("calendar", CalendarCardComponent);
        mapperRegistry.registerCard("video-chat", VcCardComponent);
        mapperRegistry.registerCard("thread", ThreadCardComponent);
        mapperRegistry.registerCard("client-profile", ClientProfileCardComponent);

        mapperRegistry.registerAction("message", () => of(CreateMessageComponent));
        mapperRegistry.registerAction("calendar", () => of(CreateInvitationComponent));
        mapperRegistry.registerAction("rfi", () => of(CreateRfiComponent));
        mapperRegistry.registerAction("video-chat", thread => createChatService.createChat(thread.id));

        threadStateService.setCloseAction(CloseAction.SameDayFileSync, SyncFilesModalComponent, {
            position: { top: "0px" },
            height: "100vh",
            maxWidth: "100vw",
            panelClass: ["mat-dialog-no-styling", "threads-sidebar"],
            disableClose: true
        });

        threadStateService.setCloseAction(CloseAction.SigmaNewThread, NewThreadModal, {
            panelClass: "mat-dialog-overflow",
            disableClose: true
        });

        // case CloseAction.SameDayFileSync:
        //         return this.syncFilesDialog(thread, role);
        //     case CloseAction.SigmaNewThread:
        //         return this.openNewThreadDialog(thread, role);
        //     case CloseAction.None:

        themer.apply();

        this.setupWindowFocusListeners();
    }

    private setupWindowFocusListeners() {
        window.addEventListener(
            "focus",
            () => {
                this.analyticsService.recordEvent("window-attention", "focus");
            },
            false
        );
        window.addEventListener(
            "blur",
            () => {
                this.analyticsService.recordEvent("window-attention", "blur");
            },
            false
        );
    }

    ngOnInit() {
        this.loader.show();
        setTimeout(() => this.loader.hide());

        this.userOnboardingProfile$ = this.authService.getUser().pipe(
            filter(user => !!user),
            switchMap(user => this.onboardingService.getCompletionDetails(user.id))
        );

        this.subscription$ = this.subscriptionService.getCurrentUserSubscriptionDetails().pipe(
            map((sub: SubscriptionDetails) => {
                if (sub) {
                    const { currentPackage, activeSubscription } = sub;

                    if (currentPackage) {
                        return { ...currentPackage, ...activeSubscription };
                    }
                    return null;
                }
                return null;
            })
        );

        this.user$ = this.authService.getUser().pipe(tap(user => this.setSentryScope(user)));

        const role = this.user$.pipe(
            filter(user => !!user),
            switchMap(user => {
                return this.loader.wrap(this.threadsService.getGlobalRole(user.id));
            })
        );

        this.roleSub = role.subscribe(role => (this.role = role));

        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {
                this.hideLayout =
                    event.url.includes("/register") ||
                    event.url.includes("/select-bookkeeper") ||
                    event.url.includes("/select-staff") ||
                    event.url.includes("/onboarding-profile");
            }
        });

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                switchMap(event =>
                    event instanceof NavigationEnd &&
                    environment.featureFlags.introductionTour &&
                    event.url.includes(environment.featureFlags.introductionTour.tourRoute)
                        ? this.user$.pipe(
                              filter(user => !!user),
                              distinctUntilChanged((prev, next) => prev.id === next.id),
                              take(1)
                          )
                        : of(null)
                )
            )
            .subscribe(user => {
                if (user) {
                    this.tourService.initializeTour(user, environment.featureFlags.introductionTour);
                } else {
                    this.tourService.hideTour();
                }
            });

        this.authService
            .onLoginSuccess()
            .pipe(
                filter(user => !!user),
                switchMap((user: User) => {
                    const updateLastLogin$ = this.userProfileService.updateUserLastLogin(user.id);
                    const updateUserTimeZone$ = this.threadsService.updateUserTimeZone(user.id);
                    return zip(updateLastLogin$, updateUserTimeZone$);
                })
            )
            .subscribe(() => {});

        this.authService.onRedirect().subscribe(() => {});
    }

    ngOnDestroy() {
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
    }

    async logout() {
        this.loader.show();
        this.slideMenuService.hide();

        try {
            this.analyticsService.recordEvent("logout", "clicked");
            await this.authService.logout().toPromise();
            this.router.navigateByUrl("/login");
        } finally {
            this.loader.hide();
        }
    }

    menuAction() {
        this.slideMenuService.toggle();
        this.analyticsService.recordEvent("side-menu", "toggle");
    }

    private setSentryScope(user: User) {
        if (!user) return;

        const scopeUser = {
            id: user.id,
            email: user.details.emailAddress,
            username: user.name
        };

        Sentry.configureScope(scope => scope.setUser(scopeUser));
    }
}
